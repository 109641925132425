import React, { useEffect, useRef } from "react";
import "./CheckOutsideClick.scss";

const CheckOutsideClick = React.forwardRef((props, ref) => {
  const currentRef = useRef(null);
  const { onClickOutside, children } = props;

  const handleClickOutside = (event) => {
    if (
      currentRef.current &&
      !currentRef.current.contains(event.target) &&
      !ref.current.contains(event.target)
    ) {
      onClickOutside && onClickOutside();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
    /*eslint-disable */
  }, []);

  if (!children) {
    return null;
  }
  return (
    <div className="minut-hour-cnt" ref={currentRef}>
      {children}
    </div>
  );
});

export default CheckOutsideClick;
