import React, { useCallback, useEffect, useState } from "react";
import InvoiceSuccessModel from "../InvoicesSuccessModel/InvoicesSuccessModel";
import "./ReportUsageTable.scss";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import PreInvoice from "../PreInvoice/PreInvoice";
import InfoIcon from "../../../assets/images/info-icon.png";
import ContractPdfLink from "../ContractPdfLink/ContractPdfLink";
import { FeatureFlags } from "../../common/FeatureFlags/FeatureFlags";

const ReportUsageTable = ({ contracts }) => {
  const { configData } = useSelector((state) => state.config);

  //reportusagehours format
  // {
  //   contractNumber: "123",
  //   tails: [
  //     {
  //       tailNumber: "SUV123",
  //       engines: [
  //         {
  //           engineName: "AIRFRAME",
  //           hours: 123,
  //           type: "AIRFRAME",
  //           beginTsn: "",
  //           endTsn: "",
  //           endTsnFlag: false,
  //         },
  //         {
  //           engineName: "APU",
  //           hours: 456,
  //           type: "APU",
  //           beginTsn: "",
  //           endTsn: "",
  //           endTsnFlag: false,
  //         },
  //       ],
  //     },
  //   ],
  // }
  const [reportUsageHours, setReportUsageHours] = useState([]);
  const [rentalInstalledState] = useState([
    {
      contractNumber: "456",
      tails: [
        {
          tailNumber: "SUV456",
          engines: [
            { engineName: "A456", type: "Engine-2", rentalInstalled: false },
          ],
        },
      ],
    },
  ]);
  const [rentalStoreState, setRentalStoreState] = useState([
    {
      contractNumber: "456",
      tails: [
        {
          tailNumber: "SUV1234",
          engines: [
            {
              engineName: "A1234",
              type: "Engine-2",
              isRentalInstalled: false,
              rentalHours: 4,
              rentalSelectType: "",
              rentalEngineSerialNumber: "",
            },
          ],
        },
      ],
    },
  ]);
  const [multipleContracts, setMultipleContracts] = useState([]);
  const [showRentalInstalled, setShowRentalInstalled] = useState(false);
  const [, setContractName] = useState("");
  const [rentalEngine, setRentalEngine] = useState("");
  const [rentalType, setRentalType] = useState("");
  const [rentalEngineSerialNumber, setRentalEngineSerialNumber] = useState("");
  const [rentalUsageHours, setRentalUsageHours] = useState("");
  const [, setUsageHoursFlag] = useState(false);
  const [endTSNValidation, showEndTSNValidation] = useState([]);
  const [rentalValidation, setRentalValidation] = useState([]);
  const [show, setShow] = useState(false);
  const [headerMessage, setHeaderMessage] = useState("");
  const [bodyMessage, setBodyMessage] = useState("");
  const [showPreInvoice, setShowPreInvoice] = useState(false);
  const [preInvoiceContract, setPreInvoiceContract] = useState("");
  const [contract_number, setContractNumber] = useState("");
  const [soldtoNumber, setSoldToNumber] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [maxHoursValidation, setMaxHoursValidation] = useState([]);
  const [rentalStoreClearValues, setRentalStoreClearValues] = useState({
    rentalClear: false,
    contractNo: "",
    tailNo: "",
    engineNumber: "",
  });
  const [rentalValidationValues, setRentalValidationValues] = useState({
    rentalValidate: false,
    contractNo: "",
    tailNo: "",
    engineNumber: "",
    engineValue: "",
    engineType: "",
  });
  const wingxFeature = FeatureFlags.find(
    (feature) => feature.name === "WingxData"
  );
  const [loadingWingexData, setLoadingWingexData] = useState(
    wingxFeature?.active ? true : false
  );

  const handleClose = () => {
    setShow(false);
    setHeaderMessage("");
    setBodyMessage("");
  };

  const showPreInvoiceHandler = (contract) => {
    setShowPreInvoice(true);
    setPreInvoiceContract(contract);
  };

  const closePreInvoiceHandler = () => {
    setShowPreInvoice(false);
  };
  const showRentalInstalledHandler = (data) => {
    const contractData = JSON.parse(data);
    setRentalEngine(contractData.engineSerialNumber);
    const rentalProductContract = rentalStoreState?.find(
      (contract) => contract.contractNumber === contractData.contractNo
    );
    const rentalProductTail = rentalProductContract?.tails.find(
      (tailData) => tailData.tailNumber === contractData.tailNo
    );
    const rentalProductEngine = rentalProductTail?.engines.find(
      (engine) => engine.engineName === contractData.engineSerialNumber
    );
    if (rentalProductEngine) {
      setRentalType(rentalProductEngine.rentalSelectType);
      setRentalEngineSerialNumber(rentalProductEngine.rentalEngineSerialNumber);
      setRentalUsageHours(rentalProductEngine.rentalHours);
    } else {
      setRentalType("");
      setRentalEngineSerialNumber("");
      setRentalUsageHours("");
    }
    setShowRentalInstalled(true);
  };

  const getEngineHours = (contractNumber, tailNumber, engineName) => {
    const calculateEngineContract = reportUsageHours?.find(
      (contract) => String(contract.contractNumber) === String(contractNumber)
    );
    const calculateEngineTail = calculateEngineContract?.tails?.find(
      (tailDetails) => tailDetails?.tailNumber === tailNumber
    );
    const engineData = calculateEngineTail?.engines?.find(
      (engineDetails) => engineDetails?.engineName === engineName
    );

    return engineData?.hours;
  };

  const getUnAssociatedContractDetails = (contractNumber, tailNumber) => {
    const getUnAssociatedContract = contracts?.find(
      (contract) => contract?.contractNumber === parseInt(contractNumber)
    );
    const tailLength = getUnAssociatedContract?.tailDetails?.length;
    let tailCount = 0;
    getUnAssociatedContract?.tailDetails?.forEach((tailData) => {
      if (!tailData?.productDetails) {
        tailCount += 1;
      }
    });
    return tailLength - tailCount === 1 && tailCount > 0 ? true : false;
  };

  const rentalStoreValidationHandler = (
    contractNumber,
    tailNumber,
    engineName,
    rentalEngineValue,
    engineType
  ) => {
    const airframeHours = getTailAirframeHours(contractNumber, tailNumber);
    const engineHours = getEngineHours(contractNumber, tailNumber, engineName);
    const engineRentalValue = rentalEngineValue;
    const engineTotal = parseFloat(
      (engineHours + engineRentalValue).toFixed(2)
    );

    if (engineHours + engineRentalValue > 999) {
      setMaxHoursValidation((maxHoursData) => [...maxHoursData, engineName]);
    } else {
      setMaxHoursValidation((prevValue) =>
        prevValue.filter((engineData) => engineData !== engineName)
      );
    }

    const unAssociatedRentalContractEngine = getUnAssociatedContractDetails(
      contractNumber,
      tailNumber
    );
    if (
      engineHours >= 0 &&
      engineRentalValue >= 0 &&
      airframeHours === engineTotal &&
      engineType !== "AIRFRAME" &&
      engineType !== "APU" &&
      !unAssociatedRentalContractEngine
    ) {
      setRentalValidation(
        rentalValidation?.filter((rentalValue) => rentalValue !== engineName)
      );
    } else if (
      engineHours >= 0 &&
      engineRentalValue >= 0 &&
      airframeHours !== engineTotal &&
      engineType !== "AIRFRAME" &&
      engineType !== "APU" &&
      !unAssociatedRentalContractEngine
    ) {
      setRentalValidation((rentalValidation) => [
        ...rentalValidation,
        engineName,
      ]);
    }
  };

  const closeRentalInstalledHandler = (data) => {
    const contractData = JSON.parse(data);
    let rentalStoreStateCopy = [...rentalStoreState];
    if (rentalType && rentalEngineSerialNumber && rentalUsageHours) {
      rentalStoreValidationHandler(
        contractData?.contractNo,
        contractData?.tailNo,
        contractData.engineSerialNumber,
        rentalUsageHours,
        contractData?.type
      );
      const findContract = rentalStoreState.find(
        (contract) => contract.contractNumber === contractData.contractNo
      );

      const findContractIndex = rentalStoreState.findIndex(
        (contract) => contract.contractNumber === contractData.contractNo
      );
      if (findContract) {
        const findTail = findContract.tails.find(
          (tail) => tail.tailNumber === contractData.tailNo
        );
        if (findTail) {
          const findEngine = findTail.engines.find(
            (engine) => engine.engineName === contractData.engineSerialNumber
          );
          if (findEngine) {
            //Parsing Float
            findEngine.rentalHours = rentalUsageHours;
            //engine type
            findEngine.type = contractData.type;
            findEngine.isRentalInstalled = true;
            findEngine.rentalSelectType = rentalType;
            findEngine.rentalEngineSerialNumber = rentalEngineSerialNumber;
          } else {
            const engineLength = findTail.engines.length - 1;
            //engine type
            //Parsing Float
            findTail.engines[engineLength + 1] = {
              engineName: contractData.engineSerialNumber,
              type: contractData.type,
              isRentalInstalled: true,
              rentalSelectType: rentalType,
              rentalEngineSerialNumber: rentalEngineSerialNumber,
              rentalHours: rentalUsageHours,
            };
          }
        } else {
          const tailsLength = findContract.tails.length - 1;
          //engine type
          //Parsing Float
          findContract.tails[tailsLength + 1] = {
            tailNumber: contractData.tailNo,
            engines: [
              {
                engineName: contractData.engineSerialNumber,
                type: contractData.type,
                isRentalInstalled: true,
                rentalSelectType: rentalType,
                rentalEngineSerialNumber: rentalEngineSerialNumber,
                rentalHours: rentalUsageHours,
              },
            ],
          };
        }
        rentalStoreStateCopy[findContractIndex] = findContract;

        setRentalStoreState(rentalStoreStateCopy);
      } else {
        setRentalStoreState((prevValue) => {
          //engine type
          //Parsing Float
          return [
            ...prevValue,
            {
              contractNumber: contractData.contractNo,
              tails: [
                {
                  tailNumber: contractData.tailNo,
                  engines: [
                    {
                      engineName: contractData.engineSerialNumber,
                      type: contractData.type,
                      isRentalInstalled: true,
                      rentalSelectType: rentalType,
                      rentalEngineSerialNumber: rentalEngineSerialNumber,
                      rentalHours: rentalUsageHours,
                    },
                  ],
                },
              ],
            },
          ];
        });
      }
    } else {
      //set error
      rentalStoreValidationHandler(
        contractData?.contractNo,
        contractData?.tailNo,
        contractData.engineSerialNumber,
        0,
        contractData?.type
      );
      if (
        rentalType === "select" &&
        !rentalUsageHours &&
        !rentalEngineSerialNumber
      ) {
        const findContract = rentalStoreState.find(
          (contract) => contract.contractNumber === contractData.contractNo
        );

        const findContractIndex = rentalStoreState.findIndex(
          (contract) => contract.contractNumber === contractData.contractNo
        );
        if (findContract) {
          const findTail = findContract.tails.find(
            (tail) => tail.tailNumber === contractData.tailNo
          );
          const tailLength = findContract?.tails.length;
          if (findTail) {
            const findEngine = findTail.engines.find(
              (engine) => engine.engineName === contractData.engineSerialNumber
            );
            if (findEngine) {
              const engineLength = findTail?.engines.length;
              if (engineLength <= 1 && tailLength <= 1) {
                rentalStoreStateCopy = rentalStoreState.filter(
                  (data) => data.contractNumber !== contractData.contractNo
                );
                setRentalStoreState(rentalStoreStateCopy);
              } else if (engineLength > 1 && tailLength <= 1) {
                const tailData = findTail?.engines.filter(
                  (engine) =>
                    engine.engineName !== contractData.engineSerialNumber
                );
                findTail.engines = tailData;
                rentalStoreStateCopy[findContractIndex] = findContract;
                setRentalStoreState(rentalStoreStateCopy);
              } else if (tailLength > 1 && engineLength <= 1) {
                //code Remove Tail
                const contract_data = findContract?.tails?.filter(
                  (tail) => tail.tailNumber !== contractData?.tailNo
                );
                findContract.tails = contract_data;
                rentalStoreStateCopy[findContractIndex] = findContract;
                setRentalStoreState(rentalStoreStateCopy);
              } else if (tailLength > 1 && engineLength > 1) {
                //code Remove Engine from Tail
                const tail_data = findTail?.engines.filter(
                  (engine) =>
                    engine.engineName !== contractData.engineSerialNumber
                );
                findTail.engines = tail_data;
                rentalStoreStateCopy[findContractIndex] = findContract;
                setRentalStoreState(rentalStoreStateCopy);
              }
            }
          }
        }
      } else {
        const findContract = rentalStoreState.find(
          (contract) => contract.contractNumber === contractData.contractNo
        );

        const findContractIndex = rentalStoreState.findIndex(
          (contract) => contract.contractNumber === contractData.contractNo
        );
        if (findContract) {
          const findTail = findContract.tails.find(
            (tail) => tail.tailNumber === contractData.tailNo
          );
          if (findTail) {
            const findEngine = findTail.engines.find(
              (engine) => engine.engineName === contractData.engineSerialNumber
            );
            if (findEngine) {
              //Parsing Float
              findEngine.rentalHours = rentalUsageHours ? rentalUsageHours : 0;
              //engine type
              findEngine.type = contractData.type;
              findEngine.isRentalInstalled = true;
              findEngine.rentalSelectType =
                rentalType === "select" ? "" : rentalType;
              findEngine.rentalEngineSerialNumber = rentalEngineSerialNumber
                ? rentalEngineSerialNumber
                : "";
            } else {
              setShow(true);
              setHeaderMessage(`Incomplete rental values`);
              setBodyMessage(
                `Please fill all the rental installed fields for selected contract- ${contractData?.contractNo}, if you have entered the values bymistake please ignore this message`
              );
            }
          } else {
            setShow(true);
            setHeaderMessage(`Incomplete rental values`);
            setBodyMessage(
              `Please fill all the rental installed fields for selected contract- ${contractData?.contractNo}, if you have entered the values bymistake please ignore this message`
            );
          }
          rentalStoreStateCopy[findContractIndex] = findContract;
          setRentalStoreState(rentalStoreStateCopy);
        } else {
          setShow(true);
          setHeaderMessage(`Incomplete rental values`);
          setBodyMessage(
            `Please fill all the rental installed fields for selected contract- ${contractData?.contractNo}, if you have entered the values bymistake please ignore this message`
          );
        }
      }
    }
    setRentalType("");
    setRentalEngineSerialNumber("");
    setRentalUsageHours("");
    setShowRentalInstalled(false);
  };

  const getTailAirframeHours = (contractNumber, tailNumber) => {
    const calculateContract = reportUsageHours?.find(
      (contract) => String(contract.contractNumber) === String(contractNumber)
    );
    const calculateTail = calculateContract?.tails?.find(
      (tailDetails) => tailDetails?.tailNumber === tailNumber
    );
    const airframeData = calculateTail?.engines?.find(
      (engineDetails) => engineDetails?.type === "AIRFRAME"
    );
    return airframeData?.hours;
  };

  const rentalValidationHandler = (
    contractNumber,
    tailNumber,
    engineName,
    engineValue,
    engineType
  ) => {
    const airframeHours =
      getTailAirframeHours(contractNumber, tailNumber) >= 0
        ? getTailAirframeHours(contractNumber, tailNumber)
        : -1;
    const engineHours = engineValue;
    const findRentalContract = rentalStoreState?.find(
      (contract) => contract.contractNumber === contractNumber
    );

    const findRentalTail = findRentalContract?.tails?.find(
      (tail) => tail.tailNumber === tailNumber
    );
    const rentalContractEngineHours = findRentalTail?.engines?.find(
      (engineDetails) => engineDetails?.engineName === engineName
    )?.rentalHours;
    const engineTotal = parseFloat(
      (engineHours + rentalContractEngineHours).toFixed(2)
    );

    if (rentalContractEngineHours) {
      if (
        engineHours >= 0 &&
        airframeHours !== engineTotal &&
        engineType !== "AIRFRAME" &&
        engineType !== "APU"
      ) {
        setRentalValidation((rentalValidation) => [
          ...rentalValidation,
          engineName,
        ]);
      } else if (
        engineHours >= 0 &&
        airframeHours === engineTotal &&
        engineType !== "AIRFRAME" &&
        engineType !== "APU"
      ) {
        setRentalValidation((prevValue) =>
          prevValue?.filter((rentalValue) => rentalValue !== engineName)
        );
      }
    } else {
      if (
        engineHours >= 0 &&
        airframeHours !== engineHours &&
        engineType !== "AIRFRAME" &&
        engineType !== "APU"
      ) {
        setRentalValidation((rentalValidation) => [
          ...rentalValidation,
          engineName,
        ]);
      } else if (
        engineHours >= 0 &&
        airframeHours === engineHours &&
        engineType !== "AIRFRAME" &&
        engineType !== "APU"
      ) {
        //updated Rental Value
        setRentalValidation((prevValue) =>
          prevValue?.filter((rentalValue) => rentalValue !== engineName)
        );
      }
    }
  };

  const runRentalHandler = (contractNumber, tailNumber, airframeHours) => {
    const findContract = reportUsageHours?.find(
      (contract) => contract.contractNumber === contractNumber
    );
    const findTail = findContract?.tails?.find(
      (tail) => tail.tailNumber === tailNumber
    );
    const findRentalContract = rentalStoreState?.find(
      (contract) => contract.contractNumber === contractNumber
    );

    const findRentalTail = findRentalContract?.tails?.find(
      (tail) => tail.tailNumber === tailNumber
    );
    if (findTail?.engines?.length > 0) {
      findTail?.engines?.forEach((engineData) => {
        const rentalContractEngineHours = findRentalTail?.engines?.find(
          (engineDetails) =>
            engineDetails?.engineName === engineData?.engineName
        )?.rentalHours;
        const engineTotal = parseFloat(
          (engineData?.hours + rentalContractEngineHours).toFixed(2)
        );
        if (rentalContractEngineHours) {
          if (
            engineData?.type !== "APU" &&
            engineData?.type !== "AIRFRAME" &&
            airframeHours !== engineTotal
          ) {
            setRentalValidation((rentalValidation) => [
              ...rentalValidation,
              engineData?.engineName,
            ]);
          } else if (
            engineData?.type !== "APU" &&
            engineData?.type !== "AIRFRAME" &&
            airframeHours === engineTotal
          ) {
            setRentalValidation((prevValue) =>
              prevValue?.filter(
                (rentalValue) => rentalValue !== engineData?.engineName
              )
            );
          }
        } else {
          if (
            engineData?.type !== "APU" &&
            engineData?.type !== "AIRFRAME" &&
            airframeHours !== engineData?.hours
          ) {
            setRentalValidation((rentalValidation) => [
              ...rentalValidation,
              engineData?.engineName,
            ]);
          } else if (
            engineData?.type !== "APU" &&
            engineData?.type !== "AIRFRAME" &&
            airframeHours === engineData?.hours
          ) {
            setRentalValidation((prevValue) =>
              prevValue?.filter(
                (rentalValue) => rentalValue !== engineData?.engineName
              )
            );
          }
        }
        if (engineData?.hours + rentalContractEngineHours > 999) {
          setMaxHoursValidation((maxHoursData) => [
            ...maxHoursData,
            engineData?.engineName,
          ]);
        } else {
          setMaxHoursValidation((prevValue) =>
            prevValue.filter(
              (engineData) => engineData !== engineData?.engineName
            )
          );
        }
      });
    }
  };

  //executed on change of endtsn hours
  const endTsnHoursHandler = useCallback(
    (e) => {
      setUsageHoursFlag(false);
      const contractData = JSON.parse(e.target.name);
      const beginTsnValue = contractData.beginTsn;
      const endTsnValue = parseFloat(e.target.value);
      const unAssociatedContractEngine = getUnAssociatedContractDetails(
        contractData?.contractNo,
        contractData?.tailNo
      );

      const maxEngineHours = parseFloat(
        parseFloat(endTsnValue - beginTsnValue).toFixed(2)
      );
      if (maxEngineHours > 999) {
        setMaxHoursValidation((maxHoursValidation) => [
          ...maxHoursValidation,
          contractData.engineSerialNumber,
        ]);
      } else {
        setMaxHoursValidation((prevValue) =>
          prevValue.filter(
            (maxValue) => maxValue !== contractData.engineSerialNumber
          )
        );
      }

      !unAssociatedContractEngine &&
        setRentalValidationValues({
          rentalValidate: true,
          contractNo: contractData?.contractNo,
          tailNo: contractData?.tailNo,
          engineNumber: contractData.engineSerialNumber,
          engineValue: parseFloat(
            parseFloat(endTsnValue - beginTsnValue).toFixed(2)
          ),
          engineType: contractData?.type,
        });
      if (endTsnValue < beginTsnValue) {
        showEndTSNValidation((endTSNValidation) => [
          ...endTSNValidation,
          contractData?.engineSerialNumber,
        ]);
      } else {
        showEndTSNValidation(
          endTSNValidation?.filter(
            (val) => val !== contractData?.engineSerialNumber
          )
        );
      }

      setContractName(contractData.contractNo);
      // const rentalInstalledEngineContract = contracts?.find(
      //   (contract) =>
      //     String(contract.contractNumber) === contractData.contractNo
      // );
      const reportUsageHoursCopy = [...reportUsageHours];

      if (e.target.value.length > 0) {
        const findContract = reportUsageHours.find(
          (contract) => contract.contractNumber === contractData.contractNo
        );
        const findContractIndex = reportUsageHours.findIndex(
          (contract) => contract.contractNumber === contractData.contractNo
        );
        if (findContract) {
          const findTail = findContract.tails.find(
            (tail) => tail.tailNumber === contractData.tailNo
          );
          if (findTail) {
            const findEngine = findTail.engines.find(
              (engine) => engine.engineName === contractData.engineSerialNumber
            );
            if (findEngine) {
              //Parsing Float
              findEngine.hours = parseFloat(
                parseFloat(endTsnValue - beginTsnValue).toFixed(2)
              );
              //engine type
              findEngine.type = contractData.type;
              findEngine.beginTsn = contractData.beginTsn;
              findEngine.endTsn = endTsnValue;
              findEngine.endTsnFlag = false;
            } else {
              const engineLength = findTail.engines.length - 1;
              //engine type
              //Parsing Float
              findTail.engines[engineLength + 1] = {
                engineName: contractData.engineSerialNumber,
                hours: parseFloat(
                  parseFloat(endTsnValue - beginTsnValue).toFixed(2)
                ),
                type: contractData.type,
                beginTsn: contractData.beginTsn,
                endTsn: endTsnValue,
                endTsnFlag: false,
              };
            }
          } else {
            const tailsLength = findContract.tails.length - 1;
            //engine type
            //Parsing Float
            findContract.tails[tailsLength + 1] = {
              tailNumber: contractData.tailNo,
              engines: [
                {
                  engineName: contractData.engineSerialNumber,
                  hours: parseFloat(
                    parseFloat(endTsnValue - beginTsnValue).toFixed(2)
                  ),
                  type: contractData.type,
                  beginTsn: contractData.beginTsn,
                  endTsn: endTsnValue,
                  endTsnFlag: false,
                },
              ],
            };
          }
          reportUsageHoursCopy[findContractIndex] = findContract;
          setReportUsageHours(reportUsageHoursCopy);
        } else {
          setReportUsageHours((prevValue) => {
            //engine type
            //Parsing Float
            return [
              ...prevValue,
              {
                contractNumber: contractData.contractNo,
                tails: [
                  {
                    tailNumber: contractData.tailNo,
                    engines: [
                      {
                        engineName: contractData.engineSerialNumber,
                        hours: parseFloat(
                          parseFloat(endTsnValue - beginTsnValue).toFixed(2)
                        ),
                        type: contractData.type,
                        beginTsn: contractData.beginTsn,
                        endTsn: endTsnValue,
                        endTsnFlag: false,
                      },
                    ],
                  },
                ],
              },
            ];
          });
        }
      } else if (e.target.value.length <= 0) {
        const findContract = reportUsageHours?.find(
          (contract) => contract.contractNumber === contractData.contractNo
        );
        const findContractIndex = reportUsageHours?.findIndex(
          (contract) => contract.contractNumber === contractData.contractNo
        );
        const reportUsageHoursArray = [...reportUsageHours];
        if (findContract) {
          const findTail = findContract?.tails?.find(
            (tail) => tail.tailNumber === contractData.tailNo
          );
          const tailLength = findContract?.tails.length;
          if (findTail) {
            const findEngine = findTail?.engines.find(
              (engine) => engine.engineName === contractData.engineSerialNumber
            );
            if (findEngine) {
              const engineLength = findTail?.engines.length;
              let reportUsageHoursCopy;
              setRentalValidation((prevValue) =>
                prevValue?.filter(
                  (rentalValue) => rentalValue !== findEngine?.engineName
                )
              );
              if (engineLength <= 1 && tailLength <= 1) {
                reportUsageHoursCopy = reportUsageHours.filter(
                  (data) => data.contractNumber !== contractData.contractNo
                );
                setReportUsageHours(reportUsageHoursCopy);
              } else if (engineLength > 1 && tailLength <= 1) {
                const tailData = findTail?.engines.filter(
                  (engine) =>
                    engine.engineName !== contractData.engineSerialNumber
                );
                findTail.engines = tailData;
                reportUsageHoursArray[findContractIndex] = findContract;
                setReportUsageHours(reportUsageHoursArray);
              } else if (tailLength > 1 && engineLength <= 1) {
                //code Remove Tail
                const contract_data = findContract?.tails?.filter(
                  (tail) => tail.tailNumber !== contractData.tailNo
                );
                findContract.tails = contract_data;
                reportUsageHoursArray[findContractIndex] = findContract;
                setReportUsageHours(reportUsageHoursArray);
              } else if (tailLength > 1 && engineLength > 1) {
                //code Remove Engine from Tail
                const tail_data = findTail?.engines.filter(
                  (engine) =>
                    engine.engineName !== contractData.engineSerialNumber
                );
                findTail.engines = tail_data;
                reportUsageHoursArray[findContractIndex] = findContract;
                setReportUsageHours(reportUsageHoursArray);
              }
              setRentalStoreClearValues({
                rentalClear: true,
                contractNo: contractData.contractNo,
                tailNo: contractData?.tailNo,
                engineNumber: findEngine?.engineName,
              });
            }
          }
        }
      }

      if (contractData?.type === "AIRFRAME" && !unAssociatedContractEngine) {
        runRentalHandler(
          contractData?.contractNo,
          contractData?.tailNo,
          parseFloat(parseFloat(endTsnValue - beginTsnValue).toFixed(2))
        );
      }
      /*eslint-disable */
    },
    [contracts, endTSNValidation, reportUsageHours]
  );

  // executed on change of report usage hours
  const reportUsageHoursHandler = useCallback(
    (e) => {
      const contractData = JSON.parse(e.target.name);
      if (e.target.value >= 0) {
        setUsageHoursFlag(true);
        const beginTsnValue = contractData.beginTsn;
        const endTsnValue = contractData.endTsn;
        const endTsnCalculatedValue = parseFloat(
          (parseFloat(e.target.value) + beginTsnValue).toFixed(2)
        );
        const unAssociatedContractEngine = getUnAssociatedContractDetails(
          contractData?.contractNo,
          contractData?.tailNo
        );
        const maxEngineHours = parseFloat(e.target.value);
        if (maxEngineHours > 999) {
          setMaxHoursValidation((maxHoursValidation) => [
            ...maxHoursValidation,
            contractData.engineSerialNumber,
          ]);
        } else {
          setMaxHoursValidation((prevValue) =>
            prevValue.filter(
              (maxValue) => maxValue !== contractData.engineSerialNumber
            )
          );
        }

        !unAssociatedContractEngine &&
          setRentalValidationValues({
            rentalValidate: true,
            contractNo: contractData?.contractNo,
            tailNo: contractData?.tailNo,
            engineNumber: contractData.engineSerialNumber,
            engineValue: parseFloat(e.target.value),
            engineType: contractData?.type,
          });

        if (endTsnValue < beginTsnValue) {
          showEndTSNValidation((endTSNValidation) => [
            ...endTSNValidation,
            contractData?.engineSerialNumber,
          ]);
        } else {
          showEndTSNValidation(
            endTSNValidation?.filter(
              (val) => val !== contractData?.engineSerialNumber
            )
          );
        }

        setContractName(contractData.contractNo);

        const reportUsageHoursCopy = [...reportUsageHours];
        //updated code
        if (e.target.value.length > 0) {
          const findContract = reportUsageHours.find(
            (contract) => contract.contractNumber === contractData.contractNo
          );
          const findContractIndex = reportUsageHours.findIndex(
            (contract) => contract.contractNumber === contractData.contractNo
          );
          if (findContract) {
            const findTail = findContract.tails.find(
              (tail) => tail.tailNumber === contractData.tailNo
            );
            if (findTail) {
              const findEngine = findTail.engines.find(
                (engine) =>
                  engine.engineName === contractData.engineSerialNumber
              );
              if (findEngine) {
                //Parsing Float
                findEngine.hours = parseFloat(e.target.value);
                //engine type
                findEngine.type = contractData.type;
                findEngine.beginTsn = contractData.beginTsn;
                findEngine.endTsn = endTsnCalculatedValue;
                findEngine.endTsnFlag = true;
              } else {
                const engineLength = findTail.engines.length - 1;
                //engine type
                //Parsing Float
                findTail.engines[engineLength + 1] = {
                  engineName: contractData.engineSerialNumber,
                  hours: parseFloat(e.target.value),
                  type: contractData.type,
                  beginTsn: contractData.beginTsn,
                  endTsn: endTsnCalculatedValue,
                  endTsnFlag: true,
                };
              }
            } else {
              const tailsLength = findContract.tails.length - 1;
              //engine type
              //Parsing Float
              findContract.tails[tailsLength + 1] = {
                tailNumber: contractData.tailNo,
                engines: [
                  {
                    engineName: contractData.engineSerialNumber,
                    hours: parseFloat(e.target.value),
                    type: contractData.type,
                    beginTsn: contractData.beginTsn,
                    endTsn: endTsnCalculatedValue,
                    endTsnFlag: true,
                  },
                ],
              };
            }
            reportUsageHoursCopy[findContractIndex] = findContract;
            setReportUsageHours(reportUsageHoursCopy);
          } else {
            setReportUsageHours((prevValue) => {
              //engine type
              //Parsing Float
              return [
                ...prevValue,
                {
                  contractNumber: contractData.contractNo,
                  tails: [
                    {
                      tailNumber: contractData.tailNo,
                      engines: [
                        {
                          engineName: contractData.engineSerialNumber,
                          hours: parseFloat(e.target.value),
                          type: contractData.type,
                          beginTsn: contractData.beginTsn,
                          endTsn: endTsnCalculatedValue,
                          endTsnFlag: true,
                        },
                      ],
                    },
                  ],
                },
              ];
            });
          }
        } else if (e.target.value.length <= 0) {
          const findContract = reportUsageHours?.find(
            (contract) => contract.contractNumber === contractData.contractNo
          );
          const findContractIndex = reportUsageHours?.findIndex(
            (contract) => contract.contractNumber === contractData.contractNo
          );
          const reportUsageHoursArray = [...reportUsageHours];
          if (findContract) {
            const findTail = findContract?.tails?.find(
              (tail) => tail.tailNumber === contractData.tailNo
            );
            const tailLength = findContract?.tails.length;
            if (findTail) {
              const findEngine = findTail?.engines.find(
                (engine) =>
                  engine.engineName === contractData.engineSerialNumber
              );

              if (findEngine) {
                const engineLength = findTail?.engines.length;
                let reportUsageHoursCopy;
                setRentalValidation((prevValue) =>
                  prevValue?.filter(
                    (rentalValue) => rentalValue !== findEngine?.engineName
                  )
                );
                if (engineLength <= 1 && tailLength <= 1) {
                  reportUsageHoursCopy = reportUsageHours.filter(
                    (data) => data.contractNumber !== contractData.contractNo
                  );
                  setReportUsageHours(reportUsageHoursCopy);
                } else if (engineLength > 1 && tailLength <= 1) {
                  const tailData = findTail?.engines.filter(
                    (engine) =>
                      engine.engineName !== contractData.engineSerialNumber
                  );
                  findTail.engines = tailData;
                  reportUsageHoursArray[findContractIndex] = findContract;
                  setReportUsageHours(reportUsageHoursArray);
                } else if (tailLength > 1 && engineLength <= 1) {
                  //code Remove Tail
                  const contract_data = findContract?.tails?.filter(
                    (tail) => tail.tailNumber !== contractData.tailNo
                  );
                  findContract.tails = contract_data;
                  reportUsageHoursArray[findContractIndex] = findContract;
                  setReportUsageHours(reportUsageHoursArray);
                } else if (tailLength > 1 && engineLength > 1) {
                  //code Remove Engine from Tail
                  const tail_data = findTail?.engines.filter(
                    (engine) =>
                      engine.engineName !== contractData.engineSerialNumber
                  );
                  findTail.engines = tail_data;
                  reportUsageHoursArray[findContractIndex] = findContract;
                  setReportUsageHours(reportUsageHoursArray);
                }
                setRentalStoreClearValues({
                  rentalClear: true,
                  contractNo: contractData.contractNo,
                  tailNo: contractData?.tailNo,
                  engineNumber: findEngine?.engineName,
                });
              }
            }
          }
        }
        if (contractData?.type === "AIRFRAME" && !unAssociatedContractEngine) {
          runRentalHandler(
            contractData?.contractNo,
            contractData?.tailNo,
            parseFloat(e.target.value)
          );
        }
        /*eslint-disable */
      }
    },
    [reportUsageHours]
  );

  //function to be excuted on click of contract number
  const openContractLinkPdf = (data, dataSold) => {
    setContractNumber(data);
    setSoldToNumber(dataSold);
    setModalShow(true);
  };

  // function to be executed on click of close
  const closeContractPdf = () => {
    setModalShow(false);
  };

  useEffect(() => {
    if (rentalStoreClearValues.rentalClear) {
      clearRentalStoreValues(
        rentalStoreClearValues.contractNo,
        rentalStoreClearValues.tailNo,
        rentalStoreClearValues.engineNumber
      );
      setRentalStoreClearValues({
        rentalClear: false,
        contractNo: "",
        tailNo: "",
        engineNumber: "",
      });
    }
    if (rentalValidationValues.rentalValidate) {
      rentalValidationHandler(
        rentalValidationValues?.contractNo,
        rentalValidationValues?.tailNo,
        rentalValidationValues?.engineNumber,
        rentalValidationValues?.engineValue,
        rentalValidationValues?.engineType
      );
      setRentalValidationValues({
        rentalValidate: false,
        contractNo: "",
        tailNo: "",
        engineNumber: "",
        engineValue: "",
        engineType: "",
      });
    }
  }, [rentalStoreClearValues, rentalValidationValues]);

  // clearing rental store values
  const clearRentalStoreValues = (contractNo, tailNo, engineName) => {
    const findContract = rentalStoreState?.find(
      (contract) => contract.contractNumber === contractNo
    );
    const findContractIndex = rentalStoreState?.findIndex(
      (contract) => contract.contractNumber === contractNo
    );
    const rentalStoreStateArray = [...rentalStoreState];
    let rentalStoreStateCopy;
    if (findContract) {
      const findTail = findContract?.tails?.find(
        (tail) => tail.tailNumber === tailNo
      );
      const tailLength = findContract?.tails.length;
      if (findTail) {
        const findEngine = findTail?.engines.find(
          (engine) => engine.engineName === engineName
        );
        if (findEngine) {
          const engineLength = findTail?.engines.length;
          if (engineLength <= 1 && tailLength <= 1) {
            rentalStoreStateCopy = rentalStoreState.filter(
              (data) => data.contractNumber !== contractNo
            );
            setRentalStoreState(rentalStoreStateCopy);
          } else if (engineLength > 1 && tailLength <= 1) {
            const tailData = findTail?.engines.filter(
              (engine) => engine.engineName !== engineName
            );
            findTail.engines = tailData;
            rentalStoreStateArray[findContractIndex] = findContract;
            setRentalStoreState(rentalStoreStateArray);
          } else if (tailLength > 1 && engineLength <= 1) {
            //code Remove Tail
            const contract_data = findContract?.tails?.filter(
              (tail) => tail.tailNumber !== tailNo
            );
            findContract.tails = contract_data;
            rentalStoreStateArray[findContractIndex] = findContract;
            setRentalStoreState(rentalStoreStateArray);
          } else if (tailLength > 1 && engineLength > 1) {
            //code Remove Engine from Tail
            const tail_data = findTail?.engines.filter(
              (engine) => engine.engineName !== engineName
            );
            findTail.engines = tail_data;
            rentalStoreStateArray[findContractIndex] = findContract;
            setRentalStoreState(rentalStoreStateArray);
          }
        }
      }
    }
  };

  //populating wingex  engine data
  const getWingexEngineData = (wingexTailData) => {
    let wingexEngineArray = [];
    if (wingexTailData?.wingXUsageHours) {
      wingexTailData?.platformDetails?.forEach((platformWingexData) => {
        wingexEngineArray.push({
          engineName: platformWingexData?.platformSerialNo,
          type: "AIRFRAME",
          hours: wingexTailData?.wingXUsageHours,
          beginTsn: platformWingexData?.beginTsn,
          endTsn: platformWingexData?.endTsn,
          endTsnFlag: false,
        });
      });

      wingexTailData?.productDetails?.forEach((productWingexData, index) => {
        wingexEngineArray.push({
          engineName: productWingexData?.productSerialNo,
          type:
            productWingexData?.type === "APU" ? "APU" : `Engine-${index + 1}`,
          hours:
            productWingexData?.type === "APU"
              ? wingexTailData?.wingXApuUsageHours
              : wingexTailData?.wingXUsageHours,
          beginTsn: productWingexData?.beginTsn,
          endTsn: productWingexData?.endTsn,
          endTsnFlag: false,
        });
      });
    }
    return wingexEngineArray;
  };

  //populating wingex tail data
  const getTailData = (tailWingex) => {
    const wingexTailArray = [];
    tailWingex?.tailDetails?.forEach((wingexTail) => {
      if (wingexTail?.wingXUsageHours) {
        wingexTailArray.push({
          tailNumber: wingexTail?.aircraftTailNumber,
          engines: getWingexEngineData(wingexTail),
        });
      }
    });
    return wingexTailArray;
  };

  //populating wingex data
  const wingexContractsData = (contractsData) => {
    const wingexArray = [];

    contractsData?.forEach((wingex) => {
      let wingexCount = 0;
      wingex?.tailDetails?.forEach((countData) => {
        if (countData?.wingXUsageHours >= 0) {
          wingexCount++;
        }
      });

      const wingexTailCount = wingex?.tailDetails?.length;
      if (wingexCount > 0 && wingexTailCount <= 10) {
        wingexArray.push({
          contractNumber: wingex?.contractNumber?.toString(),
          tails: getTailData(wingex),
        });
      }
    });

    setReportUsageHours(wingexArray);
  };

  const multipleContractsHandler = (
    multipleContractsData,
    multipleContractsChecked
  ) => {
    const contractNumber = multipleContractsData;
    if (multipleContractsChecked) {
      const contract = multipleContracts?.find(
        (contract) => contract === contractNumber
      );
      if (!contract) {
        setMultipleContracts((prevValue) => [...prevValue, contractNumber]);
      }
    } else {
      setMultipleContracts((prevValue) =>
        prevValue?.filter((contractData) => contractData !== contractNumber)
      );
    }
  };

  useEffect(() => {
    if (wingxFeature?.active) {
      wingexContractsData(contracts);
      setTimeout(() => {
        setLoadingWingexData(false);
      }, 2000);
    }
  }, [contracts]);
  return (
    <React.Fragment>
      {!loadingWingexData && (
        <>
          <div className="report-tbl-header">
            <div>CONTRACT</div>
            <div>TAIL</div>
            <div>USAGE PERIOD</div>
            <div>PART</div>
            <div>
              Begin TSN{" "}
              <span className="info-icon">
                <img src={InfoIcon} alt="contract-data" />{" "}
                <span className="tool-tip">
                  Time Since New is the total time reporting in to the MSP
                  program
                </span>
              </span>
            </div>
            <div>End TSN </div>
            <div>USAGE HOURS</div>
            <div></div>
          </div>

          {contracts?.map((data, index) => {
            let indexCount = 0;
            let totalProductsCountPerContract = 0;
            let reportUsageHoursPerContract = 0;
            let productPlatformCountPerTail = 0;
            let productSerialArray = [];
            let productsArray = [];
            let platformArray = [];
            let preInvoiceHoursValidationFlag = false;
            let preInvoiceEndtsnValidationFlag = false;
            let preInvoiceMaxHoursProductValidationFlag = false;
            let preInvoiceMaxHoursPlatformValidationFlag = false;
            let contractDetailsData = reportUsageHours?.find(
              (dataContract) =>
                dataContract?.contractNumber === String(data?.contractNumber)
            );
            const contractTailLength = data?.tailDetails?.length;
            return (
              <div className="report-tbl-row" key={index}>
                <div className="report-tbl-lft">
                  <a
                    className="contarct-lable"
                    onClick={() =>
                      openContractLinkPdf(
                        data.contractNumber,
                        data.soldToNumber
                      )
                    }
                  >
                    {data.contractNumber}
                  </a>
                </div>
                {contractTailLength <= 10 && (
                  <div className="report-tbl-rgt">
                    {data.tailDetails.map((tail, tailIndex) => {
                      let engineCount = 0;
                      const productsCount = tail?.productDetails?.length;
                      if (productsCount) {
                        productPlatformCountPerTail =
                          productsCount + tail?.platformDetails?.length;
                      }
                      if (!productsCount) {
                        productPlatformCountPerTail =
                          tail?.platformDetails?.length;
                      }
                      totalProductsCountPerContract +=
                        productPlatformCountPerTail;
                      const reportUsageContract = reportUsageHours?.find(
                        (contracts) =>
                          contracts.contractNumber ===
                          String(data.contractNumber)
                      );
                      const payLoadTailLength =
                        reportUsageContract?.tails?.length;
                      if (payLoadTailLength > 1) {
                        reportUsageContract?.tails?.forEach(
                          (reportUsageTail) => {
                            if (
                              reportUsageTail.tailNumber ===
                              tail.aircraftTailNumber
                            ) {
                              reportUsageHoursPerContract +=
                                reportUsageTail.engines?.length;
                            }
                          }
                        );
                      } else {
                        reportUsageHoursPerContract = reportUsageHours
                          ?.find(
                            (contracts) =>
                              contracts.contractNumber ===
                              String(data.contractNumber)
                          )
                          ?.tails?.find(
                            (tailData) =>
                              tailData?.tailNumber === tail.aircraftTailNumber
                          )?.engines?.length;
                      }
                      return (
                        <div
                          className="report-rgtinr"
                          key={tail.aircraftTailNumber}
                        >
                          <div className="report-rgtinr-1">
                            <div>
                              {tail.aircraftTailNumber
                                ? tail.aircraftTailNumber
                                : "N/A"}
                              <span className="info-icon">
                                <img src={InfoIcon} alt="contracts-tail-data" />{" "}
                                <span className="tool-tip">
                                  <span>
                                    <strong>Plane Name :</strong>
                                    {`${
                                      tail?.platformDetails &&
                                      tail?.platformDetails[0].platformName
                                    }`}
                                  </span>
                                  <span>
                                    <strong>Serial Number :</strong>
                                    {`${
                                      tail?.platformDetails &&
                                      tail?.platformDetails[0].platformSerialNo
                                    }`}
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div>{data.usagePeriod}</div>
                          </div>
                          <div className="report-rgtinr-2">
                            {tail?.platformDetails?.map((platform) => {
                              const nameParameterPlatform = {
                                contractNo: String(data.contractNumber),
                                tailNo: tail.aircraftTailNumber
                                  ? tail.aircraftTailNumber
                                  : "N/A",
                                engineSerialNumber: platform?.platformSerialNo,
                                type: "AIRFRAME",
                                beginTsn: platform?.beginTsn,
                                endTsn: platform?.endTsn,
                              };

                              platformArray.push(platform?.platformSerialNo);

                              const resultPlatform = JSON.stringify(
                                nameParameterPlatform
                              );
                              const platformContractUsage = reportUsageHours
                                ?.find(
                                  (contract) =>
                                    contract.contractNumber ===
                                    String(data.contractNumber)
                                )
                                ?.tails?.find(
                                  (tailData) =>
                                    tailData?.tailNumber ===
                                    tail.aircraftTailNumber
                                )
                                ?.engines?.find(
                                  (engineData) =>
                                    engineData.engineName ===
                                    platform?.platformSerialNo
                                );

                              return (
                                <div
                                  className="report-rgtinr-sub"
                                  key={platform?.platformSerialNo}
                                >
                                  <div>
                                    <span>AirFrame</span>
                                    <br />
                                    <span>{platform?.platformSerialNo}</span>
                                  </div>
                                  <div
                                    className={
                                      endTSNValidation?.includes(
                                        platform?.platformSerialNo
                                      ) ||
                                      maxHoursValidation?.includes(
                                        platform?.platformSerialNo
                                      )
                                        ? "active-red"
                                        : ""
                                    }
                                  >
                                    {platform?.beginTsn}{" "}
                                  </div>
                                  <div key={platform?.platformSerialNo}>
                                    <input
                                      type="number"
                                      className={
                                        endTSNValidation?.includes(
                                          platform?.platformSerialNo
                                        ) ||
                                        maxHoursValidation?.includes(
                                          platform?.platformSerialNo
                                        )
                                          ? "active-red"
                                          : ""
                                      }
                                      placeholder="End TSN"
                                      name={resultPlatform}
                                      onChange={endTsnHoursHandler}
                                      disabled={
                                        data.isFutureUsagePeriod === true ||
                                        multipleContracts?.includes(
                                          data?.contractNumber.toString()
                                        )
                                      }
                                      value={
                                        platformContractUsage?.endTsn >= 0
                                          ? platformContractUsage?.endTsn
                                          : ""
                                      }
                                    />
                                    {endTSNValidation?.includes(
                                      platform?.platformSerialNo
                                    ) &&
                                      !maxHoursValidation?.includes(
                                        platform?.platformSerialNo
                                      ) && (
                                        <p className="validation-text">
                                          {
                                            configData
                                              ?.MSP_REPORTUSAGE_INFORMATION_TEXT
                                              ?.ENDTSN_VALIDATION
                                          }
                                        </p>
                                      )}

                                    {maxHoursValidation?.includes(
                                      platform?.platformSerialNo
                                    ) && (
                                      <p className="validation-text">
                                        {
                                          configData
                                            ?.MSP_REPORTUSAGE_INFORMATION_TEXT
                                            ?.MAXHOURS_VALIDATION
                                        }
                                      </p>
                                    )}
                                  </div>
                                  <div>
                                    <input
                                      type="number"
                                      className={
                                        endTSNValidation?.includes(
                                          platform?.platformSerialNo
                                        ) ||
                                        maxHoursValidation.includes(
                                          platform?.platformSerialNo
                                        )
                                          ? "active-red"
                                          : ""
                                      }
                                      min="0"
                                      placeholder="Hrs"
                                      name={resultPlatform}
                                      onChange={reportUsageHoursHandler}
                                      disabled={
                                        data.isFutureUsagePeriod === true ||
                                        multipleContracts?.includes(
                                          data?.contractNumber.toString()
                                        )
                                      }
                                      value={
                                        platformContractUsage?.hours >= 0
                                          ? platformContractUsage?.hours
                                          : ""
                                      }
                                    />
                                  </div>
                                </div>
                              );
                            })}

                            {tail?.productDetails?.map((products, index) => {
                              if (products?.type !== "APU") {
                                engineCount += 1;
                                productSerialArray.push(
                                  products?.productSerialNo
                                );
                              }
                              productsArray.push(products?.productSerialNo);

                              const nameParameterProducts = {
                                contractNo: String(data.contractNumber),
                                tailNo: tail.aircraftTailNumber
                                  ? tail.aircraftTailNumber
                                  : "N/A",
                                engineSerialNumber: products?.productSerialNo,
                                type:
                                  products?.type === "APU"
                                    ? "APU"
                                    : `Engine-${engineCount}`,
                                beginTsn: products?.beginTsn,
                                endTsn: products?.endTsn,
                              };
                              const nameRentalParameter = {
                                serialNumber: products?.productSerialNo,
                              };
                              const rentalData =
                                JSON.stringify(nameRentalParameter);
                              const resultProduct = JSON.stringify(
                                nameParameterProducts
                              );
                              const productContractUsage = reportUsageHours
                                ?.find(
                                  (contract) =>
                                    contract.contractNumber ===
                                    String(data.contractNumber)
                                )
                                ?.tails?.find(
                                  (tailData) =>
                                    tailData?.tailNumber ===
                                    tail.aircraftTailNumber
                                )
                                ?.engines?.find(
                                  (engineData) =>
                                    engineData.engineName ===
                                    products?.productSerialNo
                                );
                              const airframeHoursValue = getTailAirframeHours(
                                data?.contractNumber,
                                tail?.aircraftTailNumber
                              );

                              const reportUsageProductContract =
                                rentalInstalledState?.find(
                                  (contract) =>
                                    contract.contractNumber ===
                                    data.contractNumber
                                );
                              const reportUsageProductTail =
                                reportUsageProductContract?.tails.find(
                                  (tailData) =>
                                    tailData.tailNumber ===
                                    tail.aircraftTailNumber
                                );
                              const reportUsageProductEngine =
                                reportUsageProductTail?.engines.find(
                                  (engine) =>
                                    engine.engineName ===
                                    products?.productSerialNo
                                );

                              return (
                                <div
                                  className="report-rgtinr-sub"
                                  key={products?.productSerialNo}
                                >
                                  <div>
                                    <span>
                                      {products?.type === "APU"
                                        ? "APU"
                                        : `Engine-${engineCount}`}
                                    </span>
                                    <br />
                                    <span>{products?.productSerialNo}</span>
                                  </div>
                                  <div
                                    className={
                                      (endTSNValidation?.includes(
                                        products?.productSerialNo
                                      ) &&
                                        contractDetailsData) ||
                                      (rentalValidation?.includes(
                                        products?.productSerialNo
                                      ) &&
                                        contractDetailsData) ||
                                      (maxHoursValidation?.includes(
                                        products?.productSerialNo
                                      ) &&
                                        contractDetailsData)
                                        ? "active-red"
                                        : ""
                                    }
                                  >
                                    {products?.beginTsn}
                                  </div>
                                  <div>
                                    <input
                                      type="number"
                                      placeholder="End TSN"
                                      className={
                                        (endTSNValidation?.includes(
                                          products?.productSerialNo
                                        ) &&
                                          contractDetailsData) ||
                                        (rentalValidation?.includes(
                                          products?.productSerialNo
                                        ) &&
                                          contractDetailsData) ||
                                        (maxHoursValidation?.includes(
                                          products?.productSerialNo
                                        ) &&
                                          contractDetailsData)
                                          ? "active-red"
                                          : ""
                                      }
                                      name={resultProduct}
                                      onChange={endTsnHoursHandler}
                                      disabled={
                                        data.isFutureUsagePeriod === true ||
                                        multipleContracts?.includes(
                                          data?.contractNumber.toString()
                                        )
                                      }
                                      value={
                                        productContractUsage?.endTsn >= 0
                                          ? productContractUsage.endTsn
                                          : ""
                                      }
                                    />
                                    {endTSNValidation?.includes(
                                      products?.productSerialNo
                                    ) &&
                                      !maxHoursValidation.includes(
                                        products?.productSerialNo
                                      ) &&
                                      contractDetailsData && (
                                        <p className="validation-text">
                                          {
                                            configData
                                              ?.MSP_REPORTUSAGE_INFORMATION_TEXT
                                              ?.ENDTSN_VALIDATION
                                          }
                                        </p>
                                      )}
                                    {maxHoursValidation?.includes(
                                      products?.productSerialNo
                                    ) &&
                                      contractDetailsData && (
                                        <p className="validation-text">
                                          {
                                            configData
                                              ?.MSP_REPORTUSAGE_INFORMATION_TEXT
                                              ?.MAXHOURS_VALIDATION
                                          }
                                        </p>
                                      )}
                                  </div>
                                  <div>
                                    <input
                                      type="number"
                                      placeholder="Hrs"
                                      className={
                                        (endTSNValidation?.includes(
                                          products?.productSerialNo
                                        ) &&
                                          contractDetailsData) ||
                                        (rentalValidation?.includes(
                                          products?.productSerialNo
                                        ) &&
                                          contractDetailsData) ||
                                        (maxHoursValidation?.includes(
                                          products?.productSerialNo
                                        ) &&
                                          contractDetailsData)
                                          ? "active-red"
                                          : ""
                                      }
                                      name={resultProduct}
                                      onChange={reportUsageHoursHandler}
                                      disabled={
                                        data.isFutureUsagePeriod === true ||
                                        multipleContracts?.includes(
                                          data?.contractNumber.toString()
                                        )
                                      }
                                      value={
                                        productContractUsage?.hours >= 0
                                          ? productContractUsage.hours
                                          : ""
                                      }
                                    />
                                    {rentalValidation?.includes(
                                      products?.productSerialNo
                                    ) &&
                                      endTSNValidation.length === 0 &&
                                      airframeHoursValue >= 0 &&
                                      !maxHoursValidation.includes(
                                        products?.productSerialNo
                                      ) &&
                                      contractDetailsData && (
                                        <p className="validation-text">
                                          {
                                            configData
                                              ?.MSP_REPORTUSAGE_INFORMATION_TEXT
                                              ?.RENTAL_VALIDATION
                                          }
                                        </p>
                                      )}
                                    {rentalValidation?.includes(
                                      products?.productSerialNo
                                    ) &&
                                      endTSNValidation.length === 0 &&
                                      !airframeHoursValue &&
                                      airframeHoursValue !== 0 &&
                                      !maxHoursValidation.includes(
                                        products?.productSerialNo
                                      ) &&
                                      contractDetailsData && (
                                        <p className="validation-text">
                                          {
                                            configData
                                              ?.MSP_REPORTUSAGE_INFORMATION_TEXT
                                              ?.AIRFRAME_VALIDATION
                                          }
                                        </p>
                                      )}
                                  </div>

                                  <div>
                                    <a
                                      name={rentalData}
                                      onClick={() =>
                                        showRentalInstalledHandler(
                                          resultProduct
                                        )
                                      }
                                      className={`${
                                        showRentalInstalled ||
                                        multipleContracts?.includes(
                                          data?.contractNumber.toString()
                                        )
                                          ? "hide-rental"
                                          : ""
                                      }`}
                                    >
                                      {"RENTAL INSTALLED?"}
                                    </a>
                                    {showRentalInstalled && (
                                      <div
                                        className={`${
                                          products?.productSerialNo ===
                                          rentalEngine
                                            ? "show-rental-option"
                                            : "show-rental-option-hidden"
                                        }`}
                                      >
                                        <form className="show-rental-option">
                                          <a
                                            onClick={() =>
                                              closeRentalInstalledHandler(
                                                resultProduct
                                              )
                                            }
                                          >
                                            {
                                              configData
                                                ?.MSP_REPORTUSAGE_RENTALINSTALLED_TEXT
                                                ?.SAVE_DATA
                                            }
                                          </a>
                                          <label className="lable-for-rental">
                                            {
                                              configData
                                                ?.MSP_REPORTUSAGE_RENTALINSTALLED_TEXT
                                                ?.RENTAL_TYPE
                                            }
                                            <select
                                              className="rental-select"
                                              onChange={(e) =>
                                                setRentalType(e.target.value)
                                              }
                                              defaultValue={rentalType}
                                            >
                                              <option value="select">
                                                Select
                                              </option>
                                              <option value="HW Owned Rental Chargeable at Program Rate">
                                                {
                                                  configData
                                                    ?.MSP_REPORTUSAGE_RENTALINSTALLED_TEXT
                                                    ?.RENTAL_SELECT_VALUE1
                                                }
                                              </option>
                                              <option value="HW Owned Rental No Charge per Warranty Agreement">
                                                {
                                                  configData
                                                    ?.MSP_REPORTUSAGE_RENTALINSTALLED_TEXT
                                                    ?.RENTAL_SELECT_VALUE2
                                                }
                                              </option>
                                              <option value="S/C Owned Rental Chargeable at Program Rate">
                                                {
                                                  configData
                                                    ?.MSP_REPORTUSAGE_RENTALINSTALLED_TEXT
                                                    ?.RENTAL_SELECT_VALUE3
                                                }
                                              </option>
                                              <option value="S/C Owned Rental to be paid directly to S/C">
                                                {" "}
                                                {
                                                  configData
                                                    ?.MSP_REPORTUSAGE_RENTALINSTALLED_TEXT
                                                    ?.RENTAL_SELECT_VALUE4
                                                }
                                              </option>
                                            </select>
                                          </label>

                                          <label className="lable-for-rental">
                                            Serial Number
                                            <input
                                              className="rental-select"
                                              type="text"
                                              onChange={(e) =>
                                                setRentalEngineSerialNumber(
                                                  e.target.value
                                                )
                                              }
                                              defaultValue={
                                                rentalEngineSerialNumber
                                              }
                                            />
                                          </label>

                                          <label className="lable-for-rental">
                                            Usage Hours
                                            <input
                                              className="rental-select"
                                              type="text"
                                              onChange={(e) =>
                                                setRentalUsageHours(
                                                  parseFloat(e.target.value)
                                                )
                                              }
                                              defaultValue={rentalUsageHours}
                                            />
                                          </label>
                                        </form>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                            <div className="submit-pre-invoice">
                              {
                                (preInvoiceHoursValidationFlag =
                                  productSerialArray.some((productArray) =>
                                    rentalValidation.includes(productArray)
                                  ))
                              }
                              {
                                (preInvoiceEndtsnValidationFlag =
                                  productSerialArray.some((productArrayData) =>
                                    endTSNValidation.includes(productArrayData)
                                  ))
                              }
                              {
                                (preInvoiceMaxHoursProductValidationFlag =
                                  productsArray.some((productArrayData) =>
                                    maxHoursValidation.includes(
                                      productArrayData
                                    )
                                  ))
                              }
                              {
                                (preInvoiceMaxHoursPlatformValidationFlag =
                                  platformArray.some((productArrayData) =>
                                    maxHoursValidation.includes(
                                      productArrayData
                                    )
                                  ))
                              }
                              {data?.tailDetails?.length === tailIndex + 1 && (
                                <Button
                                  variant="outline-primary"
                                  className="btn-pre-invoice"
                                  disabled={
                                    reportUsageHoursPerContract !==
                                      totalProductsCountPerContract ||
                                    preInvoiceHoursValidationFlag === true ||
                                    preInvoiceEndtsnValidationFlag === true ||
                                    preInvoiceMaxHoursProductValidationFlag ===
                                      true ||
                                    preInvoiceMaxHoursPlatformValidationFlag ===
                                      true
                                  }
                                  onClick={() =>
                                    showPreInvoiceHandler(data?.contractNumber)
                                  }
                                >
                                  Draft estimate
                                </Button>
                              )}

                              {data?.tailDetails?.length === tailIndex + 1 && (
                                <div className="input-check">
                                  <input
                                    type="checkbox"
                                    name={data?.contractNumber}
                                    id={`checkbox_${data?.contractNumber}`}
                                    onChange={(e) =>
                                      multipleContractsHandler(
                                        e.target.name,
                                        e.target.checked
                                      )
                                    }
                                    disabled={
                                      reportUsageHoursPerContract !==
                                        totalProductsCountPerContract ||
                                      preInvoiceHoursValidationFlag === true ||
                                      preInvoiceEndtsnValidationFlag === true ||
                                      preInvoiceMaxHoursProductValidationFlag ===
                                        true ||
                                      preInvoiceMaxHoursPlatformValidationFlag ===
                                        true
                                    }
                                  />
                                  <label
                                    htmlFor={`checkbox_${data?.contractNumber}`}
                                  >
                                    By clicking here, I accept that the hours
                                    used on this form are correct
                                  </label>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {contractTailLength > 10 && (
                  <div className="report-tbl-rgt">
                    {contractTailLength > 10 && (
                      <p className="notail-msg">
                        <span className="err-icon">i</span>{" "}
                        {
                          configData?.MSP_REPORTUSAGE_INFORMATION_TEXT
                            ?.BULKUPLOAD_TEXT
                        }
                      </p>
                    )}
                  </div>
                )}
              </div>
            );
          })}
        </>
      )}
      {loadingWingexData && (
        <h2 className="no-data-contracts">
          {configData?.MSP_INFORMATION_TEXT?.LOADING_CONTRACTS}
          <div className="loader"></div>
        </h2>
      )}

      <Modal
        class="text-right modal-dialog"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-success"
        centered
        data-test="test-right"
      >
        <Modal.Header
          closeButton
          className="modal-upload-button-border success-close"
        >
          <Modal.Title className="modal-title">
            <h2>{headerMessage}</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-body invoice-success">
          <p> {bodyMessage}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-blue"
            data-test="modal-cancel"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {modalShow && showPreInvoice === false && (
        <ContractPdfLink
          constractnumberdata={contract_number}
          soldToNummber={soldtoNumber}
          show={modalShow}
          onHide={closeContractPdf}
          backdrop="static"
        />
      )}

      <InvoiceSuccessModel
        rentalStore={rentalStoreState}
        contracts={contracts}
        loadingWingXData={loadingWingexData}
        isDisable={
          (reportUsageHours?.length > 0 ? false : true) ||
          (endTSNValidation?.length > 0 ? true : false) ||
          (rentalValidation?.length > 0 ? true : false) ||
          (maxHoursValidation?.length > 0 ? true : false) ||
          (showRentalInstalled === true ? true : false) ||
          (multipleContracts?.length > 0 ? false : true)
        }
        reportUsage={reportUsageHours}
        multipleContracts={multipleContracts}
      />

      {showPreInvoice && (
        <PreInvoice
          showInvoice={showPreInvoice}
          onClosePreInvoice={closePreInvoiceHandler}
          contractNumber={preInvoiceContract}
          contractsData={contracts}
          usageHoursData={reportUsageHours}
          rentalData={rentalStoreState}
        />
      )}
    </React.Fragment>
  );
};

export default ReportUsageTable;