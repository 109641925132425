import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./UploadUsagePopupLoading.scss";

const UploadUsagePopupLoading = () => {
  return (
    <div className="upload-validate">
      <h2>Validating upload complete</h2>
      <p>Please do not close the window until validation completed</p>
      <ProgressBar now={50} />
    </div>
  );
};

export default UploadUsagePopupLoading;
