import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import "./AdminPopup.scss";
import { useLocation } from "react-router-dom";
import ModalPopup from "../../common/ModalPopup/ModalPopup";
import { useSelector } from "react-redux";

const AdminPopup = (props) => {
  const { configData } = useSelector((state) => state.config);
  const [displayPopup, setDisplayPopup] = useState("true");
  const [contractNumber, setContractNumber] = useState("");
  const [dropDownValue, setDropDownValue] = useState("");
  const [soldToNumber, setSoldToNumber] = useState("");
  const [error, setError] = useState(false);
  const [soldToError, setSoldToError] = useState(false);
  const [dropDownSelect, setDropDownSelect] = useState("contract_number");
  const [maxSoldToError, setMaxSoldToError] = useState(false);
  const location = useLocation();

  const closePopupHandler = () => {
    setDisplayPopup("false");
  };

  const soldToDataWithoutSpaces = (soldToData) => {
    let soldToNumberDataArray = [];
    soldToNumberDataArray = soldToData.filter((soldToNo) => soldToNo !== "");
    return soldToNumberDataArray;
  };

  const reportUsageHandler = () => {
    if (contractNumber.length > 0 || soldToNumber.length > 0) {
      let soldToArray = soldToDataWithoutSpaces(soldToNumber.split(","));
      props.onSubmitData(contractNumber, soldToArray, true);
      setDisplayPopup("false");
    } else {
      setError(true);
    }
  };

  const invoiceHandler = () => {
    if (dropDownValue.length > 0 || soldToNumber.length > 0) {
      let soldToArray = soldToDataWithoutSpaces(soldToNumber.split(","));
      props.onSubmitData(dropDownValue, soldToArray, dropDownSelect);
      setDisplayPopup("false");
    } else {
      setError(true);
    }
  };

  const contractsHandler = () => {
    if (soldToNumber.length > 0) {
      let soldToArray = soldToDataWithoutSpaces(soldToNumber.split(","));
      props.onSubmitData(soldToArray, true);
      setDisplayPopup("false");
    } else {
      setError(true);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    location.pathname === "/"
      ? reportUsageHandler()
      : location.pathname === "/contracts"
      ? contractsHandler()
      : invoiceHandler();
  };

  const dropDownSelectHandler = (e) => {
    setDropDownSelect(e.target.value);
  };

  const contractSearchHandler = (e) => {
    e.preventDefault();
    setContractNumber(e.target.value);
    setError(false);
  };

  const dropDownValueSearchHandler = (e) => {
    e.preventDefault();
    setDropDownValue(e.target.value);
    setError(false);
  };

  const soldToSearchHandler = (e) => {
    e.preventDefault();
    setSoldToError(false);
    setSoldToNumber(e.target.value);
    let numberRegex = /^(([0-9](,)?)*)+$/;
    if (e.target.value.match(numberRegex)) {
      setSoldToError(false);
    } else {
      setSoldToError(true);
    }

    setError(false);
  };
  // Search submit on enter key
  const searchEnter = (e) => {
    if (e.key === "Enter") {
      submitHandler(e);
    }
  };

  useEffect(() => {
    let soldToNumberCountArray = soldToDataWithoutSpaces(
      soldToNumber.split(",")
    );
    if (soldToNumberCountArray.length > 10) {
      setMaxSoldToError(true);
    } else {
      setMaxSoldToError(false);
    }
  }, [soldToNumber]);

  const headerContent = (
    <React.Fragment>
      <h2>Admin Search</h2>
    </React.Fragment>
  );
  const bodyContent = (
    <React.Fragment>
      <div className="admin-search">
        <div>
          {location.pathname === "/invoices" && (
            <Form.Label name="contract-number">
              Choose contract/invoice :
            </Form.Label>
          )}
        </div>
        {location.pathname !== "/contracts" && (
        <div className="row search-cnt">
          <div className="col-md-6 p-0 m-btm-10">
            {location.pathname === "/" ? (
              <Form.Label name="contract-number">Contract Number :</Form.Label>
            ) : (
              <>
                <select
                  value={dropDownSelect}
                  onChange={dropDownSelectHandler}
                  className="dropdown"
                  title="dropdown"
                >
                  <option value="contract_number">Contract Number </option>
                  <option value="invoice_number">Invoice Number </option>
                </select>
              </>
            )}
          </div>
          <div
            className={`${
              location.pathname === "/" ? "col-md-12 p-0" : "col-md-6 p-0"
            }`}
          >
            <Form.Control
              name="contract-number"
              value={location.pathname === "/" ? contractNumber : dropDownValue}
              onChange={
                location.pathname === "/"
                  ? contractSearchHandler
                  : dropDownValueSearchHandler
              }
              placeholder={
                location.pathname === "/"
                  ? `Enter contract number`
                  : `${
                      dropDownSelect === "contract_number"
                        ? "Enter contract number"
                        : "Enter invoice number"
                    }`
              }
              onKeyDown={searchEnter}
              maxLength={configData?.MSP_FIELDS?.MAX_LENGTH}
            />
          </div>
        </div>
        )}
        <div>
          {" "}
          <Form.Label name="sold-to-number">Sold To Number :</Form.Label>          
        </div>
        
        <div className="search-cnt">
          <Form.Control
            name="sold-to-number"
            value={soldToNumber}
            onChange={soldToSearchHandler}
            placeholder="Enter sold to number"
            onKeyDown={searchEnter}
          />
          <p className="soldto-info">** Maximum 10 comma seperated sold to can be used for search</p>
        </div>
      
      </div>
      {error && (
        <p className="red">
          Please enter atleast one value for contract number or sold to number
        </p>
      )}
      {soldToError && (
        <p className="red">
          Please enter valid soldto number and use comma for multiple soldto
          numbers
        </p>
      )}
      {maxSoldToError && <p className="red">max soldto limit reached</p>}
    </React.Fragment>
  );
  const footerContent = (
    <React.Fragment>
      <Button
        variant="primary"
        className="modal-Continue btn-blue"
        onClick={closePopupHandler}
      >
        <span className="modal-Continue-text">Cancel</span>
      </Button>
      <Button
        variant="primary"
        className="modal-Continue btn-blue"
        onClick={submitHandler}
        disabled={soldToError || maxSoldToError}
      >
        <span className="modal-Continue-text">Submit</span>
      </Button>
    </React.Fragment>
  );
  return (
    <ModalPopup
      showPopup={displayPopup === "true" ? true : false}
      arialabel="admin popup"
      size="lg"
      centered="true"
      header={headerContent}
      body={bodyContent}
      footer={footerContent}
      cancelPopup={closePopupHandler}
    />
  );
};

export default AdminPopup;