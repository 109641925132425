export const FeatureFlags = [
  {
    name: "ViewInvoicesNew",
    description: "New View Invoices Component",
    active: true,
  },
  {
    name: "ViewInvoicesOld",
    description: "Old View Invoices Component",
    active: false,
  },

  {
    name: "ReportUsage",
    description: "Report Usage Component",
    active: true,
  },
  {
    name: "UBCredits",
    description: "UB Credits Component",
    active: true,
  },
  {
    name: "WelcomePopup",
    description: "WelcomePopup Component",
    active: true,
  },
  {
    name: "Contracts",
    description: "Contracts Component",
    active: true,
  },
  {
    name: "ViewRates",
    description: "View Rates Component",
    active: true,
  },
  {
    name: "FilterSort",
    description: "Filter & Sort Invoices",
    active: true,
  },
  {
    name: "ApplyCredits",
    description: "Apply Credits Component",
    active: true,
  },
  {
    name: "WingxData",
    description: "Wingx Data Component",
    active: true,
  },
];
