import React,{useState,useEffect,useRef} from "react";
import { Button, Modal } from "react-bootstrap";
import "./ViewRatesPopup.scss";
import { useSelector } from "react-redux";
import C from '../../../constants/constant'
import { fetchContractRatesList } from "../../../services/rates.service";


const ViewRatesPopup = (props) => {
  const { configData } = useSelector((state) => state.config);
  const [contractRates, setContractRates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [ratesError, setRatesError] = useState("");

  const viewRatesPopupComponent = useRef(true);

  //api to get contract rates
  const getContractRateDetails = async () => {
    setIsLoading(true);
    const payload = JSON.stringify({ contractNumber: props?.contractNumber });
    await fetchContractRatesList(payload).then((res) => {
      if (res?.status === 200) {
        setContractRates(res?.data?.data?.contractRateRes);
      } else {
        setContractRates([]);
        setRatesError(res?.data?.errorDescription);
      }
      setIsLoading(false);
    });
  };


  useEffect(() => {
    if (viewRatesPopupComponent.current === true) {
      viewRatesPopupComponent.current = false;
      getContractRateDetails();
    }
     /*eslint-disable */
  }, []);

// calculating discount length for engine and apu
  const calculateDiscount = () => {
    let count = 0;
    count = contractRates?.rates[0]?.engineRates?.discounts?.length

    return count>0?true:false;
  };

  const calculateApuDiscount = () =>{
    let count = 0;
    count = contractRates?.rates[0]?.apuRates?.discounts?.length
    return count>0?true:false;
  }

  // calculating surcharge for engine and apu
  const calculateSurchargeCount = () => {
    return contractRates?.rates[0]?.engineRates?.surchargeAmount
  };

  const calculateApuSurchargeCount = ()=>{
    return contractRates?.rates[0]?.apuRates?.surchargeAmount
  }

  // calculating baseprice length for engine and apu
  const calculateBaseEnginePriceCount = () => {
    let basePriceCount = 0;
    contractRates?.rates && contractRates?.rates[0]?.engineRates?.conditions?.forEach((rate) => {
      if (rate.description === "Base Price") {
        basePriceCount += 1;
      }
      });

    return basePriceCount>0?true:false;
  };

  const calculateBaseApuPriceCount = () => {
    let basePriceCount = 0;
    contractRates?.rates && contractRates?.rates[0]?.apuRates?.conditions?.forEach((rate) => {
        if (rate.description === "Base Price") {
          basePriceCount += 1;
        }
      });
      

    return basePriceCount>0?true:false;
  };

  
// calculating valid from and valid to dates
  const getValidFromDate = () =>{
    let validFrom = "";
    validFrom = new Date(contractRates?.rates[0]?.validFrom).toLocaleDateString('en-us', { day:"numeric",month:"short",year:"numeric"})
      return validFrom
  }

  const getValidToDate = () =>{
    let validTo = "";
    let initialDate = contractRates?.rates[0]?.validTo.split('/')
    validTo = new Date(`${parseInt(initialDate[0])}/${parseInt(initialDate[2])}/${parseInt(initialDate[1])}`).toLocaleDateString('en-us', { day:"numeric",month:"short",year:"numeric"})
    return validTo
  }

  

  return (
    <React.Fragment>
      <Modal
        class="text-right modal-dialog"
        backdrop="static"
        show={props.show}
        onHide={props.onHide}
        aria-labelledby="rates popup"
        contentClassName="rates-container"
      >
        <Modal.Header closeButton>
          <div className="rates-header-list">
            <h2 className="rates-header">Rate Details for Contract </h2>
            <span className="rates-date">
              {!isLoading && !ratesError && `Effective ${getValidFromDate()} - ${getValidToDate()}`}
            </span>
          </div>
        </Modal.Header>
        <Modal.Body className="modal-upload-button-border modal-body">
          { !isLoading && <div className="rates-details">
            <div className="engine-rates">
              <div className="engine-header">
                <span>Engine</span>
              </div>
              {calculateBaseEnginePriceCount() ? (
                <>
                  <div className="engine-details">
                    {contractRates?.rates[0]?.engineRates?.conditions
                      ?.map((engineData,index) => {
                        return (
                            <div className="rates-data" key={index}>
                              <span>{engineData?.description}</span>
                              <span>{engineData?.value} USD</span>
                            </div>
                          );
                        
                      })}
                  </div>
                  {calculateDiscount() > 0 && (
                    <div className="discount-details">
                      <div className="discount-header">
                        <span>Discounts</span>
                      </div>
                      <div className="discount-data">
                        {contractRates?.rates[0]?.engineRates?.discounts
                          ?.map((engineData,index) => {
                            
                                return (
                                  <div className="discount-rate" key={index}>
                                    <span>{engineData?.description}</span>
                                    <span>{engineData?.value} USD</span>
                                  </div>
                                );
                              
                            
                          })}
                      </div>
                    </div>
                  )}
                  {calculateSurchargeCount() && (
                    <div className="subtotal-details">
                      <div className="subtotal-data">
                        <span>SubTotal</span>
                        <span>{`${
                          contractRates?.rates[0]?.engineRates?.subTotalAmount
                        } USD`}</span>
                      </div>
                      <div className="subtotal-data">
                        <span>Surcharge</span>
                        <span>
                          +
                          {`${
                           contractRates?.rates[0]?.engineRates?.surchargeAmount
                          } USD`}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="total-details">
                    <div className="total-data">
                      <span>Total</span>
                      <span>{`${
                         contractRates?.rates[0]?.engineRates?.totalAmount
                      } USD`}</span>
                    </div>
                  </div>
                </>
              ) : (
                <div className="rates-error">
                  <p>
                      {`No Engine Rates Found. You may not have an active Engine enrolled under this contract.`}
                    </p>
                    <p>
                      {`If this is incorrect, please contact`}
                      <a href={`mailto:${C.MSP_MAIL_RATES}`}>
                        &nbsp;champs@honeywell.com
                      </a>
                      {` to update your rate.`}
                    </p>
                </div>
              )}
            </div>
            <div className="engine-rates">
              <div className="engine-header">
                <span>APU</span>
              </div>
              {calculateBaseApuPriceCount() ? (
                <>
                  <div className="engine-details">
                    {contractRates?.rates[0]?.apuRates?.conditions
                      ?.map((apuData,index) => {
                        return (
                            <div className="rates-data" key={index}>
                              <span>{apuData?.description}</span>
                              <span>{apuData?.value} USD</span>
                            </div>
                          );
                      })}
                  </div>
                  {calculateApuDiscount() > 0 && (
                    <div className="discount-details">
                      <div className="discount-header">
                        <span>Discounts</span>
                      </div>
                      <div className="discount-data">
                        {contractRates?.rates[0]?.apuRates?.discounts
                          ?.map((apuData,index) => {
                            
                                return (
                                  <div className="discount-rate" key={index}>
                                    <span>{apuData?.description}</span>
                                    <span>{apuData?.value} USD</span>
                                  </div>
                                );
                              
                          })}
                      </div>
                    </div>
                  )}
                  {calculateApuSurchargeCount() > 0 && (
                    <div className="subtotal-details">
                      <div className="subtotal-data">
                        <span>SubTotal</span>
                        <span>{`${
                          contractRates?.rates[0]?.apuRates?.subTotalAmount
                        } USD`}</span>
                      </div>
                      <div className="subtotal-data">
                        <span>Surcharge</span>
                        <span>
                          +
                          {`${
                            contractRates?.rates[0]?.apuRates?.surchargeAmount
                          } USD`}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="total-details">
                    <div className="total-data">
                      <span>Total</span>
                      <span>{`${
                        contractRates?.rates[0]?.apuRates?.totalAmount
                      } USD`}</span>
                    </div>
                  </div>
                </>
              ) : (
                <div className="rates-error">
                  <p>
                      {`No APU Rates Found. You may not have an active APU enrolled under this contract.`}
                    </p>
                    <p>
                      {`If this is incorrect, please contact`}
                      <a href={`mailto:${C.MSP_MAIL_RATES}`}>
                        &nbsp;champs@honeywell.com
                      </a>
                      {` to update your rate.`}
                    </p>
                </div>
              )}
            </div>
          </div>}

          {isLoading && <h2 className="no-data-contracts">{configData?.MSP_INFORMATION_TEXT?.LOADING_RATES}<div className="loader"></div></h2>}
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-blue" onClick={props.onHide}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default ViewRatesPopup;