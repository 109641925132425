import React, { forwardRef,  useEffect,  useImperativeHandle, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SearchIcon from "../../../assets/images/search.png";

const ReportUsageSearch = forwardRef((props,ref) => {
  const {configData} = useSelector(state=>state.config)
  const [contractNumber, setContractNumber] = useState("");
  const [contractNumberEntered, setContractNumberEntered] = useState(false);
  const handleContractSearch = (e) => {
    e.preventDefault();
    const searchValue = e.target.value;
    if (e.target.value.length > 0) {
      props.onSearch(true);
    } else {
      props.onSearch(false);
    }
    setContractNumber(searchValue);
  };



  useImperativeHandle(ref,()=>({
    changeContractValue(){
      setContractNumber("")
    }
  }))

  const handleContractSubmit = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      setContractNumberEntered(true);
      props.onSubmitContracts(contractNumber);
    }
  };


  const iconSearchHandlerSubmit = (e) => {
    e.preventDefault();
    setContractNumberEntered(true);
    props.onSubmitContracts(contractNumber);
  };
  useEffect(()=>{
    if (contractNumberEntered && contractNumber.length === 0) {
      props.onSubmitContracts("");
    }

    /*eslint-disable */
  },[contractNumber])
  

  return (
    <div
      className={`${props.showStatus ? "search-block-status" : "search-block"}`}
    >
      {props.showStatus && <p aria-hidden="true">{props.statusText}</p>}
      <div className="search-cnt">
        <input
          type="text"
          className="form-control"
          value={contractNumber}
          placeholder={props.placeholder}
          onChange={handleContractSearch}
          onKeyDown={handleContractSubmit}
          maxLength={configData?.MSP_FIELDS?.MAX_LENGTH}
        />
        <Link to="#">
          <img
            className="search-icon"
            src={SearchIcon}
            alt="search"
            onClick={iconSearchHandlerSubmit}
          />
        </Link>
      </div>
    </div>
  );
});

export default ReportUsageSearch;
