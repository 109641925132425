import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import EwalletPayment from "../EwalletPayment/EwalletPayment";
import { fetchInvoiceEntitiesSrv } from "../../../services/invoice.service";
import "./MultipleInvoices.scss";
import Loader from "../../common/Loader/Loader";
import MultiInvoiceContext from "../UserContext/MultiInvoiceContext";

const MultipleInvoices = (props) => {
  const [showInvoice, setShowInvoice] = useState(true);
  const [displayTotalCountError, setDisplayTotalCountError] = useState(false);
  const [, setShowErrorPopup] = useState(true);
  const [invoiceDetailsData, setInvoiceDetailsData] = useState([]);
  const [selectedInvoicesData, setSelectedInvoicesData] = useState([]);
  const [invoiceChecked, setInvoiceChecked] = useState([]);
  const [showEwallet, setShowEwallet] = useState(false);
  const [isInvoiceLoading, setIsInvoiceLoading] = useState(false);
  const [invoiceTotalAmount, setInvoiceTotalAmount] = useState(0);
  const [unSelectInvoices, setUnselectInvoices] = useState(false);

  const multipleInvoicesComponent = useRef(true);

  const displayPaymentHandler = () => {
    if (invoiceTotalAmount > 100000) {
      setDisplayTotalCountError(true);
      setShowErrorPopup(true);
    } else {
      setShowEwallet(true);
      setShowInvoice(false);
    }
  };

  const invoicePayload = {
    invoiceDetailsRequest: props.multipleInvoices.map((invoiceData) => {
      return {
        invoiceDmrNumber: invoiceData?.invoiceNo,
        flag: 1,
      };
    }),
  };

  // fuction to calculate total invoice
  const invoicesCheckedHandler = (invoices) => {
    const invoicesArray = [];
    let totalAmountArray = [];
    invoices.forEach((invoice) =>
      invoicesArray.push({ invoiceNo: invoice?.dmrNumber, checked: true })
    );
    totalAmountArray = invoices.map((invoice) =>
      parseFloat(invoice?.dueAmount.replace(/,/g, ""))
    );
    const totalAmount = totalAmountArray.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    setInvoiceTotalAmount(totalAmount.toFixed(2));
    setInvoiceChecked(invoicesArray);
  };

  const payload = JSON.stringify(invoicePayload);

  // fetch invoices
  const getInvoicesData = async () => {
    setIsInvoiceLoading(true);
    await fetchInvoiceEntitiesSrv(payload).then((res) => {
      if (res?.status === 200) {
        setInvoiceDetailsData(res?.data?.data?.invoiceDetailsResponse);
        setSelectedInvoicesData(
          res?.data?.data?.invoiceDetailsResponse?.invoiceDetails
        );
        invoicesCheckedHandler(
          res?.data?.data?.invoiceDetailsResponse?.invoiceDetails
        );
      } else {
        setInvoiceDetailsData([]);
        setSelectedInvoicesData([]);
      }
    });
    setIsInvoiceLoading(false);
  };

  //function triggered on click of checkbox
  const multipleSelectedInvoicesHandler = (
    multipleInvoicesObjectData,
    multipleInvoicesChecked
  ) => {
    const invoiceDataDetails = JSON.parse(multipleInvoicesObjectData);

    if (!multipleInvoicesChecked) {
      const findInvoice = selectedInvoicesData?.find(
        (invoice) => invoice?.invoiceNo === invoiceDataDetails?.dmrNumber
      );
      if (!findInvoice) {
        setSelectedInvoicesData((prevValue) => [
          ...prevValue,
          invoiceDataDetails,
        ]);
      }
      const invoiceCheckedArray = [...invoiceChecked];
      const invoiceCheckedDataIndex = invoiceChecked.findIndex(
        (invoiceSelected) =>
          invoiceSelected?.invoiceNo === invoiceDataDetails?.dmrNumber
      );
      invoiceCheckedArray[invoiceCheckedDataIndex].checked = true;
      setInvoiceChecked(invoiceCheckedArray);
    } else {
      setSelectedInvoicesData((prevValue) =>
        prevValue.filter(
          (multipleInvoiceFilter) =>
            multipleInvoiceFilter?.dmrNumber !== invoiceDataDetails?.dmrNumber
        )
      );

      const invoiceCheckedArray = [...invoiceChecked];
      const invoiceCheckedDataIndex = invoiceChecked.findIndex(
        (invoiceSelected) =>
          invoiceSelected?.invoiceNo === invoiceDataDetails?.dmrNumber
      );
      invoiceCheckedArray[invoiceCheckedDataIndex].checked = false;
      setInvoiceChecked(invoiceCheckedArray);
    }
  };

  const unselectInvoicesHandler = () => {
    setUnselectInvoices(true);
  };

  const updateInvoiceTotal = () => {
    const selectedInvoicesArray = [];
    let totalInvoiceAmountArray = [];
    selectedInvoicesData.forEach((invoice) =>
      selectedInvoicesArray.push({
        invoiceNo: invoice?.dmrNumber,
        checked: true,
      })
    );
    totalInvoiceAmountArray = selectedInvoicesData.map((invoice) =>
      parseFloat(invoice?.dueAmount.replace(/,/g, ""))
    );
    const totalAmount = totalInvoiceAmountArray.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    setInvoiceTotalAmount(totalAmount.toFixed(2));
  };

  const updateInvoicesHandler = () => {
    setInvoiceDetailsData((prevValue) => {
      let invoicesObject = { ...prevValue };
      invoicesObject.invoiceDetails = selectedInvoicesData;
      return invoicesObject;
    });
    updateInvoiceTotal();
    setUnselectInvoices(false);
  };

  useEffect(() => {
    if (multipleInvoicesComponent?.current === true) {
      multipleInvoicesComponent.current = false;
      getInvoicesData();
    }
    /*eslint-disable */
  }, []);

  console.log(invoiceDetailsData, "invoice");

  const bodyData = (
    <React.Fragment>
      <p className="red">
        <span className="err-icon">i</span>
        Please note that the due amount cannot exceed $100,000. If your invoices
        total more than the maximum limit, please group them into smaller
        amounts
      </p>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <MultiInvoiceContext.Provider
        value={{
          invoiceDetailsData,
        }}
      >
        <Modal
          show={showInvoice}
          onHide={() => {
            setShowInvoice(false);
            props.onCloseInvoice();
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton className="modal-h">
            <Modal.Title
              id="contained-modal-title-vcenter"
              className="title-modal"
            >
              Invoices
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {isInvoiceLoading ? (
              <Loader />
            ) : (
              <div className="multipleInvoice-cnt">
                <div className="report-tbl-header">
                  {unSelectInvoices && <div>Select Invoice</div>}
                  <div>Invoice No.</div>
                  <div>Contract Type</div>
                  <div>Contract No./Legacy No.</div>
                  <div>Account</div>
                  <div>Invoice Balance</div>
                </div>
                <div>
                  {invoiceDetailsData?.invoiceDetails?.map((invoiceData, i) => {
                    const multipleSelectedInvoiceObject =
                      JSON.stringify(invoiceData);
                    const invoiceSelected = invoiceChecked.find(
                      (invoice) => invoice?.invoiceNo === invoiceData?.dmrNumber
                    )?.checked;
                    return (
                      <React.Fragment>
                        <div className="tbl-cnt">
                          <div className="report-tbl-row" key={i}>
                            {unSelectInvoices && (
                              <div className="input-check-box">
                                <input
                                  type="checkbox"
                                  name={multipleSelectedInvoiceObject}
                                  onChange={(e) => {
                                    multipleSelectedInvoicesHandler(
                                      e.target.name,
                                      invoiceSelected
                                    );
                                  }}
                                  checked={invoiceSelected}
                                />
                              </div>
                            )}
                            <div>
                              {invoiceData?.dmrNumber
                                ? invoiceData?.dmrNumber
                                : "-"}
                            </div>
                            <div>
                              {
                                invoiceDetailsData?.contractItemDetails?.find(
                                  (data) =>
                                    data?.dmrNumber === invoiceData?.dmrNumber
                                )?.programCode
                              }
                            </div>
                            <div>
                              {invoiceData?.contractNumber
                                ? invoiceData?.contractNumber
                                : "-"}
                            </div>
                            <div>
                              {invoiceData?.accountName
                                ? invoiceData?.accountName
                                : "-"}
                            </div>
                            <div>
                              $
                              {invoiceData?.dueAmount
                                ? invoiceData?.dueAmount
                                : "-"}
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>

                <div className="invoice-total">
                  {invoiceDetailsData?.invoiceDetails?.length >= 1 && (
                    <div>
                      {`$ 
                      ${Number(
                        parseFloat(invoiceTotalAmount).toFixed(2)
                      ).toLocaleString("en", {
                        minimumFractionDigits: 2,
                      })}`}
                    </div>
                  )}
                  <div>Total Invoice</div>
                </div>
                {invoiceTotalAmount >= 100000 ? bodyData : ""}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              className="modal-Continue btn-blue"
              onClick={() => {
                setShowInvoice(false);
                props.onCloseInvoice();
              }}
            >
              Close
            </Button>
            {!unSelectInvoices && selectedInvoicesData.length >= 2 && (
              <button
                className="btn btn-blue"
                onClick={unselectInvoicesHandler}
              >
                Unselect Invoices
              </button>
            )}
            {unSelectInvoices && (
              <Button
                className="btn btn-blue"
                onClick={updateInvoicesHandler}
                disabled={selectedInvoicesData?.length < 1}
              >
                Update
              </Button>
            )}
            {invoiceTotalAmount >= 100000 ? (
              <>
                {!unSelectInvoices && (
                  <button className="btn btn-blue" disabled="true">
                    PAY NOW
                  </button>
                )}
              </>
            ) : (
              <Button
                variant="primary"
                className="modal-Continue btn-blue"
                onClick={displayPaymentHandler}
                disabled={
                  selectedInvoicesData.length > 0 && !unSelectInvoices
                    ? false
                    : true
                }
              >
                Pay Now
              </Button>
            )}
          </Modal.Footer>
        </Modal>

        {!displayTotalCountError && (
          <EwalletPayment
            show={showEwallet}
            onHide={() => {
              setShowEwallet(false);
              props.onCloseInvoice();
            }}
            setEwalletShow={setShowEwallet}
            onHidePopup={() => setShowEwallet(false)}
            onMultipleInvoice={true}
            onHideInvoice={() => props.onCloseInvoice()}
            multipleInvoices={selectedInvoicesData}
          />
        )}
      </MultiInvoiceContext.Provider>
    </React.Fragment>
  );
};

export default MultipleInvoices;
