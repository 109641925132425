import axios from "./axios";
import endPoint from "./endpoint";

export const fetchContractListSrv = (payload) => {
  return axios.post(endPoint.contractList(), payload);
};

export const fetchRatesListSrv = (query) => {
  return axios.get(endPoint.ratesList(query));
};

export const contractsDetailsTransferSrv = (payload) => {
  return axios.post(endPoint.contractDetailsTransfer(), payload);
};

export const fetchDocumentEntitiesSrv = (payload) => {
  return axios.post(endPoint.contractDocumentEntities(), payload);
};

export const fetchContractPdfSrv = (id) => {
  return axios.get(endPoint.contractPdf(id), {
    responseType: "blob",
  });
};

export const postContractEntitiesSrv = (payload) => {
  return axios.post(endPoint.postContractEntities(), payload);
};

export const uploadContractFileSrv = (payload) => {
  return axios.post(endPoint.uploadContractFile(), payload);
};

export const fetchExcelFileSrv = (query) => {
  return axios.get(endPoint.fetchExcelFile(query), {
    responseType: "blob",
  });
};

export const transferContractSrv = (payload) => {
  return axios.post(endPoint.transferContract(), payload);
};

//v2 services
export const fetchContractListSrvV2 = (payload) => {
  return axios.post(endPoint.contractListV2(), payload);
};
