import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./AppliedUbCreditsModal.scss";
import AppliedCreditsConfimation from "../AppliedCreditsConfimation/AppliedCreditsConfimation";

const AppliedUbCreditsModal = (props) => {
  const { selectedcreditsinvoices, selectedopeninvoices, totalcreditsammount } =
    props;
  const [remainingCreditsAmmount, setRemainingCreditsAmmount] = useState(0);
  const [showAppliedConfirmationModal, setShowAppliedConfirmationModal] =
    useState(false);

    // calculating remaining balance
  useEffect(() => {
    const totalCreditsAppliedAmmount =
      selectedopeninvoices?.length > 0 &&
      selectedopeninvoices
        ?.reduce(
          (a, v) =>
            parseFloat(a) + parseFloat(v.invoiceBalance.replace(/,/g, "")),
          0
        )
        .toFixed(2);

    setRemainingCreditsAmmount(
      (totalCreditsAppliedAmmount - totalcreditsammount).toFixed(2)
    );
  }, [selectedopeninvoices, totalcreditsammount]);

  const closePopupHandler = () => {
    props.onHide();
  };

  const openAppliedConfirmationModal = () => {
    setShowAppliedConfirmationModal(true);
  };
  const closeAppliedConfirmationModal = () => {
    setShowAppliedConfirmationModal(false);
  };

  return (
    <React.Fragment>
      <Modal
        {...props}
        aria-labelledby="selected credit invoices"
        contentClassName={
          showAppliedConfirmationModal
            ? "applied-credits-container-display"
            : "applied-credits-container"
        }
        backdropClassName="applied-credits-modal"
      >
        <Modal.Header closeButton className="modal-h">
          <Modal.Title className="title-modal">
            <h2>
              <strong>Usage Based Credit Application</strong>
            </h2>
            <p>
              Invoice will be paid from oldest to newest and any remaining
              balance will be amended to the newest invoice.
            </p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="invoice-applied-container">
            <div className="invoices-details">
              <div className="selected-credit-ammount">
                <div className="selected-credit-ammount-label">
                  <span> Credits Selected</span>
                </div>
                <div className="selected-credit-ammount-value">
                  <span>
                    {parseFloat(totalcreditsammount).toLocaleString("en", {
                      minimumFractionDigits: 2,
                    })}{" "}
                    USD
                  </span>
                </div>
              </div>

              <div className="selected-invoice-details">
                <div className="selected-invoice-details-hdr">
                  <span>Invoices Selected</span>
                  <span> </span>
                </div>
              </div>

              {selectedopeninvoices?.length > 0 &&
                selectedopeninvoices?.map((invoiceDetails, index) => (
                  <div className="selected-invoice-details" key={index}>
                    <div className="selected-invoice-label">
                      <span>{invoiceDetails?.invoiceNumber}</span>
                    </div>
                    <div className="selected-invoice-value">
                      <span> {invoiceDetails?.invoiceBalance} USD</span>
                    </div>
                  </div>
                ))}
            </div>

            <div className="remaining-credit-ammount">
              <div className="remaining-credit-ammount-label">
                <span> Remaining Balance</span>
              </div>
              <div className="remaining-credit-ammount-value">
                <span>
                  {parseFloat(remainingCreditsAmmount).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })}{" "}
                  USD
                </span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-white"
            data-test="modal-cancel"
            onClick={closePopupHandler}
          >
            Go Back
          </Button>
          <Button
            className="btn btn-blue"
            onClick={openAppliedConfirmationModal}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      {showAppliedConfirmationModal && (
        <AppliedCreditsConfimation
          selectedcreditsinvoices={selectedcreditsinvoices}
          selectedopeninvoices={selectedopeninvoices}
          remainingcreditsammount={remainingCreditsAmmount}
          show={showAppliedConfirmationModal}
          onHide={closeAppliedConfirmationModal}
          backdrop="static"
        />
      )}
    </React.Fragment>
  );
};

export default AppliedUbCreditsModal;
