import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Form, Button, Modal } from "react-bootstrap";
import "./EwalletPayment.scss";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import EditCards from "./EditCards";
import Config from "../../../services/config";
import PaymentConfirmation from "./PaymentConfirmation/PaymentConfirmation";
import UserContext from "../UserContext/UserContext";
import { Link } from "react-router-dom";
import {
  fetchCreditCardListSrv,
  updateCreditCardEntitiesSrv,
  deleteCreditCardEntitiesSrv,
  creditCardTemplateSrv,
  creditCardTokenSrv,
  makeCreditCardPaymentSrv,
} from "../../../services/ewallet.service";
import Loader from "../../common/Loader/Loader";
import C from '../../../constants/constant'

const EwalletPayment = (props) => {
  const [, setMessage] = useState("");
  const [cardPresent, setCardPresent] = useState(false);
  const { configData } = useSelector((state) => state.config);
  const [cardTemplate, setCardTemplate] = useState();
  const [showCardPopup, setShowCardpopup] = useState();
  const [, setisError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [addCard, setAddCard] = useState(false);
  const [loading, setloading] = useState(false);
  const [cardDatas, setCardDatas] = useState([]);
  const [checked, setChecked] = useState(0);
  const [loadCard, setLoadCard] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [cardDetaislApi, setCardDetailsApi] = useState("");
  const [paymentResponse, setPaymentRes] = useState("");
  const [paymentRequest, setPaymentRequest] = useState("");
  const [deleteCardData, setDeleteCardData] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [editCardData, setEditCardData] = useState("");
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [disableRadio, setDisableRadio] = useState(false);
  const [, setEwalletTokenRes] = useState([]);
  const [ewaaletTokenResponseErorr, setEwalletTokenResError] = useState([]);
  const [payConfirmModal, setPayConfirmModal] = useState(false);
  const [makePaymentError, setMakePaymentError] = useState("");
  const invoiceData = useContext(UserContext);

  const closeCardPopup = () => {
    setShowCardpopup(false);
    setAddCard(false);
    setErrorMsg("");
    setMakePaymentError("");
    setCardDatas([])
    setCardPresent(false)
  };
  const openCardPopup = () => {
    if (props.onMultipleInvoice) {
      props.onHidePopup();
    } else {
      props.onHide();
    }
    setShowCardpopup(true);
    setMakePaymentError("");
    postNewCreditCard();
  };

  useEffect(() => {
    if (props.show) {
      getSavedCreditCardDetails();
    }
    if (isEdit) {
      setChecked(0);
    }
    /*eslint-disable */
    //  hideButton.style.display = "none";
  }, [props.show, isEdit]);
  /* to get saved credit card details */
  const getSavedCreditCardDetails = async () => {
    setLoadCard(true);
    await fetchCreditCardListSrv().then((res) => {
      if (
        res?.data?.data?.count !== 0 &&
        res?.data?.data?.status === "Success"
      ) {
        setCardPresent(true);
        setCardDatas(res?.data?.data?.data);
        setLoadCard(false);
      } else {
        setCardPresent(false);
        setMessage(res?.data?.errorMessage);
        setLoadCard(false);
      }
    });
  };

  /* Update card Details  */
  const updateCardDetails = async (data) => {
    setIsEditLoading(true);
    const payload = JSON.stringify(data);
    await updateCreditCardEntitiesSrv(payload).then((res) => {
      if (res?.data?.isSuccess) {
        setIsEditLoading(false);
        setIsEdit(false);
      } else {
        setisError(true);
        setErrorMsg(res.statusText);
        setIsEditLoading(false);
      }
    });
  };

  const amnt = props?.multipleInvoices?.map((item) =>
    parseFloat(item.dueAmount?.replace(/,/g, ""))
  );

  const paymentPayload = props?.multipleInvoices
    ? props.multipleInvoices.map((item) => {
        return {
          dmrNumber: item.dmrNumber,
          cardType:
            cardDetaislApi?.creditCardTypeCd === undefined
              ? cardDatas.find(
                  (details) => details.primaryCreditCardInd === "1"
                )
                ? cardDatas.find(
                    (details) => details.primaryCreditCardInd === "1"
                  )?.creditCardTypeCd
                : cardDatas[checked]?.creditCardTypeCd
              : cardDetaislApi?.creditCardTypeCd,
          cardNumber:
            cardDetaislApi?.creditCardTokenNum === undefined
              ? cardDatas.find(
                  (details) => details.primaryCreditCardInd === "1"
                )
                ? cardDatas.find(
                    (details) => details.primaryCreditCardInd === "1"
                  )?.creditCardTokenNum
                : cardDatas[checked]?.creditCardTokenNum
              : cardDetaislApi?.creditCardTokenNum,
          validTo:
            cardDetaislApi?.creditCardExpirationDt === undefined
              ? cardDatas.find(
                  (details) => details.primaryCreditCardInd === "1"
                )
                ? cardDatas.find(
                    (details) => details.primaryCreditCardInd === "1"
                  )?.creditCardExpirationDt
                : cardDatas[checked]?.creditCardExpirationDt
              : cardDetaislApi?.creditCardExpirationDt,
          amount: parseFloat(item.dueAmount?.replace(/,/g, "")),
          hashKey: item?.hashKey,
        };
      })
    : [
        {
          dmrNumber: props.dmrNum,
          cardType: cardDatas.find(
            (details) => details.primaryCreditCardInd === "1"
          )
            ? cardDatas.find((details) => details.primaryCreditCardInd === "1")
                ?.creditCardTypeCd
            : cardDatas[checked]?.creditCardTypeCd,
          cardNumber: cardDatas.find(
            (details) => details.primaryCreditCardInd === "1"
          )
            ? cardDatas.find((details) => details.primaryCreditCardInd === "1")
                ?.creditCardTokenNum
            : cardDatas[checked]?.creditCardTokenNum,
          validTo: cardDatas.find(
            (details) => details.primaryCreditCardInd === "1"
          )
            ? cardDatas.find((details) => details.primaryCreditCardInd === "1")
                ?.creditCardExpirationDt
            : cardDatas[checked]?.creditCardExpirationDt,
          amount:
            props.invoiceAmount === undefined && props?.multipleInvoices
              ? amnt[0]
              : parseFloat(props.invoiceAmount?.replace(/,/g, "")),
          hashKey: props?.hashKey,
        },
      ];
  const paymentPayload1 = {
    paymentRequest: [
      {
        dmrNumber: props.dmrNum,
        cardType: cardDetaislApi?.creditCardTypeCd,
        cardNumber: cardDetaislApi?.creditCardTokenNum,
        validTo: cardDetaislApi?.creditCardExpirationDt,
        amount: parseFloat(props.invoiceAmount?.replace(/,/g, "")),
        hashKey:props?.hashKey
      },
    ],
  };

  /* Make Payment  */
  const makePayment = async (dataFromToken) => {
    let paymentPayloadToken;
    let flag = true;
    setloading(true);
    if (dataFromToken?.data?.data?.status === "Success") {
      flag = false;
      paymentPayloadToken = {
        paymentRequest: props.multipleInvoices
          ? props.multipleInvoices.map((item) => {
              return {
                dmrNumber: item.dmrNumber,
                cardType: dataFromToken?.data?.data?.data[0]?.creditCardTypeCd,
                cardNumber:
                  dataFromToken?.data?.data?.data[0]?.creditCardTokenNum,
                validTo:
                  dataFromToken?.data?.data?.data[0]?.creditCardExpirationDt
                    .split(" ")
                    .slice(0, 4)[0] +
                  " " +
                  dataFromToken?.data?.data?.data[0]?.creditCardExpirationDt
                    .split(" ")
                    .slice(0, 4)[1] +
                  " " +
                  dataFromToken?.data?.data?.data[0]?.creditCardExpirationDt
                    .split(" ")
                    .slice(0, 4)[2]
                    .replace(",", ""),
                amount: parseFloat(item.dueAmount?.replace(/,/g, "")),
                hashKey: item?.hashKey,
              };
            })
          : [
              {
                dmrNumber: props.dmrNum,
                cardType: dataFromToken?.data?.data?.data[0]?.creditCardTypeCd,
                cardNumber:
                  dataFromToken?.data?.data?.data[0]?.creditCardTokenNum,
                validTo:
                  dataFromToken?.data?.data?.data[0]?.creditCardExpirationDt
                    .split(" ")
                    .slice(0, 4)[0] +
                  " " +
                  dataFromToken?.data?.data?.data[0]?.creditCardExpirationDt
                    .split(" ")
                    .slice(0, 4)[1] +
                  " " +
                  dataFromToken?.data?.data?.data[0]?.creditCardExpirationDt
                    .split(" ")
                    .slice(0, 4)[2]
                    .replace(",", ""),
                amount: parseFloat(props.invoiceAmount?.replace(/,/g, "")),
                hashKey: props?.hashKey,
              },
            ],
      };
    }

    const payload = flag
      ? cardDetaislApi
        ? props?.multipleInvoices
          ? JSON.stringify({ paymentRequest: paymentPayload })
          : JSON.stringify(paymentPayload1)
        : JSON.stringify({ paymentRequest: paymentPayload })
      : JSON.stringify(paymentPayloadToken);

    await makeCreditCardPaymentSrv(payload).then((res) => {
      if (res?.data?.isSuccess) {
        setPayConfirmModal(true);
        setPaymentRes(res?.data?.data?.paymentResponse);
        setPaymentRequest(res?.data?.data?.paymentRequest);
        setloading(false);
        props.setEwalletShow((preVal) => {
          return false;
        });
        closeCardPopup();
        setCardDetailsApi("");
      } else {
        setloading(false);
        setMakePaymentError(res);
      }
    });
  };

  // delete credit card details
  const deleteCardDetails = async (id) => {
    setloading(true);
    await deleteCreditCardEntitiesSrv(id).then((res) => {
      if (res?.data?.isSuccess) {
        getSavedCreditCardDetails();
        setConfirmDelete(false);
        setloading(false);
      } else {
        setErrorMsg(res.statusText);
        setloading(false);
      }
    });
  };

  const templatePayload = {
    templateCode: "6",
    domainName: configData?.MSP_TOKEN?.MSP_DOMAIN_NAME
      ? Config?.resources[configData?.MSP_TOKEN?.MSP_DOMAIN_NAME]?.hostUrl
      : "localhost",
  };

  /*To get credit card template to add new card */
  const postNewCreditCard = async () => {
    setloading(true);
    const payload = JSON.stringify(templatePayload);
    await creditCardTemplateSrv(payload).then((res) => {
      if (res?.status === 200) {
        setCardTemplate(res?.data?.data?.data?.content);
        setloading(false);
        setEwalletTokenResError("");
      } else {
        setAddCard(true);
        setErrorMsg(res.statusText);
        setloading(false);
        setEwalletTokenResError("");
      }
    });
  };

  const loadingComponent = () => <Loader />;

  const errorMessage = (
    <>
      <React.Fragment>
        {ewaaletTokenResponseErorr?.message?.result?.errorDescription ? (
          <h6 className="red">
            {ewaaletTokenResponseErorr?.message?.result?.errorDescription}
          </h6>
        ) : (
          <h6 className="red">
            {ewaaletTokenResponseErorr?.message?.result?.errorMessage}
          </h6>
        )}
      </React.Fragment>
    </>
  );

  let paymentError =
    makePaymentError?.data?.errorCode === 504 ||
    makePaymentError?.data?.errorCode === 500;

  const onSuccessGetCcTokenHandler = async (token) => {
    setloading(true);
    const favcardIndicator = document.getElementById("setFavCard_id").checked;
    const savecardIndicator = document.getElementById("saveCard_id").checked;
    let favcard;
    let savecard;
    if (favcardIndicator === true) {
      favcard = "Y";
    } else {
      favcard = "N";
    }
    if (savecardIndicator === true) {
      savecard = "Y";
    } else {
      savecard = "N";
    }

    const tokenPayload = {
      isSaveCardIndicator: savecard,
      isPrimaryIndicator: favcard,
      paymetricAccessToken: token,
    };

    const payload = JSON.stringify(tokenPayload);
    await creditCardTokenSrv(payload).then((res) => {
      if (res?.status === 200) {
        if (res?.data?.data?.status === "Failure") {
          setloading(false);
          setEwalletTokenResError(res);
          setEwalletTokenRes([]);
        } else {
          setEwalletTokenRes(res);
          makePayment(res);
        }
      } else {
        setloading(false);
        setEwalletTokenRes("");
      }
    });
  };

  /*On Cancel delete confirmation Modal */
  const cancelDeletePopup = () => {
    setConfirmDelete(false);
  };
  const onClickDelete = (data) => {
    setConfirmDelete(true);
    setDeleteCardData(data);
  };
  const onConfirmDelete = () => {
    deleteCardDetails(deleteCardData.id);
  };

  /*For edit Functionality  */
  const onClickEdit = (data) => {
    setIsEdit(true);
    setEditCardData(data);
  };
  const cancelEditPopUp = () => {
    setIsEdit(false);
  };
  const onCardUpdate = (payload) => {
    updateCardDetails(payload);
  };
  const useThisCard = (props) => {
    window["submitform"]();
  };
  window["onSuccessGetCcTokenHandler"] = (
    accessToken,
    isSaveCard,
    isPrimaryInd
  ) => {
    onSuccessGetCcTokenHandler(accessToken);
  };

  /*Pop up having credit card template */
  const cardPopup = (
    <React.Fragment>
      <Modal
        size="sm"
        centered
        show={showCardPopup}
        onHide={() => {
          closeCardPopup();
          invoiceData.setDisplayCheckbox(false);
          invoiceData.setMultipleInvoicesData([]);
        }}
        backdrop="static"
      >
        <Modal.Header closeButton className="modal-h">
          <Modal.Title>
            {addCard ? (
              <h3 style={{ color: "red" }}>Error in Add new Card</h3>
            ) : (
              <h3>Add card</h3>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {addCard ? (
            <div style={{ textAlign: "center" }}>
              <h2>{errorMsg}</h2>
              <h3>Oops!!! Something went wrong..</h3>
            </div>
          ) : (
            <>
              <div dangerouslySetInnerHTML={{ __html: cardTemplate }}></div>

              <div className="ewaalet-button">
                {!loading && (
                  <>
                    <Button
                      variant="primary"
                      type="submit"
                      className="btn-white btn-ewaalet"
                      onClick={() => {
                        closeCardPopup();
                        invoiceData.setDisplayCheckbox(false);
                        invoiceData.setMultipleInvoicesData([]);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      type="submit"
                      className="btn-blue btn-ewaalet"
                      onClick={useThisCard}
                    >
                      Pay Now
                    </Button>
                  </>
                )}
              </div>
              {loading && loadingComponent()}
              {ewaaletTokenResponseErorr && errorMessage}
              {makePaymentError && (
                <h6 className="red text-center">
                  {makePaymentError?.data?.errorDescription} &nbsp;
                  {paymentError && (
                    <a href={configData?.MSP_TOKEN?.MSP_DOMAIN_NAME?Config?.resources[configData?.MSP_TOKEN?.MSP_DOMAIN_NAME]?.supportUrl:C?.MSP_SUPPORT_URL} target="_blank">
                    Other Portal Issues (honeywell.com)
                  </a>
                  )}
                </h6>
              )}
            </>
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );

  /* if there is no credit card */
  const noCardDetails = (
    <Modal.Body className="modal-body">
      <>
        <div className="ewallet-cnt"></div>
        {props.show && (
          <div className="card-btn no-cards">
            <Button
              variant="primary"
              type="submit"
              className="btn-white"
              onClick={openCardPopup}
              disabled={loadCard}
            >
              Add new card
            </Button>
          </div>
        )}
      </>
    </Modal.Body>
  );

  const OnSelectRadio = (details, row) => {
    setChecked(row);
    setCardDetailsApi(details);

    let today = new Date();
    let mm = today.getMonth() + 1; //January is 0!
    let yyyy = today.getFullYear();
    // checking expiration date only for this format'05/2027'
    if (parseInt(details.creditCardExpirationYear) < yyyy) {
      setDisableRadio(true);
    } else if (
      parseInt(details.creditCardExpirationYear) === yyyy &&
      mm > parseInt(details.creditCardExpirationMonth)
    ) {
      setDisableRadio(true);
    } else {
      setDisableRadio(false);
    }
  };

  const cardDetails = (
    <Modal.Body className="modal-body select-card">
      <div className="ewallet-cnt">
        <div className="cards-list">
          <div className="tbl-hdr">
            <div>Select</div>
            <div>Card Number</div>
            <div>Exp</div>
            <div>Tools</div>
          </div>
          <div className="tbl-cnt">
            <React.Fragment>
              {cardDatas
                ?.sort(
                  (a, b) => b.primaryCreditCardInd - a.primaryCreditCardInd
                )
                .map((details, index) => (
                  <div className="tbl-row" key={index}>
                    <div className="input-rdb">
                      <Form.Check
                        value="design"
                        type="radio"
                        aria-label="radio 1"
                        onChange={() => OnSelectRadio(details, index)}
                        checked={checked === index ? true : false}
                      />
                    </div>
                    <div>
                      {details?.creditCardTokenNum
                        .split("-")[1]
                        .replaceAll(
                          `${details?.creditCardTokenNum.split("-")[1]}`,
                          "* * * * - * * * * - * * * * -"
                        )}
                      {details?.creditCardTokenNum.split("-")[2]}
                    </div>
                    <div>
                      {details?.creditCardExpirationMonth}/
                      {details?.creditCardExpirationYear}
                      <span className="fav">
                        {details?.primaryCreditCardInd === "1"
                          ? "Favorite"
                          : ""}
                      </span>
                    </div>
                    <div>
                      <Link to="#" onClick={() => onClickEdit(details)}>
                        Edit
                      </Link>
                      <Link to="#" onClick={() => onClickDelete(details)}>
                        Delete
                      </Link>
                    </div>
                  </div>
                ))}
            </React.Fragment>
          </div>
          {disableRadio && (
            <p className="err m-top-20">
              This card is expired. Please edit or select other card
            </p>
          )}
          {loading && loadingComponent()}
          {/* {paymentRespounce} */}
          <div className="card-btn m-top-40">
            <Button
              variant="primary"
              type="submit"
              className="btn-white"
              disabled={loading ? true : false}
              onClick={openCardPopup}
            >
              Add new card
            </Button>

            <Button
              variant="primary"
              type="submit"
              className="btn-blue"
              disabled={disableRadio || loading ? true : false}
              onClick={() => {
                makePayment();
              }}
            >
              Pay Now
            </Button>
          </div>
        </div>
      </div>
    </Modal.Body>
  );

  return (
    <>
      {cardPopup}
      <Modal
        class="text-right modal-dialog"
        show={props.show}
        onHide={() => {
          props.setEwalletShow((preVal) => {
            return false;
          });
          setisError(false);
          setErrorMsg("");
          setChecked(0);
          setMakePaymentError("");
          setCardDatas([])
          setCardPresent(false)
          invoiceData.setDisplayCheckbox(false);
          invoiceData.setMultipleInvoicesData([]);
        }}
        backdrop="static"
        keyboard={false}
        aria-labelledby="contained-modal-title-success"
        centered
        data-test="test-right"
      >
        <Modal.Header
          closeButton
          className="modal-upload-button-border cards-hdr"
        >
          <Modal.Title className="modal-title no-card">
            <h2 className="m-btm-30">Select a payment method</h2>

            {cardPresent && cardDatas.length !== 0 ? (
              ""
            ) : loadCard ? (
              <Loader />
            ) : (
              <p>
                No Credit Card associated for the Honeywell Id, Please click on
                ADD NEW CARD to add your card
              </p>
            )}
          </Modal.Title>
        </Modal.Header>
        {cardPresent ? cardDetails : noCardDetails}
        {makePaymentError && (
          <h6 className="red text-center">
            {makePaymentError?.data?.errorDescription}&nbsp;
            {paymentError && (
               <a href={configData?.MSP_TOKEN?.MSP_DOMAIN_NAME?Config?.resources[configData?.MSP_TOKEN?.MSP_DOMAIN_NAME]?.supportUrl:C?.MSP_SUPPORT_URL} target="_blank">
               Other Portal Issues (honeywell.com)
             </a>
            )}
          </h6>
        )}
      </Modal>
      {confirmDelete && (
        <DeleteConfirmationModal
          show={confirmDelete}
          onCancel={cancelDeletePopup}
          onConfirmDelete={onConfirmDelete}
          data={deleteCardData}
          loading={loading}
        />
      )}
      {isEdit && (
        <EditCards
          show={isEdit}
          onCancel={cancelEditPopUp}
          data={editCardData}
          onCardUpdate={onCardUpdate}
          loading={isEditLoading}
          setIsEditLoading={setIsEditLoading}
        />
      )}
      {payConfirmModal && (
        <PaymentConfirmation
          show={payConfirmModal}
          onClose={() => setPayConfirmModal(false)}
          paymentResponse={paymentResponse}
          req={paymentRequest}
        />
      )}
    </>
  );
};

export default EwalletPayment;
