import React from "react";
import "./ContractsProgressBar.scss";
import { Button } from "react-bootstrap";
import ContractsImage from '../../../../assets/images/contracts.png'
import TransferProgressBar from "./TransferProgressBar";
import ModalPopup from "../../../common/ModalPopup/ModalPopup";

const ContractsProgressBar = (props) => {
  const labelArray = [
    "Information Submitted",
    "Under Review",
    "Transfer Successfull",
  ];
  const headerContentPopup = <h2>Transfer Status</h2>;

  const bodyContentPopup = (
    <>
      <TransferProgressBar step={labelArray} completed={props?.subStatus === "TransferSubmitted"? "Information Submitted":props?.subStatus === "HITR" ? "Under Review" : props?.subStatus ==="CLS"?"Transfer Successfull":"Information Submitted"} />
      <div className="contract-usage">
          <div className="contract-img">
            <img src={ContractsImage} alt="contracts" />
          </div>
          <div className="contract-content">
            <span>Report usage hours during transfer</span>
            <span>
              Make sure your are continuing to report hours while the transfer
              is underway. You will be notified once the transfer is complete.
            </span>
          </div>
        </div>
    </>
  );

  const footerContentPopup = (
    <Button className="btn btn-blue" onClick={props.onHide}>
      Close
    </Button>
  );
  return (
    <ModalPopup
      showPopup={props.show}
      size=""
      arialabel="progress bar"
      cancelPopup={props.onHide}
      contentClassName="progress-cnt"
      backdropClassName={props.backdropClassName}
      centered="true"
      header={headerContentPopup}
      body={bodyContentPopup}
      footer={footerContentPopup}
    />
  );
};

export default ContractsProgressBar;
