import React, { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Loader from "../../common/Loader/Loader";
import "./EwalletPayment.scss";

const EditCards = ({
  show,
  onCancel,
  onCardUpdate,
  data,
  loading,
  setIsEditLoading,
}) => {
  const [editData, setEditData] = useState({
    expDate: "",
    name: "",
  });
  const [checked, setChecked] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [nameErrMsg, setNameErrMsg] = useState("");
  const [isValid, setIsValid] = useState(false);

  const onHandleChange = (e) => {
    e.preventDefault();
    setEditData({
      ...editData,
      [e.target.name]: e.target.value,
    });
    const regex = /^[a-zA-Z]*$/;
    const dateRegex = /^(0?[1-9]|1[012])\/[0-9]{4}$/;
    const specialCharRegex = /^[a-zA-Z0-9 .'/,]+$/;

    const today = new Date();
    const mm = today.getMonth() + 1; //January is 0!
    const yyyy = today.getFullYear();

    if (e.target.name === "expDate") {
      if (e.target.value.length === 0) {
        setErrorMsg(`Please follow this format 'MM/YYYY' eg :10/2024`);
        setIsValid(true);
      } else if (
        e.target.value.length !== 7 ||
        e.target.value.match(regex) ||
        !e.target.value.match(dateRegex)
      ) {
        setErrorMsg(`Please follow this format 'MM/YYYY' eg :10/2024`);
        setIsValid(true);
      }
      //if expired
      else if (e.target.value.length === 7) {
        if (
          e.target.value.split("/")[1] < yyyy ||
          (parseInt(e.target.value.split("/")[1]) === yyyy &&
            mm > parseInt(e.target.value.split("/")[0]))
        ) {
          setErrorMsg(`Given date is expired.Please provide a valid date`);
          setIsValid(true);
        } else if (!e.target.value.match(regex)) {
          setErrorMsg("");
          setIsValid(false);
        }
      }
    }
    if (e.target.name === "name") {
      if (e.target.value.length === 0) {
        setNameErrMsg(`Please enter your name`);
        setIsValid(true);
      } else if (!e.target.value.match(specialCharRegex)) {
        setNameErrMsg(`Please enter a valid name`);
        setIsValid(true);
      } else {
        setNameErrMsg("");
        setIsValid(false);
      }
    }
  };
  const onClickCheckbox = () => {
    setChecked(!checked);
  };

  const payload = {
    id: data.id,
    creditCardHolderNm: editData.name,
    primaryCreditCardInd: checked !== true ? "0" : "1",
    creditCardTypeCd: data.creditCardTypeCd,
    creditCardExpirationMonth: editData.expDate.split("/")[0],
    creditCardExpirationYear: editData.expDate.split("/")[1],
    creditCardTokenNum: data?.creditCardTokenNum,
  };

  useEffect(() => {
    if (show) {
      setEditData({
        expDate: `${data.creditCardExpirationMonth}/${data.creditCardExpirationYear}`,
        name: data.creditCardHolderNm,
      });
      setErrorMsg("");
      setNameErrMsg("");
      setChecked(data.primaryCreditCardInd === "1" ? true : false);
    }
    /*eslint-disable */
  }, [show]);

  const loadingComponent = () => <Loader />;

  return (
    <Modal centered show={show} size="xl">
      <Modal.Header closeButton className="modal-h" onClick={onCancel}>
        <Modal.Title className="modal-title">
          <h2>Edit Payment Method</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <p>
          Edit the expiration date or name on the card and click on "update" to
          save.
        </p>
        <div className="ewallet-cnt edit-card">
          <Form>
            <Form.Group>
              <Form.Label>Credit Card Type</Form.Label>
              <Form.Select
                disabled={show ? true : false}
                value={data.creditCardTypeCd}
                aria-label="Credit Card Type"
              >
                <option>{show ? data.creditCardTypeCd : "Master"}</option>
              </Form.Select>
            </Form.Group>
            <Form.Group>
              <Form.Label>Credit Card Number</Form.Label>
              <Form.Control
                placeholder="Disabled input"
                aria-label="Credit Card Number"
                disabled={show ? true : false}
                value={`* * * * - * * * * - * * * * -${
                  data?.creditCardTokenNum.split("-")[2]
                }`}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label htmlFor="disabledTextInput">
                Expiration Date
              </Form.Label>
              <Form.Control
                placeholder="Date"
                aria-label="Expiration Date"
                value={editData.expDate}
                onChange={onHandleChange}
                name="expDate"
                disabled={loading}
              />
              <p className="err m-top-20">{errorMsg}</p>
            </Form.Group>
            <Form.Group>
              <Form.Label>Name on card</Form.Label>
              <Form.Control
                placeholder="Name"
                aria-label="Name on card"
                value={editData.name}
                name="name"
                onChange={onHandleChange}
                disabled={loading}
              />
              <p className="err m-top-20">{nameErrMsg}</p>
            </Form.Group>
            <Form.Group className="input-check">
              <Form.Check
                type="checkbox"
                checked={checked}
                aria-label="favorite Card"
                onChange={onClickCheckbox}
                disabled={loading}
              />
              <label>Make this my favorite Card</label>
            </Form.Group>
          </Form>
        </div>
        {loading && loadingComponent()}
      </Modal.Body>
      <Modal.Footer>
        <div className="card-btn">
          <Button
            variant="primary"
            type="submit"
            className="btn-white"
            disabled={loading}
            onClick={() => {
              onCancel();
              setErrorMsg("");
              setNameErrMsg("");
              setIsValid(false);
              setIsEditLoading(false);
            }}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            type="submit"
            className="btn-blue"
            disabled={isValid || loading}
            onClick={() => onCardUpdate(payload)}
          >
            update
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EditCards;
