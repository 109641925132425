import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import "./AppliedCreditsConfimation.scss";
import CreditsImage from "../../../../assets/images/credits.png";
import { fetchApplyCreditsSrv } from "../../../../services/credits.service";
import { appliedCreditsPayload } from "./AppliedCreditsPayload";

const AppliedCreditsConfimation = (props) => {
  const {
    selectedcreditsinvoices,
    selectedopeninvoices,
    remainingcreditsammount,
  } = props;
  const { configData } = useSelector((state) => state.config);
  const [isLoading, setIsLoading] = useState(true);
  const [creditsNumber, setCreditsNumber] = useState("");
  const [applyCreditsError, setApplyCreditsError] = useState(false);
  const [applyCreditsErrorMessage, setApplyCreditsErrorMessage] = useState("");
  const [creditsError, setCreditsError] = useState("");
  const [caseNumber, setCaseNumber] = useState("");

  const applyCreditsConfirmationComponent = useRef(true);

  const payload = appliedCreditsPayload(
    selectedcreditsinvoices,
    selectedopeninvoices,
    remainingcreditsammount
  );

  // api for submitting credits
  const submitInvoiceCredits = async () => {
    setIsLoading(true);
    await fetchApplyCreditsSrv(payload).then((res) => {
      if (res?.status === 200) {
        setCreditsNumber(res?.data?.data?.caseNumber);
        setIsLoading(false);
      } else {
        setApplyCreditsError(true);
        setApplyCreditsErrorMessage(res?.data?.message);
        setCaseNumber(res?.data?.caseNumber);
        setCreditsError(res?.data?.errorDescription);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    if (applyCreditsConfirmationComponent.current === true) {
      applyCreditsConfirmationComponent.current = false;
      submitInvoiceCredits();
    }
    /*eslint-disable */
  }, []);

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <React.Fragment>
      <Modal
        {...props}
        aria-labelledby="selected credit invoices"
        contentClassName="confirmation-container"
        backdropClassName="applied-credits-modal"
        onHide={refreshPage}
      >
        <Modal.Header closeButton className="modal-h">
          <Modal.Title className="title-modal">
            {!isLoading && !applyCreditsError && (
              <>
                <div className="credits-img">
                  <img
                    src={CreditsImage}
                    alt="creditsImage"
                    className="credit-img"
                  />
                </div>
                <div className="credits-title">
                  <div className="credits-submit">
                    <p>Thank You! Your Request Has Been Submitted</p>
                  </div>
                  <div className="credits-reference">
                    <span>Usage Based Credit Application Reference Number</span>
                    <span>{creditsNumber}</span>
                  </div>
                </div>
              </>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!isLoading && !applyCreditsError && (
            <div className="invoice-applied">
              <div className="selected-credits">
                <div className="selected-credits-hdr">
                  <span>Credits Selected</span>
                </div>
                <div className="credits-details">
                  {selectedcreditsinvoices?.length > 0 &&
                    selectedcreditsinvoices?.map((invoiceDetails, index) => (
                      <div className="credits-data">
                        <span>{invoiceDetails?.invoiceNumber}</span>
                        <span>{invoiceDetails?.invoiceAmount} USD</span>
                      </div>
                    ))}
                </div>
              </div>

              <div className="invoice-container">
                <div className="selected-invoice-hdr">
                  <span>Invoices Selected</span>
                </div>

                <div className="invoice-details">
                  {selectedopeninvoices?.length > 0 &&
                    selectedopeninvoices?.map((invoiceDetails, index) => (
                      <div className="invoice-data">
                        <span>{invoiceDetails?.invoiceNumber}</span>
                        <span>{invoiceDetails?.invoiceBalance} USD</span>
                      </div>
                    ))}
                </div>
              </div>

              <div className="remaining-credit">
                <div className="remaining-credit-ammount-label">
                  <span> Remaining Balance</span>
                </div>
                <div className="remaining-credit-ammount-value">
                  <span>
                    {parseFloat(remainingcreditsammount).toLocaleString("en", {
                      minimumFractionDigits: 2,
                    })}{" "}
                    USD
                  </span>
                </div>
              </div>
            </div>
          )}
          {isLoading && (
            <div className="credits-loading-msg">
              <h2>
                {configData?.MSP_INFORMATION_TEXT?.LOADING_APPLYED_UB_CREDITS}
                <div className="loader"></div>
              </h2>
            </div>
          )}

          {applyCreditsError && (
            <div className="credits-error-msg">
              <p>
                {applyCreditsErrorMessage
                  ? `We are unable to process your apply credits request for the selected invoices at this time. ${caseNumber} has been created for you and our customer service team will contact you via email within 48 hours.`
                  : creditsError}
              </p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-blue"
            data-test="modal-cancel"
            onClick={refreshPage}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default AppliedCreditsConfimation;
