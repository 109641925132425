import React from "react";
import './TransferProgressBar.scss'
import ProgressBar from "react-bootstrap/ProgressBar";
import StepNavigation from "./StepNavigation";

const TransferProgressBar = (props) => {
  const labelArray = props?.step || [];
  const activeStage = props?.completed || "";
  const activeIndex = labelArray?.indexOf(activeStage) || 0;
  return (
    <div className="prg-bar">
      <div className="steps-responsive">
        <div className="content">Step 1 of 3</div>
        <div className="bar">
          <ProgressBar now={60} />
        </div>
      </div>
      <StepNavigation labelArray={labelArray} activeIndex={activeIndex} />
    </div>
  );
};

export default TransferProgressBar;
