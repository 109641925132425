import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import C from "../../../../constants/constant.js"
const CreditsErrorPopup = (props) => {
  const { configData } = useSelector((state) => state.config);
  return (
    <Modal
      class="text-right modal-dialog"
      show={props.show}
      backdrop="static"
      keyboard={false}
      onHide={props.onHide}
      aria-labelledby="credits error popup"
      centered
    >
      <Modal.Header
        closeButton
        className="modal-upload-button-border success-close"
      >
        <Modal.Title className="modal-title">Debit amount should be less than total credits selected</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body invoice-success">
        <p>You have selected debit invoices that exceed the total credits selected. 
          <a href={configData?.MSP_TOKEN?.MSP_USAGE_CREDITS_URL ? configData?.MSP_TOKEN?.MSP_USAGE_CREDITS_URL : C.MSP_USAGE_CREDITS_URL } target="_blank"> Please use this form to complete the credit application process.</a></p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-blue"
          data-test="modal-cancel"
          onClick={props.onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CreditsErrorPopup;
