import { fetchInvoiceListSrv } from "../../../services/invoice.service";

export const cancelInvoiceDisabled = async (invoice, contract,changeButtonText) => {
  const bodyContent = {
    query: "",
    filters: {
      all: [
        {
          status: !changeButtonText ? ["Open"] : ["Closed"],
        },
        {
          contract_number: [`${contract}`],
        },
        {
          invoice_type:"Monthly"
        }
      ],
    },
    sort: [
      {
        invoice_period_date: "desc",
      },
    ],
  };
  const payload = JSON.stringify(bodyContent);
  let cancelInvoicesData = {};
  await fetchInvoiceListSrv(payload).then((res) => {
    if (res?.status === 200) {
      cancelInvoicesData = { status: "success", invoices: res?.data?.data?.results };
    } else {
      cancelInvoicesData =  { status: "error", invoices: [] };
    }
  });

  return cancelInvoicesData;
};
