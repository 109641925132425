import axios from "./axios";
import endPoint from "./endpoint";

export const fetchInvoiceListSrv = (payload) => {
  return axios.post(endPoint.invoiceList(), payload);
};

export const fetchInvoiceEntitiesSrv = (payload) => {
  return axios.post(endPoint.invoiceEntities(), payload);
};

export const sendInvoice2UserSrv = (payload) => {
  return axios.post(endPoint.sendInvoice2User(), payload);
};

export const usageBaseCreditSrv = (payload) => {
  return axios.post(endPoint.usageBaseCredit(), payload);
};

export const usageBaseAnayticsSrv = (payload) => {
  return axios.post(endPoint.usageBaseAnaytics(), payload);
};

export const preInvoiceEntitiesSrv = (payload) => {
  return axios.post(endPoint.preInvoiceEntities(), payload);
};

export const invoiceContractDetailsList = (payload) => {
  return axios.post(endPoint.getInvoiceContractDetails(), payload);
};


export const invoiceDownloadSrv = (query) => {
  return axios.get(endPoint.invoiceDownload(query), {
    responseType: "blob",
  });
};

export const invoiceCancelSrv = (payload) => {
  return axios.post(endPoint.cancelInvoice(), payload);
};

export const invoiceDeleteSrv = (payload) =>{
  return axios.post(endPoint.deleteInvoice(),payload)
}

export const invoiceCloseSrv = (payload) =>{
  return axios.put(endPoint.closeInvoice(),payload)
}