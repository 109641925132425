import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import "../DisplayInvoice/DisplayInvoice.scss";
import DisplayPopUpPdf from "./DisplayPdf/DisplayPopUpPdf";
import InfoIcon from "../../../assets/images/info-icon.png";
import logoIcon from "../../../assets/images/hon-logo.png";
import ProgressBar from "react-bootstrap/ProgressBar";
import EwalletPayment from "../EwalletPayment/EwalletPayment.js";
import EmailInvoices from "./EmailInvoice/EmailInvoices";
import {
  fetchInvoiceEntitiesSrv,
  invoiceDeleteSrv,
} from "../../../services/invoice.service";
import C from "../../../constants/constant";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Config from "../../../services/config";
import MaxIcon from "../../../assets/images/max.png";
import { cancelInvoiceDisabled } from "./DisplayInvoiceFunctions";
import CancelInvoicePopup from "./CancelInvoicePopup/CancelInvoicePopup";
import CloseInvoicePopup from "./CloseInvoicePopup/CloseInvoicePopup";

const DisplayInvoice = (props) => {
  const componentRef = useRef();
  const [load, setLoading] = useState(true);
  const [cancelInvoiceLoading, setCancelInvoiceLoading] = useState(true);
  const [displayData, setDisplayData] = useState([]);
  const [, setTotal] = useState(Number);
  const [showEmail, setshowEmail] = useState(false);
  const [ewalletShow, setEwalletShow] = useState(false);
  const [invoiceErrorMessage, setInvoiceErrorMessage] = useState("");
  const { configData, isHoneywellEmployee } = useSelector(
    (state) => state.config
  );
  const [cancelInvoice, setCancelInvoice] = useState([]);
  const [deleteInvoice, setDeleteInvoice] = useState(false);
  const [deleteInvoiceFlag, setDeleteInvoiceFlag] = useState(false);
  const [isInvoiceDeleted, setIsInvoiceDeleted] = useState(false);
  const [deleteInvoiceLoading, setDeleteInvoiceLoading] = useState(false);
  const [isWindow, setWindow] = useState(false);
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [showCloseInvoice, setShowCloseInvoice] = useState(false);
  const programcodeList = ['HAPP', 'MAPC', 'MAPP' ,'MPP'];
  let invoiceError =
    invoiceErrorMessage?.data?.errorCode === 504 ||
    invoiceErrorMessage?.data?.errorCode === 500;

  const getCancelInvoice = async () => {
    setCancelInvoiceLoading(true);
    let cancelInvoices = await cancelInvoiceDisabled(
      props?.data,
      props?.contract,
      props?.changeButtonText
    );
    if (cancelInvoices?.status === "success") {
      setCancelInvoice(cancelInvoices?.invoices);
      setCancelInvoiceLoading(false);
    }
  };

  const cancelInvoiceHandler = () => {
    setShowCancelPopup(true);
    props.onClose();
  };

  const closeCancelPopupHandler = () => {
    setShowCancelPopup(false);
  };

  //reload popup
  const refreshPopupHandler = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (props.data !== "" && props.show === true) {
      getDisplayInvoices();
      getCancelInvoice();
    }
    /*eslint-disable */
  }, [props.show]);

  const invoiceDetailsBody = {
    invoiceDetailsRequest: [
      {
        invoiceDmrNumber: props.data,
        flag: 1,
        invoiceDmNumber: "",
      },
    ],
  };

  let subTotal =
    displayData.length !== 0
      ? parseFloat(displayData?.invoiceDetails[0]?.subTotal.replace(/,/g, ""))
      : 0;
  let tax =
    displayData.length !== 0
      ? parseFloat(displayData?.invoiceDetails[0]?.stateTaxes.replace(/,/g, ""))
      : 0;

  let total = parseFloat(subTotal + tax)
    .toFixed(2)
    .toString();
  total = new Intl.NumberFormat("en-US", {
    currency: "USD",
    minimumFractionDigits: 2,
  }).format(total);
  total = total !== "NaN" ? total : "0.00";
  const getDisplayInvoices = async () => {
    setLoading(true);
    const payload = JSON.stringify(invoiceDetailsBody);
    await fetchInvoiceEntitiesSrv(payload).then((res) => {
      if (res?.status === 200) {
        let productTotal = 0;
        setDisplayData(res?.data?.data?.invoiceDetailsResponse);
        if (
          res?.data?.data?.invoiceDetailsResponse?.platformDetails?.length >= 1
        ) {
          res?.data?.data?.invoiceDetailsResponse?.platformDetails?.forEach(
            (item) => {
              productTotal += parseFloat(item?.amount.replace(/,/g, ""));
            }
          );
          setTotal(productTotal.toFixed(2));
        }
        setLoading(false);
      } else if (res?.status === 404) {
        setLoading(false);
        setDisplayData([]);
        setDeleteInvoice(true);
        setInvoiceErrorMessage(res);
      } else {
        setLoading(false);
        setDisplayData([]);
        setInvoiceErrorMessage(res);
      }
    });
  };

  const handleClick = () => {
    let pdfContentPage1 = window.document.getElementById("rc-resultsPdf-page1");
    pdfContentPage1.style.display = "block";
    pdfContentPage1.style.marginTop = "30px";
    printPDF();
    pdfContentPage1.style.display = "none";
    pdfContentPage1.style.marginTop = "30px";
  };
  // window min and max
  const maxWindow = () => {
    setWindow((current) => !current);
  };
  const payNow = () => {
    props.onClose();
    setEwalletShow(true);
  };

  const deleteInvoiceHandler = async () => {
    setDeleteInvoiceFlag(true);
    setDeleteInvoiceLoading(true);
    let bodyArray = [];
    bodyArray.push(props?.data?.toString());
    const payload = JSON.stringify(bodyArray);
    await invoiceDeleteSrv(payload).then((res) => {
      if (res?.status === 200) {
        setIsInvoiceDeleted(true);
        setDeleteInvoiceLoading(false);
      } else {
        setIsInvoiceDeleted(false);
        setDeleteInvoiceLoading(false);
      }
    });
  };

  const closeInvoiceHandler = () => {
    setShowCloseInvoice(true);
    props.onClose();
  };

  const invoiceCloseHandler = () => {
    setShowCloseInvoice(false);
  };

  //for cloning html
  const printPDF = () => {
    const pdfContentPage1 = window.document.getElementById(
      "rc-resultsPdf-page1"
    );
    if (
      pdfContentPage1 &&
      pdfContentPage1.getElementsByClassName("pdf-header").length === 0
    ) {
      const pdfSec1 = window.document
        .getElementsByClassName("pdf-header")[0]
        .cloneNode(true);
      const pdfSec2 = window.document
        .getElementsByClassName("pdf-body")[0]
        .cloneNode(true);
      if (pdfSec1 && pdfSec2) {
        pdfContentPage1?.appendChild(pdfSec1);
        pdfContentPage1?.appendChild(pdfSec2);
        handlePrint();
        pdfContentPage1?.removeChild(pdfSec1);
        pdfContentPage1?.removeChild(pdfSec2);
      }
    }
    if (pdfContentPage1) {
      pdfContentPage1.style.display = "none";
      pdfContentPage1.style.marginTop = "0px";
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  //end display
  const errorMessage = (
    <>
      <React.Fragment>
        <p className="red">
          <span className="err-icon">i</span> Please note that invoices with due
          amount that exceed's $100,000 cannot be processed through portal
        </p>
      </React.Fragment>
    </>
  );

  const pdfbody =
    displayData.length !== 0 ? (
      <>
        {
          <div className="re-pdt-cnt invdetail-cnt pdf-cnt">
            <div className="pdf-header">
              <div className="hw-pdf-logo">
                <Link to="#">
                  <img src={logoIcon} alt="honeywellLogo" />
                </Link>
                <div>
                  <strong>Aftermarket Programs Invoice</strong>
                </div>

                <div className="m-top-10">
                  <strong>
                    {displayData.length !== 0
                      ? displayData?.addressForAfterMarketPrograms[0]?.ampCity
                      : "No data available"}
                    ,
                    {displayData.length !== 0 &&
                      displayData?.addressForAfterMarketPrograms[0]?.ampState}
                  </strong>
                </div>
                <div>
                  <strong>Attn:</strong> MSP Engines Administration
                </div>
              </div>
              <div className="customer-info">
                <div>Dept. MSP,2102-229</div>
                <div>
                  Phone:{" "}
                  {displayData.length !== 0
                    ? displayData?.addressForAfterMarketPrograms[0]?.ampPhone
                    : "No data available"}
                </div>

                <div>
                  Fax:{" "}
                  {displayData.length !== 0
                    ? displayData?.addressForAfterMarketPrograms[0]?.ampFax
                    : "No data available"}
                </div>

                <div>
                  Federal Tax ID #:{" "}
                  {displayData.length !== 0
                    ? displayData?.addressForAfterMarketPrograms[0]?.ampFedtaxid
                    : "No data available"}
                </div>

                <div className="email">Email: mspcontracts@honeywell.com</div>
              </div>
            </div>

            <div className="pdf-body">
              <div className="info-txt1">
                <strong className="text-uppercase">
                  Please remit the total payment in USD with reference to the
                  Invoice Number listed below.
                </strong>
                <br />
                <strong className="text-uppercase">
                  Wire remittance detail to be sent to:
                  <a href={`mailto:${C.MSP_MAIL_REMIT_ID}`}>
                    &nbsp;MSPCashApplicationRequests@Honeywell.com
                  </a>
                </strong>
              </div>
              <div className="invoice-body">
                <div className="customer-info">
                  <span className="customer-name">
                    <strong>Wire Transfers:</strong>
                  </span>
                  <span>
                    {displayData.length !== 0
                      ? displayData?.addressForAfterMarketPrograms[0]
                          ?.companyName
                      : "No data available"}
                  </span>
                  <span>
                    {displayData.length !== 0
                      ? displayData?.addressForAfterMarketPrograms[0]?.wtaName
                      : "No data available"}
                  </span>
                  <span>
                    {displayData.length !== 0
                      ? displayData?.addressForAfterMarketPrograms[0]?.wtaCity
                      : "No data available"}
                    ,
                    {displayData.length !== 0 &&
                      displayData?.addressForAfterMarketPrograms[0]?.wtaState}
                    ,
                    {displayData.length !== 0 &&
                      displayData?.addressForAfterMarketPrograms[0]?.wtaZip}
                  </span>
                  <span>
                    Account:
                    {displayData.length !== 0
                      ? displayData?.addressForAfterMarketPrograms[0]
                          ?.wtaAccount
                      : "No data available"}
                  </span>
                  <span>
                    ABA#:
                    {displayData.length !== 0
                      ? displayData?.addressForAfterMarketPrograms[0]?.wtaAba
                      : "No data available"}
                  </span>
                  <span>
                    {displayData.length !== 0
                      ? displayData?.addressForAfterMarketPrograms[0]
                          ?.wireTransferSwiftCode
                      : "No data available"}
                  </span>
                </div>
                <div className="customer-info">
                  <span className="customer-name">
                    <strong>Express Mail Payments:</strong>
                  </span>
                  <span>
                    {displayData.length !== 0
                      ? displayData?.addressForAfterMarketPrograms[0]
                          ?.companyName
                      : "No data available"}
                  </span>
                  <span>
                    {displayData.length !== 0
                      ? displayData?.addressForAfterMarketPrograms[0]?.empName
                      : "No data available"}
                  </span>
                  <span>
                    {displayData.length !== 0
                      ? displayData?.addressForAfterMarketPrograms[0]?.empStreet
                      : "No data available"}
                  </span>
                  <span>
                    {displayData.length !== 0
                      ? displayData?.addressForAfterMarketPrograms[0]
                          ?.empStreet2
                      : "No data available"}
                  </span>
                  <span>
                    {displayData.length !== 0
                      ? displayData?.addressForAfterMarketPrograms[0]?.empCity
                      : "No data available"}
                    ,
                    {displayData.length !== 0 &&
                      displayData?.addressForAfterMarketPrograms[0]?.empState}
                    ,
                    {displayData.length !== 0 &&
                      displayData?.addressForAfterMarketPrograms[0]?.empZip}
                  </span>
                </div>
                <div className="customer-info">
                  <span className="customer-name">
                    <strong>Checks :</strong>
                  </span>
                  <span>
                    {displayData.length !== 0
                      ? displayData?.addressForAfterMarketPrograms[0]
                          ?.companyName
                      : "No data available"}
                  </span>
                  <span>
                    P.O Box:{" "}
                    {displayData.length !== 0
                      ? displayData?.addressForAfterMarketPrograms[0]?.cmaPobox
                      : "No data available"}
                  </span>
                  <span>
                    {displayData.length !== 0
                      ? displayData?.addressForAfterMarketPrograms[0]?.cmaCity
                      : "No data available"}
                    ,
                    {displayData.length !== 0 &&
                      displayData?.addressForAfterMarketPrograms[0]?.cmaState}
                    ,
                    {displayData.length !== 0 &&
                      displayData?.addressForAfterMarketPrograms[0]?.cmaZip}
                  </span>
                </div>
              </div>

              <div className="display-header">
                <span className="invoice-deatil-header">Invoice Details</span>
                <span className="terms-hdr">
                  Terms : Payments in U.S dollars due upon receipt
                </span>
              </div>
              <div className="invoice-body invoice-data">
                <div className="customer-info">
                  <span>
                    <strong>Invoice Number :</strong>{" "}
                    <span>
                      {displayData.length !== 0
                        ? displayData?.dmrNumber
                        : "No data available"}
                    </span>
                  </span>
                  <span>
                    <strong>Invoice Peroid :</strong>{" "}
                    <span>
                      {displayData.length !== 0
                        ? displayData?.invoiceDetails[0]?.invoicePeriod
                        : "No data available"}
                    </span>
                  </span>
                </div>
                <div className="customer-info">
                  <span>
                    <strong>Invoice Amount :</strong> $
                    <span>
                      {displayData.length !== 0
                        ? displayData?.invoiceDetails[0]?.invoiceAmount
                        : "No data available"}
                    </span>
                  </span>
                  <span>
                    <strong>Amount Paid :</strong> $
                    <span>
                      {displayData.length !== 0
                        ? displayData?.invoiceDetails[0]?.paidAmount
                        : "No data available"}
                    </span>
                  </span>
                  <span>
                    <strong>Balance Due :</strong> $
                    <span>
                      {displayData.length !== 0
                        ? displayData?.invoiceDetails[0]?.dueAmount
                        : "No data available"}
                    </span>
                  </span>
                </div>
              </div>
              <div className="display-header">
                <span className="invoice-deatil-header">
                  Account Information
                </span>
              </div>
              <div className="invoice-body invoice-data">
                <div className="customer-info">
                  <span>
                    <strong>Account :</strong>{" "}
                    <span>
                      {displayData.length !== 0
                        ? displayData?.invoiceDetails[0]?.accountName
                        : "No data available"}
                    </span>
                  </span>
                  <span>
                    <strong>Address :</strong>{" "}
                    <span>
                      {displayData.length !== 0
                        ? displayData?.invoiceDetails[0]?.streetAddress
                        : "No data available"}
                      ,
                      {displayData.length !== 0 &&
                        displayData?.invoiceDetails[0]?.city}
                      ,
                      {displayData.length !== 0 &&
                        displayData?.invoiceDetails[0]?.state}
                      ,
                      {displayData.length !== 0 &&
                        displayData?.invoiceDetails[0]?.zip}
                    </span>
                  </span>
                  <span>
                    <strong> Phone : </strong>
                    <span>
                      {displayData.length !== 0
                        ? displayData?.invoiceDetails[0]?.phone
                        : "No data available"}
                    </span>
                  </span>
                </div>

                <div className="customer-info">
                  <span>
                    <strong>Contract Number : </strong>{" "}
                    <span>
                      {displayData.length !== 0
                        ? displayData?.invoiceDetails[0]?.contractNumber
                        : "No data available"}
                    </span>
                  </span>
                  <span>
                    <strong>Legacy Contract No : </strong>{" "}
                    <span>
                      {displayData.length !== 0
                        ? displayData?.invoiceDetails[0]?.legacyContractNumber
                        : "No data available"}
                    </span>
                  </span>
                  <span>
                    <strong>Invoice Contact :</strong>{" "}
                    <span>
                      {displayData.length !== 0
                        ? displayData?.invoiceDetails[0]?.contactPerson
                        : "No data available"}
                    </span>
                  </span>
                  <span>
                    <strong>Fax : </strong>{" "}
                    <span>
                      {displayData.length !== 0
                        ? displayData?.invoiceDetails[0]?.fax
                        : "No data available"}
                    </span>
                  </span>
                </div>
              </div>
              <div className="display-header1">
                <strong class="invoice-deatil-header">Items Platforms</strong>
              </div>
              <table className="product-table">
                <thead>
                  <tr>
                    <th>Platform SN</th>
                    <th>Registration No.</th>
                    <th>Begin TSN</th>
                    <th>End TSN</th>
                    <th>Usage Hours</th>
                  </tr>
                </thead>
                {displayData.length !== 0 ? (
                  <tbody>
                    {displayData?.platformDetails?.map((products, index) => (
                      <>
                        <tr key={index}>
                          <td>{products.platformSerialNumber}</td>
                          <td>{products.registrationNumber} </td>
                          <td>{products.beginTSN}</td>
                          <td>{products.endTSN} </td>
                          <td>{products.usageHours}</td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                ) : (
                  <p className="text-center">No data available.</p>
                )}
              </table>
              <div className="display-header1">
                <strong class="invoice-deatil-header">
                  Covered Equipment - MSP
                </strong>
              </div>
              <div className="tbl-cnt">
                <table className="product-table">
                  <thead>
                    <tr>
                      <th>Material PN</th>
                      <th>Material SN/Position.</th>
                      <th>Begin TSN</th>
                      <th>End TSN</th>
                      <th>Usage Hours</th>
                      <th>Alt S/N</th>
                      <th>Alt Hours</th>
                      <th>Eff Hour Rate</th>
                      <th>Eff Cycle Rate</th>
                      <th>Flat Rate</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  {displayData.length !== 0 ? (
                    <tbody>
                      {displayData?.productDetails?.map((products, index) => (
                        <>
                          <tr key={index}>
                            <td>
                              {products?.productName
                                ? products?.productName
                                : "-"}
                            </td>
                            <td>
                              {products?.productSerialNo
                                ? products?.productSerialNo
                                : "-"}
                            </td>
                            <td>
                              {products?.beginTSN ? products?.beginTSN : "-"}
                            </td>
                            <td>{products?.endTSN ? products?.endTSN : "-"}</td>
                            <td>
                              {products?.usageHours
                                ? products?.usageHours
                                : "-"}
                            </td>
                            <td>
                              {products?.rentalSerialNo
                                ? products?.rentalSerialNo
                                : "-"}
                            </td>
                            <td>
                              {products?.rentalHours
                                ? products?.rentalHours
                                : "-"}
                            </td>
                            <td>
                              {products?.effectiveHourRate && <span>$</span>}
                              {products?.effectiveHourRate
                                ? products?.effectiveHourRate
                                : "-"}
                            </td>
                            <td>
                              {" "}
                              {products?.effectiveCycleRate
                                ? products?.effectiveCycleRate
                                : "-"}
                            </td>
                            <td>
                              {products?.flatRate && <span>$</span>}
                              {products?.flatRate ? products?.flatRate : "-"}
                            </td>
                            <td>
                              {products?.amount && <span>$</span>}
                              {products?.amount ? products?.amount : "-"}
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  ) : (
                    <p className="text-center">No data.</p>
                  )}
                </table>
                <table className="invoice-total">
                  <tfoot>
                    <tr>
                      <td colspan="11" className="final-amount">
                        <strong>Sub Total :</strong>{" "}
                        <strong>
                          ${" "}
                          {displayData.length !== 0
                            ? displayData?.invoiceDetails[0]?.subTotal
                            : "No data available"}
                        </strong>
                      </td>
                    </tr>
                    {displayData?.invoiceDetails[0]?.isTaxable === "Y" && (
                      <tr className="sales-tax">
                        <td colspan="6">
                          <strong>
                            State sales tax calculation is based on Taxable
                            Company shown above
                          </strong>
                        </td>

                        <td colspan="5" className="final-amount">
                          <strong>
                            {displayData.length !== 0
                              ? displayData?.invoiceDetails[0]?.shipToState
                              : ""}{" "}
                            Sales tax :
                          </strong>{" "}
                          <strong>
                            ${" "}
                            {displayData.length !== 0
                              ? displayData?.invoiceDetails[0]?.stateTaxes
                              : ""}
                          </strong>
                        </td>
                      </tr>
                    )}
                    <tr className="sales-tax">
                      <td colSpan={7}>
                        <p className="imp-note">
                          ** You may have up to a 12 percent surcharge added to
                          your base rate{" "}
                        </p>
                      </td>
                      <td colSpan={4} className="final-amount">
                        <strong>Total Invoice :</strong>{" "}
                        <strong>
                          ${" "}
                          {displayData.length !== 0
                            ? displayData?.invoiceDetails[0]?.isTaxable === "Y"
                              ? total
                              : displayData?.invoiceDetails[0]?.subTotal
                            : "No data available"}
                        </strong>
                      </td>
                    </tr>
                  </tfoot>
                </table>
              </div>

              <div className="display-header">
                <span className="invoice-deatil-header">Notes</span>
              </div>

              <div className="customer-info note-cnt">
                <ul className="note-list">
                  {displayData?.length !== 0 &&
                  displayData?.notes?.length > 0 ? (
                    displayData?.notes?.map((notesData, index) => (
                      <li key={index}>{notesData?.note}</li>
                    ))
                  ) : (
                    <li className="no-list">
                      <p>No records found</p>
                    </li>
                  )}
                </ul>

                <p className="disclaim">
                  Disclaimer: All calculations have been performed using system
                  calculated values. Due to rounding, calculations done outside
                  of system may vary.
                </p>
                <div>
                  I acknowledge that failure to participate in the ECTM program
                  for the applicable propulsion engine models may limit my MSP
                  coverage.
                </div>
                <div>
                  THIS IS YOUR INVOICE. Please complete the entire form. A
                  remittance for the "Amount Due", must be submitted to the
                  address shown above within 10 days after the end of the
                  reporting month. Please reference invoice number, contract
                  number, and invoice amount paid on your remittance. Interest
                  charges are assessed on late or misdirected payments.
                </div>
                <div>
                  <strong>
                    If you wish to pay by credit card, please return to the
                    INVOICE HOME PAGE and click on the "MAKE A CREDIT/DEBIT CARD
                    PAYMENT" icon.
                  </strong>
                </div>
                <p className="text-center">
                  &copy; Copyright Honeywell International Inc 2004-2012
                </p>
              </div>
            </div>
          </div>
        }
      </>
    ) : (
      <>
        {invoiceErrorMessage?.data?.errorDescription ? (
          <>
            {deleteInvoiceLoading && (
              <div className="no-data-contracts">
                <h5 className="red">
                  Please wait while we delete your invoice.....
                </h5>
              </div>
            )}
            {!deleteInvoiceLoading && (
              <>
                {deleteInvoiceFlag ? (
                  isInvoiceDeleted ? (
                    <div className="no-data-contracts">
                      <h5 className="red">Invoice Deleted Successfully</h5>
                    </div>
                  ) : (
                    <div className="no-data-contracts">
                      <h5 className="red">
                        Something went wrong while deleting the invoice
                      </h5>
                    </div>
                  )
                ) : (
                  <>
                    <div className="no-data-contracts">
                      <h6 className="red">
                        {invoiceErrorMessage?.data?.errorDescription}{" "}
                        {invoiceError && (
                          <a
                            href={
                              configData?.MSP_TOKEN?.MSP_DOMAIN_NAME
                                ? Config?.resources[
                                    configData?.MSP_TOKEN?.MSP_DOMAIN_NAME
                                  ]?.supportUrl
                                : C?.MSP_SUPPORT_URL
                            }
                            target="_blank"
                          >
                            Other Portal Issues (honeywell.com)
                          </a>
                        )}
                      </h6>
                      {isHoneywellEmployee &&
                        deleteInvoice &&
                        !deleteInvoiceLoading && (
                          <button
                            className="btn btn-white delete-inv"
                            onClick={deleteInvoiceHandler}
                          >
                            Delete Invoice
                          </button>
                        )}
                    </div>
                  </>
                )}
              </>
            )}
          </>
        ) : (
          <h2 className="no-data-contracts">No Data Available</h2>
        )}
      </>
    );

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="choose contract"
        centered
        show={props.show}
        className={isWindow ? "max-window" : ""}
      >
        <Modal.Header className="modal-h">
          <Modal.Title className="title-modal">
            <span className="invoice-num">Invoice #{props.data}</span>
          </Modal.Title>
          <button
            type="button"
            class="btn-close"
            aria-label="Close"
            onClick={!deleteInvoice ? props.onClose : refreshPopupHandler}
          ></button>
          <span className="min-max">
            <a onClick={maxWindow}>
              <img src={MaxIcon} alt="max" />
            </a>
          </span>
        </Modal.Header>
        <Modal.Body className="display-invoice">
          {(load || cancelInvoiceLoading) && (
            <div className="loading-msg">
              <h3>Please wait while we update your contract invoice(s)</h3>
              <ProgressBar now={50} />
            </div>
          )}
          {!load && !cancelInvoiceLoading && pdfbody}
          {displayData.length !== 0 &&
          parseFloat(
            (displayData?.invoiceDetails[0]?.dueAmount).replace(/,/g, "")
          ) >= 100000 &&
          !load &&
          !cancelInvoiceLoading
            ? errorMessage
            : ""}
          <DisplayPopUpPdf ref={componentRef} />
        </Modal.Body>
        <Modal.Footer>
          {!load && !cancelInvoiceLoading && displayData.length !== 0 && (
            <div className="invoice-btn">
              <div
                className={`${
                  props?.changeButtonText
                    ? displayData?.invoiceDetails[0]?.dmrNumber ===
                        cancelInvoice[0]?.invoiceNumber &&
                      (cancelInvoice[0]?.invoiceAmount === "0.00" && cancelInvoice[0]?.invoiceType === "Monthly") && (!programcodeList.includes(cancelInvoice[0]?.programCode))
                      ? "cancel-btn"
                      : "cancel-disabled"
                    : displayData?.invoiceDetails[0]?.dmrNumber ===
                      cancelInvoice[0]?.invoiceNumber && (!programcodeList.includes(cancelInvoice[0]?.programCode) && (cancelInvoice[0]?.invoiceType === "Monthly"))
                    ? "cancel-btn"
                    : "cancel-disabled"
                }`}
              >
                <span onClick={cancelInvoiceHandler}>Cancel This Invoice</span>
                <span className="info-icon">
                  <img src={InfoIcon} alt="contract-data" />{" "}
                  <span className="tool-tip">
                    <span className="invcancel-text">
                      This step will cancel this invoice and usage based hours
                      will reset
                    </span>
                  </span>
                </span>
              </div>
              <div className="paynow-btn">
                {props.changeButtonText ? (
                  <>
                    <button
                      style={{ marginLeft: "195px" }}
                      className="btn btn-white"
                      onClick={() => setshowEmail(true)}
                    >
                      EMAIL
                    </button>
                    <button className="btn btn-white" onClick={handleClick}>
                      PRINT
                    </button>
                  </>
                ) : (
                  <>
                    {parseFloat(
                      (displayData?.invoiceDetails[0]?.dueAmount).replace(
                        ",",
                        ""
                      )
                    ) === 0 &&  !props?.changeButtonText && isHoneywellEmployee && (
                      <button
                        className="btn btn-white"
                        onClick={closeInvoiceHandler}
                      >
                        Close Invoice
                      </button>
                    )}
                    <button
                      className="btn btn-white"
                      onClick={() => setshowEmail(true)}
                    >
                      EMAIL
                    </button>
                    <button className="btn btn-white" onClick={handleClick}>
                      PRINT
                    </button>
                    {(displayData.length !== 0 &&
                      parseFloat(
                        (displayData?.invoiceDetails[0]?.dueAmount).replace(
                          ",",
                          ""
                        )
                      ) >= 100000) ||
                    (displayData.length !== 0 &&
                      parseFloat(
                        (displayData?.invoiceDetails[0]?.dueAmount).replace(
                          ",",
                          ""
                        )
                      ) <= 0) ||
                    parseFloat(
                      (displayData?.invoiceDetails[0]?.dueAmount).replace(
                        /,/g,
                        ""
                      )
                    ) >= 100000 ? (
                      <button className="btn btn-blue" disabled={true}>
                        PAY NOW
                      </button>
                    ) : (
                      <button className="btn btn-blue" onClick={payNow}>
                        PAY NOW
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          )}
        </Modal.Footer>
      </Modal>
      {displayData.length !== 0 && (
        <EwalletPayment
          invoiceAmount={
            displayData.length !== 0 &&
            displayData?.invoiceDetails[0]?.dueAmount
          }
          setEwalletShow={setEwalletShow}
          dmrNum={props.data}
          show={ewalletShow}
          onHide={() => setEwalletShow(false)}
          hashKey={displayData?.invoiceDetails[0]?.hashKey}
        />
      )}
      {showEmail && (
        <EmailInvoices
          show={showEmail}
          handleClose={() => setshowEmail(false)}
          invoiceNo={props.data}
          accountName={displayData?.invoiceDetails[0]?.accountName}
          displayData={displayData}
          setshowEmail={setshowEmail}
        />
      )}

      {showCancelPopup && (
        <CancelInvoicePopup
          show={showCancelPopup}
          onCloseInvoice={closeCancelPopupHandler}
          invoiceDetails={cancelInvoice[0]}
        />
      )}

      {showCloseInvoice && (
        <CloseInvoicePopup
          show={showCloseInvoice}
          onClose={invoiceCloseHandler}
          invoiceNumber={displayData?.invoiceDetails[0]?.dmrNumber}
        />
      )}
    </>
  );
};

export default DisplayInvoice;