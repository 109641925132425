import React from "react";
import "./MinuteToHourConversion.scss";

const MinuteToHourConversionData = [
  ["1-6", "1"],
  ["7-12", "2"],
  ["13-18", "3"],
  ["19-24", "4"],
  ["25-30", "5"],
  ["31-36", "6"],
  ["37-42", "7"],
  ["43-48", "8"],
  ["49-54", "9"],
  ["55-60", "1.0"],
];

const MinuteToHourConversion = () => {
  return (
    <React.Fragment>
      <h3>
        Minute-To-Hour
        <br />
        Conversion
      </h3>
      <div className="m2h-tbl-header">
        <div>Minutes</div>
        <div>Hours</div>
      </div>

      <div className="m2h-tbl-body">
        {MinuteToHourConversionData.map((data,index) => {
          return (
            <div className="m2h-tbl-row" key={index}>
              <div>{`${data[0]}`}</div>
              <div>{`${data[1] === "1.0" ? data[1] : `.${data[1]}`}`}</div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
};

export default MinuteToHourConversion;
