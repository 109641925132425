import React from "react";
import "./UploadUsagePopup.scss";
import { Button, Modal } from "react-bootstrap";
import { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import UploadUsagePopupValidation from "./UploadUsagePopupValidation";
import DownloadIcon from "../../../assets/images/download.PNG";
import UploadUsagePopupLoading from "../UploadUsagePopupLoading/UploadUsagePopupLoading";
import fileDownload from "js-file-download";
import {
  uploadContractFileSrv,
  fetchExcelFileSrv,
} from "../../../services/contract.service";

//used contract
const UploadUsagePopup = ({ contracts, contractData, soldToData }) => {
  const [show, setShow] = useState(false);
  const [isDownloadLoading, setIsDownloadLoading] = useState(false);


  const handleClose = () => {
    setShow(false);
    setIsError(false);
    setErrorValidate([]);
    setFileFormatError(false);
    setfileUploadResponse();
    setFile();
    setResponseError(false);
    setDownloadExcelError("");
  };


  const handleShow = () => {
    setShow(true);
  };

  const [filedata, setFile] = useState();
  const [fileUploadResponse, setfileUploadResponse] = useState();
  const [errorValidate, setErrorValidate] = useState([]);
  const [isError, setIsError] = useState(false);
  const [fileFormatError, setFileFormatError] = useState(false);
  const [load, setLoad] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [downloadExcelError, setDownloadExcelError] = useState("");
  const handleFileSubmit = (e) => {
    e.target.files[0]?.name.split(".").pop() === "xlsx"
      ? setFileFormatError(false)
      : setFileFormatError(true);
    setFile(e.target.files[0]);
    setResponseError(false);
    setErrorMessage("");
  };

  const uploadFile = async (e) => {
    e.preventDefault();
    const file = new FormData();
    file.append("file", filedata);
    if (filedata && !fileFormatError) {
      setLoad(true);
    }

    await uploadContractFileSrv(file).then((res) => {
      if (res?.status === 200) {
        setfileUploadResponse(res?.data?.data?.listOfReportUsage);
      } else {
        setResponseError(true);
        setfileUploadResponse(res?.data?.listOfReportUsage);
      }
    });
    setLoad(false);
  };

  useEffect(() => {
    if (fileUploadResponse) {
      validateerror();
    }
    /*eslint-disable */
  }, [fileUploadResponse]);

  const validateerror = () => {
    fileUploadResponse?.map((data) => {
      setErrorValidate((prevState) => [...prevState, data]);
      setIsError(true);
    });
  };

  const downloadExcelHandler = async (event) => {
    event.preventDefault();
    setDownloadExcelError("");
    setIsDownloadLoading(true);

    let query;
    if (contractData && soldToData.length>0) {
      query = [`contract=${contractData}`, `soldTo=${soldToData}`];
      query = query.join("&");
    } else if (contractData && soldToData.length<=0) {
      query = `contract=${contractData}`;
    } else if (soldToData.length>0 && !contractData) {
      query = `soldTo=${soldToData}`;
    } else {
      query = ``;
    }

    await fetchExcelFileSrv(query)
      .then(async (res) => {
        if (res.status === 200) {
          const headerFileName = res.headers["file-name"].split(";");
          const fileNameData = headerFileName[1];
          const fileNameExcel = fileNameData.split("=")[1];
          fileDownload(res?.data, fileNameExcel);
          setIsDownloadLoading(false);
        } else {
          const errorData = JSON.parse(await res.data?.text());
          setIsDownloadLoading(false);
          setDownloadExcelError(errorData?.errorDescription);
        }
      })
      .catch((error) => {
        setIsDownloadLoading(false);
        setDownloadExcelError(error);
      });
  };

  const uploadContent = (
    <React.Fragment>
      <p>
        You must use the template provided by Honeywell to ensure proper
        reporting.
      </p>
      <p className="warn-cnt">
        <span className="warn-icon">i</span>Please do not change the file
        format, as it causes upload failures.
      </p>

      {
        <a
          className={`${
             isDownloadLoading === true
              ? "download-usage-hidden"
              : "download-usage"
          }`}
          onClick={downloadExcelHandler}
        >
          {" "}
          <img src={DownloadIcon} alt="DownloadIcon" />
          Download USAGE Template{" "}
          <span>
            <span
              className={`${
                isDownloadLoading === true ? "loader" : "loader-hidden"
              }`}
            ></span>
          </span>
        </a>
      }
      <div className="upload-usage">
        <Form.Control
          onChange={handleFileSubmit}
          type="file"
          aria-label="upload"
        />
        <span className="choose">Choose</span>

        <p>Supported filetypes: .xlsx</p>
        {fileFormatError && (
          <span className="err">please upload xlsx format only</span>
        )}
        {downloadExcelError && (
          <span className="err">{downloadExcelError}</span>
        )}
        {responseError && (
          <span className="err">
            {errorMessage
              ? errorMessage
              : "something went wrong please check the data and resubmit"}
          </span>
        )}
      </div>
    </React.Fragment>
  );

  const UploadSucess = (
    <Modal.Title className="modal-title">
      <h2>{!responseError ? "Upload Complete !" : "Upload Failed !"}</h2>
    </Modal.Title>
  );

  const UploadInProgress = (
    <Modal.Title className="modal-title">
      <h2>Upload Usage</h2>
    </Modal.Title>
  );

  const CloseButtonOnSucess = (
    <Button
      variant="secondary"
      className="modal-cancel btn-white"
      data-test="modal-cancel"
      onClick={handleClose}
    >
      <div className="modal-cancel-text"> CLOSE</div>
    </Button>
  );

  const CancelButtInProgress = (
    <Button
      variant="secondary"
      className="modal-cancel btn-white"
      data-test="modal-cancel"
      onClick={handleClose}
    >
      <div className="modal-cancel-text"> CANCEL</div>
    </Button>
  );

  return (
    <React.Fragment>
      <Button
        variant="primary"
        onClick={handleShow}
        className="btn-blue float-end"
        data-test="upload-button"
      >
        bulk upload
      </Button>

      <Modal
        class="text-right modal-dialog"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        aria-labelledby="upload popup"
        centered
        data-test="test-right"
      >
        <Modal.Header
          closeButton
          className={`${
            isError === true
              ? "modal-upload-button-border upload-success"
              : "modal-upload-button-border"
          }`}
        >
          {isError === true && load === false && UploadSucess}
          {isError === false && load === false && UploadInProgress}
        </Modal.Header>
        <Modal.Body className="modal-upload-button-border modal-body">
          {isError === true && load === false && fileFormatError === false && (
            <UploadUsagePopupValidation
              errorData={errorValidate}
              errorDetails={responseError}
            />
          )}
          {isError === false && load === false && uploadContent}
          {isError === false && load === true && fileFormatError === false && (
            <UploadUsagePopupLoading />
          )}
        </Modal.Body>
        <Modal.Footer className="">
          {isError === true && load === false
            ? CloseButtonOnSucess
            : CancelButtInProgress}

          {!isError && load === false && (
            <Button
              variant="primary"
              className="modal-upload btn-blue"
              onClick={uploadFile}
              disabled={fileFormatError === true ? true : false}
            >
              <div className="modal-submit-text">UPLOAD</div>
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default UploadUsagePopup;