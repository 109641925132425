import React from "react";
import { Button, Modal } from "react-bootstrap";
import Loader from "../../common/Loader/Loader";
import "./EwalletPayment.scss";

const DeleteConfirmationModal = ({
  show,
  onCancel,
  onConfirmDelete,
  data,
  loading,
}) => {
  const loadingComponent = () => (
    <Loader/>
  );
  return (
    <Modal centered show={show} size="xl">
      <Modal.Header closeButton className="modal-h" onClick={onCancel}>
        <Modal.Title className="modal-title">
          <h2>Remove a Payment Method</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body">
        <p>
          Are you sure you want to remove this card from your payment methods?
        </p>
        <div className="ewallet-cnt ewdelet-cnt">
          <div className="card-delet">
            <div className="tbl-hdr">
              <div>Card number</div>
            </div>
            <div className="tbl-cnt">
              <div className="tbl-row">
                <div>
                  {data?.creditCardTokenNum
                    .split("-")[1]
                    .replaceAll(
                      `${data?.creditCardTokenNum.split("-")[1]}`,
                      "* * * * - * * * * - * * * * -"
                    )}
                  {data?.creditCardTokenNum.split("-")[2]}
                </div>
                <div>
                  <span className="fav">
                    {data?.primaryCreditCardInd === "1" ? "Favorite" : ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {loading && loadingComponent()}
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          type="submit"
          className="btn-white"
          onClick={onCancel}
          disabled={loading}
        >
          NO, Cancel
        </Button>
        <Button
          variant="primary"
          type="submit"
          className="btn-blue"
          onClick={() => onConfirmDelete(true)}
          onLoad={loading}
          disabled={loading}
        >
          Yes, Remove
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmationModal;
