import React, { useEffect, useState, useRef } from "react";
import "./UbCredits.scss";
import UsageBasedCreditPopup from "../UsageBasedCreditPopup/UsageBasedCreditPopup";
import { usageBaseCreditSrv } from "../../../services/invoice.service";
import UbCreditsModal from "../Credits/UbCreditsModal/UbCreditsModal";
import Feature from "../../common/FeatureFlags/Feature/Feature";
import { getUbCreditsPayload } from "./UbCreditsPayload";

const UbCredits = (props) => {
  const [creditData, setCreditData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [showCreditInvoices, setShowCreditInvoices] = useState(false);
  const [isLoadingCredits, setIsLoadingCredits] = useState(false);
  // const [displayCredit, setDisplayCredit] = useState(false);

  const usageBasedCreditInfoComponent = useRef(true);

  const { dropDownValue,dropDownInputTermData, usagesoldToTermData } = props;
  
  const bodyContent = {
    query: "",
    filters: getUbCreditsPayload(dropDownValue,dropDownInputTermData,usagesoldToTermData),
  };
  const getCredit = async () => {
    setIsLoadingCredits(true);
    const payload = JSON.stringify(bodyContent);
    await usageBaseCreditSrv(payload).then((res) => {
      if (res?.status === 200) {
        setCreditData(res?.data?.data?.totalCreditAmount);
        // setDisplayCredit(
        //   res?.data?.data?.totalCreditAmount === "0.00" ? true : true
        // );
      } else {
        setCreditData([]);
        setIsLoadingCredits(false);
      }
    });
    setIsLoadingCredits(false);
  };

  const openModal = () => {
    setModalShow(true);
  };
  const creditInvoiceHandler = () => {
    setShowCreditInvoices(true);
    // window.open(configData?.MSP_TOKEN?.CREDIT_INVOICE_URL, "_blank");
  };
  useEffect(() => {
    if (
      usageBasedCreditInfoComponent.current === true ||
      dropDownInputTermData ||
      usagesoldToTermData.length > 0
    ) {
      usageBasedCreditInfoComponent.current = false;
      getCredit();
    }
    /*eslint-disable */
  }, [dropDownInputTermData, usagesoldToTermData]);

  return (
    <>
      {!isLoadingCredits && (
        <div className="ubcredits">
          <div>
            <h3>Usage-Based Credits</h3>
            <span className="Ub-amt">
              <strong>{creditData} USD</strong>
            </span>
          </div>
          <div className="Ub-btn">
            <button className="btn btn-white" onClick={openModal}>
              USAGE-BASED ANALYTICS
            </button>
            <br />
            <br />
            {parseFloat(creditData) > 0 && (
              <Feature
                name="ApplyCredits"
                children={
                  <button
                    className="btn btn-white"
                    onClick={creditInvoiceHandler}
                  >
                    APPLY CREDITS TO INVOICES
                  </button>
                }
              />
            )}
          </div>
        </div>
      )}
      {showCreditInvoices && (
        <Feature
          name="ApplyCredits"
          children={
            <UbCreditsModal
              onHide={() => setShowCreditInvoices(false)}
              onCancel={() => setShowCreditInvoices(false)}
              dropdowntermvalue={dropDownInputTermData}
              usagesoldto={usagesoldToTermData}
              show={showCreditInvoices}
              dropdownvalue={props.dropDownValue}
            />
          }
        />
      )}
      {modalShow && !isLoadingCredits && (
        <UsageBasedCreditPopup
          usagecontract={dropDownInputTermData}
          usagesoldTo={usagesoldToTermData}
          constractnumberdata={""}
          onHide={() => setModalShow(false)}
          dropDownValue={props.dropDownValue}
          show={modalShow}
        />
      )}
    </>
  );
};

export default UbCredits;