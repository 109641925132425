import axios from "./axios";
import endPoint from "./endpoint";

export const fetchCreditCardListSrv = () => {
  return axios.get(endPoint.creditCardList());
};

export const updateCreditCardEntitiesSrv = (payload) => {
  return axios.put(endPoint.updateCreditCardEntities(), payload);
};

export const deleteCreditCardEntitiesSrv = (id) => {
  return axios.delete(endPoint.deleteCreditCardEntities(id));
};

export const creditCardTemplateSrv = (payload) => {
  return axios.post(endPoint.creditCardTemplate(), payload);
};

export const creditCardTokenSrv = (payload) => {
  return axios.post(endPoint.creditCardToken(), payload);
};

export const makeCreditCardPaymentSrv = (payload) => {
  return axios.post(endPoint.makeCreditCardPayment(), payload);
};
