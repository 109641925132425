import React from 'react'
import Step from './Step'
import './TransferProgressBar.scss'

const StepNavigation = ({activeIndex,labelArray}) =>{
    return (
        <div>
            <div className="respirator-steps">
                <ul>
                    {labelArray?.map((item, index) =>
                        <Step
                            key={index}
                            index={index}
                            label={item}
                            activeIndex={activeIndex || 0}
                            completed={index <= activeIndex}
                        />
                    )}
                </ul>
            </div>
        </div>
    )
}

export default StepNavigation