import React from "react";
import { Button, Modal } from "react-bootstrap";

const MultipleInvoicesErrorPopup = (props) => {
  return (
    <Modal
      class="text-right modal-dialog"
      show={props.show}
      backdrop="static"
      keyboard={false}
      onHide={props.onHide}
      aria-labelledby="contained-modal-title-success"
      centered
      data-test="test-right"
    >
      <Modal.Header
        closeButton
        className="modal-upload-button-border success-close"
      >
        <Modal.Title className="modal-title">Maximum Invoices Selected</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body invoice-success">
        <p>You have selected maximum number of invoices allowed for multi payment. Please click on continue to go to payment or click on cancel (X) to refine the selection.</p>
      </Modal.Body>
      <Modal.Footer>
      <Button
          className="btn btn-blue"
          data-test="modal-cancel"
          onClick={props.showMultipleInvoice}
        >
          Continue 
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MultipleInvoicesErrorPopup;
