import React from "react";
import "../InvoicesSuccessModel/InvoicesSuccessModel.scss";
import { Button, Modal } from "react-bootstrap";
import { useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { postContractEntitiesSrv } from "../../../services/contract.service";
import ConfirmationModal from "./ConfirmationModal/ConfirmationModal";
// import { FeatureFlags } from "../../common/FeatureFlags/FeatureFlags";

const InvoiceSuccessModel = (props) => {
  const [show, setShow] = useState(false);
  const { rentalStore, contracts, isDisable, reportUsage, loadingWingXData,multipleContracts } = props;
  const [headerMessage, setHeaderMessage] = useState("");
  const [bodyMessage, setBodyMessage] = useState("");
  const [processedData, setProcessedData] = useState([]);
  const [refreshAfterSucc, setRefreshAfterSucc] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");
  const [displayAcceptanceModal, setDisplayAcceptanceModal] = useState(false);
  const [checked, setChecked] = useState(false)

  // const wingxFeature = FeatureFlags.find(
  //   (feature) => feature.name === "WingxData"
  // );

  // event to be triggered on click of close
  const handleClose = () => {
    setShow(false);
    setBodyMessage("");
    setDisplayAcceptanceModal(false);
    if (refreshAfterSucc) {
      refreshPage();
    }
  };

  const acceptPopupHandler = () => {
    setDisplayAcceptanceModal(true);
    setChecked(false);
  }

  const closeAcceptModal = () => {
    setDisplayAcceptanceModal(false);
    setChecked(false);
  }

  const checkboxHandler = () => {
    setChecked((prevChecked) => !prevChecked);
  }

  // page refresh after success
  const refreshPage = () => {
    window.location.reload();
  };

  const getEngineLength = (contractNumber, tailNumber) => {
    const contractDetails = contracts?.find(
      (contract) => contract?.contractNumber === contractNumber
    );
    const tailDetailsData = contractDetails?.tailDetails?.find(
      (tail) => tail?.aircraftTailNumber === tailNumber
    );
    const totalEnginesLength =
      tailDetailsData?.platformDetails?.length +
      (tailDetailsData?.productDetails
        ? tailDetailsData?.productDetails?.length
        : 0);
    return totalEnginesLength;
  };

  const updatedReportUsageHoursValidate = reportUsage?.filter(
    (contract) => contract.contractNumber !== "123"
  );
  let payloadTailLengthValidate;
  let payloadEngineLengthValidate;
  let apiEngineLengthValidate;
  let apiTailLengthValidate;
  let totalPayloadTailLength = 0;
  let totalAPITailLength = 0;
  let totalPayloadEngineLength = 0;
  let totalAPIEngineLength = 0;
  updatedReportUsageHoursValidate?.forEach((reportUsageContract) => {
    const apiContractDataValidate = contracts?.find(
      (data) =>
        data?.contractNumber === parseInt(reportUsageContract?.contractNumber)
    );
    payloadTailLengthValidate = reportUsageContract?.tails?.length;
    totalPayloadTailLength += payloadTailLengthValidate
    apiTailLengthValidate = apiContractDataValidate?.tailDetails?.length;
    totalAPITailLength += apiTailLengthValidate

    if (payloadTailLengthValidate === apiTailLengthValidate) {
      reportUsageContract?.tails?.forEach((reportUsageTail) => {
        payloadEngineLengthValidate = reportUsageTail?.engines?.length;
        totalPayloadEngineLength += payloadEngineLengthValidate
        apiEngineLengthValidate = getEngineLength(
          parseInt(reportUsageContract?.contractNumber),
          reportUsageTail?.tailNumber
        );
        totalAPIEngineLength += apiEngineLengthValidate
      });
    }
  });

  // event to be triggered on click of submit
  const handleShow = async () => {
    const updatedReportUsageHours = reportUsage?.filter(
      (contract) => multipleContracts?.includes(contract?.contractNumber)
    );
    let reportUsageArrayLength = 0;
    const payloadArray = [];
    let payloadCount = 0;
    let rentalErrorCount = 0;
    updatedReportUsageHours?.forEach((reportUsageContract) => {
      const apiContractData = contracts?.find(
        (data) =>
          data?.contractNumber === parseInt(reportUsageContract?.contractNumber)
      );
      const payloadTailLength = reportUsageContract?.tails?.length;
      const apiTailLength = apiContractData?.tailDetails?.length;
      reportUsageArrayLength += apiTailLength;
      if (payloadTailLength === apiTailLength) {
        reportUsageContract?.tails?.forEach((reportUsageTail) => {
          const payloadEngineLength = reportUsageTail?.engines?.length;
          const apiEngineLength = getEngineLength(
            parseInt(reportUsageContract?.contractNumber),
            reportUsageTail?.tailNumber
          );

          if (payloadEngineLength === apiEngineLength) {
            const apiTailData = apiContractData?.tailDetails?.find(
              (tailApi) =>
                tailApi.aircraftTailNumber === reportUsageTail.tailNumber
            );
            const enginePayload = {
              soldToNumber: apiContractData?.soldToNumber,
              contractNumber: apiContractData?.contractNumber,
              tailNumber: reportUsageTail?.tailNumber,
              dmrNumber: apiContractData?.dmrNumber,
              usagePeriod: apiContractData?.usagePeriod,
              productDetails: apiTailData?.productDetails?.map((product) => {
                return {
                  usageHours: reportUsageContract?.tails
                    ?.find(
                      (t) => t.tailNumber === apiTailData?.aircraftTailNumber
                    )
                    ?.engines?.find(
                      (e) => e.engineName === product?.productSerialNo
                    )?.hours,
                  productSerialNo: product?.productSerialNo,
                  productName: product?.productName,
                  type: product?.type,
                  beginTSN: product?.beginTsn,
                  endTSN: product?.endTsn,
                };
              }),
              platformDetails: apiTailData?.platformDetails?.map((platform) => {
                return {
                  usageHours: reportUsageContract?.tails
                    ?.find(
                      (t) => t.tailNumber === apiTailData?.aircraftTailNumber
                    )
                    ?.engines?.find(
                      (e) => e.engineName === platform?.platformSerialNo
                    )?.hours,
                  platformSerialNo: platform?.platformSerialNo,
                  platformName: platform?.platformName,
                  endTSN: platform?.endTsn,
                  beginTSN: platform?.beginTsn,
                };
              }),
            };
            payloadCount += 1;
            payloadArray.push(enginePayload);
          } else {
            setShow(true);
            setHeaderMessage(`Submission Pending`);
            setBodyMessage(
              `Please fill all the required fields for selected contract- ${reportUsageContract.contractNumber}`
            );
          }
        });
      } else {
        setShow(true);
        setHeaderMessage(`Submission Pending`);
        setBodyMessage(
          `Please fill all the required fields for selected contract- ${reportUsageContract.contractNumber}`
        );
      }
    });

    const updatedContractPostPayload = payloadArray?.map((contract) => {
      const rentalContract = rentalStore?.find(
        (rentalContract) =>
          rentalContract.contractNumber === String(contract.contractNumber)
      );
      const rentalTail = rentalContract?.tails?.find(
        (rentalTail) => rentalTail.tailNumber === contract.tailNumber
      );

      const updatedProductDetails = contract?.productDetails?.map((product) => {
        const productEngine = rentalTail?.engines?.find(
          (engine) => engine.engineName === product.productSerialNo
        );

        if (productEngine?.isRentalInstalled && productEngine !== undefined) {
          if (
            productEngine?.rentalSelectType &&
            productEngine?.rentalHours &&
            productEngine?.rentalEngineSerialNumber
          ) {
            return {
              ...product,
              rental: "Y",
              rentalType: productEngine.rentalSelectType,
              rentalSerialNo: productEngine.rentalEngineSerialNumber,
              rentalEngineHours: productEngine.rentalHours,
            };
          } else {
            rentalErrorCount += 1;
            setShow(true);
            setIsLoading(false);
            setHeaderMessage(`Submission Pending`);
            setBodyMessage(
              `Please fill all the rental required fields for selected contract- ${contract.contractNumber}`
            );
            return { ...product };
          }
        } else {
          return { ...product };
        }
      });

      return { ...contract, productDetails: updatedProductDetails };
    });

    if (reportUsageArrayLength === payloadCount && rentalErrorCount <= 0) {
      setIsLoading(true);
      const payload = JSON.stringify(updatedContractPostPayload);
      await postContractEntitiesSrv(payload).then((res) => {
        if (res?.status === 200) {
          setIsLoading(false);
          setShow(true);
          setRefreshAfterSucc(true);
          setHeaderMessage(`Submission Completed`);
          setProcessedData(res?.data?.data);
        } else {
          setShow(true);
          setIsLoading(false);
          setHeaderMessage(`Submission Failed`);
          setError(true);
          setErrorResponse(res?.data?.error?.errorDescription);
        }
      });
    }
  };

  return (
    <React.Fragment>
      <div className="submit-cnt">
        <div className="col-md-12 text-end">
              {!loadingWingXData && (
                <Button
                  className="btn-blue btn"
                  onClick={acceptPopupHandler}
                  disabled={
                    isDisable ||
                    !(
                      totalPayloadTailLength === totalAPITailLength &&
                      totalPayloadEngineLength === totalAPIEngineLength
                    )
                  }
                >
                  Continue
                </Button>
              )}
         
        </div>
        <Modal
          class="text-right modal-dialog"
          show={show}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-success"
          centered
          data-test="test-right"
        >
          <Modal.Header
            closeButton
            className="modal-upload-button-border success-close"
          >
            <Modal.Title className="modal-title">
              <h2>{headerMessage}</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="modal-body invoice-success">
            <p>
              {bodyMessage && bodyMessage}
              {!error &&
                !bodyMessage &&
                !errorResponse &&
                processedData?.length > 0 &&
                processedData?.map((dataDetails) => (
                  <p
                    className={`${dataDetails?.description.includes("Successfully")
                      ? "blue"
                      : "red"
                      }`}
                  >
                    {dataDetails?.description}
                  </p>
                ))}
              {error && errorResponse && errorResponse}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn btn-blue"
              data-test="modal-cancel"
              onClick={handleClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          class="text-right modal-dialog"
          show={isLoading}
          onHide={handleClose}
          backdrop="static"
          keyboard={false}
          aria-labelledby="contained-modal-title-success"
          centered
          data-test="test-right"
        >
          <Modal.Body className="modal-body invoice-success">
            <div className="loading-msg">
              <h3>Please wait while we update your contract invoice(s)</h3>
              <ProgressBar now={50} />
            </div>
          </Modal.Body>
        </Modal>

        {displayAcceptanceModal && (
          <ConfirmationModal
            show={displayAcceptanceModal}
            onHide={closeAcceptModal}
            checkboxHandler={checkboxHandler}
            closeAcceptModal={closeAcceptModal}
            handleShow={handleShow}
            checked={checked}
            isLoading={isLoading}
          />
        )}
      </div>
    </React.Fragment>
  );
};
export default React.memo(InvoiceSuccessModel);