import {FeatureFlags} from '../FeatureFlags'
const Feature = ({ name, children }) => {
  let feature = FeatureFlags?.find((feature) => feature.name === name);
  if (feature && feature?.active) {
    return children;
  }

  return null;
};

export default Feature;