import React, { useEffect, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import "./PreInvoice.scss";
import ProgressBar from "react-bootstrap/ProgressBar";
import { preInvoiceEntitiesSrv } from "../../../services/invoice.service";

const PreInvoice = React.memo((props) => {
  const [preInvoiceDetails, setPreInvoiceDetails] = useState([]);
  const [isPreInvoiceLoading, setIsPreInvoiceLoading] = useState(true);
  const [preInvoiceError,setPreInvoiceError] = useState('')
  const [total, setTotal] = useState(Number);
  const preInvoiceComponent = useRef(true);

  const handleClose = () => {
    props.onClosePreInvoice();
  };

  const preInvoicePayload = [];

  const findContractContractsList = props.contractsData.find(
    (contract) => contract?.contractNumber === props.contractNumber
  );
  const findReportUsageContract = props.usageHoursData.find(
    (contractData) =>
      contractData.contractNumber === String(props.contractNumber)
  );
  const findRentalContractList = props.rentalData.find(
    (rentalContractData) =>
      rentalContractData?.contractNumber === String(props.contractNumber)
  );

  findContractContractsList?.tailDetails.forEach((tailData) => {
    const contractPayLoadData = {
      soldToNumber: findContractContractsList?.soldToNumber,
      contractNumber: findContractContractsList?.contractNumber,
      tailNumber: tailData?.aircraftTailNumber,
      dmrNumber: findContractContractsList?.dmrNumber,
      usagePeriod: findContractContractsList?.usagePeriod,
      flag: "C",
      productDetails: tailData?.productDetails?.map((product) => {
        return {
          usageHours: findReportUsageContract?.tails
            ?.find((t) => t.tailNumber === tailData?.aircraftTailNumber)
            ?.engines?.find((e) => e.engineName === product?.productSerialNo)
            ?.hours,
          productSerialNo: product?.productSerialNo,
          productName: product?.productName,
          beginTSN: product?.endTsn,
          endTSN: product?.endTsn,
          rental: findRentalContractList?.tails
            ?.find(
              (tailRental) =>
                tailRental?.tailNumber === tailData?.aircraftTailNumber
            )
            ?.engines.find(
              (engineRental) =>
                engineRental?.engineName === product?.productSerialNo
            )?.isRentalInstalled
            ? "Y"
            : "N",
          rentalType:
            findRentalContractList?.tails
              ?.find(
                (tailRental) =>
                  tailRental?.tailNumber === tailData?.aircraftTailNumber
              )
              ?.engines.find(
                (engineRental) =>
                  engineRental?.engineName === product?.productSerialNo
              )?.isRentalInstalled &&
            findRentalContractList?.tails
              ?.find(
                (tailRental) =>
                  tailRental?.tailNumber === tailData?.aircraftTailNumber
              )
              ?.engines.find(
                (engineRental) =>
                  engineRental?.engineName === product?.productSerialNo
              )?.rentalSelectType,
          rentalSerialNo:
            findRentalContractList?.tails
              ?.find(
                (tailRental) =>
                  tailRental?.tailNumber === tailData?.aircraftTailNumber
              )
              ?.engines.find(
                (engineRental) =>
                  engineRental?.engineName === product?.productSerialNo
              )?.isRentalInstalled &&
            findRentalContractList?.tails
              ?.find(
                (tailRental) =>
                  tailRental?.tailNumber === tailData?.aircraftTailNumber
              )
              ?.engines.find(
                (engineRental) =>
                  engineRental?.engineName === product?.productSerialNo
              )?.rentalEngineSerialNumber,
          rentalEngineHours:
            findRentalContractList?.tails
              ?.find(
                (tailRental) =>
                  tailRental?.tailNumber === tailData?.aircraftTailNumber
              )
              ?.engines.find(
                (engineRental) =>
                  engineRental?.engineName === product?.productSerialNo
              )?.isRentalInstalled &&
            findRentalContractList?.tails
              ?.find(
                (tailRental) =>
                  tailRental?.tailNumber === tailData?.aircraftTailNumber
              )
              ?.engines.find(
                (engineRental) =>
                  engineRental?.engineName === product?.productSerialNo
              )?.rentalHours,
        };
      }),
      platformDetails: tailData?.platformDetails?.map((platform) => {
        return {
          usageHours: findReportUsageContract?.tails
            ?.find((t) => t.tailNumber === tailData?.aircraftTailNumber)
            ?.engines?.find((e) => e.engineName === platform?.platformSerialNo)
            ?.hours,
          platformSerialNo: platform?.platformSerialNo,
          platformName: platform?.platformName,
          endTSN: platform?.endTsn,
          beginTSN: platform?.endTsn,
        };
      }),
    };
    preInvoicePayload.push(contractPayLoadData);
  });

  const getPreInvoice = async () => {
    setIsPreInvoiceLoading(true);
    const payload = JSON.stringify(preInvoicePayload);
    await preInvoiceEntitiesSrv(payload).then((res) => {
      if (res?.status === 200) {
        setPreInvoiceDetails(res?.data?.data?.preInvoiceResponse);
        let productTotal = 0;
        if (res?.data?.data?.preInvoiceResponse?.tailDetails?.length >= 1) {
          res?.data?.data?.preInvoiceResponse?.tailDetails?.forEach((item) => {
            item?.products?.forEach((product) => {
              productTotal += parseFloat(product?.amount.replace(/,/g, ""));
            });
          });
          setTotal(productTotal.toFixed(2));
        }
      } else {
        setPreInvoiceDetails([]);
        setPreInvoiceError(res?.data?.errorDescription)
      }
    });
    setIsPreInvoiceLoading(false);
  };

  useEffect(() => {
    if (props.contractNumber && preInvoiceComponent.current === true) {
      preInvoiceComponent.current = false;
      getPreInvoice();
    }
    /*eslint-disable */
  }, []);

  return (
    <React.Fragment>
      <Modal
        show={props.showInvoice}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="modal-h">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="title-modal"
          >
            {!isPreInvoiceLoading && (
              <h5>{preInvoiceDetails?.invoiceNumber ?`Draft estimates #${preInvoiceDetails?.invoiceNumber}`:`Draft estimates`}</h5>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isPreInvoiceLoading && (
            <div className="loading-msg">
              <h3>Please wait while we update your Draft estimates...</h3>
              <ProgressBar now={50} />
            </div>
          )}
          {!isPreInvoiceLoading &&
            preInvoiceDetails?.tailDetails?.length !== 0 && !preInvoiceError && (
              <>
              <div className="preinv-cnt">
                <div class="report-tbl-header">
                  <div>Tail</div>
                  <div>SN</div>
                  <div>Part</div>
                  <div>Rate</div>
                  <div>Hours</div>
                  <div>Alt hours </div>
                  <div>Amount</div>
                </div>
                <div>
                  {preInvoiceDetails?.tailDetails?.map((invoiceDetails, i) => {
                    return (
                      <React.Fragment>
                        {invoiceDetails?.products?.length > 0 && (
                          <div className="tbl-cnt">
                            {invoiceDetails?.products?.map((invoiceproduct) => {
                              return (
                                <div className="report-tbl-row" key={i}>
                                  <div>
                                    {invoiceDetails?.registrationNumber}
                                  </div>
                                  <div>{invoiceproduct?.productSerialNo}</div>
                                  <div>{invoiceproduct?.productLine}</div>
                                  {!invoiceproduct?.effectiveHourRate ? (
                                    <div>$0</div>
                                  ) : (
                                    <div>
                                      ${invoiceproduct?.effectiveHourRate}
                                    </div>
                                  )}
                                  <div>{invoiceproduct?.usageHours}</div>
                                  <div>
                                    {invoiceproduct?.rentalHours
                                      ? invoiceproduct?.rentalHours
                                      : "-"}
                                  </div>
                                  <div>${invoiceproduct?.amount}</div>
                                </div>
                              );
                            })}
                          </div>
                        )}
                      </React.Fragment>
                    );
                  })}
                </div>

                <div class="invoice-total">
                  {preInvoiceDetails.tailDetails?.length >= 1 && (
                    <div>
                      {`$ 
                      ${Number(parseFloat(total).toFixed(2)).toLocaleString(
                        "en",
                        {
                          minimumFractionDigits: 2,
                        }
                      )}`}
                    </div>
                  )}

                  <div>Total</div>
                </div>
              </div>
              </>
            )}

          {!isPreInvoiceLoading && preInvoiceDetails.length === 0 && preInvoiceError &&  (
            <h2 className="no-data-invoices">
              {preInvoiceError}
            </h2>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            className="modal-Continue btn-blue"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
});

export default PreInvoice;