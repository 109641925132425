import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import "./CloseInvoicePopup.scss";
import { invoiceCloseSrv } from "../../../../services/invoice.service";

const CloseInvoicePopup = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const { configData } = useSelector((state) => state.config);
  const [isInvoiceClosed, setIsInvoiceClosed] = useState(false);
  const closeInvoiceComponent = useRef(true);

  const payloadDetails = {
    id: props?.invoiceNumber,
    status: "Closed",
  };

  const getCloseInvoice = async () => {
    setIsLoading(true);
    const payload = JSON.stringify(payloadDetails);
    await invoiceCloseSrv(payload).then((res) => {
      if (res?.status === 200) {
        setIsInvoiceClosed(true);
      } else {
        setIsInvoiceClosed(false);
      }
    });
    setIsLoading(false);
  };
  useEffect(() => {
    if (closeInvoiceComponent?.current === true) {
      closeInvoiceComponent.current = false;
      getCloseInvoice();
    }
    /*eslint-disable */
  }, [props?.invoiceNumber]);

  //close popup
  const closeInvoiceHandler = () => {
    props?.onClose()
    window.location.reload();
  };

  return (
    <>
      <Modal
        show={props.show}
        size="lg"
        aria-labelledby="report usage"
        contentClassName="reportpopup-cnt"
        onHide={props.onClose}
        centered
        backdrop="static"
      >
        {
          <Modal.Header closeButton className="modal-h">
            <Modal.Title className="title-modal">
              <>
                <span>Close Invoice</span>
              </>
            </Modal.Title>
          </Modal.Header>
        }

        <Modal.Body>
          <>
            <div className="close-inv-cnt">
              {isLoading && (
                <h2>
                  {configData?.MSP_INFORMATION_TEXT?.LOADING_CLOSE_INVOICE}
                </h2>
              )}
              {!isLoading && isInvoiceClosed && (
                <h2>{configData?.MSP_INFORMATION_TEXT?.CLOSED_INVOICE}</h2>
              )}
              {!isLoading && !isInvoiceClosed && (
                <h2>
                  {configData?.MSP_INFORMATION_TEXT?.CLOSED_INVOICE_ERROR}
                </h2>
              )}
            </div>
          </>
        </Modal.Body>
        <Modal.Footer>
          {
            <Button
              className="btn btn-white"
              data-test="modal-cancel"
              onClick={closeInvoiceHandler}
            >
              Close
            </Button>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CloseInvoicePopup;
