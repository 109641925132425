import { Button, Modal } from "react-bootstrap";
import React, { useState, useEffect, useRef } from "react";
import "./RateEscalationPdfPopup.scss";
import logoIcon from "../../../assets/images/hon-logo.png";
import { useReactToPrint } from "react-to-print";
import RateEscalationPDF from "./PDF/RateEscalationPDF";
import { fetchRatesListSrv } from "../../../services/contract.service";
import Loader from "../../common/Loader/Loader";

const RateEscalationPdfPopup = (props) => {
  const [esaclationdata, setEscalationData] = useState("");
  const [load, setLoad] = useState(false);
  const componentRef = useRef();

  const getRates = async () => {
    setLoad(true);
    const query = `contract=${props.constractnumberdata}`;
    await fetchRatesListSrv(query).then((res) => {
      if (res?.status === 200) {
        setEscalationData(res?.data?.data);
        setLoad(false);
      } else {
        setEscalationData("");
        setLoad(false);
      }
    });
  };

  const printPDF = () => {
    const pdfContentPage1 = window.document.getElementById(
      "rc-resultsPdf-page1"
    );
    pdfContentPage1.style.display = "block";
    pdfContentPage1.style.marginTop = "50px";
    if (
      pdfContentPage1 &&
      pdfContentPage1.getElementsByClassName("pdf-header").length === 0
    ) {
      const pdfSec1 = window.document
        .getElementsByClassName("pdf-header")[0]
        .cloneNode(true);
      const pdfSec2 = window.document
        .getElementsByClassName("pdf-body")[0]
        .cloneNode(true);
      if (pdfSec1 && pdfSec2) {
        pdfContentPage1?.appendChild(pdfSec1);
        pdfContentPage1?.appendChild(pdfSec2);
      }
    }
    handlePrint();
    pdfContentPage1.style.display = "none";
    pdfContentPage1.style.marginTop = "0px";
  };

  useEffect(() => {
    if (props.constractnumberdata !== "" && props.show === true) {
      getRates();
    }
    /*eslint-disable */
  }, [props.show]);
  const handleClick = () => {
    printPDF();
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const pdfbody = (
    <React.Fragment>
      {esaclationdata?.productList?.length > 0 ? (
        <div className="re-pdt-cnt">
          <div className="pdf-header">
            <div className="hw-pdf-logo">
              <a>
                <img src={logoIcon} alt="hon-logo" />
              </a>
              <span>
                Honeywell <br /> Phoenix, AZ 85034
              </span>
            </div>

            <div className="customer-info">
              <span className="customer-name">{esaclationdata.firstName}</span>
              <br />
              <span>{esaclationdata.accountName}</span>
              <br />
              <span>
                {esaclationdata.street} ,{esaclationdata.city} ,
                {esaclationdata.state}{" "}
              </span>
              <br />
              <span>
                {esaclationdata.county} ,{esaclationdata.postalCode}
              </span>
            </div>

            <div className="customer-info">
              <span>Dept. MSP,2102-229</span>
              <br />
              <span>Fax: (602)822-7502</span>
              <br />
              <span>Phone: (602)365-6442</span>
              <br />
              <span className="email">Email: mspcontracts@honeywell.com</span>
            </div>
          </div>
          <div className="pdf-body rate-cnt">
            <p className="subject">
              Subject MSP Contract #{props.constractnumberdata}
            </p>
            <p className="subject">Dear MSP Operator,</p>
            <p>
              This letter details your new rate information for{" "}
              {esaclationdata.escalationYear}. The economic indices<sup>*</sup>{" "}
              upon which rate changes are based under your MSP contract has
              yeilded the following escalations:
            </p>
            <table className="product-table">
              <thead>
                <tr>
                  <th>Serial No.</th>
                  <th>Hours Escalation Percentage</th>
                  <th>Effective Hour Rate</th>
                  <th>Cycles Escalation Percentage</th>
                  <th>Effective Cycle Rate</th>
                </tr>
              </thead>
              <tbody>
                {esaclationdata.productList?.map((products) => (
                  <>
                    <tr>
                      <td>{products.eqpSerialNumber}</td>
                      <td>{products.escalationHourPct2} %</td>
                      <td>{products.effectiveHourRateNumber}</td>
                      <td>{products.escalaionCyclePct} %</td>
                      <td>{products.effectiveCycleRateNumber}</td>
                    </tr>
                  </>
                ))}
              </tbody>
            </table>

            <p>
              Additional rate changes may also be incurred at the time of
              contract renewal, transfer, or for any rate increases based on TSN
              values, per your specific contact. For aircraft enrolled with
              special gratis time or discounted rates, rate adjustsments may
              become applicable in {esaclationdata.escalationYear} when the
              special gratis time or discounted rate time peroid has expired.
            </p>
            <p>
              <sup>*</sup>Based on your specific contract, some pricing values
              will be escalate by a different percentage.
            </p>
            <p>
              We appreciate your participation in our MSP program and remain
              dedicated to supporting your operational needs.
            </p>
            <p>
              Thank You <br />{" "}
              <strong className="subject">MSP Administration</strong>
            </p>
          </div>
        </div>
      ) : (
        <h3 className="no-data-contracts">
          You are not due for a rate escalation at this time
        </h3>
      )}
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="modal-h">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="title-modal"
          >
            <h2 className="m-btm-20">
              Rate escalation for contract {props.constractnumberdata}
            </h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {esaclationdata && load === false && pdfbody}
            {!esaclationdata && load === false && (
              <h4 className="text-center">No data available</h4>
            )}
            {load && <Loader />}
          </div>
          <RateEscalationPDF ref={componentRef} />
        </Modal.Body>
        <Modal.Footer>
          {!load && (
            <Button
              variant="secondary"
              className="modal-Continue btn-white"
              onClick={handleClick}
              disabled={
                !esaclationdata
                  ? true
                  : false || esaclationdata?.productList?.length === 0
              }
            >
              <div className="modal-Continue-text">Print</div>
            </Button>
          )}
          {!load && (
            <Button
              variant="secondary"
              className="modal-Continue btn-white"
              onClick={() => props.onHide()}
            >
              <div className="modal-Continue-text">Close</div>
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default RateEscalationPdfPopup;
