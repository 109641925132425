import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

const initialState = {
  contracts: [],
  isContractsLoading: false,
};

export const fetchAsyncContractsDetails = createAsyncThunk(
  "Contract/Details",
  async (payload) => {
    let payloadData;
    if (payload?.contractNo && payload?.soldToNumber?.length>0) {
      payloadData = [
        { contract_number: payload?.contractNo },
        { sold_to_number: payload?.soldToNumber },
      ];
    } else if (payload?.contractNo && !payload?.soldToNumber?.length<=0) {
      payloadData = [
        {
          contract_number: payload?.contractNo,
        },
      ];
    } else if (payload?.soldToNumber?.length>0 && !payload?.contractNo) {
      payloadData = [
        {
          sold_to_number: payload?.soldToNumber,
        },
      ];
    } else {
      payloadData = [{}];
    }
    const bodyContent = {
      query: "",
      filters: { all: payloadData },
    };
    try {
      const response = await fetch("/api/v1/contract/details", {
        method: "POST",
        headers: {
          authorization: `Bearer ${payload?.tokenValidate}`,
          "Content-Type": "application/json",
          "X-Request-ID": uuidv4(),
        },
        body: JSON.stringify(bodyContent),
      });

      const data = await response.json();
      if (response.status === 200) {
        return data?.data?.results;
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const contractDetailsReducer = createSlice({
  name: "contracts",
  initialState,
  extraReducers: {
    [fetchAsyncContractsDetails.pending]: (state, action) => {
      state.isContractsLoading = true;
    },
    [fetchAsyncContractsDetails.fulfilled]: (state, { payload }) => {
      return { ...state, contracts: payload, isContractsLoading: false };
    },
    [fetchAsyncContractsDetails.rejected]: (state, action) => {
      state.isContractsLoading = false;
    },
  },
});

export const contractDetailsActions = contractDetailsReducer.actions;
