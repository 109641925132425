import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./SelectedUbCreditsModal.scss";
import ApplyCreditsInvoices from "../ApplyCreditInvoices/ApplyCreditsInvoices";

const SelectedUbCreditsModal = (props) => {
  const { selectedcreditsinvoices } = props;
  const [totalCreditsAmmount, setTotalCreditsAmmount] = useState(0);
  const [showApplyCredits, setShowApplyCredits] = useState(false);
  const { dropdownselectedvalue, soldtodata, dropdownterm,soldtoinvoice } = props

  // calculating total credits
  useEffect(() => {
    const totalCreditsAmmount =
      selectedcreditsinvoices?.length > 0 &&
      selectedcreditsinvoices
        ?.reduce(
          (a, v) =>
            parseFloat(a) + parseFloat(v.invoiceBalance.replace(/,/g, "")),
          0
        )
        .toFixed(2);
    setTotalCreditsAmmount(totalCreditsAmmount);
  }, [selectedcreditsinvoices]);

  const closePopupHandler = () => {
    props.onHide();
  };
  const openApplyCreditsPopup = () => {
    setShowApplyCredits(true);
  };
  const closeApplyCreditsPopupHandler = () => {
    setShowApplyCredits(false);
  };
  return (
    <React.Fragment>
      <Modal
        {...props}
        aria-labelledby="selected credit invoices"
        contentClassName={
          showApplyCredits
            ? "selected-credits-display"
            : "selected-credits-container"
        }
        backdropClassName="selected-credits-modal"
      >
        <Modal.Header closeButton className="modal-h">
          <Modal.Title className="title-modal">
            <h2>
              <strong>Total To Be Applied</strong>
            </h2>
            <p>You will choose which invoices to apply to in the next step.</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="credits-total-container">
            <div className="credit-invoice-details">
              {selectedcreditsinvoices?.length > 0 &&
                selectedcreditsinvoices?.map((invoiceDetails, index) => (
                  <div className="credit-invoice" key={index}>
                    <div className="credit-invoice-label">
                      <span>{invoiceDetails?.invoiceNumber}</span>
                    </div>
                    <div className="credit-invoice-value">
                      <span>{invoiceDetails?.invoiceBalance} USD</span>
                    </div>
                  </div>
                ))}
            </div>

            <div className="credit-invoice-total">
              <div className="credit-invoice-total-label">
                <span>Total</span>
              </div>
              <div className="credit-invoice-total-value">
                <span>
                  {parseFloat(totalCreditsAmmount).toLocaleString("en", {
                    minimumFractionDigits: 2,
                  })}{" "}
                  USD
                </span>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-white"
            data-test="modal-cancel"
            onClick={closePopupHandler}
          >
            Go Back
          </Button>
          <Button className="btn btn-blue" onClick={openApplyCreditsPopup}>
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
      {showApplyCredits && (
        <ApplyCreditsInvoices
          selectedcreditsinvoices={selectedcreditsinvoices}
          totalcreditsammount={totalCreditsAmmount}
          show={showApplyCredits}
          dropdowntermvalue={dropdownselectedvalue}
          usagesoldto={soldtodata}
          dropdownvalue={dropdownterm}
          onHide={closeApplyCreditsPopupHandler}
          invoicesoldtodata = {soldtoinvoice}
          backdrop="static"
        />
      )}
    </React.Fragment>
  );
};

export default SelectedUbCreditsModal;
