import React from "react";
import "./ViewStatementPDF.scss";
const ViewStatementPDF = React.forwardRef((props, ref) => {
  //Modal Functionality Ends
  return (
    <div className="rc-results-wrapper11" ref={ref}>
      <div id="rc-resultsPdf-page11" className="rc-results1"></div>
    </div>
  );
});
export default ViewStatementPDF;
