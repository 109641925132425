export const callToAPI = async (routeUrl, methodData, headerContent) => {
  const res = await fetch(routeUrl, {
    method: methodData,
    headers: headerContent,
  });

  return res;
};

export const BRANDING_JS_PATHS = (key) => {
  let apiPath = "/js/header-footer-aero-msp.js";
  return apiPath;
};