import axios from "./axios";
import endPoint from "./endpoint";

export const fetchApplyCreditsSrv = (payload) => {
  return axios.post(endPoint.applyUBCredits(), payload);
};

export const fetchCreditPdfSrv = (payload) =>{
  return axios.post(endPoint.getCreditPdf(), payload, {
    responseType: "blob",
  });
}