const baseUrl = "/api/v1";
const baseUrlV2 = "/api/v2";

const endpoint = {
  configList: () => `${baseUrl}/auth/get-config`,
  getToken: () => `${baseUrl}/auth/get-token`,
  checkAccess: () => `${baseUrl}/auth/check-access`,

  contractList: () => `${baseUrl}/contract/details`,
  contractPdf: (id) => `${baseUrl}/contract/${id}/contract-pdf`,
  contractDocumentEntities: () => `${baseUrl}/contract/document-details`,
  postContractEntities: () => `${baseUrl}/contract/post-contract`,
  uploadContractFile: () => `${baseUrl}/files/upload-usage`,
  fetchExcelFile: (query) => `${baseUrl}/files/get-excel?${query}`,
  ratesList: (query) => `${baseUrl}/rates/get-rates?${query}`,

  invoiceList: () => `${baseUrl}/invoice`,
  invoiceEntities: () => `${baseUrl}/invoice/details`,
  invoiceDownload: (query) => `${baseUrl}/invoice/download?${query}`,
  cancelInvoice: () => `${baseUrl}/invoice/invoice-cancel`,
  deleteInvoice: () => `${baseUrl}/invoice/delete-invoice`,
  closeInvoice:() => `${baseUrl}/invoice/close-invoice`,
  preInvoiceEntities: () => `${baseUrl}/invoice/pre-invoice`,
  sendInvoice2User: () => `${baseUrl}/invoice/send-invoice`,
  usageBaseCredit: () => `${baseUrl}/invoice/usage-base-credit`,
  usageBaseAnaytics: () => `${baseUrl}/invoice/usage-base-anaytics`,
  makeCreditCardPayment: () => `${baseUrl}/ewallet/credit-card/make-payment`,

  creditCardList: () => `${baseUrl}/ewallet/credit-card`,
  updateCreditCardEntities: () => `${baseUrl}/ewallet/credit-card`,
  deleteCreditCardEntities: (id) => `${baseUrl}/ewallet/credit-card/${id}`,
  creditCardTemplate: () => `${baseUrl}/ewallet/credit-card/template`,
  creditCardToken: () => `${baseUrl}/ewallet/credit-card/token`,
  getInvoiceContractDetails: () =>
    `${baseUrl}/invoice/contract-invoice-details`,

  contractRatesList: () => `${baseUrl}/rates/contract-rate-details`,
  applyUBCredits: () => `${baseUrl}/credits/apply-ub-credits`,
  getCreditPdf: () => `${baseUrl}/credits/credit-pdf`,
  contractDetailsTransfer: () => `${baseUrl}/contract/contract-transfer`,
  transferContract: () =>`${baseUrl}/contract/sales-transfer`,

  //v2 endpoints
  contractListV2: () => `${baseUrlV2}/contract/details`,
};

export default endpoint;