import React, { useEffect, useRef, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import "./UbCreditsModal.scss";
import { Link } from "react-router-dom";
import { FaListUl } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
  FiChevronsLeft,
  FiChevronsRight,
  FiChevronLeft,
  FiChevronRight,
} from "react-icons/fi";
import { fetchInvoiceListSrv } from "../../../../services/invoice.service";
import SelectedUbCreditsModal from "../SelectedUbCreditsModal/SelectedUbCreditsModal";
import C from "../../../../constants/constant";

const UbCreditsModal = (props) => {
  const [invoicesData, setInvoicesData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [invoicesError, setInvoicesError] = useState("");
  const [selectedCreditsInvoices, setSelectedCreditsInvoices] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginantionData, setPaginantionData] = useState([]);
  const [, setTailList] = useState([]);
  const [, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const ubCreditsComponent = useRef(true);
  const [recordsPerPage] = useState(100);
  const { configData } = useSelector((state) => state.config);
  const [soldToInvoice, setSoldToInvoice] = useState("");
  const [showSelectedCreditsPopup, setshowSelectedCreditsPopup] =
    useState(false);

  const { dropdowntermvalue, usagesoldto, dropdownvalue } = props;

  const nPages = paginantionData?.total_pages;

  const pageNumbers = [];
  let start = currentPage;
  let end = currentPage + 4;

  // on selecting multiple invoices this method gets executed
  const multipleInvoicesHandler = (
    multipleInvoicesObjectData,
    multipleInvoicesChecked
  ) => {
    const invoiceDataDetails = JSON.parse(multipleInvoicesObjectData);

    if (multipleInvoicesChecked) {
      const findInvoice = selectedCreditsInvoices?.find(
        (invoice) => invoice?.id === invoiceDataDetails?.id
      );
      if (!findInvoice) {
        setSelectedCreditsInvoices((prevValue) => [
          ...prevValue,
          invoiceDataDetails,
        ]);
      }
    } else {
      setSelectedCreditsInvoices((prevValue) =>
        prevValue.filter(
          (multipleInvoiceFilter) =>
            multipleInvoiceFilter?.id !== invoiceDataDetails?.id
        )
      );
    }
  };

  if (end > paginantionData?.total_pages) {
    start -= end - paginantionData?.total_pages;
    end = paginantionData?.total_pages;
  }
  if (start <= 0) {
    end += (start - 1) * -1;
    start = 1;
  }

  end = end > paginantionData?.total_pages ? paginantionData?.total_pages : end;

  for (let i = start; i <= end; i++) {
    pageNumbers.push(i);
  }
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
    ubCreditsComponent.current = true;
  };

  const nextPage = () => {
    if (currentPage !== nPages) {
      if (currentPage === 100) {
        setCurrentPage(100);
      } else {
        setCurrentPage(currentPage + 1);
      }
    }
    ubCreditsComponent.current = true;
  };

  const gotoLastPage = () => {
    if (paginantionData.total_pages <= 100) {
      setCurrentPage(paginantionData.total_pages);
    } else {
      setCurrentPage(99);
    }
    ubCreditsComponent.current = true;
  };

  const gotoFirstPage = () => {
    setCurrentPage(1);
    ubCreditsComponent.current = true;
  };

  let usagePayLoad;
  const dropDownData =
    dropdownvalue === "contract_number"
      ? { contract_number: dropdowntermvalue }
      : { invoice_number: dropdowntermvalue };
  if (dropdowntermvalue && usagesoldto?.length > 0) {
    usagePayLoad = {
      all: [
        dropDownData,
        { sold_to_number: usagesoldto },
        {
          status: ["Open"],
        },
        { program_code: ["MSP", "MSPG", "GNLS", "FMA", "GNLI", "CFE"] },
        {
          is_credit_flag: "Y",
        },
      ],
    };
  } else if (dropdowntermvalue && usagesoldto?.length <= 0) {
    usagePayLoad = {
      all: [
        dropDownData,
        {
          status: ["Open"],
        },
        { program_code: ["MSP", "MSPG", "GNLS", "FMA", "GNLI", "CFE"] },
        {
          is_credit_flag: "Y",
        },
      ],
    };
  } else if (usagesoldto?.length > 0 && !dropdowntermvalue) {
    usagePayLoad = {
      all: [
        { sold_to_number: usagesoldto },
        {
          status: ["Open"],
        },
        { program_code: ["MSP", "MSPG", "GNLS", "FMA", "GNLI", "CFE"] },
        {
          is_credit_flag: "Y",
        },
      ],
    };
  } else {
    usagePayLoad = {
      all: [
        {
          status: ["Open"],
        },
        { program_code: ["MSP", "MSPG", "GNLS", "FMA", "GNLI", "CFE"] },
        {
          is_credit_flag: "Y",
        },
      ],
    };
  }

  const bodyContent = {
    query: "",
    filters: usagePayLoad,
    sort: { invoice_period_date: "asc" },
    page: {
      current: currentPage,
      size: recordsPerPage,
    },
  };

  // api to get the invoice details
  const getInvoiceDetails = async () => {
    setLoading(true);
    const payload = JSON.stringify(bodyContent);
    await fetchInvoiceListSrv(payload).then((res) => {
      if (res?.status === 200) {
        setInvoicesData(res?.data?.data?.results);
        setSoldToInvoice(res?.data?.data?.results[0]?.soldToNumber);
        setPaginantionData(res?.data?.data?.meta?.page);
      } else if (res?.status === 401) {
        setInvoicesData([]);
        setInvoicesError(res?.data?.error?.errorDescription);
      } else {
        setInvoicesData([]);
        setInvoicesError(res?.data?.errorDescription);
      }
      setLoading(false);
    });
  };

  useEffect(() => {
    if (ubCreditsComponent.current === true) {
      ubCreditsComponent.current = false;
      getInvoiceDetails();
    }
    /*eslint-disable */
  }, [currentPage]);

  const closePopupHandler = () => {
    props.onCancel();
  };

  //open modal for multiple tails
  const openTailDetails = (data) => {
    if (data.tailDetails === undefined || data.tailDetails === "") {
      setTailList([]);
    } else {
      setTailList(data.tailDetails);
    }
    handleShow();
  };

  const openSelectedCreditsPopup = () => {
    setshowSelectedCreditsPopup(true);
  };

  const closeSelectedCreditsPopupHandler = () => {
    setshowSelectedCreditsPopup(false);
  };

  return (
    <React.Fragment>
      <Modal
        {...props}
        aria-labelledby="choose credit invoices"
        contentClassName={
          showSelectedCreditsPopup
            ? "credits-container-display"
            : "credits-container"
        }
      >
        <Modal.Header closeButton className="modal-h">
          <Modal.Title className="title-modal">
            <h3>
              <strong>Invoice Credits</strong>
            </h3>
            <h5>Select the Credits you wish to apply</h5>
            <div className="credits-notes">
              <span class="err-icon">i</span>
              <span>
                In order to apply credits for Fleet Maintenance Plan related
                contracts please{" "}
                <a
                  href={
                    configData?.MSP_TOKEN?.CREDIT_INVOICE_URL
                      ? configData?.MSP_TOKEN?.CREDIT_INVOICE_URL
                      : C.MSP_CREDITS_URL
                  }
                  target="_blank"
                >
                  click here
                </a>
              </span>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row paginate-cnt">
            <div className="col-8">
              {invoicesData?.length > 0 && !loading && (
                <span className="page-count">
                  {`${currentPage}-${invoicesData?.length} of ${paginantionData?.total_results} Items`}
                </span>
              )}
            </div>
            <div className="col-4">
              <div>
                {invoicesData.length !== 0 && (
                  <nav>
                    <ul className="pagination justify-content-center">
                      <li className="page-item">
                        <Link
                          className={
                            currentPage === 1
                              ? "page-link disabled"
                              : "page-link"
                          }
                          onClick={gotoFirstPage}
                          to="#"
                        >
                          <FiChevronLeft />
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link
                          className={
                            currentPage === 1
                              ? "page-link disabled"
                              : "page-link"
                          }
                          onClick={prevPage}
                          to="#"
                        >
                          <FiChevronsLeft />
                        </Link>
                      </li>
                      {pageNumbers?.map((pgNumber) => (
                        <li
                          key={pgNumber}
                          className={`page-item ${
                            currentPage === pgNumber ? "active" : ""
                          } `}
                        >
                          <Link
                            onClick={() => {
                              setCurrentPage(pgNumber <= 100 ? pgNumber : 100);
                              ubCreditsComponent.current = true;
                            }}
                            className="page-link"
                            to="#"
                          >
                            {pgNumber}
                          </Link>
                        </li>
                      ))}

                      <li className="page-item">
                        <Link
                          className={
                            currentPage === paginantionData?.total_pages
                              ? "page-link disabled"
                              : "page-link"
                          }
                          onClick={nextPage}
                          to="#"
                        >
                          <FiChevronsRight />
                        </Link>
                      </li>

                      <li className="page-item">
                        <Link
                          className={
                            currentPage === paginantionData?.total_pages
                              ? "page-link disabled"
                              : "page-link"
                          }
                          onClick={gotoLastPage}
                          to="#"
                        >
                          <FiChevronRight />
                        </Link>
                      </li>
                    </ul>
                  </nav>
                )}
              </div>
            </div>
          </div>
          {loading && (
            <h2 className="no-data-invoices">
              {configData?.MSP_INFORMATION_TEXT?.LOADING_INVOICES}
              <div className="loader"></div>
            </h2>
          )}
          {!loading && invoicesData?.length !== 0 ? (
            <div className="credits-invoice-content">
              {invoicesData.map((invoiceDetails, index) => {
                const multipleInvoiceObject = JSON.stringify({
                  ...invoiceDetails,
                });
                return (
                  <div className="invoice-info" key={index}>
                    <div className="info-hdr">
                      <div className="input-check">
                        <input
                          type="checkbox"
                          name={multipleInvoiceObject}
                          onChange={(e) => {
                            multipleInvoicesHandler(
                              e.target.name,
                              e.target.checked
                            );
                          }}
                          disabled={!invoiceDetails?.debitMemoNumber}
                        />
                      </div>
                      <div className="contract-cnt">
                        <span className="contract-num">
                          {invoiceDetails?.invoiceNumber}
                        </span>
                        <span className="single-tail">
                          {invoiceDetails?.tailDetails?.length === 1 ? (
                            invoiceDetails?.tailDetails[0].aircraftTailNumber &&
                            `Tail No. ${invoiceDetails?.tailDetails[0].aircraftTailNumber}`
                          ) : (
                            <span
                              className="multiple-tail"
                              onClick={() => openTailDetails(invoiceDetails)}
                            >
                              Multiple Tails <FaListUl className="mtail-icon" />
                            </span>
                          )}
                        </span>
                      </div>
                      <div className="invoice-amt">
                        <span className="open-value">
                          <span>{invoiceDetails?.invoiceBalance} USD</span>
                          <span>{invoiceDetails?.status}</span>
                        </span>
                      </div>
                      <div className="info-ftr">
                        <div>
                          <span>Contract/SAP Invoice/Reference No.</span>
                          <span className="contract-no">
                            <Link className="contarct-lable" to="#">
                              {invoiceDetails?.contractNumber}
                            </Link>
                            {invoiceDetails?.debitMemoNumber &&
                              `/${invoiceDetails?.debitMemoNumber}`}
                          </span>
                        </div>
                        <div>
                          <span>Period</span>
                          <span>{invoiceDetails?.invoicePeriod}</span>
                        </div>
                        <div>
                          <span>Type</span>
                          <span>{invoiceDetails?.invoiceType}</span>
                        </div>
                        <div>
                          <span>Account</span>
                          <span>{invoiceDetails?.accountName}</span>
                        </div>

                        <div>
                          <span>Invoice Total</span>
                          <span>{invoiceDetails?.invoiceAmount} USD</span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="no-data-invoices red">
              <h6>{invoicesError ? invoicesError : "No Data Available"}</h6>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-white"
            data-test="modal-cancel"
            onClick={closePopupHandler}
          >
            cancel
          </Button>
          <Button
            className={
              selectedCreditsInvoices?.length !== 0
                ? "btn btn-blue"
                : "btn btn-blue disabled"
            }
            onClick={openSelectedCreditsPopup}
          >
            View Total
          </Button>
        </Modal.Footer>
      </Modal>
      {showSelectedCreditsPopup && (
        <SelectedUbCreditsModal
          selectedcreditsinvoices={selectedCreditsInvoices}
          show={showSelectedCreditsPopup}
          onHide={closeSelectedCreditsPopupHandler}
          dropdownselectedvalue={dropdowntermvalue}
          soldtodata={usagesoldto}
          dropdownterm={dropdownvalue}
          soldtoinvoice={soldToInvoice}
          backdrop="static"
        />
      )}
    </React.Fragment>
  );
};

export default UbCreditsModal;
