import React, { useEffect, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import "./TransferContractModal.scss";
import PhoneInput from "react-phone-input-2";
import {
  contractsDetailsTransferSrv,
  transferContractSrv,
} from "../../../../services/contract.service";
import "react-phone-input-2/lib/style.css";
import TransferContractConfirmationPopup from "../TransferContractConfirmation/TransferContractConfirmationPopup";
import ModalPopup from "../../../common/ModalPopup/ModalPopup";
import { contractPayload } from "./TransferContractPayload";

const TransferContractModal = (props) => {
  const [checked, setChecked] = useState(false);
  const [file, setFile] = useState();
  const [buyerMail, setBuyerMail] = useState("");
  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileFormatError, setFileFormatError] = useState(false);
  const [alternateBuyerMail, setAlternateBuyerMail] = useState("");
  const [enginesCount, setEnginesCount] = useState([]);

  const { configData } = useSelector((state) => state.config);
  const { contractdetails } = props;
  const [transferContractConfirmation, setTransferContractConfirmation] =
    useState(false);
  const [contracts, setContracts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [tailPlatformDetails, setTailPlatformDetails] = useState([]);
  const [tailEngineDetails, setTailEngineDetails] = useState([]);
  // const [tailApuDetails, setTailApuDetails] = useState([]);
  const [additionalDetails, setAdditionalDetails] = useState("");
  const [caseNumber, setCaseNumber] = useState("");
  const [isTransferLoading, setIsTransferLoading] = useState(false);
  const [tailNumber, setTailNumber] = useState("");
  const [base64File, setBase64File] = useState("");
  const [platformTsn, setPlatformTsn] = useState("");
  const [platformSerialNumber, setPlatformSerialNumber] = useState("");
  const [apuTsn, setApuTsn] = useState("");
  const [apuSerialNumber, setApuSerialNumber] = useState("");
  const [engineSerialNoDetails, setEngineSerialNoDetails] = useState([]);
  const [engineTsnDetails, setEngineTsnDetails] = useState([]);
  const [transferError, setTransferError] = useState(false);
  const [transferErrorMessage, setTransferErrorMessage] = useState("");
  const [buyerName, setBuyerName] = useState("");
  const [buyerCompany, setBuyerCompany] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [tailLength, setTailLength] = useState(0);
  const transferContractModel = useRef(true);
  let REGEX_EMAIL = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  // function to be executed on click of radio button
  const selectedTailDetailsHandler = (tailNumber) => {
    setApuSerialNumber("");
    setApuTsn("");
    setPlatformSerialNumber("");
    setPlatformTsn("");
    setTailEngineDetails([]);
    setEngineSerialNoDetails([]);
    setEngineTsnDetails([]);
    setTailNumber(tailNumber);
    const tailPlatform = contracts?.tailDetails?.find(
      (tail) => tail?.aircraftTailNumber === tailNumber
    )?.platformDetails;
    // setTailPlatformDetails(tailPlatform);
    if (tailPlatform) {
      setPlatformSerialNumber(tailPlatform[0]?.platformSerialNo);
      setPlatformTsn(tailPlatform[0]?.beginTsn);
    }
    const tailEngine = contracts?.tailDetails
      ?.find((tail) => tail?.aircraftTailNumber === tailNumber)
      ?.productDetails?.filter((engine) => engine?.type !== "APU");
    setTailEngineDetails(tailEngine);
    if (tailEngine?.length > 0) {
      getTailEnginesData(tailEngine);
    }
    const tailApu = contracts?.tailDetails
      ?.find((tail) => tail?.aircraftTailNumber === tailNumber)
      ?.productDetails?.find((product) => product?.type === "APU");
    // setTailApuDetails(tailApu);
    if (tailApu) {
      setApuSerialNumber(tailApu?.productSerialNo);
      setApuTsn(tailApu?.beginTsn);
    }
  };

  // function to calculate tails length
  const getTailsLength = () => {
    const tailLength = contracts?.tailDetails?.length;
    setTailLength(tailLength);
    if (tailLength > 0) {
      selectedTailDetailsHandler(contracts?.tailDetails[0]?.aircraftTailNumber);
    }
  };

  // function to get the engine details
  const getTailEnginesData = (enginesData) => {
    const engineTailsArray = [];
    enginesData?.forEach((tailEngine, index) => {
      engineTailsArray.push({
        engineNumber: `Engine-${index + 1}`,
        engineSerialNo: tailEngine?.productSerialNo,
      });
    });
    setEngineSerialNoDetails(engineTailsArray);
    const engineTsnArray = [];
    enginesData?.forEach((tails, index) => {
      engineTsnArray.push({
        engineNumber: `Engine-${index + 1}`,
        engineTsn: tails?.beginTsn,
      });
    });
    setEngineTsnDetails(engineTsnArray);
  };

  const payload = {
    query: "",
    filters: {
      all: [
        {
          contract_number: contractdetails,
        },
      ],
    },
  };

  // api to get contract details
  const getContractDetails = async () => {
    setIsLoading(true);
    const body = JSON.stringify(payload);

    await contractsDetailsTransferSrv(body).then((res) => {
      if (res?.status === 200) {
        setContracts(res?.data?.data?.results[0]);
      } else {
        setContracts([]);
      }
    });
    setIsLoading(false);
  };

  // function to be executed on click of submit
  const transferContractHandler = async () => {
    setIsTransferLoading(true);
    let payload = contractPayload(
      buyerMail,
      additionalDetails,
      contractdetails,
      tailNumber,
      engineTsnDetails,
      platformTsn,
      apuTsn,
      "",
      file,
      base64File,
      "",
      alternateBuyerMail,
      buyerName,
      buyerCompany,
      phoneNumber,
      engineSerialNoDetails,
      platformSerialNumber,
      apuSerialNumber
    );

    // api for sales transfer
    await transferContractSrv(payload).then((res) => {
      setIsTransferLoading(true);
      if (res?.status === 200) {
        setCaseNumber(res?.data?.data?.salesTransferResponse?.caseNumber);
        setTransferContractConfirmation(true);
      } else if (res?.status === 404) {
        setTransferError(true);
        setTransferErrorMessage(res?.data?.salesTransferResponse?.message);
      } else {
        setTransferError(true);
        setTransferErrorMessage(res?.data?.errorDescription);
      }
      setIsTransferLoading(false);
    });
  };

  // generating base64 string for pdf
  const getBase64 = (event) => {
    let selectedFile = event.target.files[0];
    if (selectedFile) {
      var reader = new FileReader();
      reader.onload = function (event) {
        // Convert the file to Base64
        let selectedFileBase64 = event.target.result.split(",")[1];
        setBase64File(selectedFileBase64);
      };
      reader.readAsDataURL(selectedFile);
    }
  };

  // executed on click of upload file
  const fileUploadHandler = (e) => {
    setFileFormatError(false);
    setFileSizeError(false);
    setFile(e?.target?.files[0]);
    getBase64(e);
    const file = e.target.files[0]?.name?.split(".").pop();
    const filesize = e.target.files[0]?.size;
    if (
      file &&
      file !== "pdf" &&
      e?.target?.files[0]?.type !== "application/pdf"
    ) {
      setFileFormatError(true);
    } else if (file && filesize > 5 * 1024 * 1024) {
      setFileSizeError(true);
    }
  };

  useEffect(() => {
    getTailsLength();
    /*eslint-disable */
  }, [contracts]);

  useEffect(() => {
    if (transferContractModel.current === true) {
      transferContractModel.current = false;
      getContractDetails();
    }
    /*eslint-disable */
  }, [contractdetails]);

  const closePopupHandler = () => {
    props.onHide();
  };

  // function to store engine details
  const engineSerialNoValuesHandler = (engine, serialNo) => {
    const engineDetails = engineSerialNoDetails?.find(
      (engineData) => engineData?.engineNumber === engine
    );
    const engineIndex = engineSerialNoDetails?.findIndex(
      (engineTail) => engineTail?.engineNumber === engine
    );
    const enginesArray = [...engineSerialNoDetails];
    if (engineDetails) {
      if (serialNo.length > 0) {
        engineDetails.engineSerialNo = serialNo;
        enginesArray[engineIndex] = engineDetails;
        setEngineSerialNoDetails(enginesArray);
      } else {
        const updatedEngineSerialNoArray = enginesArray?.filter(
          (engineSerialNo) => engineSerialNo?.engineNumber !== engine
        );
        setEngineSerialNoDetails(updatedEngineSerialNoArray);
      }
    } else {
      setEngineSerialNoDetails((prevValue) => [
        ...prevValue,
        { engineNumber: engine, engineSerialNo: serialNo },
      ]);
    }
  };

  const engineTsnHandler = (engine, tsnValue) => {
    const engineDetails = engineTsnDetails?.find(
      (engineData) => engineData?.engineNumber === engine
    );
    const engineIndex = engineTsnDetails?.findIndex(
      (engineTail) => engineTail?.engineNumber === engine
    );
    const enginesArray = [...engineTsnDetails];
    if (engineDetails) {
      if (tsnValue > 0) {
        engineDetails.engineTsn = tsnValue;
        enginesArray[engineIndex] = engineDetails;
        setEngineTsnDetails(enginesArray);
      } else {
        const updatedEngineTsnArray = enginesArray?.filter(
          (engineSerialNo) => engineSerialNo?.engineNumber !== engine
        );
        setEngineTsnDetails(updatedEngineTsnArray);
      }
    } else {
      setEngineTsnDetails((prevValue) => [
        ...prevValue,
        { engineNumber: engine, engineTsn: tsnValue },
      ]);
    }
  };

  const addEnginesHandler = () => {
    const engineLength = enginesCount.length;
    setEnginesCount([...enginesCount, `engine-${engineLength + 1}`]);
  };

  const headerContentPopup = <h1>Initiate A Transfer</h1>;

  const bodyContentPopup = (
    <>
      {!isLoading && !isTransferLoading && !transferError && (
        <>
          <div className="tail-selection">
            {contracts?.tailDetails?.length > 0 && (
              <div className="tail-header">
                <span>Please Select the Tail</span>
              </div>
            )}
            <div className="tail-radio">
              {contracts?.tailDetails?.map((tailData, index) => {
                return (
                  <div className="tail-radio-btn" key={index}>
                    {tailLength === 1 ? (
                      <input
                        type="radio"
                        name="tail"
                        id="tail"
                        value={tailData?.aircraftTailNumber}
                        onChange={(e) =>
                          selectedTailDetailsHandler(e.target.value)
                        }
                        checked={true}
                      />
                    ) : (
                      <input
                        type="radio"
                        name="tail"
                        id="tail"
                        value={tailData?.aircraftTailNumber}
                        onChange={(e) =>
                          selectedTailDetailsHandler(e.target.value)
                        }
                        defaultChecked={index === 0 ? true : false}
                      />
                    )}
                    <label aria-labelledby="tail" htmlFor="tail">
                      {tailData?.aircraftTailNumber}
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
          {
            <>
              <span className="sub-heading">Aircraft Information</span>
              <div className="tail-details">
                <form aria-labelledby="aircraft-detail-form">
                  {!contracts?.tailDetails && (
                    <div className="row">
                      <div className="col-1">
                        <label aria-labelledby="tail-number">
                          Tail Number
                          <input
                            className="form-control"
                            id="tailNumber"
                            type="text"
                            onChange={(e) => setTailNumber(e.target.value)}
                            value={tailNumber || ""}
                          ></input>
                        </label>
                      </div>
                    </div>
                  )}
                  <div className="row">
                    <div className="col-1">
                      <label aria-labelledby="aircraft-make">
                        Aircraft Serial Number
                        <input
                          className="form-control"
                          id="aircraft-make"
                          type="text"
                          onChange={(e) =>
                            setPlatformSerialNumber(e.target.value)
                          }
                          value={platformSerialNumber || ""}
                        ></input>
                      </label>
                    </div>

                    <div className="col-2">
                      <label aria-labelledby="aircraft-modal">
                        TSN Aircraft
                        <input
                          className="form-control"
                          id="aircraft-modal"
                          type="text"
                          onChange={(e) => setPlatformTsn(e.target.value)}
                          value={platformTsn || ""}
                        ></input>
                      </label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-1">
                      <label aria-labelledby="serial-apu">
                        APU Serial number
                        <input
                          className="form-control"
                          name="apu-serial"
                          type="text"
                          onChange={(e) => setApuSerialNumber(e.target.value)}
                          value={apuSerialNumber || ""}
                          autoComplete="off"
                        ></input>
                      </label>
                    </div>

                    <div className="col-2">
                      <label aria-labelledby="TSN-APU">
                        TSN APU
                        <input
                          className="form-control"
                          id="TSN-APU"
                          type="text"
                          onChange={(e) => setApuTsn(e.target.value)}
                          value={apuTsn || ""}
                          autoComplete="off"
                        ></input>
                      </label>
                    </div>
                  </div>
                  {tailEngineDetails?.map((engineData, index) => {
                    return (
                      <div className="row" key={index}>
                        <div className="col-1">
                          <label aria-labelledby={`engine-${index + 1}`}>
                            {`Engine ${index + 1} Serial Number`}
                            <input
                              className="form-control"
                              id={`engine-${index + 1}`}
                              name={`engine-${index + 1}`}
                              type="text"
                              readOnly
                              value={engineData?.productSerialNo}
                              autoComplete="off"
                            ></input>
                          </label>
                        </div>

                        <div className="col-2">
                          <label aria-labelledby={`TSN-Engine${index + 1}`}>
                            {`Engine ${index + 1} TSN`}
                            <input
                              className="form-control"
                              id={`Engine-${index + 1}`}
                              name={`Engine-${index + 1}`}
                              type="text"
                              readOnly
                              value={engineData?.beginTsn}
                              autoComplete="off"
                            ></input>
                          </label>
                        </div>
                      </div>
                    );
                  })}
                  {enginesCount?.map((engine, index) => {
                    return (
                      <div className="row" key={index}>
                        <div className="col-1">
                          <label aria-labelledby={`engine-${index + 1}`}>
                            {`Engine ${index + 1} Serial Number`}
                            <input
                              className="form-control"
                              id={`Engine-${index + 1}`}
                              name={`Engine-${index + 1}`}
                              type="text"
                              onChange={(e) =>
                                engineSerialNoValuesHandler(
                                  e.target.name,
                                  e.target.value
                                )
                              }
                              value={
                                engineSerialNoDetails?.find(
                                  (engineNo) =>
                                    engineNo?.engineNumber ===
                                    `Engine-${index + 1}`
                                )?.engineSerialNo || ""
                              }
                              autoComplete="off"
                            ></input>
                          </label>
                        </div>
                        <div className="col-2">
                          <label aria-labelledby={`TSN-engine-${index + 1}`}>
                            {`Engine ${index + 1} TSN`}
                            <input
                              className="form-control"
                              id={`Engine-${index + 1}`}
                              name={`Engine-${index + 1}`}
                              type="text"
                              onChange={(e) =>
                                engineTsnHandler(e.target.name, e.target.value)
                              }
                              value={
                                engineTsnDetails?.find(
                                  (engineNo) =>
                                    engineNo?.engineNumber ===
                                    `Engine-${index + 1}`
                                )?.engineTsn || ""
                              }
                              autoComplete="off"
                            ></input>
                          </label>
                        </div>
                      </div>
                    );
                  })}
                  {!contracts && enginesCount?.length < 3 && (
                    <div className="row-2">
                      <div className="col-2">
                        <Button onClick={addEnginesHandler}>Add Engine</Button>
                      </div>
                    </div>
                  )}
                  <div className="row-2">
                    <label aria-labelledby="Additional-details">
                      Additional Details{" "}
                      <span className="optional">Optional</span>
                      <textarea
                        id="Additinal-detail"
                        className="form-control"
                        placeholder="Enter details"
                        onChange={(e) => setAdditionalDetails(e.target.value)}
                      ></textarea>
                    </label>
                    <span className="hint">
                      To ensure fast setup, please use your company email
                      address.
                    </span>
                  </div>
                </form>
              </div>
              <div className="buyer-details">
                <span className="sub-heading form-2">
                  New Buyer Information
                </span>
                <br />
                <span className="hint">
                  To facilitate the transfer as quickly as possible, please
                  provide the below information.
                </span>
                <form aria-labelledby="buyer-detail-form">
                  <div className="row">
                    <div className="col-1">
                      <label aria-labelledby="email">
                        Buyer Primary Email Address
                        <input
                          className={
                            buyerMail !== "" && !REGEX_EMAIL.test(buyerMail)
                              ? "form-control error-border"
                              : "form-control"
                          }
                          type="email"
                          id="email"
                          placeholder="name@domain.com"
                          onChange={(event) => setBuyerMail(event.target.value)}
                          autoComplete="none"
                        ></input>
                      </label>
                    </div>

                    <div className="col-2">
                      <label aria-labelledby="alternate-email">
                        Alternate Email{" "}
                        <span className="optional">Optional</span>
                        <input
                          className={
                            alternateBuyerMail !== "" &&
                            !REGEX_EMAIL.test(alternateBuyerMail)
                              ? "form-control error-border"
                              : "form-control"
                          }
                          type="email"
                          id="alternate-email"
                          placeholder="name@domain.com"
                          onChange={(event) =>
                            setAlternateBuyerMail(event.target.value)
                          }
                          autoComplete="none"
                        ></input>
                      </label>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-1">
                      <label aria-labelledby="buyer-name">
                        Buyer Name <span className="optional">Optional</span>
                        <input
                          className="form-control"
                          type="text"
                          id="buyer-name"
                          placeholder="First and Last"
                          onChange={(e) => setBuyerName(e.target.value)}
                        ></input>
                      </label>
                    </div>

                    <div className="col-2">
                      <label aria-labelledby="buyer-company">
                        Buyer Company <span className="optional">Optional</span>
                        <input
                          className="form-control"
                          id="buyer-company"
                          type="text"
                          placeholder="Enter a Company Name"
                          onChange={(e) => setBuyerCompany(e.target.value)}
                        ></input>
                      </label>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-1">
                      <label aria-labelledby="contact">
                        Company Phone <span className="optional">Optional</span>
                        <PhoneInput
                          country={"us"}
                          aria-labelledby="contact-input"
                          onChange={(e) => setPhoneNumber(e)}
                        />
                      </label>
                    </div>
                    <div className="col-2"></div>
                  </div>
                  <div className="row">
                    <div className="col-1">
                      <label
                        aria-labelledby="sales document"
                        htmlFor="sales-document"
                      >
                        Sales Document
                      </label>
                      <input
                        id="sales-document"
                        type="file"
                        onChange={fileUploadHandler}
                      />
                      <span className="file-error">
                        {file &&
                          fileFormatError &&
                          configData?.MSP_INFORMATION_TEXT
                            ?.SALES_TRANSFER_FILE_FORMAT_ERROR}
                      </span>
                      <span className="file-error">
                        {file &&
                          fileSizeError &&
                          configData?.MSP_INFORMATION_TEXT
                            ?.SALES_TRANSFER_FILE_SIZE_ERROR}
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </>
          }
        </>
      )}
      {isLoading && (
        <h2 className="no-data-contracts">
          {configData?.MSP_INFORMATION_TEXT?.LOADING_TAILS}
          <div className="loader"></div>
        </h2>
      )}
      {isTransferLoading && (
        <h2 className="no-data-contracts">
          {configData?.MSP_INFORMATION_TEXT?.LOADING_TRANSFER}
          <div className="loader"></div>
        </h2>
      )}
      {transferError && (
        <h2 className="no-data-contracts">
          {transferErrorMessage
            ? transferErrorMessage
            : "Something went wrong please try again later"}
        </h2>
      )}
    </>
  );

  const footerContentPopup = (
    <>
      {!isLoading && !isTransferLoading && !transferError && (
        <div className="submit-modal-container">
          <div className="accept-modal-form" aria-labelledby="confirmation">
            <input
              className="checkbox"
              aria-label="confirmation-checkbox"
              type="checkbox"
              onChange={() => setChecked((prev) => !prev)}
            />

            <h6>
              I acknowledge that I am responsible for reporting hours on this
              contract until the transfer is complete or the contract ends.
            </h6>
          </div>
          <Button
            className="btn btn-white"
            aria-labelledby="close"
            data-test="modal-cancel"
            onClick={closePopupHandler}
          >
            Cancel
          </Button>

          {tailEngineDetails?.length === 0 ? (
            <Button
              className="btn btn-blue"
              aria-labelledby="submit"
              disabled={
                (alternateBuyerMail?.length > 0
                  ? !checked ||
                    !REGEX_EMAIL.test(buyerMail) ||
                    buyerMail === "" ||
                    !REGEX_EMAIL.test(alternateBuyerMail)
                  : !checked ||
                    buyerMail?.length === 0 ||
                    !REGEX_EMAIL.test(buyerMail)) ||
                !platformTsn ||
                !platformSerialNumber ||
                !tailNumber ||
                fileFormatError === true ||
                fileSizeError === true
              }
              onClick={transferContractHandler}
            >
              Submit
            </Button>
          ) : (
            <Button
              className="btn btn-blue"
              aria-labelledby="submit"
              disabled={
                (alternateBuyerMail?.length > 0
                  ? !checked ||
                    !REGEX_EMAIL.test(buyerMail) ||
                    buyerMail === "" ||
                    !REGEX_EMAIL.test(alternateBuyerMail)
                  : !checked ||
                    buyerMail?.length === 0 ||
                    !REGEX_EMAIL.test(buyerMail)) ||
                !platformTsn ||
                !platformSerialNumber ||
                fileFormatError === true ||
                fileSizeError === true
              }
              onClick={transferContractHandler}
            >
              Submit
            </Button>
          )}
        </div>
      )}
    </>
  );

  return (
    <>
      <React.Fragment>
        <ModalPopup
          showPopup={props.show}
          size=""
          arialabel="transfer modal"
          cancelPopup={props.onHide}
          contentClassName={
            transferContractConfirmation
              ? "transfer-contract-display"
              : "transfer-contract-modal"
          }
          header={headerContentPopup}
          body={bodyContentPopup}
          footer={footerContentPopup}
        />
        {transferContractConfirmation && (
          <TransferContractConfirmationPopup
            show={transferContractConfirmation}
            subStatus={props.subStatus}
            caseNumber={caseNumber}
            buyerMail={buyerMail}
            onHide={() => {
              setTransferContractConfirmation(false);
              props.onHide();
            }}
          />
        )}
      </React.Fragment>
    </>
  );
};

export default TransferContractModal;
