import React, { useState } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { sendInvoice2UserSrv } from "../../../../services/invoice.service";
import InfoIcon from "../../../../assets/images/info-icon.png";

const EmailInvoices = ({
  show,
  handleClose,
  accountName,
  invoiceNo,
  displayData,
  setshowEmail,
}) => {
  const [data, setdata] = useState({
    name: "",
    email: "",
    subject: `${accountName} and Invoice Number #${invoiceNo}`,
  });
  const [isloading, setIsloading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const payload = JSON.stringify({
    to: data.email,
    subject: data.subject,
    fileName: `Invoice #${invoiceNo}.pdf`,
    invoiceDetails: displayData,
  });

  const sendInvoice2User = async (e) => {
    setIsloading(true);
    e.preventDefault();
    await sendInvoice2UserSrv(payload).then((res) => {
      if (res?.status === 200) {
        setIsloading(false);
        setIsSuccess(true);
      } else {
        setIsloading(false);
        setIsSuccess(false);
      }
    });
  };

  const onChange = (e) => {
    e.preventDefault();
    setdata({ ...data, [e.target.name]: e.target.value });

    const emailRegex =
      /^(([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*(,)\s*|\s*$))*$/;

    if (e.target.name === "email") {
      if (!e.target.value.match(emailRegex)) {
        setErrorMsg(
          "Please enter valid email id and use comma for multiple emails separation"
        );
        setIsValid(true);
      } else {
        setErrorMsg("");
        setIsValid(false);
      }
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        aria-labelledby="choose contract"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="modal-title">
            <h2 className="m-btm-20">{isSuccess ? " " : "Email To :"}</h2>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="modal-body email-body">
          <div className="ewallet-cnt edit-card">
            {isSuccess ? (
              <>
                <h2 className="email-sent"> Email sent successfully.</h2>
              </>
            ) : (
              <Form>
                <Form.Group>
                  <Form.Label htmlFor="disabledTextInput">
                    Email id{" "}
                    <span className="info-icon">
                      <img src={InfoIcon} alt="contract-data" />{" "}
                      <span className="tool-tip">
                        Please use comma for multiple emails
                      </span>
                    </span>
                  </Form.Label>
                  <Form.Control
                    name="email"
                    placeholder="Email Id"
                    onChange={onChange}
                  />
                </Form.Group>
                <p className="err m-top-20">{errorMsg}</p>

                <Form.Group>
                  <Form.Label>Subject</Form.Label>
                  <Form.Control
                    name="subject"
                    placeholder="Sub"
                    onChange={onChange}
                    value={data.subject}
                    disabled={data.subject !== ""}
                  />
                </Form.Group>
              </Form>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isSuccess ? (
            <Button
              className="btn btn-blue"
              data-test="modal-cancel"
              onClick={() => {
                setshowEmail((preValue) => {
                  return false;
                });
                setIsSuccess(false);
              }}
            >
              Close
            </Button>
          ) : (
            <Button
              className="btn btn-blue"
              data-test="modal-sent"
              onClick={sendInvoice2User}
              disabled={data.email === "" || isValid || isloading}
            >
              {isloading ? "Sending.." : "Send"}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EmailInvoices;
