const getEngineDetails = (engineTsnDetails, engineSerialNoDetails) => {
  let enginesArray = [];
  engineTsnDetails?.forEach((engine, index) => {
    enginesArray.push({
      engineSerialNumber: engineSerialNoDetails?.find(
        (engineData) => engineData?.engineNumber === `Engine-${index + 1}`
      )?.engineSerialNo,
      engineTsnHours: parseFloat(parseFloat(engine?.engineTsn).toFixed(2)),
      enginePosition: index + 1,
    });
  });
  return enginesArray;
};

export const contractPayload = (
  buyerMail,
  additionalDetails,
  contractNumber,
  tailNumber,
  engineTsnDetails,
  platformTsn,
  apuTsn,
  aircraftMake,
  file,
  base64File,
  aircraftModel,
  alternateEmail,
  buyerName,
  buyerCompany,
  phone,
  engineSerialNoDetails,
  platformSerialNumber,
  apuSerialNumber
) => {
  return JSON.stringify({
    primaryEmailAddress: buyerMail,
    additionalDetails: additionalDetails,
    contractNumber: contractNumber,
    aircraftTailNumber: tailNumber,
    engines: getEngineDetails(engineTsnDetails, engineSerialNoDetails),
    platformDetails: {
      platformSerialNumber: platformSerialNumber,
      platformTsnHours: parseFloat(parseFloat(platformTsn).toFixed(2)),
    },
    apuDetails: apuSerialNumber
      ? {
          apuSerialNumber: apuSerialNumber,
          tsnApuHours: parseFloat(parseFloat(apuTsn).toFixed(2)),
        }
      : {},
    aircraftMake: aircraftMake,
    attachments: file && [
      {
        attachmentFileName: file?.name,
        attachmentContentType: "application/pdf",
        attachmentBody: base64File,
      },
    ],
    aircraftModel: aircraftModel,
    alternateEmail: alternateEmail,
    buyerName: buyerName,
    buyerCompany: buyerCompany,
    phone: phone,
  });
};
