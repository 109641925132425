import React, { useState, useRef } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import logoIcon from "../../../assets/images/hon-logo.png";
import { useReactToPrint } from "react-to-print";
import "./ViewStatement.scss";
import ViewStatementPDF from "./ViewStatementPDF/ViewStatementPDF";
import { fetchInvoiceListSrv } from "../../../services/invoice.service";
import { Link } from "react-router-dom";
import Loader from "../../common/Loader/Loader";

const ViewStatement = (props) => {
  const [viewData, setViewData] = useState([]);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [viewmodal, setViewModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dateErr, setDateErr] = useState(false);
  const viewStatementComponent = useRef();

  const fromHandler = (e) => {
    setFrom(e.target.value);
  };

  const toHandler = (e) => {
    setTo(e.target.value);
  };

  const handleClose = () => {
    setFrom("");
    setTo("");
    setDateErr(false);
    setViewData([]);
    setViewModal(false);
    props.onClose();
  };

  let temp = 0;
  let total = 0;
  let tempBal = 0;
  let totalBal = 0;
  // let currentDate = new Date();
  const startDate = new Date(from);
  const endDate = new Date(to);
  let payloadData;
  const dropDownData =
    props.dropDownValue === "contract_number"
      ? { contract_number: props.dropDownInputData }
      : { invoice_number: props.dropDownInputData };
  if (props.dropDownInputData && props.soldToData.length > 0) {
    payloadData = {
      any: [
        {
          credit_period: {
            from: startDate,
            to: endDate,
          },
        },
        {
          invoice_period_date: {
            from: startDate,
            to: endDate,
          },
        },
      ],
      all: [dropDownData, { sold_to_number: props.soldToData }],
    };
  } else if (props.dropDownInputData && props.soldToData.length <= 0) {
    payloadData = {
      any: [
        {
          credit_period: {
            from: startDate,
            to: endDate,
          },
        },
        {
          invoice_period_date: {
            from: startDate,
            to: endDate,
          },
        },
      ],
      all: [dropDownData],
    };
  } else if (props.soldToData.length > 0 && !props.dropDownInputData) {
    payloadData = {
      any: [
        {
          credit_period: {
            from: startDate,
            to: endDate,
          },
        },
        {
          invoice_period_date: {
            from: startDate,
            to: endDate,
          },
        },
      ],
      all: [{ sold_to_number: props.soldToData }],
    };
  } else {
    payloadData = {
      any: [
        {
          credit_period: {
            from: startDate,
            to: endDate,
          },
        },
        {
          invoice_period_date: {
            from: startDate,
            to: endDate,
          },
        },
      ],
    };
  }
  const bodyContent = {
    query: "",
    filters: payloadData,
    sort: {
      invoice_period_date: "desc",
    },
  };

  const loadingComponent = () => <Loader />;

  const getViewStatement = async () => {
    setLoading(true);
    const payload = JSON.stringify(bodyContent);
    await fetchInvoiceListSrv(payload).then((res) => {
      if (res?.status === 200) {
        setViewData(res?.data?.data?.results);
        setLoading(false);
      } else {
        setViewData([]);
        setLoading(false);
      }
    });
  };

  const handleSubmit = () => {
    if (from && to) {
      getViewStatement();
      setViewModal(true);
      setDateErr(false);
    } else {
      setDateErr(true);
    }
  };

  const disableFutureDates = () => {
    const todayDate = new Date();
    const date =
      todayDate.getDate() < 10
        ? `0${todayDate.getDate()}`
        : todayDate.getDate();
    const month =
      todayDate.getMonth() + 1 < 10
        ? `0${todayDate.getMonth() + 1}`
        : todayDate.getMonth() + 1;
    const year = todayDate.getFullYear();
    const maxDate = year + "-" + month + "-" + date;

    return maxDate;
  };
  const disablePastDates = () => {
    const tDate = new Date();
    const dd = tDate.getDate() < 10 ? `0${tDate.getDate()}` : tDate.getDate();
    const mm =
      tDate.getMonth() + 1 < 10
        ? `0${tDate.getMonth() + 1}`
        : tDate.getMonth() + 1;
    const yyyy = tDate.getFullYear() - 2;
    const minDate = yyyy + "-" + mm + "-" + dd;
    return minDate;
  };

  const printDataHandler = () => {
    printPDF();
  };

  const printPDF = () => {
    const pdfContentPage1 = window.document.getElementById(
      "rc-resultsPdf-page11"
    );
    pdfContentPage1.style.display = "block";
    pdfContentPage1.style.margin = "20px";
    if (pdfContentPage1) {
      const pdfSec1 = window.document
        .getElementsByClassName("viewstate-data")[0]
        .cloneNode(true);
      const pdfSec2 = window.document
        .getElementsByClassName("total")[0]
        .cloneNode(true);
      const pdfSec3 = window.document
        .getElementsByClassName("viewstate-hdr")[0]
        .cloneNode(true);
      if (pdfSec1 && pdfSec2 && pdfSec3) {
        pdfContentPage1?.appendChild(pdfSec3);
        pdfContentPage1?.appendChild(pdfSec1);
        pdfSec1.getElementsByClassName("total")[0].style.display = "none";
        pdfContentPage1?.appendChild(pdfSec2);

        handlePrint();

        pdfContentPage1?.removeChild(pdfSec1);
        pdfContentPage1?.removeChild(pdfSec2);
        pdfContentPage1?.removeChild(pdfSec3);
      }
    }
    if (pdfContentPage1) {
      pdfContentPage1.style.display = "none";
      pdfContentPage1.style.marginTop = "0px";
    }
  };

  const handlePrint = useReactToPrint({
    content: () => viewStatementComponent.current,
  });

  return (
    <>
      <Modal size="xl" aria-labelledby="choose contract" show={props.show}>
        <Modal.Header closeButton className="modal-h" onHide={handleClose}>
          <Modal.Title className="title-modal"></Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="viewstate-mn">
            <div className="viewstate-hdr">
              <div className="hw-pdf-logo">
                <Link to="#">
                  <img src={logoIcon} alt="hon-logo" />
                </Link>
                <span>
                  Honeywell <br /> Phoenix, AZ 85034
                </span>
              </div>
              <div>
                <h2>Invoice History</h2>
              </div>
              <div>
                <span>Honeywell Phoenix,AZ </span>
                <br />
                <span>Phone: (602)365-6442</span>
                <br />
                <span>Fax: (602)822-7502</span>
                <br />
                <span>FederalTax ID# 22-2640650</span>

                <br />
                <span className="email">Email: mspcontracts@honeywell.com</span>
              </div>
            </div>
            <h6>
              Please select the dates to see the results (Max search range is 24
              months)
            </h6>
            <div className="date-filter">
              <div>
                <label htmlFor="from">From Date</label>
                <input
                  min={disablePastDates()}
                  max={disableFutureDates()}
                  id="from"
                  type="date"
                  className="form-control"
                  placeholder="from"
                  onChange={fromHandler}
                  value={from}
                />
              </div>
              <div>
                <label htmlFor="to">To Date</label>
                <input
                  min={disablePastDates()}
                  max={disableFutureDates()}
                  id="to"
                  type="date"
                  className="form-control"
                  placeholder="to"
                  onChange={toHandler}
                  value={to}
                />
              </div>
              <div className="search-btn">
                <Button onClick={handleSubmit} className="btn btn-blue">
                  Search
                </Button>
              </div>
              {viewData.length > 0 ? (
                <div>
                  <button className="btn btn-white" onClick={printDataHandler}>
                    PRINT
                  </button>
                </div>
              ) : null}
            </div>
            <div className={`${dateErr ? "" : "d-none"} err`}>
              <p className="err">Please select the dates</p>
            </div>
            {loading ? loadingComponent() : null}
            {dateErr ? (
              ""
            ) : (
              <>
                <div className="viewstate-data">
                  {loading ? (
                    ""
                  ) : viewmodal ? (
                    <>
                      <Table>
                        <thead>
                          <tr>
                            <th>Invoice No.</th>
                            <th>Contract No.</th>
                            <th>Legacy Contract No.</th>
                            <th>Period</th>
                            <th>Type</th>
                            <th>Closed Date</th>
                            <th>Account</th>
                            <th>Status</th>
                            <th>Total Invoice</th>
                            <th>Invoice Balance</th>
                          </tr>
                        </thead>
                        {viewData.map((item, ind) => {
                          if (item.isCreditFlag === "Y") {
                            temp =
                              temp -
                              parseFloat(item.creditAmount.replace(",", ""));
                          } else {
                            temp =
                              temp +
                              parseFloat(item.invoiceAmount.replace(",", ""));
                          }

                          total = temp.toFixed(2);

                          if (item.isCreditFlag === "Y") {
                            tempBal =
                              tempBal -
                              parseFloat(item.invoiceBalance.replace(",", ""));
                          } else {
                            tempBal =
                              tempBal +
                              parseFloat(item.invoiceBalance.replace(",", ""));
                          }

                          totalBal = tempBal.toFixed(2);

                          return (
                            <tbody key={ind}>
                              <tr>
                                <td>{item?.invoiceNumber}</td>
                                <td>{item?.contractNumber}</td>
                                <td>{item?.legacyInvoiceNumber}</td>
                                <td>{item?.invoicePeriod}</td>
                                <td>{item?.invoiceType}</td>
                                <td>{item?.invoiceClosedDate}</td>
                                <td>{item?.accountName}</td>
                                <td>{item?.status}</td>
                                <td>
                                  {item?.isCreditFlag === "Y" ? (
                                    <span>
                                      {item?.creditAmount !== "0.00" && (
                                        <strong>-</strong>
                                      )}
                                      {item?.creditAmount} USD
                                    </span>
                                  ) : (
                                    <span>{item?.invoiceAmount} USD</span>
                                  )}
                                </td>
                                <td>
                                  {item?.isCreditFlag === "Y" &&
                                    item?.invoiceBalance !== "0.00" && (
                                      <strong>-</strong>
                                    )}
                                  {item?.invoiceBalance}
                                </td>
                              </tr>
                            </tbody>
                          );
                        })}
                        <tfoot className="total">
                          <tr>
                            <td colSpan={7}></td>
                            <td>
                              <strong>Total:</strong>
                            </td>
                            <td>
                              <strong>{total}</strong>
                            </td>
                            <td>
                              <strong>{totalBal}</strong>
                            </td>
                          </tr>
                        </tfoot>
                      </Table>
                    </>
                  ) : null}
                </div>
                <ViewStatementPDF ref={viewStatementComponent} />
              </>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewStatement;
