import React, { useContext, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import "./PaymentConfimation.scss";
import PaymentPdf from "./PaymentPdf/PaymentPdf";
import { useReactToPrint } from "react-to-print";
import verisign from "../../../../assets/images/verisign-logo.gif";
import UserContext from "../../UserContext/UserContext";
import MultiInvoiceContext from "../../UserContext/MultiInvoiceContext";

import InfoIcon from "../../../../assets/images/info-icon.png";

const PaymentConfirmation = ({ show, onClose, paymentResponse, req }) => {
  const componentRef = useRef();
  const today = new Date().toLocaleDateString();
  const invoiceData = useContext(UserContext);
  const multiple = useContext(MultiInvoiceContext);
  const dmr = req && req?.map((item) => item?.dmrNumber);

  const arrayInvoice = [];
  dmr.forEach((item) => {
    const invoice = invoiceData?.invoicesData?.filter(
      (data) => item === data.invoiceNumber
    );
    arrayInvoice.push(invoice[0]);
  });

  const onClickPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const handleClick = () => {
    const pdfContentPage1 = window.document.getElementById(
      "rc-resultsPdf-page1"
    );
    pdfContentPage1.style.display = "block";
    pdfContentPage1.style.margin = "30px";
    onClickPrint(); //call print
    pdfContentPage1.style.display = "none";
    pdfContentPage1.style.marginTop = "30px";
  };
  useEffect(() => {
    if (show === true) {
      printPDF();
    }
  }, [show]);

  //for cloning html
  const printPDF = () => {
    const pdfContentPage1 = window.document.getElementById(
      "rc-resultsPdf-page1"
    );
    if (
      pdfContentPage1 &&
      pdfContentPage1.getElementsByClassName("card-details row").length === 0
    ) {
      const pdfSec1 = window.document
        .getElementsByClassName("title-modal")[0]
        .cloneNode(true);

      const pdfSec2 = window.document
        .getElementsByClassName("payment-desc")[0]
        .cloneNode(true);
      const pdfSec3 = window.document
        .getElementsByClassName("card-details row")[0]
        .cloneNode(true);
      const pdfSec4 = window.document
        .getElementsByClassName("payconfirm-cnt")[0]
        .cloneNode(true);

      if (pdfSec1 && pdfSec2 && pdfSec3 && pdfSec4) {
        pdfContentPage1?.appendChild(pdfSec1);
        pdfContentPage1?.appendChild(pdfSec2);
        pdfContentPage1?.appendChild(pdfSec3);
        pdfContentPage1?.appendChild(pdfSec4);
      }
    }
    if (pdfContentPage1) {
      pdfContentPage1.style.display = "none";
      pdfContentPage1.style.marginTop = "0px";
    }
  };

  return (
    <div>
      <Modal
        show={show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        aria-hidden="true"
        onHide={() => {
          onClose();
          invoiceData.setDisplayCheckbox(false);
          invoiceData.setMultipleInvoicesData([]);
          window.location.reload();
        }}
        backdrop="static"
      >
        <Modal.Header closeButton className="modal-h">
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="title-modal"
          >
            <h2>Payment confirmation</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="payment-desc">
            <p>
              Please note that the due amount cannot exceed $100,000.00. If your
              invoices total is more than the maximum limit, please group them
              into smaller amounts.
            </p>
          </div>
          <div className="card-details row">
            <div className="col-9 row">
              {req &&
                req?.map((item) => (
                  <ul>
                    <>
                      <li>
                        <strong>{`Card Number : `}</strong>
                        {`xxxx - xxxx - xxxx - ${
                          item.cardNumber.split("-")[2]
                        }`}
                      </li>
                      <li>
                        <strong>{`Card Expiry : `}</strong>
                        {` ${item.validTo.split("/")[0]}/${
                          item.validTo.split("/")[2]
                        }`}
                      </li>

                      <li>
                        <strong>{`Amount Billed : `}</strong>
                        {`$${item.amount}`}
                      </li>
                    </>
                  </ul>
                ))}
              <div className="pay-date">
                <strong>{`Date : `}</strong> {today}
              </div>
            </div>

            <div className="col-3">
              <img src={verisign} alt="verisign" />
            </div>
          </div>
          {
            <div className="payconfirm-cnt">
              <div class="tbl-hdr">
                <div>Invoice No.</div>
                <div>Contract Type</div>
                <div>Contract No./Legacy No.</div>
                <div>Account</div>
                <div>TRANSACTION NO.</div>
                <div>Invoice Amount</div>
                <div>status</div>
              </div>

              <div className="tbl-cnt pay-list">
                {invoiceData.multipleInvoicesData.length > 1
                  ? multiple.invoiceDetailsData.invoiceDetails?.map(
                      (item, index) => (
                        <div className="tbl-row" key={index}>
                          <div>{item?.dmrNumber}</div>
                          <div>
                            {invoiceData?.multipleInvoicesData?.find(
                              (contract) =>
                                contract?.invoiceNo === item?.dmrNumber
                            )?.contractType || "-"}
                          </div>
                          <div>{`${
                            item?.contractNumber ? item?.contractNumber : "-"
                          }${
                            item?.referenceNumber
                              ? `/${item?.referenceNumber}`
                              : " "
                          }`}</div>
                          <div>
                            {item?.accountName ? item?.accountName : "-"}
                          </div>
                          <div>
                            {paymentResponse?.paymentStatus?.find(
                              (details) => details.dmrNumber === item?.dmrNumber
                            )?.paymentTransactionId === undefined
                              ? "-"
                              : paymentResponse?.paymentStatus?.find(
                                  (details) =>
                                    details.dmrNumber === item?.dmrNumber
                                )?.paymentTransactionId}
                          </div>
                          <div>${item?.dueAmount}</div>
                          <div>
                            {paymentResponse?.status?.find(
                              (trans) =>
                                parseInt(trans?.invoiceNumber) ===
                                item?.dmrNumber
                            )?.status === "E" ? (
                              <>
                                <span className="red">Declined</span>
                                <span className="info-icon">
                                  <img src={InfoIcon} alt="contract-data" />
                                  <span className="tool-tip">
                                    <span>
                                      {
                                        paymentResponse?.status?.find(
                                          (details) =>
                                            parseInt(details?.invoiceNumber) ===
                                            item?.dmrNumber
                                        )?.message
                                      }
                                    </span>
                                  </span>
                                </span>
                              </>
                            ) : (
                              <span className="green">Success</span>
                            )}
                          </div>
                        </div>
                      )
                    )
                  : invoiceData?.invoicesData
                      ?.filter((data) => data.invoiceNumber === dmr[0])
                      .map((item, index) => (
                        <div className="tbl-row" key={index}>
                          <div>{item?.invoiceNumber}</div>
                          <div>{item?.programCode}</div>
                          <div>{`${item?.contractNumber}/${item?.referenceNumber}`}</div>
                          <div>{item?.accountName}</div>
                          <div>
                            {paymentResponse?.paymentStatus?.find(
                              (paymentInvoice) =>
                                parseInt(paymentInvoice?.dmrNumber) ===
                                item?.invoiceNumber
                            )?.paymentTransactionId === undefined
                              ? "-"
                              : paymentResponse?.paymentStatus?.find(
                                  (paymentInvoice) =>
                                    parseInt(paymentInvoice?.dmrNumber) ===
                                    item?.invoiceNumber
                                )?.paymentTransactionId}
                          </div>
                          <div>${item.invoiceBalance}</div>
                          <div>
                            <span>
                              {paymentResponse?.status?.find(
                                (invoice) =>
                                  parseInt(invoice?.invoiceNumber) ===
                                  item?.invoiceNumber
                              )?.status === "E" ? (
                                <>
                                  <span className="red">Declined</span>
                                  <span className="info-icon">
                                    <img src={InfoIcon} alt="contract-data" />
                                    <span className="tool-tip">
                                      <span>
                                        {paymentResponse?.status?.find(invoice=>parseInt(invoice?.invoiceNumber) === item?.invoiceNumber)?.message}
                                      </span>
                                    </span>
                                  </span>
                                </>
                              ) : (
                                <span className="green">Success</span>
                              )}
                            </span>
                          </div>
                        </div>
                      ))}
              </div>
            </div>
          }
        </Modal.Body>

        <Modal.Footer>
          <div className="btn-center">
            <Button
              variant="primary"
              className="btn btn-white"
              onClick={handleClick}
            >
              print
            </Button>
            <Button
              variant="primary"
              className="modal-Continue btn-blue"
              onClick={() => {
                onClose();
                invoiceData.setDisplayCheckbox(false);
                invoiceData.setMultipleInvoicesData([]);
                window.location.reload();
              }}
            >
              Close
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <PaymentPdf ref={componentRef} />
    </div>
  );
};

export default PaymentConfirmation;
