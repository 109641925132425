import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import "./ViewInvoices.scss";
import { Button, Modal } from "react-bootstrap";
import { FaListUl } from "react-icons/fa";
import InfoIcon from "../../../assets/images/info-icon.png";
import ReportUsageSearch from "../../common/ReportUsageSearch/ReportUsageSearch";
import DisplayInvoice from "../DisplayInvoice/DisplayInvoice.js";
import MultipleInvoices from "../MultipleInvoices/MultipleInvoices";
import UserContext from "../UserContext/UserContext";
import ViewStatement from "../ViewStatement/ViewStatement";
import { Helmet } from "react-helmet";
import icon from "../../../assets/images/icon.png";
import fileDownload from "js-file-download";
import DownloadIcon from "../../../assets/images/download.PNG";
import ViewIcon from "../../../assets/images/view.png";
import {
  FiChevronsLeft,
  FiChevronsRight,
  FiChevronLeft,
  FiChevronRight,
} from "react-icons/fi";
import AdminPopup from "../AdminPopup/AdminPopup";
import ContractPdfLink from "../ContractPdfLink/ContractPdfLink";
import {
  fetchInvoiceListSrv,
  invoiceDownloadSrv,
} from "../../../services/invoice.service";
import { Link } from "react-router-dom";
import MultipleInvoicesErrorPopup from "../MultipleInvoicesErrorPopup/MultipleInvoicesErrorPopup";
import MaxAmountPopup from "./MaxAmountPopup/MaxAmountPopup";
import { FeatureFlags } from "../../common/FeatureFlags/FeatureFlags";
import CreditInvoice from "../CreditInvoice/CreditInvoice";

const ViewInvoices = (props) => {
  const [changeButtonText, setChangeButtonText] = useState(false);
  const [invoicesData, setInvoicesData] = useState([]);
  const [isInvoiceDataLoading, setIsInvoiceDataLoading] = useState(true);
  const [tailList, setTailList] = useState([]);
  const [paginantionData, setPaginantionData] = useState([]);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { configData, isHoneywellEmployee, isLoadingToken, tokenValid } =
    useSelector((state) => state.config);
  const [searchActive, setSearchActive] = useState(false);
  const [showCreditInvoice, setShowCreditInvoice] = useState(false);
  const [searchField, setSearchField] = useState("");
  const [openPopUp, setOpenPopUp] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [openContractPdf, setOpenContractPdf] = useState(false);
  const [contractNumber, setContractNumber] = useState("");
  const [soldToNumber, setSoldToNumber] = useState("");
  const [isDownloadingInvoice, setIsDownloadingInvoice] = useState(false);
  const [multipleInvoicesData, setMultipleInvoicesData] = useState([]);
  const [displayCheckBox, setDisplayCheckbox] = useState(false);
  const [displayMultipleInvoice, setDisplayMultipleInvoice] = useState(false);
  const [creditInvoiceNumber, setCreditInvoiceNumber] = useState("");
  const [customerDropDownValue, setCustomerDropDownValue] =
    useState("invoice_number");
  const [dropDownSelectedValue, setDropDownSelectedValue] =
    useState("contract_number");
  const [selectedInvoicesCount, setSelectedInvoicesCount] = useState(0);
  const [showSelectInvoicesError, setShowSelectedInvoicesError] =
    useState(false);
  const [showMaxAmountPopup, setShowMaxAmountPopup] = useState(false);
  const [viewStatement, SetViewStatement] = useState(false);
  const [invoiceContractNumber, setInvoiceContractNumber] = useState("");
  const [filterOption, setFilterOption] = useState("");
  const [sortOption, setSortOption] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(100);
  const nPages = paginantionData?.total_pages;
  const reportUsageComponentRef = useRef();
  const [invoiceSortAsc, setInvoiceSortAsc] = useState(false);
  const [sortClicked, setSortClicked] = useState(false);

  const viewFilterSort = FeatureFlags?.find(
    (feature) => feature.name === "FilterSort"
  );

  const pageNumbers = [];
  let start = currentPage;
  let end = currentPage + 4;

  if (end > paginantionData?.total_pages) {
    start -= end - paginantionData?.total_pages;
    end = paginantionData?.total_pages;
  }
  if (start <= 0) {
    end += (start - 1) * -1;
    start = 1;
  }

  end = end > paginantionData?.total_pages ? paginantionData?.total_pages : end;

  for (let i = start; i <= end; i++) {
    pageNumbers.push(i);
  }

  const [dropDownInputTermData, setDropDownInputTermData] = useState("");
  const [soldToTermData, setSoldToTermData] = useState([]);
  const [adminSearchFlag, setAdminSearchFlag] = useState(false);
  const invoiceComponentRef = useRef(true);

  const submitDataHandler = (
    searchContract,
    searchSoldTo,
    dropDownSelectValue
  ) => {
    setDropDownInputTermData(searchContract);
    setSoldToTermData(searchSoldTo);
    setAdminSearchFlag(true);
    setDropDownSelectedValue(dropDownSelectValue);
    props.onSubmit(searchContract, searchSoldTo, dropDownSelectValue);
  };

  //executed on selection of multiple invoices
  const multipleInvoicesHandler = (
    multipleInvoicesObjectData,
    multipleInvoicesChecked
  ) => {
    const invoiceDataDetails = JSON.parse(multipleInvoicesObjectData);
    if (
      parseFloat(invoiceDataDetails?.invoiceTotal.replace(/,/g, "")) >= 100000
    ) {
      setShowMaxAmountPopup(true);
    } else {
      if (multipleInvoicesChecked) {
        const findInvoice = multipleInvoicesData?.find(
          (invoice) => invoice?.invoiceNo === invoiceDataDetails?.invoiceNo
        );
        if (!findInvoice) {
          setMultipleInvoicesData((prevValue) => [
            ...prevValue,
            invoiceDataDetails,
          ]);
          setSelectedInvoicesCount((prevCount) => prevCount + 1);
        }
      } else {
        setMultipleInvoicesData((prevValue) =>
          prevValue.filter(
            (multipleInvoiceFilter) =>
              multipleInvoiceFilter?.invoiceNo !== invoiceDataDetails?.invoiceNo
          )
        );
        setSelectedInvoicesCount((prevCount) => prevCount - 1);
      }
    }
  };
  const filterOptionHandler = (e) => {
    setFilterOption(e.target.value);
    invoiceComponentRef.current = true;
  };

  const sortOptionHandler = (e) => {
    setSortClicked(true);
    setSortOption(e.target.value);
    if (e.target.value === "ascending") {
      setInvoiceSortAsc(true);
    } else {
      setInvoiceSortAsc(false);
    }
  };

  //open modal for multiple tails
  const openTailDetails = (data) => {
    if (data.tailDetails === undefined || data.tailDetails === "") {
      setTailList([]);
    } else {
      setTailList(data.tailDetails);
    }
    handleShow();
  };

  const changeButtonTextHandler = () => {
    invoiceComponentRef.current = true;
    setChangeButtonText((prevValue) => !prevValue);
    setSortClicked((prev) => !prev);
    setSortOption((prev) => !prev);
    setCurrentPage(1);
    setDisplayCheckbox(false);
    setMultipleInvoicesData([]);
    setSearchField("");
    setFilterOption("");
    reportUsageComponentRef.current.changeContractValue();
  };

  // open popup for view details
  const viewDetailsHandlePopUp = (invoice,contract) => {
    setInvoiceNumber(invoice);
    setInvoiceContractNumber(contract);
    setOpenPopUp(true);
  };

  // open popup for view button
  const handlePopUp = (invoice,contract) => {
    setInvoiceNumber(invoice);
    setInvoiceContractNumber(contract);
    setOpenPopUp(true);
  };

  const closePopUp = () => {
    setOpenPopUp(false);
  };

  const closeViewStatement = () => {
    SetViewStatement(false);
  };

  let payloadSearchValue;

  //contract and invoice number value
  const dropDownData =
    dropDownSelectedValue === "contract_number"
      ? { contract_number: dropDownInputTermData }
      : { invoice_number: dropDownInputTermData };

  // invoices and credits filter
  const invoiceTypeFilter =
    filterOption &&
    (filterOption === "invoices"
      ? { is_credit_flag: "N" }
      : { is_credit_flag: "Y" });

  //payload admin and customer
  if (isHoneywellEmployee && adminSearchFlag) {
    if (dropDownInputTermData && soldToTermData.length > 0) {
      payloadSearchValue = [
        {
          status: !changeButtonText ? ["Open", "Pending"] : "Closed",
        },
        dropDownData,
        { sold_to_number: soldToTermData },
      ];
    } else if (dropDownInputTermData && soldToTermData.length <= 0) {
      payloadSearchValue = [
        {
          status: !changeButtonText ? ["Open", "Pending"] : "Closed",
        },
        dropDownData,
      ];
    } else if (soldToTermData.length > 0 && !dropDownInputTermData) {
      payloadSearchValue = [
        {
          status: !changeButtonText ? ["Open", "Pending"] : "Closed",
        },
        {
          sold_to_number: soldToTermData,
        },
      ];
    }
    invoiceTypeFilter && payloadSearchValue.push(invoiceTypeFilter);
  } else {
    payloadSearchValue = [
      {
        status: !changeButtonText ? ["Open", "Pending"] : "Closed",
      },
    ];
    invoiceTypeFilter && payloadSearchValue.push(invoiceTypeFilter);
  }

  //sorting for invoices
  const sortHandler = () => {
    //for closed invoices page
    if (changeButtonText) {
      if (sortClicked && invoiceSortAsc) {
        let ascendingSort = [
          { invoice_period_date: "asc" },
          { credit_period: "asc" },
        ];
        ascendingSort =
          filterOption === "credits"
            ? { credit_period: "asc" }
            : filterOption === "invoices"
            ? { invoice_period_date: "asc" }
            : [{ invoice_period_date: "asc" }, { credit_period: "asc" }];
        return ascendingSort;
      }
      let descendingSort = [
        { invoice_period_date: "desc" },
        { credit_period: "desc" },
      ];
      descendingSort =
        filterOption === "credits"
          ? { credit_period: "desc" }
          : filterOption === "invoices"
          ? { invoice_period_date: "desc" }
          : [{ invoice_period_date: "desc" }, { credit_period: "desc" }];
      return descendingSort;
    } else {
      //for open invoices page
      if (sortClicked && !invoiceSortAsc) {
        let descendingSort = [
          { invoice_period_date: "desc" },
          { credit_period: "desc" },
        ];
        descendingSort =
          filterOption === "credits"
            ? { credit_period: "desc" }
            : filterOption === "invoices"
            ? { invoice_period_date: "desc" }
            : [{ invoice_period_date: "desc" }, { credit_period: "desc" }];
        return descendingSort;
      }
      let ascendingSort = [
        { invoice_period_date: "asc" },
        { credit_period: "asc" },
      ];
      ascendingSort =
        filterOption === "credits"
          ? { credit_period: "asc" }
          : filterOption === "invoices"
          ? { invoice_period_date: "asc" }
          : [{ invoice_period_date: "asc" }, { credit_period: "asc" }];
      return ascendingSort;
    }
  };

  //payload data for admin and customer
  const invoiceBody = {
    query: "",
    filters: {
      all: payloadSearchValue,
    },
    sort: sortHandler(),
    page: {
      current: currentPage,
      size: recordsPerPage,
    },
  };

  //payload for customer search
  const bodyContent = {
    query: "",
    filters: {
      all: [
        {
          status: !changeButtonText ? ["Open", "Pending"] : "Closed",
        },
      ],
      any:
        customerDropDownValue === "invoice_number"
          ? [
              {
                [customerDropDownValue]: [searchField],
              },
              {
                debit_memo_number: [searchField],
              },
            ]
          : [
              {
                [customerDropDownValue]: [searchField],
              },
            ],
    },
    sort: sortHandler(),
  };

  //open and closed invoices api call
  const getInvoicesDetails = async () => {
    if (
      (!isHoneywellEmployee && adminSearchFlag === false) ||
      adminSearchFlag === true
    ) {
      setIsInvoiceDataLoading(true);
      const payload = searchField
        ? JSON.stringify(bodyContent)
        : JSON.stringify(invoiceBody);
      await fetchInvoiceListSrv(payload).then((res) => {
        if (res?.status === 200) {
          setInvoicesData(res?.data?.data?.results);
          setPaginantionData(res?.data?.data?.meta?.page);
        } else {
          setInvoicesData([]);
        }
      });
    }

    setIsInvoiceDataLoading(false);
  };

  let soldToLength = soldToTermData.length;

  useEffect(() => {
    if (
      invoiceComponentRef.current === true ||
      adminSearchFlag ||
      sortClicked
    ) {
      invoiceComponentRef.current = false;
      getInvoicesDetails();
    }
    /*eslint-disable */
  }, [
    searchField,
    dropDownInputTermData,
    sortClicked,
    soldToLength,
    changeButtonText,
    invoiceSortAsc,
    adminSearchFlag,
    currentPage,
    filterOption,
  ]);

  //function to be executed on click of submit
  const submitContractshandler = (serachItem) => {
    setSearchField(serachItem);
    invoiceComponentRef.current = true;
  };

  const setSearchHandler = (searchdata) => {
    setSearchActive(searchdata);
  };

  const gotoFirstPage = () => {
    setCurrentPage(1);
    invoiceComponentRef.current = true;
  };

  const gotoLastPage = () => {
    if (paginantionData.total_pages <= 100) {
      setCurrentPage(paginantionData.total_pages);
    } else {
      setCurrentPage(99);
    }
    invoiceComponentRef.current = true;
  };

  // function to be executed on change of dropdown value
  const customerDropDownValueHandler = (e) => {
    setCustomerDropDownValue(e.target.value);
    reportUsageComponentRef.current.changeContractValue();
  };

  // excecuted on click of contract number
  const openContractLinkPdfHandler = (contract, soldTo) => {
    setOpenContractPdf(true);
    setContractNumber(contract);
    setSoldToNumber(soldTo);
  };

  //executed on click of view credit invoice
  const creditInvoiceHandler = (invoiceNumber) => {
    setShowCreditInvoice(true);
    setCreditInvoiceNumber(invoiceNumber);
  };

  // excuted on click of close in contract details popup
  const closeContractPdfHandler = () => {
    setOpenContractPdf(false);
    setContractNumber("");
    setSoldToNumber("");
  };

  const nextPage = () => {
    if (currentPage !== nPages) {
      if (currentPage === 100) {
        setCurrentPage(100);
      } else {
        setCurrentPage(currentPage + 1);
      }
    }
    invoiceComponentRef.current = true;
  };

  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
    invoiceComponentRef.current = true;
  };

  //popup for tail list display
  const tailNumberPopup = (
    <React.Fragment>
      <Modal
        size="lg"
        aria-labelledby="choose contract"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton className="modal-h">
          <Modal.Title className="title-modal">
            <h2>Invoiced Tails</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="tail-row">
            <div>TAIL</div>
            <div>SERIAL NUMBER</div>
          </div>

          <div className="tail-cnt-row">
            {tailList.map((tailno) => (
              <div className="tail-num">
                <span>
                  {tailno?.aircraftTailNumber}
                  <span className="info-icon">
                    <img src={InfoIcon} alt="invoice-tool-tip" />{" "}
                    <span className="tool-tip list-data">
                      <span>
                        <strong>Plane Name :</strong>
                        {`${tailno?.platformDetails[0].platformName}`}
                      </span>
                      <span>
                        <strong>Serial Number :</strong>
                        {`${tailno?.platformDetails[0].platformSerialNo}`}
                      </span>
                    </span>
                  </span>
                </span>
                {tailno?.platformDetails.map((tailserialno) => (
                  <span>{tailserialno.platformSerialNo}</span>
                ))}
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </React.Fragment>
  );

  //payload for invoice download
  let query;
  if (dropDownInputTermData && soldToTermData.length > 0) {
    query = [
      `inputValue=${dropDownInputTermData}`,
      `soldTo=${soldToTermData}`,
      `dropDownValue=${dropDownSelectedValue}`,
      `status=${!changeButtonText ? ["Open", "Pending"] : "Closed"}`,
    ];
    query = query.join("&");
  } else if (dropDownInputTermData && soldToTermData.length <= 0) {
    query = [
      `inputValue=${dropDownInputTermData}`,
      `dropDownValue=${dropDownSelectedValue}`,
      `status=${!changeButtonText ? ["Open", "Pending"] : "Closed"}`,
    ];
    query = query.join("&");
  } else if (soldToTermData.length > 0 && !dropDownInputTermData) {
    query = [
      `soldTo=${soldToTermData}`,
      `dropDownValue=${dropDownSelectedValue}`,
      `status=${!changeButtonText ? ["Open", "Pending"] : "Closed"}`,
    ];
    query = query.join("&");
  } else {
    query = `status=${!changeButtonText ? ["Open", "Pending"] : "Closed"}`;
  }

  //download invoice api call
  const downloadInvoiceHandler = async () => {
    setIsDownloadingInvoice(true);
    await invoiceDownloadSrv(query)
      .then((res) => {
        if (res) {
          const headerFileName = res.headers["file-name"].split(";");
          const fileNameData = headerFileName[1];
          const fileNameExcel = fileNameData.split("=")[1];
          fileDownload(res?.data, fileNameExcel);
          setIsDownloadingInvoice(false);
        } else {
          setIsDownloadingInvoice(false);
        }
      })
      .catch(async (error) => {
        setIsDownloadingInvoice(false);
      });
  };

  useEffect(() => {
    if (selectedInvoicesCount >= 7) {
      setShowSelectedInvoicesError((prevValue) => !prevValue);
    }
  }, [selectedInvoicesCount]);

  return (
    <UserContext.Provider
      value={{
        invoicesData,
        multipleInvoicesData,
        setMultipleInvoicesData,
        setDisplayCheckbox,
      }}
    >
      <Helmet>
        <title>{configData?.MSP_VIEWINVOICES_METADATA?.TITLE}</title>
        <meta
          name="description"
          content={configData?.MSP_VIEWINVOICES_METADATA?.DESCRIPTION}
        />
        <meta
          property="og:title"
          content={configData?.MSP_VIEWINVOICES_METADATA?.TITLE}
        />
        <meta property="og:type" content="Website" />
        <link rel="icon" type="image/png" href={icon} sizes="16*16" />
        <link
          rel="canonical"
          href={configData?.MSP_VIEWINVOICES_METADATA?.CANONICAL_URL}
        />
      </Helmet>
      <div className="invoice-cnt">
        <h2 className="invoice-hdr">
          {changeButtonText === false ? "Open Invoices" : "Closed Invoices"}
          <span>
            <Button
              variant="secoundary"
              className="btn btn-open-close btn-white"
              onClick={changeButtonTextHandler}
              disabled={isInvoiceDataLoading ? true : false}
            >
              {changeButtonText === false
                ? "Show Closed Invoices"
                : "Show Open Invoices"}
            </Button>
          </span>
          <a
            className={`${
              isDownloadingInvoice ? "download-usage-hidden" : "download-usage"
            }`}
            onClick={downloadInvoiceHandler}
            disabled={isDownloadingInvoice ? true : false}
          >
            {" "}
            <img src={DownloadIcon} alt="download" />
            Invoice Download{" "}
            <span>
              <span
                className={`${
                  isDownloadingInvoice ? "loader" : "loader-hidden"
                }`}
              ></span>
            </span>
          </a>
          <a
            className="view-state download-usage"
            variant="primary"
            onClick={() => SetViewStatement(true)}
            disabled={isInvoiceDataLoading ? true : false}
          >
            <img src={ViewIcon} alt="download" />
            View Statement
          </a>
        </h2>

        <div className="invoice-filter"></div>

        <div className="row top-btns">
          <div className="col-3">
            {!isHoneywellEmployee && (
              <select
                value={customerDropDownValue}
                onChange={customerDropDownValueHandler}
                className="dropdown"
                title="Choose invoice or contract number"
              >
                <option value="invoice_number">Invoice Number </option>
                <option value="contract_number">Contract Number </option>
              </select>
            )}
          </div>
          <div className="col-3">
            {!isHoneywellEmployee && (
              <ReportUsageSearch
                placeholder={
                  customerDropDownValue === "contract_number"
                    ? "Enter contract number"
                    : "Enter invoice number"
                }
                onSubmitContracts={submitContractshandler}
                onSearch={setSearchHandler}
                ref={reportUsageComponentRef}
              />
            )}
          </div>
          <div className="col-6">
            {!isInvoiceDataLoading && !changeButtonText && (
              <div className="select-btns">
                {!displayCheckBox && invoicesData?.length > 0 && (
                  <Button
                    variant="primary"
                    className="btn btn-blue"
                    onClick={() => setDisplayCheckbox(true)}
                  >
                    Select multiple to pay
                  </Button>
                )}

                {displayCheckBox && invoicesData?.length > 0 && (
                  <Button
                    variant="outline-primary"
                    className="btn btn-white"
                    onClick={() => {
                      setDisplayCheckbox(false);
                      setMultipleInvoicesData([]);
                      setSelectedInvoicesCount(0);
                    }}
                  >
                    Cancel
                  </Button>
                )}
                {displayCheckBox && invoicesData?.length > 0 && (
                  <Button
                    variant="primary"
                    className="btn btn-blue"
                    onClick={() => {
                      setDisplayMultipleInvoice(true);
                      setSelectedInvoicesCount(0);
                    }}
                    disabled={
                      multipleInvoicesData.length === 0 ||
                      selectedInvoicesCount > 7
                    }
                  >
                    Continue
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="row paginate-cnt">
          <div className="col-6">
            {viewFilterSort?.active && (
              <div className="filter-sort">
                <div className="filter-container">
                  <select
                    name="filters"
                    className="dropdown"
                    value={filterOption}
                    aria-label="invoice filters"
                    onChange={filterOptionHandler}
                  >
                    <option value="" defaultChecked>
                      {!changeButtonText
                        ? "All Open Invoices"
                        : "All Closed Invoices"}
                    </option>
                    <option value="invoices">Invoices</option>
                    <option value="credits">Credits</option>
                  </select>
                </div>
                <div className="sort-container">
                  <select
                    name="sort"
                    className="dropdown"
                    aria-label="invoices sort"
                    value={sortOption}
                    onChange={sortOptionHandler}
                  >
                    <option value="" defaultChecked hidden>
                      Sort By
                    </option>
                    <option value="ascending">Oldest to Newest</option>
                    <option value="descending">Newest to Oldest</option>
                  </select>
                </div>
              </div>
            )}
          </div>
          <div className="col-4">
            <div>
              {!isInvoiceDataLoading &&
                invoicesData.length !== 0 &&
                paginantionData?.total_results >= 10 && (
                  <nav>
                    <ul className="pagination justify-content-center">
                      <li className="page-item">
                        <Link
                          className={
                            currentPage === 1
                              ? "page-link disabled"
                              : "page-link"
                          }
                          onClick={gotoFirstPage}
                          to="#"
                        >
                          <FiChevronLeft />
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link
                          className={
                            currentPage === 1
                              ? "page-link disabled"
                              : "page-link"
                          }
                          onClick={prevPage}
                          to="#"
                        >
                          <FiChevronsLeft />
                        </Link>
                      </li>
                      {pageNumbers?.map((pgNumber) => (
                        <li
                          key={pgNumber}
                          className={`page-item ${
                            currentPage === pgNumber ? "active" : ""
                          } `}
                        >
                          <Link
                            onClick={() => {
                              setCurrentPage(pgNumber <= 100 ? pgNumber : 100);
                              invoiceComponentRef.current = true;
                            }}
                            className="page-link"
                            to="#"
                          >
                            {pgNumber}
                          </Link>
                        </li>
                      ))}

                      <li className="page-item">
                        <Link
                          className={
                            currentPage === paginantionData?.total_pages
                              ? "page-link disabled"
                              : "page-link"
                          }
                          onClick={nextPage}
                          to="#"
                        >
                          <FiChevronsRight />
                        </Link>
                      </li>

                      <li className="page-item">
                        <Link
                          className={
                            currentPage === paginantionData?.total_pages
                              ? "page-link disabled"
                              : "page-link"
                          }
                          onClick={gotoLastPage}
                          to="#"
                        >
                          <FiChevronRight />
                        </Link>
                      </li>
                    </ul>
                  </nav>
                )}
            </div>
          </div>
          <div className="col-2">
            {!isInvoiceDataLoading && invoicesData?.length > 0 && (
              <span className="page-count">
                {`${currentPage}-${invoicesData?.length} of ${paginantionData?.total_results} Items`}
              </span>
            )}
          </div>
        </div>

        {!isInvoiceDataLoading &&
          invoicesData.map((invoiceDetails, index) => {
            const multipleInvoiceObject = JSON.stringify({
              invoiceNo: invoiceDetails?.invoiceNumber,
              contractType: invoiceDetails?.programCode,
              contractNo: invoiceDetails?.contractNumber,
              account: invoiceDetails?.accountName,
              invoiceTotal: invoiceDetails?.invoiceBalance,
            });
            return (
              <div className="invoice-info" key={index}>
                <div className="info-hdr">
                  {displayCheckBox && (
                    <div className="input-check">
                      <input
                        type="checkbox"
                        name={multipleInvoiceObject}
                        onChange={(e) => {
                          multipleInvoicesHandler(
                            e.target.name,
                            e.target.checked
                          );
                          setDisplayMultipleInvoice(false);
                        }}
                        checked={
                          (multipleInvoicesData.find(
                            (data) =>
                              data?.invoiceNo === invoiceDetails?.invoiceNumber
                          )
                            ? true
                            : false) ||
                          (showMaxAmountPopup && false)
                        }
                        disabled={
                          invoiceDetails?.isCreditFlag == "Y" ||
                          invoiceDetails?.invoiceBalance === "0.00" ||
                          (selectedInvoicesCount >= 7 &&
                            !multipleInvoicesData.find(
                              (data) =>
                                data?.invoiceNo ===
                                invoiceDetails?.invoiceNumber
                            ))
                        }
                      />
                    </div>
                  )}
                  <div className="contract-cnt">
                    <span className="contract-num">
                      {invoiceDetails?.invoiceNumber}
                    </span>
                    <span
                      className={`${
                        (invoiceDetails?.programCode === "MSP" &&
                          "tag-orange") ||
                        (invoiceDetails?.programCode === "FMP" && "tag-blue") ||
                        "tag-grey"
                      }`}
                    >
                      {invoiceDetails?.programCode}
                    </span>
                    <span className="single-tail">
                      {invoiceDetails?.tailDetails?.length === 1 ? (
                        invoiceDetails?.tailDetails[0].aircraftTailNumber &&
                        `Tail No. ${invoiceDetails?.tailDetails[0].aircraftTailNumber}`
                      ) : (
                        <span
                          className="multiple-tail"
                          onClick={() => openTailDetails(invoiceDetails)}
                        >
                          Multiple Tails <FaListUl className="mtail-icon" />
                        </span>
                      )}
                    </span>
                  </div>

                  <div className="invoice-amt">
                    {changeButtonText && (
                      <span className="close-value">
                        <span>
                          {invoiceDetails?.isCreditFlag === "Y" &&
                            invoiceDetails?.invoiceBalance !== "0.00" && (
                              <strong>-</strong>
                            )}
                          {invoiceDetails?.invoiceBalance} USD
                        </span>
                        <span>{invoiceDetails?.status}</span>
                      </span>
                    )}
                    {!changeButtonText && (
                      <span className="open-value">
                        {invoiceDetails?.status !== "Pending" ? (
                          <span>
                            {invoiceDetails?.isCreditFlag === "Y" &&
                              invoiceDetails?.invoiceBalance !== "0.00" && (
                                <strong>-</strong>
                              )}
                            {invoiceDetails?.invoiceBalance} USD
                          </span>
                        ) : (
                          <span>0.00 USD</span>
                        )}
                        <span>{invoiceDetails?.status}</span>
                      </span>
                    )}
                  </div>

                  <div className="btn-pay">
                    {changeButtonText &&
                      (invoiceDetails?.isCreditFlag === "Y" ? (
                        <Button
                          variant="primary"
                          className="btn-blue btn"
                          onClick={() =>
                            creditInvoiceHandler(invoiceDetails?.invoiceNumber)
                          }
                        >
                          View Credit Invoice
                        </Button>
                      ) : (
                        <Button
                          variant="primary"
                          className="btn-blue"
                          onClick={() =>
                            viewDetailsHandlePopUp(
                              invoiceDetails?.invoiceNumber,
                              invoiceDetails?.contractNumber
                            )
                          }
                        >
                          View Details
                        </Button>
                      ))}
                    {!changeButtonText &&
                      (invoiceDetails?.isCreditFlag === "Y" ? (
                        <Button
                          variant="primary"
                          className="btn-blue btn"
                          onClick={() =>
                            creditInvoiceHandler(invoiceDetails?.invoiceNumber)
                          }
                        >
                          View Credit Invoice
                        </Button>
                      ) : (
                        <Button
                          variant="primary"
                          className="btn-blue btn"
                          onClick={() =>
                            handlePopUp(
                              invoiceDetails?.invoiceNumber,
                              invoiceDetails?.contractNumber
                            )
                          }
                          disabled={displayCheckBox ? true : false}
                        >
                          {`${
                            invoiceDetails?.invoiceBalance === "0.00" ||
                            invoiceDetails?.status === "Pending"
                              ? "View"
                              : "View & Pay"
                          }`}
                        </Button>
                      ))}
                  </div>
                </div>

                <div className="info-ftr">
                  <div>
                    <span>Contract/SAP Invoice/Reference No.</span>
                    <span>
                      <Link
                        className="contarct-lable"
                        onClick={() =>
                          openContractLinkPdfHandler(
                            invoiceDetails?.contractNumber,
                            invoiceDetails?.soldToNumber
                          )
                        }
                        to="#"
                      >
                        {invoiceDetails?.contractNumber}
                      </Link>
                      {invoiceDetails?.debitMemoNumber &&
                        `/${invoiceDetails?.debitMemoNumber}`}
                    </span>
                  </div>
                  <div>
                    <span>Period</span>
                    {`${invoiceDetails?.invoicePeriod
                      .split(" ")[0]
                      .substr(0, 3)} ${
                      invoiceDetails?.invoicePeriod.split(" ")[1]
                    }`}
                  </div>
                  <div>
                    <span>Type</span>
                    <span>{invoiceDetails?.invoiceType}</span>
                  </div>
                  <div>
                    <span>Account</span>
                    <span>{invoiceDetails?.accountName}</span>
                  </div>

                  <div>
                    <span>Invoice Total</span>
                    {invoiceDetails?.isCreditFlag === "Y" ? (
                      <span>
                        {invoiceDetails?.creditAmount !== "0.00" && (
                          <strong>-</strong>
                        )}
                        {invoiceDetails?.creditAmount} USD
                      </span>
                    ) : (
                      <span>{invoiceDetails?.invoiceAmount} USD</span>
                    )}
                  </div>
                  {invoiceDetails?.status === "Closed" ? (
                    <div>
                      <span>Closed Date</span>
                      <span>{invoiceDetails?.invoiceClosedDate}</span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          })}
        {!isInvoiceDataLoading && (
          <div className="load-more">
            {invoicesData.length !== 0 &&
              paginantionData?.total_results >= 10 && (
                <nav>
                  <ul className="pagination justify-content-center">
                    <li className="page-item">
                      <Link
                        className={
                          currentPage === 1 ? "page-link disabled" : "page-link"
                        }
                        onClick={gotoFirstPage}
                        to="#"
                      >
                        <FiChevronLeft />
                      </Link>
                    </li>
                    <li className="page-item">
                      <Link
                        className={
                          currentPage === 1 ? "page-link disabled" : "page-link"
                        }
                        onClick={prevPage}
                        to="#"
                      >
                        <FiChevronsLeft />
                      </Link>
                    </li>
                    {pageNumbers?.map((pgNumber) => (
                      <li
                        key={pgNumber}
                        className={`page-item ${
                          currentPage === pgNumber ? "active" : ""
                        } `}
                      >
                        <Link
                          onClick={() => {
                            setCurrentPage(pgNumber <= 100 ? pgNumber : 100);
                            invoiceComponentRef.current = true;
                          }}
                          className="page-link"
                          to="#"
                        >
                          {pgNumber}
                        </Link>
                      </li>
                    ))}

                    <li className="page-item">
                      <Link
                        className={
                          currentPage === paginantionData?.total_pages
                            ? "page-link disabled"
                            : "page-link"
                        }
                        onClick={nextPage}
                        to="#"
                      >
                        <FiChevronsRight />
                      </Link>
                    </li>

                    <li className="page-item">
                      <Link
                        className={
                          currentPage === paginantionData?.total_pages
                            ? "page-link disabled"
                            : "page-link"
                        }
                        onClick={gotoLastPage}
                        to="#"
                      >
                        <FiChevronRight />
                      </Link>
                    </li>
                  </ul>
                </nav>
              )}
          </div>
        )}
        {!isInvoiceDataLoading && !changeButtonText && (
          <div className="select-btns">
            {!displayCheckBox && invoicesData?.length > 0 && (
              <Button
                variant="primary"
                className="btn btn-blue"
                onClick={() => setDisplayCheckbox(true)}
              >
                Select multiple to pay
              </Button>
            )}

            {displayCheckBox && invoicesData?.length > 0 && (
              <Button
                variant="outline-primary"
                className="btn btn-white"
                onClick={() => {
                  setDisplayCheckbox(false);
                  setMultipleInvoicesData([]);
                }}
              >
                Cancel
              </Button>
            )}
            {displayCheckBox && invoicesData?.length > 0 && (
              <Button
                variant="primary"
                className="btn btn-blue"
                onClick={() => {
                  setDisplayMultipleInvoice(true);
                  setSelectedInvoicesCount(0);
                }}
                disabled={
                  multipleInvoicesData.length === 0 || selectedInvoicesCount > 7
                }
              >
                Continue
              </Button>
            )}
          </div>
        )}
        {invoicesData?.length === 0 &&
          isInvoiceDataLoading === false &&
          searchActive === true && (
            <h2 className="no-data-invoices">
              {`Please check the ${
                customerDropDownValue === "contract_number"
                  ? "contract number"
                  : "invoice number"
              } and resubmit.`}
            </h2>
          )}
        {isInvoiceDataLoading && (
          <h2 className="no-data-invoices">
            {configData?.MSP_INFORMATION_TEXT?.LOADING_INVOICES}
            <div className="loader"></div>
          </h2>
        )}
        {invoicesData?.length <= 0 &&
          !isInvoiceDataLoading &&
          searchActive !== true && (
            <h3 className="no-data-invoices">
              {!changeButtonText
                ? configData?.MSP_INFORMATION_TEXT?.NO_DATA_INVOICES_OPEN
                : configData?.MSP_INFORMATION_TEXT?.NO_DATA_INVOICES_CLOSED}
            </h3>
          )}
        <DisplayInvoice
          data={invoiceNumber}
          contract={invoiceContractNumber}
          show={openPopUp}
          onClose={closePopUp}
          changeButtonText={changeButtonText}
        />

        {tokenValid && isLoadingToken === false && isHoneywellEmployee && (
          <AdminPopup onSubmitData={submitDataHandler} />
        )}

        {tailNumberPopup}

        {displayMultipleInvoice && multipleInvoicesData.length > 0 && (
          <MultipleInvoices
            onCloseInvoice={() => {
              setDisplayCheckbox(false);
              setMultipleInvoicesData([]);
            }}
            multipleInvoices={multipleInvoicesData}
          />
        )}
        {openContractPdf && (
          <ContractPdfLink
            constractnumberdata={contractNumber}
            soldToNummber={soldToNumber}
            show={openContractPdf}
            onHide={closeContractPdfHandler}
            backdrop="static"
          />
        )}
        <ViewStatement
          show={viewStatement}
          onClose={closeViewStatement}
          dropDownInputData={dropDownInputTermData}
          soldToData={soldToTermData}
          dropDownValue={dropDownSelectedValue}
        />
      </div>
      <MultipleInvoicesErrorPopup
        show={showSelectInvoicesError}
        onHide={() => setShowSelectedInvoicesError(false)}
        showMultipleInvoice={() => {
          setDisplayMultipleInvoice(true);
          setShowSelectedInvoicesError(false);
          setSelectedInvoicesCount(0);
        }}
      />
      {showCreditInvoice && (
        <CreditInvoice
          show={showCreditInvoice}
          onHide={() => setShowCreditInvoice(false)}
          creditInvoiceNumber={creditInvoiceNumber}
        />
      )}
      <MaxAmountPopup
        show={showMaxAmountPopup}
        onHide={() => setShowMaxAmountPopup(false)}
      />
    </UserContext.Provider>
  );
};

export default ViewInvoices;
