import { Modal } from "react-bootstrap";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { usageBaseAnayticsSrv } from "../../../services/invoice.service";
import Loader from "../../common/Loader/Loader";

const UsageBasedCreditPopup = (props) => {
  const { configData } = useSelector((state) => state.config);
  const [creditData, setCreditData] = useState({});
  const [loading, setLoading] = useState(false);
  const usageBasedCreditComponent = useRef(true);
  const [isErr, setIsErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const { usagecontract, usagesoldTo } = props;

  const loadingComponent = () => <Loader />;

  const creditBody = {
    contractsRequest: {
      soldToNumber: usagesoldTo.length > 0 ? usagesoldTo : [],
      operatorAccountNumber: ["0"],
      contractNumber: usagecontract ? [usagecontract] : [],
      webStatus: "A",
      legacyContractNumber: "",
      effectiveDateFrom: "",
      effectiveDateTo: "",
      expirationDateFrom: "",
      expirationDateTo: "",
      contractStatus: "",
      operatorAccount: "",
      contractType: "",
      page: {
        startingCount: 1,
        endingCount: 9999,
      },
      sort: {
        sortBy: "TAIL_NUMBER",
        sortOrder: "A",
      },
      platformDetails: {
        platformName: "",
        platformSerialNumber: "",
        platformDescription: "",
      },
      productDetails: {
        productSerialNumber: "",
        productDescription: "",
      },
      operatorName: "",
      programType: "",
      modelDescription: "",
      userType: "O",
      aircraftSerialFrom: "",
      flag: 5,
      requestFrom: "CO",
    },
  };

  const contractNumberUsageBased = async () => {
    setLoading(true);
    const payload = JSON.stringify(creditBody);
    await usageBaseAnayticsSrv(payload).then((res) => {
      if (res?.status === 200) {
        setCreditData(res?.data?.data);
        setLoading(false);
        setIsErr(false);
      } else {
        setErrMsg(res?.data?.errorMessage);
        setIsErr(true);
        setCreditData([]);
        setLoading(false);
      }
    });
  };

  useEffect(() => {
    if (usageBasedCreditComponent.current === true) {
      usageBasedCreditComponent.current = false;
      contractNumberUsageBased();
    }
    /*eslint-disable */
  }, []);

  return (
    <React.Fragment>
      <Modal {...props} size="lg" aria-labelledby="choose contract" centered>
        <Modal.Header closeButton className="modal-h">
          <Modal.Title className="title-modal">
            <h2>Contract With Usage Based Credit</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {isErr ? (
            <h4 className="d-flex justify-content-center align-content-center pt-5">
              {errMsg}
            </h4>
          ) : (
            <>
              <h6 className="contract-hdr">Contract</h6>
              {loading && loadingComponent()}
              <div className="contract-list-cnt">
                {creditData &&
                  Object.keys(creditData).map((data, index) => {
                    return (
                      <div className="contract-num" key={index}>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={
                            configData?.MSP_TOKEN?.USAGE_BASED_ANALYTICS +
                            creditData[data]
                          }
                        >
                          {data}
                        </a>
                      </div>
                    );
                  })}
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default UsageBasedCreditPopup;
