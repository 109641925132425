import "./App.scss";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAsyncData } from "./storage/reducers/headerFooterDataReducer";
import {
  fetchAsyncConfig,
  fetchAsyncToken,
  fetchAsyncTokenValidation,
} from "./storage/reducers/configReducer";
import { Helmet } from "react-helmet";
import icon from "./assets/images/icon.png";
import Layout from "./components/pages/Layout";
import Loader from "./components/common/Loader/Loader";
// import C from "./constants/constant";
import ErrorBoundary from "./components/common/ErrorBoundary/ErrorBoundary";

function App() {
  const dispatch = useDispatch();
  const { configData, status, tokenAPIStatus, token } = useSelector(
    (state) => state.config
  );
  const storeData = useSelector((state) => state.headerFooterData);
  
  //To check config is present
  if (status == null) {
    dispatch(fetchAsyncConfig());
  }
  //To check header and footer details present
  if (storeData?.status === "") {
    dispatch(fetchAsyncData());
  }
  //To make a default call to get the token from backend.
  //If the AEM team change the token config we would able to take from backend
  useEffect(() => {
    dispatch(fetchAsyncToken());
    /*eslint-disable */
  }, []);
  //To check the config and token information are correct an validate the token

  //api call to fetch token status
  useEffect(() => {
    setTimeout(()=>{
      if(status === "success" && tokenAPIStatus === "success"){
        if(configData?.MSP_TOKEN?.TOKEN){
          const accessToken = token?.cookies[configData?.MSP_TOKEN?.TOKEN] ;
            if(accessToken){
                dispatch(fetchAsyncTokenValidation(accessToken)); 
            }
        }   
    }
    },2000)

    /*eslint-disable */
  }, [status, tokenAPIStatus, token]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{configData?.MSP_REPORTUSAGE_METADATA?.TITLE}</title>
        <meta
          name="description"
          content={configData?.MSP_REPORTUSAGE_METADATA?.DESCRIPTION}
        />
        <meta
          property="og:title"
          content={configData?.MSP_REPORTUSAGE_METADATA?.TITLE}
        />
        <meta property="og:type" content="Website" />
        <link rel="icon" type="image/png" href={icon} sizes="16*16" />
        <link
          rel="canonical"
          href={configData?.MSP_REPORTUSAGE_METADATA?.CANONICAL_URL}
        />
      </Helmet>
      {storeData?.status === "loading" ? (
        <Loader />
      ) : (
        <ErrorBoundary>
          <Layout />
        </ErrorBoundary>
      )}
    </React.Fragment>
  );
}
export default App;