import React, { useState } from "react";
import { useDispatch } from "react-redux";
import ModalPopup from "../../common/ModalPopup/ModalPopup";
import { welcomeReducer } from "../../../storage/reducers/welcomePopReducer";
const Welcome = ({ onLoad, setOnLoad }) => {
  const dispatch = useDispatch();
  const [displayPopup, setDisplayPopup] = useState("true");
  const closePopupHandler = () => {
    setDisplayPopup("false");
    setOnLoad(true);
    dispatch(welcomeReducer(true));
  };

  const headerContent = (
    <React.Fragment>
      <h2>Important Messages</h2>
    </React.Fragment>
  );
  const bodyContent = (
    <React.Fragment>
      <div className="notes-cnt">
        <div class="note-info">
          <span class="err-icon">i</span>
          <div>
            <h3>
              PLEASE READ: Change to Hours Reporting
            </h3>
            <p>
              <strong>Pre-Loaded Hours:</strong> Hours data from WingX has been pre-loaded into your monthly usage
              reporting form and may be used as-is or edited before submission.
            </p>
            <p>
              <strong>Checkbox per Contract:</strong> You are now required to select validation checkbox for each contract that you want to submit hours for.
              Only contracts with this checkbox selected will be submitted for invoicing when you continue. 
              </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <ModalPopup
      showPopup={displayPopup === "true" ? true : false}
      size="lg"
      arialabel="welcome popup"
      centered="true"
      header={headerContent}
      body={bodyContent}
      cancelPopup={closePopupHandler}
    />
  );
};

export default Welcome;