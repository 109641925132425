import React,{useState} from "react";
import "./GlobalHeader.scss";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import "bootstrap/dist/css/bootstrap.css";
import Config from '../../../services/config'
import { BRANDING_JS_PATHS } from "../../../services/service"

const GlobalHeader = (props) => {
  const data = useSelector((state) => state.headerFooterData);
  const { configData } = useSelector(
    (state) => state.config
  );
  const [header, setHeader] = useState("");

  // loading header css and js files and creating corresponding link tags
  useEffect(() => {
    if (data) {
      //Setting CSS using Map Function Ends
        //your code to be executed after 2 second
        data?.css?.map((cssUrlValue) => {
          const cssFile = document.createElement("link");
          cssFile.href = cssUrlValue;
          cssFile.rel = "stylesheet";
          return document.head.appendChild(cssFile);
        });

        setTimeout(function () {
          //your code to be executed after 5 second
          setHeader(data.header);
        }, 800);


        //Setting JS using Map Function Ends
        // data.jsFile?.map((jsUrlValue) => {
        //   const scriptFile = document.createElement("script");
        //   scriptFile.src = jsUrlValue;
        //   return document.body.appendChild(scriptFile);
        // });

        setTimeout(() => {
          if (!document.getElementById("headerFooterJs")) {
            const scriptFile = document.createElement("script");
            scriptFile.id = "headerFooterJs";
            const appId = configData?.MSP_TOKEN?.MSP_DOMAIN_NAME?Config?.resources[configData?.MSP_TOKEN?.MSP_DOMAIN_NAME]?.appId:226;
            console.log(appId,'app')
           scriptFile.src = BRANDING_JS_PATHS(appId);
           document.body.appendChild(scriptFile);
          }  
        
       }, 1200);
      
    }
    /*eslint-disable */
  }, [data]);
  return (
    <div id="myAeroNavMnuDvNew">
      <div dangerouslySetInnerHTML={{ __html: header }} />
    </div>
  );
};

export default GlobalHeader;