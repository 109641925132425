import React, { useState } from "react";
import "./TransferContractConfirmationPopup.scss";
import ContractsImage from "../../../../assets/images/contracts.png";
import CreditsImage from "../../../../assets/images/credits.png";

import { Button } from "react-bootstrap";
import ContractsProgressBar from "../ContractsProgressBar/ContractsProgressBar";
import ModalPopup from "../../../common/ModalPopup/ModalPopup";

const TransferContractConfirmationPopup = (props) => {
  const [showTransferStatus, setShowTransferStatus] = useState(false);
  const transferStatusHandler = () => {
    setShowTransferStatus(true);
  };
  const closeStatusHandler = () => {
    setShowTransferStatus(false);
    props.onHide();
  };

  //reload the page
  const reloadPage = (e) =>{
    e.preventDefault();
    window.location.reload();
  }

  const headerContentPopup = (
    <>
      {
        <>
          <div className="transfercontract-img">
            <img
              src={CreditsImage}
              alt="creditsImage"
              className="contract-img"
            />
          </div>
          <div className="transfercontract-title">
            <div className="transfercontract-submit">
              <h2>Your Transfer Request Has Been Initiated</h2>
            </div>
            <div className="transfercnt-texttitle">
              <p>What happens now?</p>
            </div>
            <div className="transfercnt-text">
              <p>
                Fortunately, you're role in transfer is now complete. You will
                receive a final invoice that - once paid in full - will
                effectively end your commitment to this maintenance service
                plan.
              </p>
            </div>
            <div className="transfercnt-textemail">
              <p>
                A confirmation email has been sent to <span className="email">{props?.buyerMail}</span>, and
                you can track the status of the <a href={window.location.href} onClick={reloadPage}>transfer here</a>.
                The transfer
                process can take weeks or even months, so be sure to keep an eye
                out for updates.`
              </p>
            </div>
          </div>
        </>
      }
    </>
  );

  const bodyContentPopup = (
    <>
      <div className="transfercntusg-cnt">
        <div className="transfercnt-usage">
          <div className="transfercnt-img">
            <img src={ContractsImage} alt="contracts" />
          </div>
          <div className="transfercnt-content">
            <span>Report usage hours during transfer</span>
            <span>
              Make sure your are continuing to report hours while the transfer
              is underway.
            </span>
            <span>You will be notified once the transfer is complete.</span>
          </div>
        </div>
        <div className="transfercnt-number">
          <span>Transfer Number</span>
          <span>{props?.caseNumber}</span>
        </div>
        <div className="transfer-btn">
          <Button className="btn btn-blue" onClick={transferStatusHandler}>
            View Transfer Details
          </Button>
        </div>
      </div>
    </>
  );

  const footerContentPopup = (
    <>
      <Button className="btn btn-blue" onClick={props.onHide}>
        Close
      </Button>
    </>
  );
  return (
    <React.Fragment>
      <ModalPopup
        showPopup={props.show}
        size=""
        arialabel="transfer contract confirmation"
        cancelPopup={props.onHide}
        contentClassName={
          showTransferStatus
            ? "transfercontract-display"
            : "transfercontract-cnt"
        }
        backdropClassName="transfercnt-backdrop"
        header={headerContentPopup}
        body={bodyContentPopup}
        footer={footerContentPopup}
      />
      {showTransferStatus && (
        <ContractsProgressBar
          show={showTransferStatus}
          subStatus={props.subStatus}
          onHide={closeStatusHandler}
          backdropClassName="progresscnt-backdrop"
        />
      )}
    </React.Fragment>
  );
};

export default TransferContractConfirmationPopup;