import React, { useEffect, useState, useRef } from "react";
import "../Invoices/ViewInvoices.scss";
import "./Contracts.scss";
import { Modal, Button } from "react-bootstrap";
// import InfoIcon from "../../../assets/images/info-icon.png";
import { FaListUl } from "react-icons/fa";
import { fetchContractListSrvV2 } from "../../../services/contract.service";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import icon from "../../../assets/images/icon.png";
import RateEscalationPdfPopup from "../RateEscalationPdfPopup/RateEscalationPdfPopup";
import ContractPdfLink from "../ContractPdfLink/ContractPdfLink";
import { useSelector } from "react-redux";
import AdminPopup from "../AdminPopup/AdminPopup";
import { FeatureFlags } from "../../common/FeatureFlags/FeatureFlags";
import ViewRatesPopup from "../ViewRates/ViewRatesPopup";
import contextMenu from "../../../assets/images/contextMenu.svg";
import OnPar from "../../../assets/images/OnPar.svg";
import transfer from "../../../assets/images/transfer.png";
import TransferContractModal from "./TransferContract/TransferContractModal";
import ContractsProgressBar from "./ContractsProgressBar/ContractsProgressBar";

const Contracts = (props) => {
  const [contracts, setContracts] = useState([]);
  const [contractData, setContractData] = useState("");
  const [contractNumber, setContractNumber] = useState("");
  const [showRate, setShowRate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tailList, setTailList] = useState([]);
  const [openContractPdf, setOpenContractPdf] = useState(false);
  const [show, setShow] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { configData, isHoneywellEmployee, isLoadingToken, tokenValid } =
    useSelector((state) => state.config);

  const contractsComponent = useRef(true);
  const [soldToSearchTerm, setSoldToSearchTerm] = useState([]);
  const [adminSearchFlag, setAdminSearchFlag] = useState(false);

  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuID, setContextMenuID] = useState("");
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [subStatus, setSubStatus] = useState("");
  const [showTransferBar, setShowTransferBar] = useState(false);

  // const subStatusFields = ["Transfer Submitted", "HITR", "CLS"];
  const viewRatesFlag = FeatureFlags?.find(
    (feature) => feature.name === "ViewRates"
  );

  useEffect(() => {
    if (contractsComponent.current === true || adminSearchFlag === true) {
      contractsComponent.current = false;
      getContractDetailsData();
    }
    /*eslint-disable */
  }, [soldToSearchTerm]);

  let payloadSearchValue;
  if (isHoneywellEmployee && adminSearchFlag) {
    if (soldToSearchTerm.length > 0) {
      payloadSearchValue = [
        {
          sold_to_number: soldToSearchTerm,
        },
      ];
    }
  } else {
    payloadSearchValue = {};
  }

  const payloadDetails = {
    query: "",
    filters: {
      all: payloadSearchValue,
    },
  };

  // api to get contract details
  const getContractDetailsData = async () => {
    if (
      (!isHoneywellEmployee && adminSearchFlag === false) ||
      adminSearchFlag === true
    ) {
      setIsLoading(true);
      const body =
        adminSearchFlag === true ? JSON.stringify(payloadDetails) : {};

      await fetchContractListSrvV2(body).then((res) => {
        if (res?.status === 200) {
          setContracts(res?.data?.data?.results);
        } else {
          setContracts([]);
        }
      });
    }
    setIsLoading(false);
  };

  // multiple tails popup
  const openTailDetails = (data) => {
    if (data) {
      setTailList(data?.aircraftTailNumbers);
    }
    handleShow();
  };

  const displayRateEscalationHandler = (e) => {
    setContractData(e.target.name);
    setModalShow(true);
  };

  const submitDataHandler = (searchSoldTo, searchFlagData) => {
    setSoldToSearchTerm(searchSoldTo);
    setAdminSearchFlag(true);
    props.onAdminSubmit(searchSoldTo);
  };

  // contracts popup
  const openContractLinkPdfHandler = (contract_number) => {
    setOpenContractPdf(true);
    setContractNumber(contract_number);
  };

  const closeContractPdfHandler = () => {
    setOpenContractPdf(false);
  };

  //rates popup
  const displayRatesHandler = (e) => {
    setContractNumber(e.target.name);
    setShowRate(true);
  };

  //context menu handler
  const handleContextMenu = (id) => {
    if (id === contextMenuID) {
      setShowContextMenu(!showContextMenu);
    } else {
      setShowContextMenu(true);
    }
    setContextMenuID(id);
  };

  //executed on click of context menu
  const openTransferModal = (subStatus) => {
    setShowContextMenu(false);
    setShowTransferModal(true);
    setSubStatus(subStatus);
  };

  const transferBarHandler = (subStatus) => {
    setShowTransferBar(true);
    setSubStatus(subStatus);
  };

  const tailNumberPopup = (
    <React.Fragment>
      <Modal
        size="lg"
        aria-labelledby="choose contract"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton className="modal-h">
          <Modal.Title className="title-modal">
            <h2>Invoiced Tails</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="tail-row">
            <div>TAIL</div>
            {/* <div>SERIAL NUMBER</div> */}
          </div>

          <div className="tail-cnt-row">
            {tailList.map((tailno, index) => (
              <div className="tail-num" key={index}>
                <span>{tailno}</span>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </React.Fragment>
  );

  return (
    <>
      <Helmet>
        <title>{configData?.MSP_CONTRACTS_METADATA?.TITLE}</title>
        <meta
          name="description"
          content={configData?.MSP_CONTRACTS_METADATA?.DESCRIPTION}
        />
        <meta
          property="og:title"
          content={configData?.MSP_CONTRACTS_METADATA?.TITLE}
        />
        <meta property="og:type" content="Website" />
        <link rel="icon" type="image/png" href={icon} sizes="16*16" />
        <link
          rel="canonical"
          href={configData?.MSP_CONTRACTS_METADATA?.CANONICAL_URL}
        />
      </Helmet>
      <div className="invoice-cnt">
        <div className="invoice-hdr">
          <h2>My Contracts</h2>
        </div>
        <div className="row top-btns">
          {/* <div className="col-8">
            <ReportUsageSearch placeholder="Search By Contract Number" />
          </div> */}
          <div className="col-4">
            {/* <select
                className="dropdown"
                title=""
              >
                <option value="invoice_number">Sort By:Contract End Date(Soonest)</option>
                <option value="contract_number">Sort By:Contract Start Date</option>
              </select> */}
          </div>
        </div>
        {tailNumberPopup}
        {tokenValid && isLoadingToken === false && isHoneywellEmployee && (
          <AdminPopup onSubmitData={submitDataHandler} />
        )}
        {isLoading ? (
          <h2 className="no-data-contracts">
            {configData?.MSP_INFORMATION_TEXT?.LOADING_CONTRACTS}
            <div className="loader"></div>
          </h2>
        ) : (
          contracts?.map((contractDetails, index) => {
            return (
              <div className="invoice-info" key={index}>
                <div className="info-hdr">
                  <div className="contract-hdr-cnt">
                    <div className="contract-hdr-num">
                      <span className="contract-num">
                        <Link
                          className="contarct-lable"
                          onClick={() =>
                            openContractLinkPdfHandler(
                              contractDetails?.contractNumber
                            )
                          }
                          to="#"
                        >
                          {contractDetails?.contractNumber}
                        </Link>
                      </span>
                      <span className="single-tail">
                        {contractDetails?.aircraftTailNumbers?.length <= 1 ? (
                          contractDetails?.aircraftTailNumbers[0] ? (
                            `Tail No. ${contractDetails?.aircraftTailNumbers[0]}`
                          ) : (
                            <div className="blank"></div>
                          )
                        ) : (
                          <span
                            className="multiple-tail"
                            onClick={() => openTailDetails(contractDetails)}
                          >
                            Multiple Tails <FaListUl className="mtail-icon" />
                          </span>
                        )}
                      </span>
                    </div>
                    <div className="contract-hdr-status">
                      {contractDetails?.subStatus === "HITR" &&
                        contractDetails?.status === "Open" &&
                        contractDetails?.aircraftTailNumbers?.length <= 1 && (
                          <div className="transfer-st-cnt">
                            <span className="transfer-img">
                              <img src={transfer} alt="transfer started" />
                            </span>
                            <span className="transfer-text">
                              Transfer Started
                            </span>
                          </div>
                        )}
                      {
                        <div className="status-container">
                          {contractDetails?.status === "Open" &&
                            contractDetails?.aircraftTailNumbers?.length >
                              1 && (
                              <span className="label label-success">
                                Active
                              </span>
                            )}
                          {contractDetails?.status === "Open" &&
                            contractDetails?.aircraftTailNumbers?.length <= 1 &&
                            contractDetails?.subStatus !== "HITR" && (
                              <span className="label label-success">
                                Active
                              </span>
                            )}
                          {contractDetails?.status === "Closed" && (
                            <span className="label label-default">Closed</span>
                          )}
                          {contractDetails?.status !== "Open" &&
                            contractDetails?.status !== "Closed" && (
                              <span className="label label-danger">
                                <img
                                  className="tooltip-img"
                                  alt="onpar"
                                  src={OnPar}
                                />
                                On Par
                                <span className="tooltiptext">
                                  {contractDetails?.subStatusMessage ? (
                                    <span>
                                      <strong>Status :</strong>
                                      {`${contractDetails?.subStatusMessage}`}
                                    </span>
                                  ) : (
                                    <span>
                                      Please ensure that your payments and
                                      &#013; usage reporting are up-to-date.
                                    </span>
                                  )}
                                </span>
                              </span>
                            )}
                        </div>
                      }
                    </div>
                    <div className="contract-hdr-btn">
                      <div>
                        {contractDetails?.status === "Open" &&
                          contractDetails?.subStatus === "HITR" &&
                          contractDetails?.aircraftTailNumbers?.length <= 1 && (
                            <Button
                              variant="primary"
                              className="btn-white transfer-status-bt"
                              onClick={() =>
                                transferBarHandler(contractDetails?.subStatus)
                              }
                            >
                              Transfer Status
                            </Button>
                          )}
                      </div>
                      <div>
                        {contractDetails?.aircraftTailNumbers?.length > 1
                          ? contractDetails?.status !== "Closed" && (
                              <div className="context-menu">
                                <img
                                  alt="context"
                                  src={contextMenu}
                                  onClick={() =>
                                    handleContextMenu(
                                      String(contractDetails?.contractNumber)
                                    )
                                  }
                                />
                              </div>
                            )
                          : contractDetails?.status !== "Closed" &&
                            contractDetails?.aircraftTailNumbers?.length <= 1 &&
                            !contractDetails?.subStatus && (
                              <div className="context-menu">
                                <img
                                  alt="context"
                                  src={contextMenu}
                                  onClick={() =>
                                    handleContextMenu(
                                      String(contractDetails?.contractNumber)
                                    )
                                  }
                                />
                              </div>
                            )}
                        {showContextMenu &&
                          contextMenuID ===
                            String(contractDetails?.contractNumber) && (
                            <div
                              className="btn btn-white context-menu-btn"
                              onClick={() =>
                                openTransferModal(contractDetails?.subStatus)
                              }
                            >
                              I've Sold This Aircraft
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  {/* <div className="contract-amt">
                    <span className="pre-pay">Pre-Pay Balance</span>
                    <span className="pre-pay-value">0.00 USD</span>
                  </div> */}
                  {/* <div className="btn-pay">
                    <button
                      type="button"
                      className="btn-white btn btn btn-primary"
                    >
                      Download
                    </button>
                  </div> */}
                </div>
                <div className="contract-details-container">
                  <div className="contract-details">
                    <span>Contract Start</span>
                    {contractDetails?.contractStartDate ? (
                      <span>{contractDetails?.contractStartDate}</span>
                    ) : (
                      <span className="contract-date">-</span>
                    )}
                  </div>
                  <div className="contract-details">
                    <span>Contract End</span>

                    {contractDetails?.contractEndDate ? (
                      <span>{contractDetails?.contractEndDate}</span>
                    ) : (
                      <span className="contract-date">-</span>
                    )}
                  </div>
                  {viewRatesFlag?.active && (
                    <div className="contract-details">
                      <span>
                        <button
                          className="contract-rate"
                          name={contractDetails?.contractNumber}
                          onClick={displayRatesHandler}
                        >
                          View Rate Details
                        </button>
                      </span>
                    </div>
                  )}
                  <div className="contract-details">
                    <span>
                      <button
                        className="contract-rate"
                        name={contractDetails?.contractNumber}
                        onClick={displayRateEscalationHandler}
                      >
                        View Rate Escalation
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            );
          })
        )}
        {!isLoading && contracts.length <= 0 && (
          <h2 className="no-data">No Data Available</h2>
        )}
      </div>

      <RateEscalationPdfPopup
        constractnumberdata={contractData}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      {openContractPdf && (
        <ContractPdfLink
          constractnumberdata={contractNumber}
          show={openContractPdf}
          onHide={closeContractPdfHandler}
          backdrop="static"
        />
      )}

      {showRate && (
        <ViewRatesPopup
          show={showRate}
          contractNumber={contractNumber}
          onHide={() => setShowRate(false)}
        />
      )}

      {showTransferBar && (
        <ContractsProgressBar
          show={showTransferBar}
          subStatus={subStatus}
          onHide={() => setShowTransferBar(false)}
        />
      )}

      {showTransferModal && (
        <TransferContractModal
          show={showTransferModal}
          subStatus={subStatus}
          contractdetails={contextMenuID}
          onHide={() => setShowTransferModal(false)}
        />
      )}
    </>
  );
};

export default Contracts;