export const appliedCreditsPayload = (credits, invoices, remainingBalance) => {
  let creditAmount = 0;
  let debitAmount = 0;
  invoices.forEach((invoice) => {
    debitAmount += parseFloat(invoice?.invoiceBalance.replace(/,/g, ""));
  });
  credits?.forEach((credit) => {
    creditAmount += parseFloat(credit?.invoiceBalance.replace(/,/g, ""));
  });
  const payloadObject = {
    debitInvoices: invoices?.map((invoiceData) => {
      return {
        invoiceNumber: invoiceData?.invoiceNumber
          ? Number(invoiceData?.invoiceNumber)
          : "",
        debitMemoNumber: invoiceData?.debitMemoNumber
          ? Number(invoiceData?.debitMemoNumber)
          : "",
        soldToNumber: invoiceData?.soldToNumber
          ? Number(invoiceData?.soldToNumber)
          : "",
        debitAmount: `${invoiceData?.invoiceBalance} USD`,
      };
    }),
    creditInvoices: credits?.map((creditsData) => {
      return {
        invoiceNumber: creditsData?.invoiceNumber
          ? Number(creditsData?.invoiceNumber)
          : "",
        debitMemoNumber: creditsData?.debitMemoNumber
          ? Number(creditsData?.debitMemoNumber)
          : "",
        soldToNumber: creditsData?.soldToNumber
          ? Number(creditsData?.soldToNumber)
          : "",
        creditAmount: `${creditsData?.invoiceBalance} USD`,
      };
    }),
    creditAmount: parseFloat(creditAmount).toLocaleString("en", {
      minimumFractionDigits: 2,
    }),
    debitAmount: parseFloat(debitAmount).toLocaleString("en", {
      minimumFractionDigits: 2,
    }),
    remainingBalance: `${parseFloat(remainingBalance).toLocaleString("en", {
      minimumFractionDigits: 2,
    })} USD`,
  };

  return JSON.stringify({ creditUsageRequest: payloadObject });
};
