import React, { useEffect, useState } from "react";
import "./UploadUsagePopupValidation.scss";

const UploadUsagePopupValidation = ({ errorData, errorDetails }) => {
  const [error, setError] = useState(false);

  useEffect(() => {
    let count = 0;
    errorData.forEach((data) => {
      if (data.error) {
        count++;
      }
    });
    if (count > 0) {
      setError(true);
    }
    /*eslint-disable */
  }, []);

  return (
    <React.Fragment>
      {!error && (
        <p> Your invoice will be generated and will be available soon </p>
      )}

      {error && (
        <p>
          {!errorDetails
            ? "Upload completed with below error"
            : "Upload failed with below error"}
        </p>
      )}
      {
        <div className="upload-error-info">
          {errorData.map((errorDetails) => {
            return (
              <React.Fragment>
                <p
                  className={`${
                    errorDetails?.description.includes("successful")
                      ? "upload-success-info"
                      : ""
                  }`}
                >
                  {errorDetails?.description}
                </p>
                ;
                {errorDetails?.error &&
                  Object.entries(errorDetails?.error).map((errorData) => {
                    return <p>{errorData[1]}</p>;
                  })}
              </React.Fragment>
            );
          })}
        </div>
      }
    </React.Fragment>
  );
};

export default UploadUsagePopupValidation;
