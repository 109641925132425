import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import "./ContractUsagePopup.scss";
import { Modal } from "react-bootstrap";
import ReportUsageTable from "../../ReportUsageTable/ReportUsageTable";
import { fetchContractListSrv } from "../../../../services/contract.service";

const ReportUsagePopup = (props) => {
  const { configData } = useSelector((state) => state.config);
  const [isLoading, setIsLoading] = useState(true);
  const [contracts, setContracts] = useState([]);

  const reportUsagePopupRef = useRef(true);

  //contract payload
  const payloadDetails = {
    query: "",
    filters: {
      all: [
        {
          contract_number: props.contractNumber.toString(),
        },
      ],
    },
  };

  //getcontractdetails api call
  const getContractDetails = async () => {
    setIsLoading(true);
    const payload = JSON.stringify(payloadDetails);
    await fetchContractListSrv(payload).then((res) => {
      if (res?.status === 200) {
        setContracts(res?.data?.data?.results);
      } else {
        setContracts([]);
      }
    });
    setIsLoading(false);
  };

  //function executed on click of close
  const closePopupHandler = () => {
    props.onClose();
    props.onCloseInvoice();
  };

 

  useEffect(() => {
    if (reportUsagePopupRef.current) {
      reportUsagePopupRef.current = false;
      getContractDetails();
    }
    /*eslint-disable */
  }, []);

  return (
    <>
      <Modal
        show={props.showContract}
        size="xl"
        aria-labelledby="report usage"
        contentClassName="reportpopup-cnt"
        onHide={closePopupHandler}
        backdrop="static"
      >
        {contracts[0]?.isFutureUsagePeriod ? (
          <Modal.Header closeButton className="modal-h">
            <Modal.Title className="title-modal">
              <>
                <span>Report Usage</span>
              </>
            </Modal.Title>
          </Modal.Header>
        ) : (
          <Modal.Header className="modal-h">
            <Modal.Title className="title-modal">
              <>
                <span>Report Usage</span>
              </>
            </Modal.Title>
          </Modal.Header>
        )}

        <Modal.Body>
          <>
            <div className="report-usg-cnt">
              {isLoading && (
                <h2>{configData?.MSP_INFORMATION_TEXT?.LOADING_CONTRACTS}</h2>
              )}
            </div>
            {!isLoading && <ReportUsageTable contracts={contracts} />}
          </>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ReportUsagePopup;
