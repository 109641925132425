import axios from "axios";
import constant from "../constants/constant";
import { v4 as uuidv4 } from "uuid";
import { store } from "../../src/storage/mspStore";

store.subscribe(listener);

function select(state) {
  return state;
}

let tokenValidate;
function listener() {
  let storeData = select(store.getState());
  const configData = storeData?.config?.configData;
  const token = storeData?.config?.token;
  if (token) {
    if (token?.cookies[configData?.MSP_TOKEN?.TOKEN]) {
      tokenValidate = token?.cookies[configData?.MSP_TOKEN?.TOKEN];
    } else {
      tokenValidate = constant.ACCESS_TOKEN;
    }
  }
}

const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
    "X-Request-ID": uuidv4(),
  },
});

const requestHandler = (request) => {
  request.headers.authorization = `Bearer ${tokenValidate}`;
  return request;
};

const responseHandler = (response) => {
  return response;
};

const errorHandler = (error) => {
  const errorResponse = error.response;
  return errorResponse;
};

instance.interceptors.request.use((request) => requestHandler(request));

instance.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default instance;
