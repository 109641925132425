import React, { useState} from "react";
import { useSelector } from "react-redux";
import "./Layout.scss";
import { Route, Routes, useLocation } from "react-router-dom";

import ReportUsage from "./ReportUsage/ReportUsage";
import LinksInfo from "./LinksInfo/LinksInfo";
import GlobalFooter from "../common/GlobalFooter/GlobalFooter";
import GlobalHeader from "../common/GlobalHeader/GlobalHeader";
import HeaderLeft from "../common/HeaderLeft/HeaderLeft";
import { Container, Row, Col } from "react-bootstrap";
import NoDataAvailable from "../common/NoDataAvailable/NoDataAvailable";
import ViewInvoices from "./Invoices/ViewInvoices";
import Feature from "../common/FeatureFlags/Feature/Feature";
import UbCredits from "./UbCreditsInfo/UbCredits";
import Contracts from "./Contracts/Contracts";

const Layout = (props) => {
  const { tokenValid, isLoadingToken,isTokenUpdated,configData,token } = useSelector((state) => state.config);
  const [displayPage, setDisplayPage] = useState("");
  const location = useLocation();

  const displayPageHandler = (data) => {
    setDisplayPage(data);
  };

  const buttonClickHandler = () => {
    setDisplayPage("");
    setUsageContractTermData("");
    setUsageSoldToTermData([]);
    setDropDownInputTermData("")
    setInvoiceSoldToTermData([])
    setInitialLoading(false)
  };

  const [usagecontractTermData, setUsageContractTermData] = useState("");
  const [usagesoldToTermData, setUsageSoldToTermData] = useState([]);

  const [dropDownInputTermData, setDropDownInputTermData] = useState("");
  const [invoiceSoldToTermData, setInvoiceSoldToTermData] = useState([]);

  const [contractsSoldToTermData, setContractsSoldToTermData] = useState([]);

  const [initialLoading,setInitialLoading] = useState(true);
  const [dropDownValue,setDropDownValue] = useState("contract_number")

  const submitUsageDataHandler = (dropDownInputValue, searchSoldTo, dropDownValue) => {
    setDropDownInputTermData(dropDownInputValue);
    setInvoiceSoldToTermData(searchSoldTo);
    setDropDownValue(dropDownValue);
  };

  const submitContractsDataHandler = (searchSoldTo) => {
    setContractsSoldToTermData(searchSoldTo);
  };


  const reportHoursAdminSearch = (searchContract, searchSoldTo) => {
    setUsageContractTermData(searchContract);
    setUsageSoldToTermData(searchSoldTo);
  };




  return (
    <React.Fragment>
      {/* Global Header Component */}
      <GlobalHeader />

      {/* Header Left Component */}
      <HeaderLeft onButtonClick={buttonClickHandler} />
      <main>{props.children}</main>
      {!tokenValid && isLoadingToken === true && (
        <p className="no-data-contracts">Loading Data......</p>
      )}
      {tokenValid && isLoadingToken === false && (
        <Container fluid className="bg-grey">
          <Row className="main_container">
            <Routes>
              <Route
                exact
                path="/"
                element={
                  <Col md={9}>
                    <Feature
                      name="ReportUsage"
                      children={
                        <ReportUsage
                          onPopupDisplay={displayPage}
                          onAdminSubmit={reportHoursAdminSearch}
                          initialLoad={initialLoading}
                        />
                      }
                    />
                  </Col>
                }
              />
              <Route
                exact
                path="/invoices"
                element={
                  <Col md={9}>
                    <Feature
                      name="ViewInvoicesNew"
                      children={
                        <ViewInvoices onSubmit={submitUsageDataHandler} />
                      }
                    />
                  </Col>
                }
              />
              <Route
                exact
                path="/contracts"
                element={
                  <Col md={9}>
                    <Feature
                      name="Contracts"
                      children={
                        <Contracts
                        onPopupDisplay={displayPage}
                        onAdminSubmit={submitContractsDataHandler}
                        initialLoad={initialLoading}
                      />
                      }
                    />
                  </Col>
                }
              />
            </Routes>
            <Col md={3}>
              <LinksInfo
                onDisplay={(data) => displayPageHandler(data)}
                usagecontractTermData={location.pathname === '/'?usagecontractTermData:dropDownInputTermData}
                usagesoldToTermData={location.pathname === '/' ? usagesoldToTermData : location.pathname === '/contracts' ? contractsSoldToTermData : invoiceSoldToTermData}              
              />

              {location?.pathname === "/invoices" && (
                <Feature
                  name="UBCredits"
                  children={
                    <UbCredits
                     dropDownInputTermData={dropDownInputTermData}
                      usagesoldToTermData={invoiceSoldToTermData}
                      dropDownValue={dropDownValue}
                    />
                  }
                />
              )}
            </Col>
          </Row>
        </Container>
      )}
      {!tokenValid && isLoadingToken === false && isTokenUpdated === true && <NoDataAvailable configData={configData} token={token}/>}

      <GlobalFooter />
    </React.Fragment>
  );
};

export default Layout;