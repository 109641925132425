module.exports = {
  API_END_POINT: "http://localhost:3010",
  MSP_AWSESB_URL: "",
  ACCESS_TOKEN: "token",
  MSP_LOGIN_URL: "https://dpublish-aerospacebt.aws.aem.honeywell.com/pif/cwa/oauth/request/j_security_check?resource=https://devmspaerospace.honeywell.com/&appId=226",
  MSP_MAIL_ID:"MSPContracts@Honeywell.com",
  MSP_MAIL_REMIT_ID:"MSPCashApplicationRequests@Honeywell.com",
  MSP_MAIL_RATES:"champs@honeywell.com",
  MSP_SUPPORT_URL: "https://dpublish-aerospacebt.aws.aem.honeywell.com/us/en/support-and-resources/contact-us/forms/website-issues/other-portal-issues",
  EWALLET_URL:"https://qaerocontent.honeywell.com/aero/portal/Menus/eWallet/ewallet.js",
  MSP_CREDITS_URL:"https://dpublish-aerospacebt.aws.aem.honeywell.com/us/en/support-and-resources/contact-us/forms/connected-services/usage-msp-credits",
  MSP_USAGE_CREDITS_URL:"https://dpublish-aerospacebt.aws.aem.honeywell.com/us/en/support-and-resources/contact-us/forms/connected-services/usage-msp-credits"
  };