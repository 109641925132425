import React, { useEffect, useRef, useState } from "react";
import "./LinksInfo.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import CheckOutsideClick from "../../common/CheckOutsideClick/CheckOutsideClick";
import MinuteToHourConversion from "../MinuteToHourConversion/MinuteToHourConversion";
import RateEscalationPopup from "../RateEscalationPopup/RateEscalationPopup";
import { fetchAsyncContractsDetails } from "../../../storage/reducers/contractDetailsReducer";
import constant from "../../../constants/constant";

const LinksInfo = (props) => {
  const minuteToHourConversionRef = useRef(null);
  const [minuteToHourConversionDisplay, setMinuteToHourConversionDisplay] =
    useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const { configData, token, isHoneywellEmployee } = useSelector(
    (state) => state.config
  );

  const location = useLocation();
  let tokenValidate;
  if (token) {
    if (token?.cookies[configData?.MSP_TOKEN?.TOKEN]) {
      tokenValidate = token?.cookies[configData?.MSP_TOKEN?.TOKEN];
    } else {
      tokenValidate = constant.ACCESS_TOKEN;
    }
  }
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    setMinuteToHourConversionDisplay(
      JSON.parse(sessionStorage.getItem("minuteToHourConversionDisplay"))
    );
  }, []);

  const minuteToHourConversionDisplayHandler = () => {
    setMinuteToHourConversionDisplay((prevValue) => {
      sessionStorage.setItem(
        "minuteToHourConversionDisplay",
        JSON.stringify(!prevValue)
      );
      return !prevValue;
    });
  };
  const payloadAdminSearch = {
    contractNo: props.usagecontractTermData,
    soldToNumber: props.usagesoldToTermData,
    tokenValidate: tokenValidate,
  };
  const handleShow = () => {
    dispatch(fetchAsyncContractsDetails(payloadAdminSearch));
    setModalShow(true);
  };
  // event to be triggered on click of minute to hour conversion
  const minuteToHourConversionHandlerClose = () => {
    setMinuteToHourConversionDisplay(false);
    sessionStorage.setItem(
      "minuteToHourConversionDisplay",
      JSON.stringify(false)
    );
  };

  const employeeSearchRefreshPageHandler = (e) => {
    e.preventDefault();
    props.onDisplay(location?.pathname === "/" ? "ReportUsage" : "Invoice");
    navigate(0);
  };

  const navigatePath = () => {
    if (!token?.cookies?.hasOwnProperty(configData?.MSP_TOKEN?.TOKEN)) {
      window.open(location?.pathname, "_blank");
    }
  };

  return (
    <>
      <div className="links_info" data-test="links_info_modal">
        <h3 data-test="links_info">Links & Information</h3>
        <Link
          to={location?.pathname}
          data-test="minute_to_hour_conversion"
          onClick={minuteToHourConversionDisplayHandler}
          ref={minuteToHourConversionRef}
        >
          {" "}
          Minute-To-Hour Conversion
        </Link>      
        {isHoneywellEmployee && (
          <a
            target="_blank"
            rel="noreferrer"
            href={`${
              token?.cookies?.hasOwnProperty(configData?.MSP_TOKEN?.TOKEN)
                ? configData?.MSP_TOKEN?.ADMIN_MSP_AVIONICS_QUOTE_URL
                : "#"
            }`}
            onClick={navigatePath}
          >
            Admin MSP Avionics/Mechanical quotes
          </a>
        )}

        {isHoneywellEmployee && (
          <p href="#" onClick={employeeSearchRefreshPageHandler}>{`${
            location?.pathname === "/"
              ? "Report usage For Customer"
              : location?.pathname === "/contracts"
              ? "Contract Search Admin"
              : "Invoice Search Admin"
          }`}</p>
        )}

        {minuteToHourConversionDisplay && (
          <CheckOutsideClick
            onClickOutside={minuteToHourConversionHandlerClose}
            ref={minuteToHourConversionRef}
          >
            <MinuteToHourConversion />
          </CheckOutsideClick>
        )}

        <RateEscalationPopup
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      </div>
    </>
  );
};

export default LinksInfo;
