export const getUbCreditsPayload = (
    dropDownValue,
    dropDownInputTermData,
    usagesoldToTermData
  ) => {
    let usagePayLoad;
    const dropDownData =
      dropDownValue === "contract_number"
        ? { contract_number: dropDownInputTermData }
        : { invoice_number: dropDownInputTermData };
    const documentType = {
      document_type: "Invoices",
    };
    const creditType = {
      is_credit_flag: "Y",
    };
    const status = {
      status: "Open",
    };
    if (dropDownInputTermData && usagesoldToTermData.length > 0) {
      usagePayLoad = {
        all: [
          dropDownData,
          { sold_to_number: usagesoldToTermData },
          documentType,
          creditType,
          status,
        ],
      };
    } else if (dropDownInputTermData && usagesoldToTermData.length <= 0) {
      usagePayLoad = {
        all: [dropDownData, documentType, creditType, status],
      };
    } else if (usagesoldToTermData.length > 0 && !dropDownInputTermData) {
      usagePayLoad = {
        all: [
          { sold_to_number: usagesoldToTermData },
          documentType,
          creditType,
          status,
        ],
      };
    } else {
      usagePayLoad = {
        all: [documentType, creditType, status],
      };
    }
  
    return usagePayLoad;
  };