import React from "react";

const Loader = () => {
  return (
    <div className="page-load">
      <h2>
        Loading, please wait...<div className="loader"></div>
      </h2>
    </div>
  );
};

export default Loader;
