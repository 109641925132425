import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { callToAPI } from "../../services/service";
import { v4 as uuidv4 } from "uuid";
//inital state for config, token
const initialState = {
  configData: "",
  token: "",
  tokenValid: false,
  soldToNumbers: [],
  isLoadingToken: true,
  isHoneywellEmployee: false,
  isTokenUpdated: false,
  tokenAPIStatus:null,
  status:null,
  validToken: null
};

//loading config data
export const fetchAsyncConfig = createAsyncThunk("msp/config", async () => {
  try {
    const res = await callToAPI("/api/v1/auth/get-config", "POST", {
      "Content-Type": "application.json",
      "X-Request-ID": uuidv4(),
    });
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error);
  }
});

//loading token
export const fetchAsyncToken = createAsyncThunk("/msp/token", async () => {
  try {
    const res = await callToAPI("/api/v1/auth/get-token", "GET", {
      "Content-Type": "application.json",
      "X-Request-ID": uuidv4(),
    });
    const data = await res.json();
    return data;
  } catch (error) {
    console.log(error);
  }
});

//validating token
export const fetchAsyncTokenValidation = createAsyncThunk(
  "/msp/tokenvalidation",
  async (token_msp) => {
    try {
      const res = await callToAPI("/api/v1/auth/check-access", "GET", {
        authorization: `Bearer ${token_msp}`,
        "X-Request-ID": uuidv4(),
      });
      const data = await res.json();
      if (res.status === 200) {
        return {
          tokenAccess: data?.message?.result?.toolAccess?.status,
          honeywellEmployee: data?.message?.result?.isInternalUser,
        };
      } else {
        return { tokenAccess: false, honeyWellEmployee: false };
      }
    } catch (error) {
      console.log(error);
    }
  }
);

export const configReducer = createSlice({
  name: "configdata",
  initialState,
  extraReducers: {
    [fetchAsyncConfig.fulfilled]: (state, { payload }) => {
      return { ...state, configData: payload.message.result, status: "success" };
    },
    [fetchAsyncConfig.pending]: (state, action) => {
      return {...state, status : "loading"};
    },
    [fetchAsyncConfig.rejected]: (state, action) => {
      //state.status = "unable to fetch data";
      return {...state, status : "unable to fetch data"};
    },
    [fetchAsyncToken.fulfilled]: (state, { payload }) => {
      return { ...state, token: payload,isTokenUpdated:true, tokenAPIStatus:"success" };
    },
    [fetchAsyncToken.pending]: (state) => {
      return { ...state, isTokenUpdated:false, tokenAPIStatus:"pending" };
    },
    [fetchAsyncToken.rejected]: (state) => {
      return { ...state, isTokenUpdated:false , tokenAPIStatus:"rejected"};
    },
    [fetchAsyncTokenValidation.pending]: (state, { payload }) => {
      return { ...state, isLoadingToken: true, validToken: null };
    },
    [fetchAsyncTokenValidation.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        tokenValid: payload.tokenAccess,
        isHoneywellEmployee: payload.honeywellEmployee,
        isLoadingToken: false,
        validToken: payload.tokenAccess
      };
    },
    [fetchAsyncTokenValidation.rejected]: (state, { payload }) => {
      return {
        ...state,
        isLoadingToken: false,
        tokenValid: false,
        isHoneywellEmployee: false,
        validToken: false
      };
    },
  },
});
export const configActions = configReducer.actions;
