import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { callToAPI } from "../../services/service";
import { v4 as uuidv4 } from "uuid";
//Initial state for header and footer
const initialState = {
  headerFooterMarkupData: {
    header: "",
    footer: "",
    css: [],
    jsFile: [],
   },
    status: "",
  
};

export const fetchAsyncData = createAsyncThunk("headerData/Data", async () => {
  try {
    const res = await callToAPI("/api/v1/auth/markup", "GET", {
      "Content-Type": "application.json",
      "X-Request-ID": uuidv4(),
    });
    const data = await res.json();
    return data.message.result;
  } catch (error) {
    console.log(error);
  }
});

export const headerFooterDataReducer = createSlice({
  name: "headerFooterData",
  initialState,

  extraReducers: {
    [fetchAsyncData.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchAsyncData.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        header: payload.headerMarkup,
        footer: payload.footerMarkup,
        css: payload.cssResourcePaths,
        jsFile: payload.jsResourcePaths,
        status: "success",
      };
    },
    [fetchAsyncData.rejected]: (state, action) => {
      state.status = "unable to fetch data";
    },
  },
});

export const headerFooterDataActions = headerFooterDataReducer.actions;
