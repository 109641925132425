import React, { useEffect, useState, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { fetchInvoiceListSrv } from "../../../../services/invoice.service";
import "./ApplyCreditsInvoices.scss";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaListUl } from "react-icons/fa";
import {
  FiChevronsLeft,
  FiChevronsRight,
  FiChevronLeft,
  FiChevronRight,
} from "react-icons/fi";
import AppliedUbCreditsModal from "../AppliedUbCreditsModal/AppliedUbCreditsModal";
import CreditsErrorPopup from "../CreditsErrorPopup/CreditsErrorPopup";

const ApplyCreditsInvoices = (props) => {
  const {
    dropdowntermvalue,
    usagesoldto,
    dropdownvalue,
    invoicesoldtodata,
    totalcreditsammount,
  } = props;
  const [invoicesData, setInvoicesData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [invoicesError, setInvoicesError] = useState("");
  const [totalCreditsError, setTotalCreditsError] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginantionData, setPaginantionData] = useState([]);
  const [selectedOpenInvoices, setSelectedOpenInvoices] = useState([]);
  const [totalApplyAmmount, setTotalApplyAmmount] = useState(0);
  const [, setTailList] = useState([]);
  const [, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [recordsPerPage] = useState(100);
  const { configData } = useSelector((state) => state.config);
  const [showAppliedCreditsModal, setShowAppliedCreditsModal] = useState(false);
  const applyCreditInvoicesComponent = useRef(true);

  const nPages = paginantionData?.total_pages;

  const pageNumbers = [];
  let start = currentPage;
  let end = currentPage + 4;

  // on selecting invoices this menthod gets executed
  const multipleInvoicesHandler = (
    multipleInvoicesObjectData,
    multipleInvoicesChecked
  ) => {
    const invoiceDataDetails = JSON.parse(multipleInvoicesObjectData);

    if (multipleInvoicesChecked) {
      const findInvoice = selectedOpenInvoices?.find(
        (invoice) => invoice?.id === invoiceDataDetails?.id
      );
      if (!findInvoice) {
        setSelectedOpenInvoices((prevValue) => [
          ...prevValue,
          invoiceDataDetails,
        ]);
        setTotalApplyAmmount(
          (prevAmount) =>
            prevAmount +
            parseFloat(invoiceDataDetails?.invoiceBalance.replace(/,/g, ""))
        );
      }
    } else {
      setSelectedOpenInvoices((prevValue) =>
        prevValue.filter(
          (multipleInvoiceFilter) =>
            multipleInvoiceFilter?.id !== invoiceDataDetails?.id
        )
      );
      setTotalApplyAmmount(
        (prevAmount) =>
          prevAmount -
          parseFloat(invoiceDataDetails?.invoiceBalance.replace(/,/g, ""))
      );
    }
  };

  if (end > paginantionData?.total_pages) {
    start -= end - paginantionData?.total_pages;
    end = paginantionData?.total_pages;
  }
  if (start <= 0) {
    end += (start - 1) * -1;
    start = 1;
  }

  end = end > paginantionData?.total_pages ? paginantionData?.total_pages : end;

  for (let i = start; i <= end; i++) {
    pageNumbers.push(i);
  }
  const prevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
    applyCreditInvoicesComponent.current = true;
  };

  const nextPage = () => {
    if (currentPage !== nPages) {
      if (currentPage === 100) {
        setCurrentPage(100);
      } else {
        setCurrentPage(currentPage + 1);
      }
    }
    applyCreditInvoicesComponent.current = true;
  };

  const gotoLastPage = () => {
    if (paginantionData.total_pages <= 100) {
      setCurrentPage(paginantionData.total_pages);
    } else {
      setCurrentPage(99);
    }
    applyCreditInvoicesComponent.current = true;
  };

  const gotoFirstPage = () => {
    setCurrentPage(1);
    applyCreditInvoicesComponent.current = true;
  };

  let usagePayLoad;
  const dropDownData =
    dropdownvalue === "contract_number"
      ? { sold_to_number: invoicesoldtodata }
      : { sold_to_number: invoicesoldtodata };
  if (dropdowntermvalue && usagesoldto?.length > 0) {
    usagePayLoad = {
      all: [
        dropDownData,
        { sold_to_number: usagesoldto },
        {
          status: ["Open"],
        },
        { program_code: ["MSP", "MSPG", "GNLS", "FMA", "GNLI", "CFE"] },
        {
          is_credit_flag: "N",
        },
      ],
    };
  } else if (dropdowntermvalue && usagesoldto?.length <= 0) {
    usagePayLoad = {
      all: [
        dropDownData,
        {
          status: ["Open"],
        },
        { program_code: ["MSP", "MSPG", "GNLS", "FMA", "GNLI", "CFE"] },
        {
          is_credit_flag: "N",
        },
      ],
    };
  } else if (usagesoldto?.length > 0 && !dropdowntermvalue) {
    usagePayLoad = {
      all: [
        { sold_to_number: usagesoldto },
        {
          status: ["Open"],
        },
        { program_code: ["MSP", "MSPG", "GNLS", "FMA", "GNLI", "CFE"] },
        {
          is_credit_flag: "N",
        },
      ],
    };
  } else {
    usagePayLoad = {
      all: [
        {
          status: ["Open"],
        },
        { program_code: ["MSP", "MSPG", "GNLS", "FMA", "GNLI", "CFE"] },
        {
          is_credit_flag: "N",
        },
      ],
    };
  }

  const bodyContent = {
    query: "",
    filters: usagePayLoad,
    sort: { invoice_period_date: "asc" },
    page: {
      current: currentPage,
      size: recordsPerPage,
    },
  };

  // api to get the invoices data
  const getInvoiceDetails = async () => {
    setIsLoading(true);
    const payload = JSON.stringify(bodyContent);
    await fetchInvoiceListSrv(payload).then((res) => {
      if (res?.status === 200) {
        setInvoicesData(res?.data?.data?.results);
        setPaginantionData(res?.data?.data?.meta?.page);
      } else if (res?.status === 401) {
        setInvoicesData([]);
        setInvoicesError(res?.data?.error?.errorDescription);
      } else {
        setInvoicesData([]);
        setInvoicesError(res?.data?.errorDescription);
      }
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (totalApplyAmmount > totalcreditsammount) {
      setTotalCreditsError(true);
    } else {
      setTotalCreditsError(false);
    }
    /*eslint-disable */
  }, [totalApplyAmmount]);

  useEffect(() => {
    if (applyCreditInvoicesComponent.current === true) {
      applyCreditInvoicesComponent.current = false;
      getInvoiceDetails();
    }
    // eslint-disable-next-line
  }, [currentPage]);

  //open modal for multiple tails
  const openTailDetails = (data) => {
    if (data.tailDetails === undefined || data.tailDetails === "") {
      setTailList([]);
    } else {
      setTailList(data.tailDetails);
    }
    handleShow();
  };

  const closePopupHandler = () => {
    props.onHide();
  };

  const openAppliedCreditsModal = () => {
    setShowAppliedCreditsModal(true);
  };
  const closeAppliedCreditsModalHandler = () => {
    setShowAppliedCreditsModal(false);
  };

  return (
    <React.Fragment>
      <Modal
        {...props}
        aria-labelledby="selected credit invoices"
        contentClassName={
          showAppliedCreditsModal
            ? "apply-credits-container-display"
            : "apply-credits-container"
        }
      >
        <Modal.Header closeButton className="modal-h">
          <Modal.Title className="title-modal">
            <h2>
              <strong>Invoice Credits</strong>
            </h2>
            <p>Select what you want to apply the Credits towards</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="apply-invoice-list">
          <div className="row paginate-cnt">
            <div className="col-8">
              {invoicesData?.length > 0 && !isLoading && (
                <span className="page-count">
                  {`${currentPage}-${invoicesData?.length} of ${paginantionData?.total_results} Items`}
                </span>
              )}
            </div>
            <div className="col-4">
              <div>
                {invoicesData.length !== 0 && (
                  <nav>
                    <ul className="pagination justify-content-center">
                      <li className="page-item">
                        <Link
                          className={
                            currentPage === 1
                              ? "page-link disabled"
                              : "page-link"
                          }
                          onClick={gotoFirstPage}
                          to="#"
                        >
                          <FiChevronLeft />
                        </Link>
                      </li>
                      <li className="page-item">
                        <Link
                          className={
                            currentPage === 1
                              ? "page-link disabled"
                              : "page-link"
                          }
                          onClick={prevPage}
                          to="#"
                        >
                          <FiChevronsLeft />
                        </Link>
                      </li>
                      {pageNumbers?.map((pgNumber) => (
                        <li
                          key={pgNumber}
                          className={`page-item ${
                            currentPage === pgNumber ? "active" : ""
                          } `}
                        >
                          <Link
                            onClick={() => {
                              setCurrentPage(pgNumber <= 100 ? pgNumber : 100);
                              applyCreditInvoicesComponent.current = true;
                            }}
                            className="page-link"
                            to="#"
                          >
                            {pgNumber}
                          </Link>
                        </li>
                      ))}

                      <li className="page-item">
                        <Link
                          className={
                            currentPage === paginantionData?.total_pages
                              ? "page-link disabled"
                              : "page-link"
                          }
                          onClick={nextPage}
                          to="#"
                        >
                          <FiChevronsRight />
                        </Link>
                      </li>

                      <li className="page-item">
                        <Link
                          className={
                            currentPage === paginantionData?.total_pages
                              ? "page-link disabled"
                              : "page-link"
                          }
                          onClick={gotoLastPage}
                          to="#"
                        >
                          <FiChevronRight />
                        </Link>
                      </li>
                    </ul>
                  </nav>
                )}
              </div>
            </div>
          </div>
          {isLoading && (
            <h2 className="no-data-invoices">
              {configData?.MSP_INFORMATION_TEXT?.LOADING_INVOICES}
              <div className="loader"></div>
            </h2>
          )}
          {!isLoading && invoicesData?.length !== 0 ? (
            <div className="credits-invoice-content">
              {invoicesData.map((invoiceDetails, index) => {
                const multipleInvoiceObject = JSON.stringify({
                  ...invoiceDetails,
                });
                return (
                  <div className="invoice-info" key={index}>
                    <div className="info-hdr">
                      <div className="input-check">
                        <input
                          type="checkbox"
                          name={multipleInvoiceObject}
                          onChange={(e) => {
                            multipleInvoicesHandler(
                              e.target.name,
                              e.target.checked
                            );
                          }}
                          disabled={
                            invoiceDetails?.invoiceBalance === "0.00" ||
                            !invoiceDetails?.debitMemoNumber ||
                            (totalApplyAmmount > totalcreditsammount &&
                              !selectedOpenInvoices?.find(
                                (data) =>
                                  data?.invoiceNumber ===
                                  invoiceDetails?.invoiceNumber
                              ))
                          }
                        />
                      </div>
                      <div className="contract-cnt">
                        <span className="contract-num">
                          {invoiceDetails?.invoiceNumber}
                        </span>
                        <span className="single-tail">
                          {invoiceDetails?.tailDetails?.length === 1 ? (
                            invoiceDetails?.tailDetails[0].aircraftTailNumber &&
                            `Tail No. ${invoiceDetails?.tailDetails[0].aircraftTailNumber}`
                          ) : (
                            <span
                              className="multiple-tail"
                              onClick={() => openTailDetails(invoiceDetails)}
                            >
                              Multiple Tails <FaListUl className="mtail-icon" />
                            </span>
                          )}
                        </span>
                      </div>
                      <div className="invoice-amt">
                        <span className="open-value">
                          <span>
                            {invoiceDetails?.isCreditFlag === "Y" &&
                              invoiceDetails?.invoiceBalance !== "0.00" && (
                                <strong>-</strong>
                              )}
                            {invoiceDetails?.invoiceBalance} USD
                          </span>
                          <span>{invoiceDetails?.status}</span>
                        </span>
                      </div>
                      <div className="info-ftr">
                        <div>
                          <span>Contract/SAP Invoice/Reference No.</span>
                          <span className="contract-no">
                            <Link className="contarct-lable" to="#">
                              {invoiceDetails?.contractNumber}
                            </Link>
                            {invoiceDetails?.debitMemoNumber &&
                              `/${invoiceDetails?.debitMemoNumber}`}
                          </span>
                        </div>
                        <div>
                          <span>Period</span>
                          <span>{invoiceDetails?.invoicePeriod}</span>
                        </div>
                        <div>
                          <span>Type</span>
                          <span>{invoiceDetails?.invoiceType}</span>
                        </div>
                        <div>
                          <span>Account</span>
                          <span>{invoiceDetails?.accountName}</span>
                        </div>

                        <div>
                          <span>Invoice Total</span>
                          <span>
                            <span>{invoiceDetails?.invoiceAmount} USD</span>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="no-data-invoices red">
              <h6>{invoicesError ? invoicesError : "No Data Available"}</h6>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-white"
            data-test="modal-cancel"
            onClick={closePopupHandler}
          >
            Go Back
          </Button>
          <Button
            className={
              selectedOpenInvoices?.length !== 0
                ? "btn btn-blue"
                : "btn btn-blue disabled"
            }
            onClick={openAppliedCreditsModal}
            disabled={totalApplyAmmount > totalcreditsammount ? true : false}
          >
            Continue
          </Button>
        </Modal.Footer>
      </Modal>

      {totalCreditsError && (
        <CreditsErrorPopup
          show={totalCreditsError}
          onHide={() => setTotalCreditsError(false)}
        />
      )}

      {showAppliedCreditsModal && (
        <AppliedUbCreditsModal
          selectedcreditsinvoices={props?.selectedcreditsinvoices}
          totalcreditsammount={props?.totalcreditsammount}
          selectedopeninvoices={selectedOpenInvoices}
          totalapplyammount={totalApplyAmmount}
          show={showAppliedCreditsModal}
          onHide={closeAppliedCreditsModalHandler}
          backdrop="static"
        />
      )}
    </React.Fragment>
  );
};

export default ApplyCreditsInvoices;
