import React from "react";
import "../DisplayPdf/DisplayPdf.scss";

const DisplayPopUpPdf = React.forwardRef((props, ref) => {
  //Modal Functionality Ends
  return (
    <div className="rc-results-wrapper11" ref={ref}>
      <div id="rc-resultsPdf-page1" className="rc-results"></div>
    </div>
  );
});
export default DisplayPopUpPdf;
