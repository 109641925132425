import React from "react";

function NoContractsMessage(props) {
  return (
    <React.Fragment>
      <h2 className="no-data-contracts">
        {props.employee ? <p>Please recheck your contract or sold-to number.</p> : <p>We cannot display your contract or contracts right now. Please try again
        in a few hours. Sorry for the inconvenience.</p>}
      </h2>

    </React.Fragment>
  );
}

export default NoContractsMessage;
