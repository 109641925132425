import React, { useEffect, useState, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { fetchCreditPdfSrv } from "../../../services/credits.service";
import "./CreditInvoice.scss";
import fileDownload from "js-file-download";
import { Document, Page,pdfjs} from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const CreditInvoice = (props) => {
  const [load, setLoad] = useState(false);
  const [pdf, setPdf] = useState("");
  const [pdfResponse, setPdfResponse] = useState("");
  const pageNumber = 1;
  const [errorDescription, setErrorDescription] = useState("");

  const creditInvoiceComponent = useRef(true);
  const componentRef = useRef();
  const bodyContent = {
    creditInvoiceRequest: {
      cmrNumber: props?.creditInvoiceNumber,
    },
  };

  const getCreditInvoicePdf = async () => {
    setLoad(true);
    const payload = JSON.stringify(bodyContent);
    await fetchCreditPdfSrv(payload).then(async (res) => {
      if (res?.status === 200) {
        setPdfResponse(res?.data);
        const blob = new Blob([res?.data], {
          type: "application/pdf",
        });
        const fileUrl = window.URL.createObjectURL(blob);
        setPdf(fileUrl);
      } else {
        const errorResponse = await res?.data?.text();
        const errorData = JSON.parse(errorResponse);
        setErrorDescription(errorData?.errorDescription);
      }
    });
    setLoad(false);
  };
  useEffect(() => {
    if (creditInvoiceComponent?.current === true) {
      creditInvoiceComponent.current = false;
      getCreditInvoicePdf();
    }
    /*eslint-disable */
  }, []);

  const downloadPdf = async () => {
    const fileName = `Credit_${props.creditInvoiceNumber}.pdf`
    fileDownload(pdfResponse, fileName);
  };

  return (
    <React.Fragment>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton className="modal-h">
          <Modal.Title className="title-modal credit-inv">Credit Invoice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!errorDescription && <div className="re-pdt-cnt">
            {load && (
              <div style={{ textAlign: "center" }}>
                <div className="loader"></div>
              </div>
            )}
            {!load && !errorDescription && (
              <div ref={componentRef}>
                <Document size="A4" file={pdf}>
                  <Page
                    pageNumber={pageNumber}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    customTextRenderer={false}
                  />
                </Document>
              </div>
            )}
          </div>}
          {errorDescription && (
            <h2 className="no-data-contracts">
              {errorDescription && !load ? errorDescription : ""}
            </h2>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!errorDescription && !load && (
            <>
              <Button
                variant="secondary"
                className="modal-Continue btn-white"
                onClick={downloadPdf}
              >
                Download
              </Button>
            </>
          )}
          <Button
            variant="secondary"
            className="modal-Continue btn-white"
            onClick={() => props.onHide()}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default CreditInvoice;