import React, { useState } from "react";
import { useSelector } from "react-redux";
import "../CancelInvoicePopup/CancelInvoicePopup.scss";
import { invoiceCancelSrv } from "../../../../services/invoice.service";
import { Modal, Button } from "react-bootstrap";
import ProgressBar from "react-bootstrap/ProgressBar";
import ReportUsagePopup from "../ContractUsagePopup/ContractUsagePopup";

const CancelInvoicePopup = (props) => {
  const { configData } = useSelector((state) => state.config);
  const [loading, setLoading] = useState(false);
  const [invoiceCancelSuccess, setInvoiceCancelSuccess] = useState(false);
  const [invoiceCancelErrorMessage, setInvoiceCancelErrorMessage] =
    useState("");
  const [invoiceCancelSuccessMessage, setInvoiceCancelSuccessMessage] =
    useState("");
  const [invoiceCancelError, setInvoiceCancelError] = useState("");
  const [invoiceCancelled, setInvoiceCancelled] = useState(false);
  const [showContracts, setShowContracts] = useState(false);
  const { invoiceDetails } = props;

  const invoiceCancelPayload = {
    cancelInvoiceRequest: {
      dmrNumber: invoiceDetails?.invoiceNumber,
      dmNumber: invoiceDetails?.debitMemoNumber
        ? parseInt(invoiceDetails?.debitMemoNumber)
        : "",
      resetDmr: "",
      invoicePeriod: "",
    },
  };
  //trigerred on click of confirm cancellation
  const handleInvoiceCancellation = async () => {
    setInvoiceCancelled(true);
    setLoading(true);
    const payload = JSON.stringify(invoiceCancelPayload);
    await invoiceCancelSrv(payload).then((res) => {
      if (res?.status === 200) {
        setInvoiceCancelSuccess(true);
        setInvoiceCancelSuccessMessage(res?.data?.data?.message);
      } else {
        setInvoiceCancelSuccess(false);
        setInvoiceCancelErrorMessage(res?.data?.message);
        setInvoiceCancelError(res?.data?.errorDescription);
      }
      setLoading(false);
    });
  };

  const contractSubmissionHandler = () => {
    setShowContracts(true);
  };

  const closeContractsHandler = () => {
    setShowContracts(false);
  };

  return (
    <>
      <Modal
        show={props.show}
        aria-labelledby="cancel invoice"
        onHide={props.onCloseInvoice}
        contentClassName={
          showContracts ? "cancelinv-cnt-display" : "cancelinv-cnt"
        }
        centered
        backdrop="static"
      >
        {invoiceCancelSuccess || loading ? (
          <Modal.Header className="modal-h">
            <Modal.Title className="title-modal">
              {!invoiceCancelled &&
                `Are you sure you want to cancel the below invoice ?`}
            </Modal.Title>
          </Modal.Header>
        ) : (
          <Modal.Header closeButton className="modal-h">
            <Modal.Title className="title-modal">
              {!invoiceCancelled &&
                `Are you sure you want to cancel the below invoice ?`}
            </Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body>
          {!invoiceCancelled ? (
            <div className="cancelinv-cnt">
              <div class="cancelinv-tbl-header">
                <div>Contract</div>
                <div>Invoice</div>
                <div>Period</div>
                <div>Type</div>
                <div>Account</div>
                <div>Amount</div>
              </div>
              <div>
                <div className="cancelinv-tbl">
                  <div className="cancelinv-tbl-row">
                    <div>{invoiceDetails?.contractNumber}</div>
                    <div>{invoiceDetails?.invoiceNumber}</div>
                    <div>{invoiceDetails?.invoicePeriod}</div>
                    <div>{invoiceDetails?.invoiceType}</div>
                    <div>{invoiceDetails?.accountName}</div>
                    <div>{invoiceDetails?.invoiceAmount} USD</div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="loading-inv">
              {loading ? (
                <div className="loading-msg">
                  <h3>
                    {configData?.MSP_INFORMATION_TEXT?.LOADING_CANCEL_INVOICE}
                  </h3>
                  <ProgressBar now={50} />
                </div>
              ) : (
                <span>
                  {invoiceCancelSuccessMessage
                    ? `${invoiceCancelSuccessMessage}, Please click on continue to report hours`
                    : invoiceCancelErrorMessage
                    ? invoiceCancelErrorMessage
                    : invoiceCancelError}
                </span>
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!invoiceCancelSuccess && !loading && (
            <Button
              className="btn btn-white"
              data-test="modal-cancel"
              onClick={props.onCloseInvoice}
            >
              Close
            </Button>
          )}
          {!invoiceCancelled && (
            <Button
              className="btn btn-blue"
              data-test="modal-cancel"
              onClick={handleInvoiceCancellation}
            >
              Confirm Cancellation
            </Button>
          )}

          {invoiceCancelSuccess && !loading && invoiceCancelled && (
            <Button
              className="btn btn-blue"
              onClick={contractSubmissionHandler}
            >
              Continue
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      {showContracts && (
        <ReportUsagePopup
          showContract={showContracts}
          contractNumber={invoiceDetails?.contractNumber}
          onClose={closeContractsHandler}
          onCloseInvoice={props.onCloseInvoice}
        />
      )}
    </>
  );
};

export default CancelInvoicePopup;
