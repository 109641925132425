const resources = {
  DEV: {
    hostUrl: "devmspaerospace.honeywell.com",
    loginUrl: "https://dpublish-aerospacebt.aws.aem.honeywell.com/pif/cwa/oauth/request/j_security_check?resource=https://devmspaerospace.honeywell.com/&appId=226",
    supportUrl: "https://dpublish-aerospacebt.aws.aem.honeywell.com/us/en/support-and-resources/contact-us/forms/website-issues/other-portal-issues",
    appId:226
  },
  QA: {
    hostUrl: "qamspaerospace.honeywell.com",
    loginUrl: "https://qpublish-aerospacebt.aws.aem.honeywell.com/pif/cwa/oauth/request/j_security_check?resource=https://qamspaerospace.honeywell.com/&appId=262",
    supportUrl: "https://qpublish-aerospacebt.aws.aem.honeywell.com/us/en/support-and-resources/contact-us/forms/website-issues/other-portal-issues",
    appId:262
  },
  STAGE: {
    hostUrl: "stagemspaerospace.honeywell.com",
    loginUrl: "https://aerospacebt.stage.honeywell.com/pif/cwa/oauth/request/j_security_check?resource=https://stagemspaerospace.honeywell.com/&appId=235",
    supportUrl: "https://aerospacebt.stage.honeywell.com/us/en/support-and-resources/contact-us/forms/website-issues/other-portal-issues",
    appId:235
  },
  PROD: {
    hostUrl: "mspaerospace.honeywell.com",
    loginUrl: "https://aerospace.honeywell.com/pif/cwa/oauth/request/j_security_check?resource=https://mspaerospace.honeywell.com/&appId=75",
    supportUrl: "https://aerospace.honeywell.com/us/en/support-and-resources/contact-us/forms/website-issues/other-portal-issues",
    appId:75
  },
};

module.exports = { resources };