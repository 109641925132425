import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import DisplayPopUpPdf from "./DisplayPdf/DisplayPopUpPdf";
import logoIcon from "../../../assets/images/hon-logo.png";
import ProgressBar from "react-bootstrap/ProgressBar";
import { invoiceContractDetailsList } from "../../../services/invoice.service";
import "../ContractPdfLink/ContractPdfLink.scss";
import {
  fetchDocumentEntitiesSrv,
  fetchContractPdfSrv,
} from "../../../services/contract.service";
import { Link } from "react-router-dom";
import PdfIcon from "../../../assets/images/pdf.png";
import { useSelector } from "react-redux";
import Config from '../../../services/config'
import C from '../../../constants/constant'

const ContractPdfLink = (props) => {
  const componentRef = useRef();
  const [load, setLoading] = useState(false);
  const [displayData, setDisplayData] = useState([]);
  const [loadingDocument, setLoadingDocument] = useState(false);
  // const [errordiscription, setError] = useState("");
  const [documentData, setDocumentData] = useState([]);
  const [dataObject, setdataObject] = useState([{}]);
  const [loadingContractPdf, setLoadingContractPdf] = useState(false);
  const [contractPdfError, setContractPdfError] = useState("");
  const [, setPdf] = useState([]);
  const { configData } = useSelector((state) => state.config);

  const contractComponentRef = useRef(true);
  const tempArray = [];

  let contractError =
    contractPdfError?.data?.errorCode === 504 ||
    contractPdfError?.data?.errorCode === 500;

  const getContractPdf = async (id, len) => {
    setLoadingContractPdf(true);
    await fetchContractPdfSrv(id).then((res) => {
      const blob = new Blob([res?.data], {
        type: "application/pdf",
      });
      const fileUrl = URL.createObjectURL(blob);
      if (len >= 1) {
        tempArray.push({ id: id, url: fileUrl });
      }
      setdataObject(tempArray);
      setPdf(fileUrl);
      setLoadingContractPdf(false);
    });
    setLoadingContractPdf(false);
  };

  const body = {
    contractNumber: props.constractnumberdata,
    soldToNumber: props.soldToNummber,
    documentType: "signed",
  };

  const getDocumentsDetails = async () => {
    setLoadingDocument(true);
    const payload = JSON.stringify(body);
    await fetchDocumentEntitiesSrv(payload).then((res) => {
      if (res?.status === 200) {
        for (let i = 0; i < res?.data?.data?.documentList.length; i++) {
          getContractPdf(
            res?.data?.data?.documentList[i]?.id,
            res?.data?.data?.documentList.length
          );
        }
        setDocumentData(res?.data?.data?.documentList);
        setLoadingDocument(false);
        // setError("");
      } else if (res?.status === 400) {
        setLoadingDocument(false);
        // setError(res?.data?.error?.errorDescription);
      } else {
        setLoadingDocument(false);
        // setError("No Data Available");
      }
    });
  };

  useEffect(() => {
    if (
      props.data !== "" &&
      props.show === true &&
      contractComponentRef.current
    ) {
      contractComponentRef.current = false;
      getDisplayInvoices();
      getDocumentsDetails();
    }
    /*eslint-disable */
  }, [props.show]);

  const invoiceDetailsBody = {
    contractDetailsRequest: {
      contractNumber: props.constractnumberdata,
      mode: 1,
      requestFrom: "",
    },
  };

  const getDisplayInvoices = async () => {
    setLoading(true);
    const payload = JSON.stringify(invoiceDetailsBody);
    await invoiceContractDetailsList(payload).then((res) => {
      if (res?.status === 200) {
        setDisplayData(res?.data?.data?.contractDetailsResponse);

        setLoading(false);
      } else {
        setLoading(false);
        setDisplayData([]);
        setContractPdfError(res);
      }
    });
  };

  const handleClick = () => {
    let pdfContentPage1 = window.document.getElementById("rc-resultsPdf-page1");
    pdfContentPage1.style.display = "block";
    pdfContentPage1.style.marginTop = "30px";
    printPDF();
    pdfContentPage1.style.display = "none";
    pdfContentPage1.style.marginTop = "30px";
  };

  const downloadPdf = (pdfId, pdfName) => {
    const link = document.createElement("a");
    const pdfData = dataObject.find(
      (documentItem) => documentItem.id === pdfId
    );
    console.log(pdfData);
    link.setAttribute("download", pdfName);
    link.setAttribute("href", pdfData.url);
    link.click();

    // // Append to html link element page
    // document.body.appendChild(link);
    // for (let i = 0; i < dataObject.length; i++) {
    //   link.setAttribute("download", `contract.pdf`);

    //   link.setAttribute("href", dataObject[i]);
    //   link.click();
    // }

    // Start download
    //link.click();

    // Clean up and remove the link
    // link.parentNode.removeChild(link);
  };

  //for cloning html
  const printPDF = () => {
    const pdfContentPage1 = window.document.getElementById(
      "rc-resultsPdf-page1"
    );
    if (
      pdfContentPage1 &&
      pdfContentPage1.getElementsByClassName("pdf-header").length === 0
    ) {
      const pdfSec1 = window.document
        .getElementsByClassName("pdf-header")[0]
        .cloneNode(true);
      const pdfSec2 = window.document
        .getElementsByClassName("pdf-body")[0]
        .cloneNode(true);
      if (pdfSec1 && pdfSec2) {
        pdfContentPage1?.appendChild(pdfSec1);
        pdfContentPage1?.appendChild(pdfSec2);
        handlePrint();
        pdfContentPage1?.removeChild(pdfSec1);
        pdfContentPage1?.removeChild(pdfSec2);
      }
    }
    if (pdfContentPage1) {
      pdfContentPage1.style.display = "none";
      pdfContentPage1.style.marginTop = "0px";
    }
  };

  const downloadPdfHandler = (event) => {
    const pdfDocumentData = JSON.parse(event.target.name);
    const pdfId = pdfDocumentData.id;
    const pdfName = pdfDocumentData.name;
    downloadPdf(pdfId, pdfName);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  //end display

  const pdfbody =
    displayData.length !== 0 ? (
      <>
        {
          <div className="re-pdt-cnt invdetail-cnt">
            <div className="pdf-header">
              <div className="hw-pdf-logo">
                <Link to="#">
                  <img src={logoIcon} alt="honeywellLogo" />
                </Link>
                <br />
                <span>Contract Details</span>
                <br />
              </div>
            </div>
            <hr />
            <div className="pdf-body">
              <div className="display-header">
                <span className="invoice-deatil-header">
                  Contract Information
                </span>
              </div>
              <div className="invoice-body invoice-data">
                <div className="customer-info">
                  <div>
                    <strong>Contract No.:</strong>{" "}
                    <span>
                      {displayData.length !== 0
                        ? displayData?.contractNumber
                        : "No data available"}
                    </span>
                  </div>
                  <div>
                    <strong>Effective Date:</strong>{" "}
                    <span>
                      {displayData.length !== 0
                        ? displayData?.effectiveDate
                        : "No data available"}
                    </span>
                  </div>
                  <div>
                    <strong>Status:</strong>{" "}
                    <span>
                      {displayData.length !== 0
                        ? displayData?.contractStatus
                        : "No data available"}
                    </span>
                  </div>
                  <div className="invoice-pdf">
                    {loadingDocument ||
                      (loadingContractPdf && <div className="loader"></div>)}
                    {!loadingDocument &&
                      !loadingContractPdf &&
                      documentData.map((document) => {
                        return (
                          <>
                            <a
                              name={JSON.stringify({
                                id: document.id,
                                name: document.name,
                              })}
                              onClick={downloadPdfHandler}
                            >
                              <img src={PdfIcon} alt="download" />
                              {document.name}
                            </a>
                            <br />
                          </>
                        );
                      })}
                  </div>
                </div>
                <div className="customer-info">
                  <div>
                    <strong>Legacy Contract No.:</strong>
                    <span>
                      {displayData?.legacyContractNumber
                        ? displayData?.legacyContractNumber
                        : "-"}
                    </span>
                  </div>
                  <div>
                    <strong>Expiration Date:</strong>
                    <span>
                      {displayData?.expirationDate
                        ? displayData?.expirationDate
                        : "-"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="display-header1">
                <strong className="invoice-deatil-header">
                  Account Information
                </strong>
              </div>
              <div className="invoice-body invoice-data p-btm-20">
                <div className="customer-info">
                  <div>
                    <strong>Account :</strong>{" "}
                    <span>
                      {displayData?.accountName
                        ? displayData?.accountName
                        : "-"}
                    </span>
                  </div>
                  <div>
                    <strong>Address :</strong>
                    <span>
                      {displayData?.address1 ? displayData?.address1 : "-"},
                      {displayData?.city ? displayData?.city : "-"},
                      {displayData?.state ? displayData?.state : "-"},
                      {displayData?.zip ? displayData?.zip : "-"},
                      {displayData?.country ? displayData?.country : "-"}
                    </span>
                  </div>
                  <div>
                    <strong> Phone : </strong>
                    <span>
                      {displayData?.accountPhone
                        ? displayData?.accountPhone
                        : "-"}
                    </span>
                  </div>
                  <div>
                    <strong> Fax:</strong>
                    <span>
                      {displayData?.accountFax ? displayData?.accountFax : "-"}
                    </span>
                  </div>
                </div>
                <div className="customer-info">
                  <div>
                    <strong>Admin:</strong>{" "}
                    <span>
                      {displayData?.adminName ? displayData?.adminName : "-"}
                    </span>
                  </div>

                  <div>
                    <strong>Phone: </strong>{" "}
                    <span>
                      {displayData?.adminPhone
                        ? displayData?.adminPhone
                        : "No data available"}
                    </span>
                  </div>

                  <div>
                    <strong>Fax:</strong>{" "}
                    <span>
                      {displayData?.adminFax
                        ? displayData?.adminFax
                        : "No data available"}
                    </span>
                  </div>

                  <div>
                    <strong>Email:</strong>{" "}
                    <span>
                      {displayData?.adminEmail
                        ? displayData?.adminEmail
                        : "No data available"}
                    </span>
                  </div>
                </div>
              </div>
              <div className="display-header1">
                <strong className="invoice-deatil-header">
                  Covered Platforms
                </strong>
              </div>
              <table className="product-table">
                <thead>
                  <tr>
                    <th>Platform S/N</th>
                    <th>Platform Name</th>
                    <th>Registration No.</th>
                    <th>Last Resported TSN</th>
                    <th>Last TSN Date</th>
                    <th>Operator Account</th>
                  </tr>
                </thead>
                {displayData.length !== 0 ? (
                  <tbody>
                    {displayData?.platformDetails?.map((products) => (
                      <>
                        <tr>
                          <td>
                            {products?.platformSerialNumber
                              ? products?.platformSerialNumber
                              : "-"}
                          </td>
                          <td>
                            {products?.platformName
                              ? products?.platformName
                              : "-"}{" "}
                          </td>
                          <td>
                            {products?.registrationNumber
                              ? products?.registrationNumber
                              : "-"}
                          </td>
                          <td>
                            {products?.lastReportedTsn
                              ? products?.lastReportedTsn
                              : "-"}{" "}
                          </td>
                          <td>
                            {products?.lastTsnDate
                              ? products?.lastTsnDate
                              : "-"}
                          </td>
                          <td>
                            {products?.operatorAccount
                              ? products?.operatorAccount
                              : "-"}
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                ) : (
                  <p className="text-center">No data available.</p>
                )}
              </table>

              <div className="display-header1">
                <strong className="invoice-deatil-header">
                  Covered Materials
                </strong>
              </div>
              <div className="display-header1 contract-subhdr">
                <strong className="invoice-deatil-header">
                  Covered Equipment - MSP
                </strong>
              </div>
              <div className="tbl-cnt">
                <table className="product-table">
                  <thead>
                    <tr>
                      <th>Service Program</th>
                      <th>Material SN</th>
                      <th>PartNo./Material</th>
                      <th>Material Description</th>
                      <th>Begin Date</th>
                      <th>End Date</th>
                      <th>Last Reported TSN</th>
                      <th>Last TSN Date</th>
                    </tr>
                  </thead>
                  {displayData.length !== 0 ? (
                    <tbody>
                      {displayData?.productDetails?.map((products) => (
                        <>
                          <tr>
                            <td>
                              {products?.programCode
                                ? products?.programCode
                                : "-"}
                            </td>
                            <td>
                              {products?.productSerialNumber
                                ? products?.productSerialNumber
                                : "-"}
                            </td>
                            <td>
                              {products?.productName
                                ? products?.productName
                                : "-"}
                            </td>
                            <td>
                              {products?.description
                                ? products?.description
                                : "-"}
                            </td>
                            <td>
                              {products?.beginDate ? products?.beginDate : "-"}
                            </td>
                            <td>
                              {products?.endDate ? products?.endDate : "-"}
                            </td>
                            <td>
                              {products?.lastReportedTsn
                                ? products?.lastReportedTsn
                                : "-"}
                            </td>
                            <td>
                              {products?.tsnDate ? products?.tsnDate : "-"}
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  ) : (
                    <p className="text-center">No data.</p>
                  )}
                </table>
              </div>
              <div className="display-header1 m-top-10">
                <strong className="invoice-deatil-header">Notes</strong>
              </div>

              <div className="customer-info notes">
                <p>No records found</p>
              </div>
            </div>
          </div>
        }
      </>
    ) : (
      <>
        {contractPdfError?.data?.errorDescription ? (
          <h6 className="no-data-contracts red">
            {contractPdfError?.data?.errorDescription}
            {contractError && (
               <a href={configData?.MSP_TOKEN?.MSP_DOMAIN_NAME?Config?.resources[configData?.MSP_TOKEN?.MSP_DOMAIN_NAME]?.supportUrl:C?.MSP_SUPPORT_URL} target="_blank">
               Other Portal Issues (honeywell.com)
             </a>
            )}
          </h6>
        ) : (
          <h2 className="no-data-contracts">No Data Available</h2>
        )}
      </>
    );

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="choose contract"
        centered
        show={props.show}
      >
        <Modal.Header closeButton className="modal-h" onClick={props.onHide}>
          <Modal.Title className="title-modal">
            <span className="invoice-num">Contract Details</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {load && (
            <div className="loading-msg">
              <h3>Please wait while we update your contract details</h3>
              <ProgressBar now={50} />
            </div>
          )}
          {!load && pdfbody}
          <DisplayPopUpPdf ref={componentRef} />
        </Modal.Body>
        <Modal.Footer>
          {!load && displayData.length !== 0 && (
            <div>
              <button className="btn btn-white" onClick={handleClick}>
                PRINT
              </button>
              <button onClick={props.onHide} className="btn btn-white">
                close
              </button>
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ContractPdfLink;
