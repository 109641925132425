import React from "react";
import "./HeaderLeft.scss";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {FeatureFlags} from '../../common/FeatureFlags/FeatureFlags'

const HeaderLeft = (props) => {
  //Reading Config,Token from the store
  const { configData, token } = useSelector((state) => state.config);

  const navigatePath = (event) => {
    if (!token?.cookies?.hasOwnProperty(configData?.MSP_TOKEN?.TOKEN)) {
      event.preventDefault();
    }
  };

  const viewInvoiceOldData = FeatureFlags?.find(
    (feature) => feature.name === "ViewInvoicesOld"
  );

  const contractsData = FeatureFlags?.find((feature)=>feature.name === "Contracts")
  return (
    <div className="hdr-inr">
      <Row>
        <Col md={8}>
          <span className="hdr-sub">PRODUCTS AND SERVICES /</span>
          <h1>Maintenance Service Plans</h1>
        </Col>
        <Col md={4}>
          <div className="report-invoice">
            <a
              href={`${
                token?.cookies?.hasOwnProperty(configData?.MSP_TOKEN?.TOKEN)
                  ? configData?.MSP_TOKEN?.AVIONIC_DASHBOARD_URL
                  : "#"
              }`}
              onClick={navigatePath}
            >
              Avionic Dashboard
            </a>
            <Link to="/" onClick={() => props.onButtonClick()}>
              REPORT HOURS
            </Link>
            {viewInvoiceOldData?.active ? (
              <a href={configData?.MSP_TOKEN?.VIEW_INVOICE_URL}>
                VIEW INVOICES
              </a>
            ) : (
              <Link to="/invoices" onClick={() => props.onButtonClick()}>
                VIEW INVOICES
              </Link>
            )}
             {contractsData?.active && <Link to="/contracts" onClick={() => props.onButtonClick()}>
              Contracts
            </Link>}
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default HeaderLeft;