import React from "react";
import "./TransferProgressBar.scss";
const Step = (props) => {
  return (
    <li
      className={
        props.completed ? (props?.index === 2 ? "completed" : "complete") : ""
      }
    >
      <span>{props.label}</span>
    </li>
  );
};

export default Step;
