import React from "react";
import { Row } from "react-bootstrap";
import "./NoDataAvailable.scss";

const NoDataAvailable = (props) => {
  return (
    <Row>
      <h2 className="no-data">
        If you are seeing this message, please click on "Sign In" at the top of the page.      
      </h2>
    </Row>
  );
};

export default NoDataAvailable;
