import React from "react";
import { Button, Modal } from "react-bootstrap";
import "../../InvoicesSuccessModel/ConfirmationModal/ConfirmationModal.scss";

const ConfirmationModal = (props) => {
  const { checked } = props;
  const handleSubmit = () => {
    props.closeAcceptModal();
    props.handleShow();
  };

  return (
    <Modal
      class="accept-modal-container"
      {...props}
      backdrop="static"
      keyboard={false}
      aria-labelledby="contained-modal-accept"
      centered
    >
      <Modal.Header closeButton className="modal-h">
        <Modal.Title className="title-modal">
          <h2>
            <strong>Confirmation</strong>
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body accept-modal">
        <div className="accept-modal-body">
          <div className="input-check">
            <input
              className="checkbox"
              type="checkbox"
              id="checkbox_confirm"
              onChange={props.checkboxHandler}
            />
            <label htmlFor="checkbox_confirm">
              By clicking on "Submit & Generate Invoice" you are agreeing to
              submit the Reported Hours.Click on "Cancel" to Revalidate the
              Reported Hours
            </label>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-white"
          data-test="modal-cancel"
          onClick={props.closeAcceptModal}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          className="btn-blue btn"
          data-test="submit-invoice"
          disabled={!checked}
        >
          SUBMIT & GENERATE INVOICE
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
