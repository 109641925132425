import { Button, Modal } from "react-bootstrap";
import React, { useState } from "react";
import "./RateEscalationPopup.scss";
import { useSelector } from "react-redux";
import RateEscalationPdfPopup from "../RateEscalationPdfPopup/RateEscalationPdfPopup";
import Loader from "../../common/Loader/Loader";

const RateEscalationPopup = (props) => {
  const contarct_data = useSelector((state) => state.contracts);
  const [contract_number, setContractNumber] = useState("");
  const [modalShow, setModalShow] = React.useState(false);
  const [showButton, setShowButton] = useState(true);
  const loadContractDetailsPdf = (e) => {
    setContractNumber(e.target.value);
    setShowButton(false);
  };

  const showContarctPdf = () => {
    if (contract_number !== "") {
      props.onHide();
      setModalShow(true);
      setShowButton(true);
    }
  };

  const hidePopupHandler = () => {
    props.onHide();
    setShowButton(true);
  };
  const contractPdfPopup = (
    <React.Fragment>
      <RateEscalationPdfPopup
        constractnumberdata={contract_number}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {(
        <>
          {" "}
          <Modal
            {...props}
            size="lg"
            aria-labelledby="choose contract"
            centered
          >
            <Modal.Header
              closeButton
              className="modal-h"
              onHide={hidePopupHandler}
            >
              <Modal.Title className="title-modal">
                <h2>Choose a contract to view rate escalation</h2>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h6 className="contract-hdr">Contract</h6>
              {(contarct_data?.contracts === undefined || contarct_data?.contracts.length<=0) &&
                !contarct_data.isContractsLoading && (
                  <h2 className="no-data-contracts">No Data Available</h2>
                )}
              {contarct_data.isContractsLoading && <Loader />}
              <div className="contract-list-cnt">
                {!contarct_data?.isContractsLoading &&
                  contarct_data?.contracts?.map((data, index) => {
                    return (
                      <div className="contract-num" key={index}>
                        <label className="input-rdb">
                          <input
                            type="radio"
                            name="vote"
                            id={index}
                            value={data.contractNumber}
                            key={index}
                            onChange={loadContractDetailsPdf}
                          />{" "}
                          <a>{data.contractNumber}</a>
                        </label>
                      </div>
                    );
                  })}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary"
                className="modal-Continue btn-blue"
                onClick={showContarctPdf}
                disabled={showButton}
              >
                <div className="modal-Continue-text">Continue</div>
              </Button>
            </Modal.Footer>
          </Modal>
          {contractPdfPopup}
        </>
      )}
    </React.Fragment>
  );
};

export default RateEscalationPopup;
