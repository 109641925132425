import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import "./ReportUsage.scss";
import UploadUsagePopup from "../UploadUsagePopup/UploadUsagePopup";
import { Row, Col } from "react-bootstrap";
import ReportUsageTable from "../ReportUsageTable/ReportUsageTable";
import ReportUsageSearch from "../../common/ReportUsageSearch/ReportUsageSearch";
import AdminPopup from "../AdminPopup/AdminPopup";
import { fetchContractListSrv } from "../../../services/contract.service";
import WelcomePopup from "../WelComePopUp/WelcomePopup";
import Feature from "../../common/FeatureFlags/Feature/Feature";
import NoContractsMessage from "../../common/NoContractsMessage/NoContractsMessage";
import { useNavigate } from "react-router-dom";

const ReportUsage = (props) => {
  const [searchActive, setSearchActive] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [employeeSearchFlag, setEmployeeSearchFlag] = useState(true);
  const { isHoneywellEmployee, isLoadingToken, tokenValid } = useSelector(
    (state) => state.config
  );

  const [contractSearchTerm, setContractSearchTerm] = useState("");
  const [soldToSearchTerm, setSoldToSearchTerm] = useState([]);
  const [adminSearchFlag, setAdminSearchFlag] = useState(false);
  //useselectorr for modalwindow welcome
  const selector = useSelector((state) => state.welcomePopUp);

  const { onPopupDisplay } = props;

  const reportUsageComponent = useRef(true);
  const navigate = useNavigate();

  const submitDataHandler = (searchContract, searchSoldTo, searchFlagData) => {
    setContractSearchTerm(searchContract);
    setSoldToSearchTerm(searchSoldTo);
    setAdminSearchFlag(true);
    props.onAdminSubmit(searchContract, searchSoldTo);
  };

  const [contracts, setContracts] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [onLoad, setOnLoad] = useState(false);
  let soldToTermLength = soldToSearchTerm.length
  useEffect(() => {
    if (reportUsageComponent.current === true || adminSearchFlag === true) {
      reportUsageComponent.current = false;
      getContractDetails();
    }
    /*eslint-disable */
  }, [
    searchField,
    contractSearchTerm,
    soldToTermLength,
    adminSearchFlag,
    onPopupDisplay,
  ]);

  const submitContractshandler = (serachItem) => {
    setSearchField(serachItem);
    setEmployeeSearchFlag(false);
    reportUsageComponent.current = true;
  };

  const setSearchHandler = (searchdata) => {
    setSearchActive(searchdata);
  };

  const contractDetailNumber = searchField;
  let payloadSearchValue;

  if (isHoneywellEmployee && adminSearchFlag) {
    if (contractSearchTerm && soldToSearchTerm.length>0) {
      payloadSearchValue = [
        { contract_number: contractSearchTerm },
        { sold_to_number: soldToSearchTerm },
      ];
    } else if (contractSearchTerm && soldToSearchTerm.length<=0) {
      payloadSearchValue = [
        {
          contract_number: contractSearchTerm,
        },
      ];
    } else if (soldToSearchTerm.length>0 && !contractSearchTerm) {
      payloadSearchValue = [
        {
          sold_to_number: soldToSearchTerm,
        },
      ];
    }
  } else {
    payloadSearchValue = [
      {
        contract_number: contractDetailNumber,
      },
    ];
  }

  const payloadDetails = {
    query: "",
    filters: {
      all: payloadSearchValue,
    },
  };

  const getContractDetails = useCallback(async () => {
    if (
      (!isHoneywellEmployee && adminSearchFlag === false) ||
      adminSearchFlag === true
    ) {
      setIsDataLoading(true);
      const body =
        searchField || (adminSearchFlag === true && employeeSearchFlag === true)
          ? JSON.stringify(payloadDetails)
          : {};

      await fetchContractListSrv(body).then((res) => {
        if (res?.status === 200) {
          setContracts(res?.data?.data?.results);
        } else {
          setContracts([]);
        }
      });
    }
    setIsDataLoading(false);
  });

  return (
    <div className="report_usage">
      <Row>
        <h2 className="report-usage-heading col-md-9">Report Usage</h2>
        <Col md={3}>
          {/* here */}
          <UploadUsagePopup
            contracts={contracts}
            contractData={contractSearchTerm}
            soldToData={soldToSearchTerm}
          />
        </Col>
      </Row>

      {!isHoneywellEmployee && (
        <ReportUsageSearch
          placeholder="Search By Contract"
          onSubmitContracts={submitContractshandler}
          onSearch={setSearchHandler}
        />
      )}

      {isDataLoading === true && (
        <h2 className="no-data-contracts">
          Loading Contract Details....<div className="loader"></div>
        </h2>
      )}

      {!isHoneywellEmployee
        ? contracts?.length === 0 &&
          isDataLoading === false &&
          searchActive === false &&
          props.initialLoad ===
            false && (<NoContractsMessage employee={isHoneywellEmployee} />)
        : contracts?.length === 0 &&
          isDataLoading === false &&
          searchActive === false && (
            !adminSearchFlag?<h2 className="no-data-contracts"> We have not loaded this year usage period into the system, your
            invoices are available in the invoice area.</h2>:<NoContractsMessage employee={isHoneywellEmployee} />
          )}
      {contracts?.length === 0 &&
        isDataLoading === false &&
        searchActive === false &&
        props.initialLoad === true &&
        !isHoneywellEmployee &&
        navigate("/invoices")}
      {contracts?.length === 0 &&
        isDataLoading === false &&
        searchActive === true && (
          <h2 className="no-data-contracts">
            Please check the contract number and resubmit.
          </h2>
        )}
      {tokenValid && isLoadingToken === false && isHoneywellEmployee && (
        <AdminPopup onSubmitData={submitDataHandler} />
      )}
      {selector.welcomePopUp === false && !onLoad && (
        <Feature
          name="WelcomePopup"
          children={<WelcomePopup onLoad={onLoad} setOnLoad={setOnLoad} />}
        />
      )}
      {contracts?.length > 0 && isDataLoading === false && (
        <ReportUsageTable contracts={contracts} />
      )}
    </div>
  );
};

export default React.memo(ReportUsage);
